$nsw-form-chevron-down: '<svg fill="#333333" version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M16 26l16-16-4-4-12 12-12-12-4 4 16 16z"></path></svg>';
$nsw-form-tick: '<svg fill="#ffffff" version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M29.362 3.742l-18.867 19.204-7.857-7.997-2.638 2.685 9.175 9.34 1.319 1.284 1.318-1.284 20.187-20.547z"></path></svg>';

.nsw-form {
  &__select {
    @include font-size('sm');
    padding: rem(11px) rem(16px);
    border-radius: var(--nsw-border-radius);
    background-color: var(--nsw-white);
    color: var(--nsw-text-dark);
    border: 1px solid var(--nsw-grey-01);
    vertical-align: middle;
    appearance: none;
    width: 100%;
    padding-right: rem(48px);
    background: var(--nsw-white) svg-url($nsw-form-chevron-down) no-repeat right rem(16px) center;
    background-size: rem(16px) auto;
    cursor: pointer;

    &:hover:not(.nsw-multi-select__button) {
      background: svg-url($nsw-form-chevron-down) no-repeat right rem(16px) center, linear-gradient(var(--nsw-hover), var(--nsw-hover));
      background-size: rem(16px) auto, auto;
      background-color: var(--nsw-white);
    }

    &:focus {
      @include nsw-focus;

      .nsw-section--invert & {   
        @include nsw-focus($color: var(--nsw-focus-light));
      }
    }

    &:disabled {
      background: var(--nsw-off-white) svg-url($nsw-form-chevron-down) no-repeat right rem(16px) center;
      background-size: rem(16px) auto;
      border-color: var(--nsw-grey-03);
      color: var(--nsw-grey-03);
      cursor: not-allowed;
      opacity: 1;
    }

    &[aria-invalid='true'],
    &.has-error {
      border-color: var(--nsw-status-error);
      border-width: 2px;

      &:hover {
        background: var(--nsw-status-error-bg) svg-url($nsw-form-chevron-down) no-repeat right rem(16px) center;
        background-size: rem(16px) auto;
      }
    }

    option {
      background-color: var(--nsw-white);
    }
  }

  &__label {
    + .nsw-multi-select {
      margin-top: 0.35rem;
    }
  }
}

.nsw-multi-select {
  position: relative;

  &__button {
    text-align: left;
    background: var(--nsw-white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 0.5rem;

    .nsw-material-icons {
      font-size: rem(32px);
      line-height: 0.8;
      transform: rotate(0deg);
      transition: transform $nsw-transition-duration, color $nsw-transition-duration;
      width: 2rem;
    }

    &:hover {
      background: linear-gradient(var(--nsw-hover), var(--nsw-hover));
      background-color: var(--nsw-white);
    }

    &[aria-invalid='true'],
    &.has-error {
      border-color: var(--nsw-status-error);
      border-width: 2px;

      &:hover {
        background: var(--nsw-status-error-bg);
        background-size: revert;
      }
    }

    &[aria-expanded='true'] {
      + .nsw-multi-select__dropdown {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s, opacity 0.2s;
      }

      .nsw-material-icons {
        transform: rotate(180deg);
      }
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;

    .nsw-section--invert & {    
      color: var(--nsw-text-dark);
    }

    > li {
      &:first-child {
        margin-top: 0;
      }
    }

    &:not(:first-of-type) {
      padding-top: 0.25rem;
    }
    
    &:not(:last-of-type) {
      padding-bottom: 0.25rem;
    }
  }

  &__label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  &__dropdown {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    min-width: rem(200px);
    max-height: 1px;
    background-color: var(--nsw-white);
    box-shadow: var(--nsw-box-shadow);
    padding: 1rem;
    border-radius: 0.25em;
    z-index: 5;
    overflow: auto;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.2s, opacity 0.2s;

    &--right {
      right: 0;
      left: auto;
    }
    
    &--up {
      bottom: 100%;
      top: auto;
    }
  }

  &__term {
    + .nsw-multi-select__details::before {
      content: ': ';
    }
  }

  &__all {
    @include font-size('sm');
    padding: rem(4px) 0 rem(4px) rem(48px);
    min-height: rem(32px);
    display: block;
    position: relative;
    border: 0;
    background: transparent;
    appearance: none;
  
    &:focus {
      border: 0;
      outline: 0;
    }
  
    &::before {
      content: '';
      width: rem(32px);
      height: rem(32px);
      margin-right: rem(16px);
      border: 1px var(--nsw-grey-01) solid;
      border-radius: var(--nsw-border-radius);
      background-color: var(--nsw-white);
      top: 0;
      left: 0;
      display: inline-block;
      vertical-align: middle;
      position: absolute;
    }
  
    &::after {
      content: '';
      position: absolute;
      width: rem(22px);
      height: rem(22px);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: rem(16px) auto;
      left: rem(5px);
      top: rem(5px);
    }
  
    &:focus::before {
      @include nsw-focus;
    }
  
    &.active {
      &:hover::before {
        background-image: linear-gradient(var(--nsw-hover), var(--nsw-hover));
      }
  
      &::after {
        background-image: svg-url($nsw-form-tick);
        background-color: var(--nsw-brand-dark);
      }
  
      &:disabled {
        cursor: not-allowed;
  
        &::before,
        &::before {
          border-color: var(--nsw-grey-03);
          background-color: var(--nsw-off-white);
        }
  
        &::after,
        &::after {
          background-color: var(--nsw-grey-03);
        }
      }
  
      &[aria-invalid='true'],
      &.has-error {
        &:hover::before {
          background-image: linear-gradient(var(--nsw-status-error-bg), var(--nsw-status-error-bg));
        }
  
        &::before {
          border-width: 2px;
          border-color: var(--nsw-status-error);
        }
      }
    }
  }
}
