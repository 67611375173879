.nsw-footer {
  hr {
    margin: rem(16px) 0;
    height: 1px;
    border: 0;
    background-color: var(--nsw-grey-03);
  }

  a:not(.nsw-icon-button) {
    line-height: 1.5;
    padding: rem(8px) rem(24px) rem(8px) 0;
    
    @include breakpoint('md') {
      padding: rem(4px) rem(4px) rem(4px) 0;
    }
  }

  &__upper {
    background-color: var(--nsw-white);
    border-top: solid 1px var(--nsw-grey-03);
    padding-top: rem(24px);

    .nsw-container {
      padding-left: rem(8px);
      padding-right: rem(8px);
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      @include breakpoint('lg') {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &__group {
    padding: 0 rem(8px) rem(16px) rem(8px);
    flex: 1 0 auto;
    min-width: rem(256px);
    max-width: 50%;

    @include breakpoint('md') {
      flex-basis: 50%;
      min-width: auto;
    }

    @include breakpoint('lg') {
      padding: 0 rem(16px) rem(24px) rem(16px);
      flex-basis: 25%;
    }

    ul {
      @include font-size('xs');
      list-style: none;
      margin: rem(4px) 0 0 0;
      padding: 0;

      a {
        font-weight: var(--nsw-font-normal);
      }
    }

    li:first-child {
      margin-top: 0;
    }
  }

  &__heading {
    @include font-size('sm');
  }

  &__lower {
    @include font-size('xs');
    background-color: var(--nsw-grey-01);
    color: var(--nsw-text-light);
    padding: rem(16px) 0 rem(24px) 0;

    a {
      @include link-light;
    }

    p {
      margin: rem(12px) 0;
    }

    ul {
      list-style: none;
      margin: rem(16px) rem(-8px) 0 rem(-8px);
      padding: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      @include breakpoint('md') {
        margin-left: 0;
        margin-right: 0;
      }
    }

    li {
      flex: 1 0 50%;
      max-width: 50%;
      padding: 0 rem(8px);
      margin: 0 0 rem(16px) 0;

      @include breakpoint('md') {
        margin-right: rem(24px);
        padding: 0;
        flex: 0 0 auto;
        max-width: none;
      }
    }
  }

  &__links {
    @include breakpoint('md') {
      display: flex;
      justify-content: space-between;
      margin-top: rem(-16px);
    }
  }

  &__acknowledgement {
    @include font-size('sm');
    padding-bottom: rem(12px);
  }

  &__social {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 rem(-12px);
    padding: rem(8px) 0 rem(12px) 0;

    @include breakpoint('md') {
      padding-bottom: rem(8px);
    }

    a {
      margin: 0 rem(4px);
    }
  }

  &__info {
    @include breakpoint('md') {
      display: flex;
      flex-wrap: nowrap;
    }
  }

  &__copyright {
    flex: 1 1 auto;
    margin: rem(12px) 0;
  }

  &__built {
    flex: 0 0 rem(200px);
    margin: rem(12px) 0;

    @include breakpoint('md') {
      text-align: right;
    }
  }

  &--dark {
    .nsw-footer__lower {
      background-color: var(--nsw-brand-dark);
    }
  }

  &--light {
    hr {
      background-color: var(--nsw-grey-02);
    }

    .nsw-footer__lower {
      background-color: var(--nsw-brand-light);
      color: var(--nsw-text-dark);

      a {
        @include link-dark;
      }
    }
  }
}
