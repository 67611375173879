.nsw-hero-banner {
  background-color: var(--nsw-white);
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: rgba(var(--nsw-black-rgb), 0.1);
  }

  &__container {
    @include container;
  }

  &__wrapper {
    @include grid();
  }

  &__content {
    @include column-default();
    @include column(7, md);
    @include column(6, xl);

    @include breakpoint('xs') { 
      padding-top: rem(32px);
      padding-bottom: rem(32px);
    }

    @include breakpoint('md') { 
      min-height: rem(260px);
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @include breakpoint('lg') { 
      padding-top: rem(88px);
      padding-bottom: rem(88px);
    }

    .nsw-hero-banner--wide & {
      @include column(8, md);
      @include column(7, xl);      
    } 

    .nsw-hero-banner--featured & {
      @include breakpoint('md') { 
        min-height: 0;
        display: block;
      }   
    } 

    .nsw-button {
      margin: rem(24px) 0 0 0;
    }

    .nsw-intro {
      margin: rem(8px) 0 0 0;

      @include breakpoint('md') {
        margin-top: rem(16px);
      }    
    }
  }

  &__links {
    @include column-default();
    @include column(4, md);
    @include column(4, xl, 1);

    @include breakpoint('xs') { 
      margin-top: rem(-8px);
      padding-top: 0;
      padding-bottom: rem(32px);
    }

    @include breakpoint('md') { 
      margin-top: 0;
      padding-top: rem(32px);
    }

    @include breakpoint('lg') { 
      padding-top: rem(88px);
      padding-bottom: rem(88px);
    }
  }

  &__list {
    @include z-index;
    border-left: solid 2px var(--nsw-brand-accent);
    padding-left: rem(16px);
    position: relative;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      display: block;
      margin-top: rem(16px);

      &:first-child {
        margin-top: 0;
      }
    }

    a:not(.nsw-icon-button) {
      line-height: 1.5;
      padding: rem(8px) rem(24px) rem(8px) 0;
      
      @include breakpoint('md') {
        padding: rem(4px) rem(4px) rem(4px) 0;
      }
    }
  }

  &__sub-title {
    @include font-size(md);
    margin: 0 0 rem(16px) 0;
    font-weight: var(--nsw-font-bold);
  }

  &__box {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 41.66667%;
    padding-left: rem($grid-gutters*2);
    overflow: hidden;

    @include breakpoint('md') {
      display: flex;
    }

    @include breakpoint('xl') {
      width: 50%;
      padding-left: calc(var(--nsw-container-width)/12 + #{rem($grid-gutters)}*2);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__lines {
    display: none;
    background-color: var(--nsw-brand-light);
    position: relative;

    @include breakpoint('md') {
      display: block;
      width: 100%;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: rem(-16px);
      width: rem(32px);
      background-color: var(--nsw-brand-supplementary);
    }
  }

  &--light {
    background-color: var(--nsw-brand-light);

    .nsw-hero-banner__lines {
      background-color: var(--nsw-brand-dark);
    }
  }

  &--off-white {
    background-color: var(--nsw-off-white);
  }

  &--dark {
    background-color: var(--nsw-brand-dark);

    .nsw-hero-banner__content {
      color: var(--nsw-text-light);
    }

    .nsw-hero-banner__links {
      color: var(--nsw-text-light);

      a {
        @include link-light;
      }
    }

    .nsw-hero-banner__list {
      border-color: var(--nsw-brand-light);
    }
  }

  &--lines {
    border-bottom: rem(32px) solid var(--nsw-brand-light);

    @include breakpoint('md') {
      border: 0;
    }

    &.nsw-hero-banner--light {
      border-color: var(--nsw-brand-dark);
    }

    &::after {
      visibility: hidden;

      @include breakpoint('md') {
        visibility: visible;
      }
    }

    .nsw-hero-banner__container {
      border-bottom: rem(16px) solid var(--nsw-brand-supplementary);

      @include breakpoint('md') {
        border: 0;
      }
    }

    .nsw-hero-banner__box {
      width: 25%;

      @include breakpoint('xl') {
        width: 50%;
        padding-left: calc(var(--nsw-container-width)/4 + #{rem($grid-gutters)}*2);
      }
    }
  }
}
