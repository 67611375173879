@use 'sass:color';

// Purple Theme Colours
$yellow-dark: $yellow-01 !default;
$yellow-light: $yellow-04 !default;
$yellow-supplementary: $yellow-02 !default;
$yellow-accent: $green-02 !default;

// Purple State Colours
$yellow-link: $yellow-dark !default;
$yellow-link-light: tint($yellow-dark, 90%) !default;
$yellow-visited: a11y-color(lighten(adjust-hue($yellow-dark, 18), 16.08), #ffffff) !default;
$yellow-visited-light: tint($yellow-visited, 80%) !default;
$yellow-hover: color.adjust($yellow-dark, $alpha: -0.9) !default;
$yellow-hover-light: color.adjust($yellow-link-light, $alpha: -0.9) !default;
$yellow-active: color.adjust($yellow-dark, $alpha: -0.8) !default;
$yellow-active-light: color.adjust($yellow-link-light, $alpha: -0.8) !default;
$yellow-focus: lighten(adjust-hue($yellow-dark, -22), 15.49) !default;
$yellow-focus-light: tint($yellow-focus, 80%);
$yellow-disabled: tint($yellow-dark, 50%) !default;

$yellow-colors: (
  'brand-dark': $yellow-dark,
  'brand-light': $yellow-light,
  'brand-supplementary': $yellow-supplementary,
  'brand-accent': $yellow-accent,
  'link': $yellow-link,
  'link-light': $yellow-link-light,
  'visited': $yellow-visited,
  'visited-light': $yellow-visited-light,
  'hover': $yellow-hover,
  'hover-light': $yellow-hover-light,
  'active': $yellow-active,
  'active-light': $yellow-active-light,
  'focus': $yellow-focus,
  'focus-light': $yellow-focus-light,
  'disabled': $yellow-disabled
) !default;

$yellow-colors-rgb: map-loop($yellow-colors, to-rgb, '$value') !default;

.yellow {
  @each $color, $value in $yellow-colors {
    --nsw-#{$color}: #{$value};
  }

  @each $color, $value in $yellow-colors-rgb {
    --nsw-#{$color}-rgb: #{$value};
  }
}

.yellow-accent {
  --nsw-brand-accent: #{$yellow-02};
  --nsw-brand-accent-rgb: #{to-rgb($yellow-02)};
}
