.nsw-list-item {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: rem(24px) 0;
  border-bottom: 1px solid var(--nsw-grey-04);

  &:first-child {
    border-top: 1px solid var(--nsw-grey-04);
  }

  &__title {
    @include font-size('md');
    font-weight: var(--nsw-font-bold);
  }

  &__content {
    width: 100%;
  }

  &__label,
  &__info,
  &__title,
  &__copy,
  &__tags {
    margin-top: rem(8px);

    &:first-child {
      margin: 0;
    }
  }

  &__label,
  &__info {
    @include font-size('xs');
  }

  &__label {
    font-weight: var(--nsw-font-bold);
  }

  &__image {
    min-width: rem(250px);
    width: rem(250px);
    margin: 0 0 0 rem(24px);
    display: none;

    @include breakpoint('lg') {
      display: block;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;      
    }
  }

  &--block {
    &:focus-within {
      @include nsw-focus($offset: false);

      .nsw-section--invert & {   
        @include nsw-focus($color: var(--nsw-focus-light));
      }
    }

    .nsw-list-item__title a {
      @include pseudo-clickable-block;

      &:focus {
        outline: none;
      }
    }
  }

  &--reversed {
    flex-direction: row-reverse;

    .nsw-list-item__image {
      margin: 0 rem(24px) 0 0;
    }
  }
}
