.nsw-tooltip,
.nsw-toggletip {
  white-space: nowrap;
  border-bottom: 2px dotted var(--nsw-brand-dark);

  &:focus,
  &:active {
    @include nsw-focus;
  }

  &__element {
    display: none;
    width: max-content;
    position: absolute;
    top: 0;
    left: 0;
    max-width: 260px;
    border-radius: var(--nsw-border-radius);
    box-shadow: var(--nsw-box-shadow);
    line-height: var(--nsw-line-height-md-desktop);

    @include z-index('upper');
    @include font-size('xs');

    &:focus,
    &:active {
      @include nsw-focus;
    }

    &--dark {
      background-color: var(--nsw-grey-02);
      color: var(--nsw-text-light);

      a {
        @include link-light();

        &:focus,
        &:active {
          @include nsw-focus(true, var(--nsw-text-light));
        }
      }

      .nsw-icon-button {
        color: var(--nsw-text-light);
        text-decoration: none;

        @include link-light();
      }
    }

    &--light {
      background-color: var(--nsw-white);
      color: var(--nsw-text-dark);

      a {
        @include link;

        &:focus,
        &:active {
          @include nsw-focus;
        }
      }

      .nsw-icon-button {
        color: var(--nsw-brand-dark);
        text-decoration: none;

        @include link-dark;
      }

      .nsw-tooltip__arrow,
      .nsw-toggletip__arrow {
        background-color: var(--nsw-white);
      }
    }

    &.active {
      display: inline-block;
    }
  }

  &__arrow {
    position: absolute;
    background-color: var(--nsw-grey-02);
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
  }

  .nsw-section--invert & {    
    border-bottom: 2px dotted var(--nsw-text-light);
  }
}

.nsw-tooltip {
  cursor: help;

  &__element {
    padding: rem(8px);

    @include breakpoint('lg') {
      padding: rem(16px);
    }
  }
}

.nsw-toggletip {
  cursor: pointer;

  &__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .nsw-icon-button {
      margin: rem(6px) rem(6px) 0 rem(8px);

      .nsw-material-icons {
        font-size: var(--nsw-font-size-sm-mobile);
    
        @include breakpoint('lg') {
          font-size: var(--nsw-font-size-sm-desktop);
        }
      }
    }
  }

  &__content {
    padding: 0 rem(16px) rem(16px);
    
    &:first-child {
      margin-top: 0;
    }
  }
}
