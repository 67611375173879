.nsw-content-block {
  --nsw-icon-colour-dark: var(--nsw-text-dark);
  --nsw-icon-colour-accent: var(--nsw-brand-supplementary);

  &__title {
    @include font-size('lg');
    font-weight: var(--nsw-font-bold);
  }

  &__list {
    padding: 0;
    list-style-type: none;

    li {
      margin: 0;
      padding: rem(8px) 0 rem(8px) rem(14px);
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: rem(4px);
        height: rem(4px);
        border-radius: 100%;
        background-color: var(--nsw-text-dark);
        top: rem(18px);
        left: 0;
      
        .nsw-section--invert & {    
          background-color: var(--nsw-text-light);
        }
      }
    }
  }

  &__link {
    margin-top: rem(16px);
  }

  &__image {
    max-height: rem(200px);
    margin-bottom: rem(24px);

    img {
      width: 100%;
      height: rem(200px);
      object-fit: cover;      
    }
  }

  &__icon {
    max-width: rem(64px);
    max-height: rem(64px);

    svg {
      max-width: rem(64px);
      max-height: rem(64px);

      .nsw-section--invert & {
        --nsw-icon-colour-dark: var(--nsw-text-light);
      }
    }
  }
}
