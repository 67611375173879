/* stylelint-disable declaration-no-important */

.nsw-block {
  @include component-spacing();  
}

@each $prop, $abbrev in (margin: nsw-m, padding: nsw-p) {
  @each $size, $lengths in $spacers {
    $length-mobile: rem(map-get($lengths, mobile));
    $length-desktop: rem(map-get($lengths, desktop));

    .#{$abbrev}-#{$size} {
      #{$prop}: $length-mobile !important;
    }

    .#{$abbrev}-top-#{$size} {
      #{$prop}-top: $length-mobile !important;
    }

    .#{$abbrev}-right-#{$size} {
      #{$prop}-right: $length-mobile !important;
    }

    .#{$abbrev}-bottom-#{$size} {
      #{$prop}-bottom: $length-mobile !important;
    }

    .#{$abbrev}-left-#{$size} {
      #{$prop}-left: $length-mobile !important;
    }

    .#{$abbrev}-x-#{$size} {
      #{$prop}-left: $length-mobile !important;
      #{$prop}-right: $length-mobile !important;
    }

    .#{$abbrev}-y-#{$size} {
      #{$prop}-top: $length-mobile !important;
      #{$prop}-bottom: $length-mobile !important;
    }

    @include breakpoint('lg') {
      .#{$abbrev}-#{$size} {
        #{$prop}: $length-desktop !important;
      }

      .#{$abbrev}-top-#{$size} {
        #{$prop}-top: $length-desktop !important;
      }

      .#{$abbrev}-right-#{$size} {
        #{$prop}-right: $length-desktop !important;
      }

      .#{$abbrev}-bottom-#{$size} {
        #{$prop}-bottom: $length-desktop !important;
      }

      .#{$abbrev}-left-#{$size} {
        #{$prop}-left: $length-desktop !important;
      }

      .#{$abbrev}-x-#{$size} {
        #{$prop}-left: $length-desktop !important;
        #{$prop}-right: $length-desktop !important;
      }

      .#{$abbrev}-y-#{$size} {
        #{$prop}-top: $length-desktop !important;
        #{$prop}-bottom: $length-desktop !important;
      }
    }
  }
}
