$nsw-helper-list-sizes: (-8, -16, -32, 8, 16, 32);

.nsw-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &--center {
    justify-content: center;
  }

  @each $size in $nsw-helper-list-sizes {
    &--#{"" + $size} {
      margin: rem(-$size * 0.5);

      > * {
        margin: rem($size * 0.5);
      }
    }
  }
}
