@use 'sass:math';

.nsw-container {
  @include container;

  &--flush {
    padding-left: 0;
    padding-right: 0;
  }
}

.nsw-grid {
  @include grid();

  &--spaced {
    margin-top: rem(-$grid-gutters);
    margin-bottom: rem(-$grid-gutters);

    @include breakpoint('md') {
      margin-top: rem(-$grid-gutters*2);
      margin-bottom: rem(-$grid-gutters*2);
    }

    .nsw-col {
      padding-top: rem($grid-gutters*2);
      padding-bottom: rem($grid-gutters*2);

      @include breakpoint('md') {
        padding-top: rem($grid-gutters*4);
        padding-bottom: rem($grid-gutters*4);
      }
    }
  }

  &--flush {
    margin-top: rem(-$grid-gutters);
    margin-bottom: rem(-$grid-gutters);

    @include breakpoint('md') {
      margin-top: rem(-$grid-gutters*2);
      margin-bottom: rem(-$grid-gutters*2);
    }
  }

  &-overlay {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;

    .nsw-container,
    .nsw-grid {
      height: 100%;
    }

    .nsw-col {
      padding-top: 0;
      padding-bottom: 0;

      > div {
        height: 100%;
        background-color: var(--nsw-brand-accent);
        opacity: 0.1;
      }
    }
  }
}

.nsw-row {
  @include row();

  &--reverse {
    @include breakpoint('lg') {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
  }
}

.nsw-col {
  @include column-default();

  @for $i from 1 through 12 {
    &-#{$i} {
      width: percentage(math.div($i, 12));
      max-width: percentage(math.div($i, 12));
    }
  }

  @each $breakpoint, $breakpoint-size in $nsw-breakpoints {
    @include breakpoint($breakpoint) {
      @for $i from 1 through 12 {
        &-#{$breakpoint}-#{$i} {
          width: percentage(math.div($i, 12));
          max-width: percentage(math.div($i, 12));
        }
      }
    }
  }
}

.nsw-offset {
  @for $i from 1 through 12 {
    &-#{$i} {
      margin-left: percentage(math.div($i, 12));
    }
  }

  @each $breakpoint, $breakpoint-size in $nsw-breakpoints {
    @include breakpoint($breakpoint) {
      @for $i from 1 through 12 {
        &-#{$breakpoint}-#{$i} {
          margin-left: percentage(math.div($i, 12));
        }
      }
    }
  }
}
