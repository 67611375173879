.nsw-skip {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  a {
    @include font-size('xxs');
    @include z-index('middle');
    background-color: var(--nsw-brand-dark);
    color: var(--nsw-white);
    width: 100%;
    text-decoration: none;
    padding: rem(8px) rem(16px);
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-101%);

    &:focus {
      transform: translateY(0);
      outline: none;

      span {
        @include nsw-focus($color: var(--nsw-white));
      }
    }

    @include breakpoint('lg') {
      text-align: left;
    }
  }

  &--light {
    a {
      background-color: var(--nsw-off-white);
      color: var(--nsw-palette-grey-900);

      &:focus span {
        @include nsw-focus();
      }
    }
  }
}

.nsw-masthead {
  @include font-size('xxs');
  padding: rem(8px) 0;
  background-color: var(--nsw-brand-dark);
  color: var(--nsw-white);

  &--light {
    background-color: var(--nsw-off-white);
    color: var(--nsw-palette-grey-900);
  }
}
