.nsw-header {
  background-color: var(--nsw-white);
  position: relative;
  border-bottom: solid 1px var(--nsw-grey-04);

  @include breakpoint($nsw-desktop-breakpoint) {
    border-bottom: 0;
  }

  &--simple {
    @include breakpoint($nsw-desktop-breakpoint) {
      border-bottom: solid 1px var(--nsw-grey-04);
    }
  }

  &__container {
    @include container;
    position: relative;
  }

  &__inner {
    position: relative;

    @include breakpoint($nsw-desktop-breakpoint) {
      display: flex;
    }
  }

  &__menu,
  &__search {
    button,
    a {      
      @include font-size('xxs');
      font-weight: var(--nsw-font-bold);
      border-radius: var(--nsw-border-radius);
      color: var(--nsw-brand-dark);
      width: rem(48px);
      height: rem(48px);
      background: none;
      border: 0;
      padding: 0;
      text-align: center;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        @include nsw-hover;
      }

      &:focus {
        @include nsw-focus;
      }

      .nsw-material-icons {
        font-size: rem(map-get($nsw-icon-sizes, 24));
      }
    }

  }

  &__menu {
    position: absolute;
    top: rem(12px);
    left: rem(-6px);

    button {      
      @include breakpoint($nsw-desktop-breakpoint) {
        display: none;
      }
    }
  }

  &__search {
    position: absolute;
    top: rem(12px);
    right: rem(-4px);

    @include breakpoint($nsw-desktop-breakpoint) {
      right: 0;
      top: 50%;
      margin-top: rem(-24px);
    }

    button {      
      @include breakpoint($nsw-desktop-breakpoint) {
        > span:last-child {
          @include sr-only;
        }

        .nsw-material-icons {
          font-size: rem(map-get($nsw-icon-sizes, 36));
        }
      }
    }
  }

  &__main {
    @include breakpoint($nsw-desktop-breakpoint) {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: rem(20px) rem(72px) rem(20px) 0;

      &:focus-within {
        @include nsw-focus;
      }
    }

    .nsw-header__rectangular-logo {
      @include breakpoint('lg') {
        height: rem(60px);
        margin-top: rem(8px);
        margin-bottom: rem(8px);
      }
    }
  }

  &__waratah {
    padding: rem(12px) rem(48px);
    display: flex;
    justify-content: center;

    @include breakpoint($nsw-desktop-breakpoint) {
      padding: 0;
    }

    svg {
      height: rem(48px);
      display: block;

      @include breakpoint($nsw-desktop-breakpoint) {
        height: rem(76px);
      }

      &.nsw-header__waratah-gov {
        height: rem(55px);

        @include breakpoint($nsw-desktop-breakpoint) {
          height: rem(76px);
        }
      }
    }

    a {
      display: flex;
      color: var(--nsw-brand-dark);
      text-decoration: none;
      align-items: center;

      &:hover {
        background: transparent;
        outline-width: 0;
      }

      &:focus {
        outline-width: 3px;

        @include breakpoint($nsw-desktop-breakpoint) {
          outline: none;
        }
      }

      @include breakpoint($nsw-desktop-breakpoint) {
        @include pseudo-clickable-block;
      }

      svg:not(:first-child) {
        margin-left: rem(24px);

        @include breakpoint($nsw-desktop-breakpoint) {
          margin-left: rem(40px);
        }
      }      
    }
  }

  &__waratah-gov {
    margin-bottom: rem(-7px);

    @include breakpoint($nsw-desktop-breakpoint) {
      margin-bottom: 0;
    }

    .hide {
      display: none;

      @include breakpoint($nsw-desktop-breakpoint) {
        display: inline;
      }
    }
  }

  &__name {
    padding: rem(12px) 0;
    color: var(--nsw-brand-dark);

    @include breakpoint($nsw-desktop-breakpoint) {
      padding: 0;
      margin-left: rem(40px);
    }
  }

  &__title {
    @include font-size('lg');
    font-weight: var(--nsw-font-bold);
    color: var(--nsw-brand-dark);
    display: flex;
    align-items: center;

    @include breakpoint('lg') {
      line-height: rem(28px);
    }

    .nsw-tag {
      margin-left: rem(24px);
    }
  }

  &__description {
    @include font-size('xs');
    color: var(--nsw-brand-dark);
  }

  &__logo {
    display: flex;
    justify-content: center;

    @include breakpoint($nsw-desktop-breakpoint) {
      justify-content: flex-start;
    }

    svg {
      height: rem(48px);
      display: block;

      @include breakpoint($nsw-desktop-breakpoint) {
        height: rem(76px);
      }
    }
  }
  
  &__search-area {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    @include breakpoint($nsw-desktop-breakpoint) {
      width: rem(394px);
      left: auto;
      right: rem(16px);
      top: 50%;
      transform: translateY(-50%);
    }

    button {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      cursor: pointer;
    }

    > button {
      border-radius: 0;
      
      @include breakpoint($nsw-desktop-breakpoint) {
        display: none;
      }
    }

    form,
    div {
      button {
        display: none;
        padding: 0;
        width: rem(48px);

        @include breakpoint($nsw-desktop-breakpoint) {
          display: flex;
        }
      }

      .nsw-material-icons {
        font-size: rem(map-get($nsw-icon-sizes, 36));
      }
    }

    input {
      @include font-size('md');
      background: var(--nsw-off-white);
      padding: rem(8px) rem(72px) rem(8px) rem(16px);
      color: var(--nsw-text-dark);
      width: 100%;
      appearance: none;
      border: 0;
      height: rem(72px);

      &:focus {
        @include nsw-focus($offset: false);
      }

      @include breakpoint($nsw-desktop-breakpoint) {
        border-radius: var(--nsw-border-radius);
        height: rem(48px);
        padding: 0 rem(16px);
      }
    }
  }
}
