.nsw-popover {
  display: none;
  width: max-content;
  position: absolute;
  top: 0;
  left: 0;
  min-width: rem(280px);
  max-width: rem(280px);
  border-radius: var(--nsw-border-radius);
  box-shadow: var(--nsw-box-shadow);
  background-color: var(--nsw-white);

  @include z-index('upper');

  &:focus,
  &:active {
    @include nsw-focus(false);
  }

  &.active {
    display: inline-block;
  }
}
