.nsw-progress-indicator {
  @include component-spacing();

  &__count {
    @include font-size('sm');
  }

  &__bar {
    display: flex;
    padding-top: rem(8px);

    div {
      height: rem(4px);
      width: 100%;
      background-color: var(--nsw-grey-04);

      &:not(:first-child) {
        margin-left: rem(4px);
      }

      &.active {
        background-color: var(--nsw-brand-accent);
      }
    }
  }
}
