.dialog-active {
  overflow: hidden;
}

.nsw-dialog {
  @include z-index('upper');
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(var(--nsw-black-rgb), 0.7);
  justify-content: center;
  align-items: flex-end;

  .nsw-section--invert & {
    color: var(--nsw-text-dark);

    .nsw-icon-button {
      @include link-dark();
    }
  }

  @include breakpoint('sm') {
    align-items: center;
    padding: 0 rem(16px);
  }

  &.active {
    display: flex;
  }

  &__wrapper {
    background-color: var(--nsw-white);
    width: 100%;
    max-width: rem(736px);
    position: relative;
    padding-bottom: rem(145px);

    @include breakpoint('sm') {
      border-radius: var(--nsw-border-radius);
      align-items: center;
      max-height: 90%;
    }

    @include breakpoint('md') {
      padding-bottom: rem(81px);
    }

    @include breakpoint('lg') {
      padding-bottom: rem(113px);
    }

    .nsw-dialog--single-action & {
      padding-bottom: rem(81px);

      @include breakpoint('lg') {
        padding-bottom: rem(113px);
      }
    }
  }

  &__container {
    height: 100%;
    max-height: calc(100vh - #{rem(145px)});
    overflow-y: auto;

    @include breakpoint('sm') {
      max-height: calc(90vh - #{rem(145px)});
    }

    @include breakpoint('md') {
      max-height: calc(90vh - #{rem(81px)});
    }

    @include breakpoint('lg') {
      max-height: calc(90vh - #{rem(113px)});
    }

    .nsw-dialog--single-action & {
      max-height: calc(100vh - #{rem(81px)});

      @include breakpoint('sm') {
        max-height: calc(90vh - #{rem(81px)});
      }

      @include breakpoint('lg') {
        max-height: calc(90vh - #{rem(113px)});
      }
    }
  }

  &__top {
    border-bottom: 1px solid var(--nsw-grey-04);
    display: flex;
  }

  &__title {
    padding: rem(16px);
    width: 100%;

    @include breakpoint('lg') {
      padding: rem(32px);
    }
  }

  &__close {
    min-width: rem(48px);
    max-width: rem(48px);
    padding: rem(8px) rem(8px) rem(8px) 0;

    @include breakpoint('lg') {
      min-width: rem(64px);
      max-width: rem(64px);
      padding: rem(24px) rem(24px) rem(24px) 0;
    }
  }

  &__content {
    padding: rem(16px);

    @include breakpoint('lg') {
      padding: rem(32px);
    }
  }

  &__bottom {
    border-top: 1px solid var(--nsw-grey-04);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    padding: rem(16px);

    @include breakpoint('md') {
      flex-direction: row-reverse;
      justify-content: flex-start;
    }

    @include breakpoint('lg') {
      padding: rem(32px);
    }

    button {
      margin-bottom: rem(16px);

      @include breakpoint('md') {
        margin-left: rem(16px);
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;

        @include breakpoint('md') {
          margin-left: 0;
        }
      }
    }
  }
}
