.nsw-word-break { 
  word-break: break-all;

  &--mobile {
    @include breakpoint($nsw-desktop-breakpoint) {
      word-break: normal;
    }
  }
}

.nsw-text-truncate {
  @include text-truncate();
}
