$nsw-vertical-align: (
  valign-baseline: baseline,
  valign-top: top,
  valign-middle: middle,
  valign-bottom: bottom,
);

.nsw-table {
  @include component-spacing();
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  &:focus {
    @include nsw-focus;

    .nsw-section--invert & {   
      @include nsw-focus($color: var(--nsw-focus-light));
    }
  }

  table {
    margin: 0;
    min-width: rem(600);
  }

  &--caption-top {
    table {
      caption-side: top;
    }
  }

  &--striped {
    th,
    td {
      border-bottom: 0;
    }

    tbody tr:nth-of-type(even) {
      background-color: var(--nsw-off-white);
      
      .nsw-section--invert & {    
        background-color: var(--nsw-hover-light);
      }
    }
  }

  &--bordered {
    th,
    td {
      border: solid 1px var(--nsw-grey-01);
    }
  }
}

@each $name, $alignment in $nsw-vertical-align {
  .nsw-table--#{"" + $name} {
    th,
    td {
      vertical-align: $alignment;
    }
  }
}
