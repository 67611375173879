.hero-search {
  position: relative;
  z-index: 1;

  @include breakpoint('md') {
    padding: rem(32px) rem(16px);
  }

  &__form {
    padding: rem(24px) 0;
    text-align: left;

    .nsw-material-icons { 
      &:first-child {
        margin: 0;
      }
    }
  }

  &__wrapper {
    padding: rem(32px) rem(16px);
    background-color: var(--nsw-off-white);
    border-bottom: solid 4px var(--nsw-brand-accent);

    @include breakpoint('md') {
      border-top-left-radius: var(--nsw-border-radius);
      border-top-right-radius: var(--nsw-border-radius);
      padding: rem(32px) rem(80px);
    }
  }

  &__content {    
    max-width: rem(700px);
    margin: 0 auto;
    text-align: center;
  }

  &__suggested {
    ul {
      list-style: none;
      text-align: center;
      margin-top: rem(-8px);
      padding: 0;
    }

    li {
      margin: rem(8px) rem(12px) 0 rem(12px);
      display: inline-block;
    }

    a:not(.nsw-icon-button) {
      line-height: 1.5;
      padding: rem(4px) rem(4px);
    }
  }

  .nsw-container {
    padding: 0;
  }
}
