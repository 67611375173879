html {
  font-size: 100%;
  line-height: var(--nsw-line-height);
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
}
