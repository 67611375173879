.nsw-accordion {
  @include component-spacing();

  &__title,
  &__button {
    @include font-size('md');
    font-weight: var(--nsw-font-bold);
    padding: rem(16px) rem(48px) rem(16px) rem(16px);
    margin-top: rem(8px);
    background-color: var(--nsw-off-white);
    display: block;
    width: 100%;
    text-align: left;
    position: relative;
    transition: background $nsw-transition-duration, color $nsw-transition-duration;

    .active,
    &:hover & {
      background-color: var(--nsw-brand-dark);
      color: var(--nsw-text-light);
      cursor: pointer;

      .nsw-section--invert & {    
        background-color: var(--nsw-brand-light);
        color: var(--nsw-text-dark);
        
        .nsw-material-icons {
          color: var(--nsw-brand-dark);
        }
      }

      .nsw-material-icons {
        color: var(--nsw-text-light);
      }
    }
  }

  &__toggle {
    display: flex;
    justify-content: flex-end;
    margin-bottom: rem(16px);

    button {
      @include font-size('sm');
      color: var(--nsw-link);
      text-decoration: underline;
      text-decoration-skip-ink: auto;
      font-weight: var(--nsw-font-bold);
      border: 0;
      padding: 0;
      background: none;

      &:hover:enabled {
        @include nsw-hover;
        outline: 2px solid var(--nsw-hover);
        cursor: pointer;

        .nsw-section--invert & {    
          background-color: var(--nsw-hover-light);
          outline-color: var(--nsw-hover-light);
        }
      }

      &:focus {
        @include nsw-focus($offset: false);
      }

      &:first-child {
        margin-right: rem(24px);
      }

      &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }      

      .nsw-section--invert & {    
        color: var(--nsw-text-light);

        &:focus {
          outline-color: var(--nsw-white);
        }
      }
    }
  }

  &__title {
    .ready & {
      padding: 0;
      margin-top: 0;
    }

    .nsw-material-icons {
      font-size: rem(map-get($nsw-icon-sizes, 30));
      color: var(--nsw-brand-dark);
      transform: translateY(-50%);
      position: absolute;
      top: 50%;
      right: rem(8px);
      transition: transform $nsw-transition-duration, color $nsw-transition-duration;
    }
  }

  &__button {
    border: 0;
    color: var(--nsw-text-dark);

    &:focus {
      @include nsw-focus;
      @include z-index;
      position: relative;

      .nsw-section--invert & {   
        @include nsw-focus($color: var(--nsw-focus-light));
      }
    }

    &.active {
      .nsw-material-icons {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  &__content[hidden='until-found'] {
    padding: 0;
  }

  &__content {
    padding: rem(16px);
  }
}
