@import 'brown';
@import 'fuchsia';
@import 'green';
@import 'orange';
@import 'purple';
@import 'red';
@import 'teal';
@import 'yellow';

.blue-accent {
  --nsw-brand-accent: #{$blue-02};
  --nsw-brand-accent-rgb: #{to-rgb($blue-02)};
}
