//Variations
$nsw-sections: (
  brand-dark: (
    background: var(--nsw-brand-dark),
    color: var(--nsw-text-light)
  ),
  brand-supplementary: (
    background: var(--nsw-brand-supplementary),
    color: var(--nsw-text-light)
  ),
  black: (
    background: var(--nsw-black),
    color: var(--nsw-text-light)
  ),
  grey-01: (
    background: var(--nsw-grey-01),
    color: var(--nsw-text-light)
  ),
  grey-02: (
    background: var(--nsw-grey-02),
    color: var(--nsw-text-light)
  ),
  brand-light: (
    background: var(--nsw-brand-light),
    color: var(--nsw-text-dark)
  ),
  white: (
    background: var(--nsw-white),
    color: var(--nsw-text-dark)
  ),
  off-white: (
    background: var(--nsw-off-white),
    color: var(--nsw-text-dark)
  ),
  grey-03: (
    background: var(--nsw-grey-03),
    color: var(--nsw-text-dark)
  ),
  grey-04: (
    background: var(--nsw-grey-04),
    color: var(--nsw-text-dark)
  )
);

.nsw-section {
  padding: rem($grid-gutters*2) 0;

  @include breakpoint('md') {
    padding: rem($grid-gutters*4) 0;
  }

  &--half-padding {
    padding: 0;

    @include breakpoint('md') {
      padding: rem($grid-gutters*2) 0;
    }    
  }

  &--no-padding {
    padding: 0;

    @include breakpoint('md') {
      padding: 0;
    }    
  }

  &--image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 0;

    @include breakpoint('md') {
      padding: rem($grid-gutters*4) 0;
    }
  }

  &--image-html {
    position: relative;
    padding: 0;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include breakpoint('md') {
      padding: rem($grid-gutters*4) 0;
    }
  }

  &--box {
    border-radius: var(--nsw-border-radius);
    border: 1px solid var(--nsw-grey-04);
    padding: rem(24px);

    @include breakpoint('md') {
      padding: rem(24px);
    }
  }

  @each $name, $variant in $nsw-sections {
    &--#{"" + $name} {
      --nsw-section-bg: #{map-get($variant, background)};
      background-color: map-get($variant, background);
      color: map-get($variant, color);
    }
  }

  &--invert {
    color: var(--nsw-text-light);
  }
}
