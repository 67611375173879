.sr-only,
.sr-only-focusable:not(:focus):not(:focus-within) {
  @include sr-only();
}

.nsw-show {
  @each $breakpoint, $breakpoint-size in $nsw-breakpoints {
    &-#{$breakpoint} {
      display: none;
    }

    @include breakpoint($breakpoint) {
      &-#{$breakpoint} {
        display: block;
      }
    }
  }
}

.nsw-hide {
  @each $breakpoint, $breakpoint-size in $nsw-breakpoints {
    @include breakpoint($breakpoint) {
      &-#{$breakpoint} {
        display: none;
      }
    }
  }
}
