@use 'sass:color';

// Purple Theme Colours
$fuchsia-dark: $fuchsia-01 !default;
$fuchsia-light: $fuchsia-04 !default;
$fuchsia-supplementary: $fuchsia-02 !default;
$fuchsia-accent: $orange-02 !default;

// Purple State Colours
$fuchsia-link: $fuchsia-dark !default;
$fuchsia-link-light: tint($fuchsia-dark, 90%) !default;
$fuchsia-visited: a11y-color(lighten(desaturate(adjust-hue($fuchsia-dark, 4), 48.34), 21.57), #ffffff) !default;
$fuchsia-visited-light: tint($fuchsia-visited, 80%) !default;
$fuchsia-hover: color.adjust($fuchsia-dark, $alpha: -0.9) !default;
$fuchsia-hover-light: color.adjust($fuchsia-link-light, $alpha: -0.9) !default;
$fuchsia-active: color.adjust($fuchsia-dark, $alpha: -0.8) !default;
$fuchsia-active-light: color.adjust($fuchsia-link-light, $alpha: -0.8) !default;
$fuchsia-focus: lighten(adjust-hue($fuchsia-dark, -22), 15.49) !default;
$fuchsia-focus-light: tint($fuchsia-focus, 80%);
$fuchsia-disabled: tint($fuchsia-dark, 50%) !default;

$fuchsia-colors: (
  'brand-dark': $fuchsia-dark,
  'brand-light': $fuchsia-light,
  'brand-supplementary': $fuchsia-supplementary,
  'brand-accent': $fuchsia-accent,
  'link': $fuchsia-link,
  'link-light': $fuchsia-link-light,
  'visited': $fuchsia-visited,
  'visited-light': $fuchsia-visited-light,
  'hover': $fuchsia-hover,
  'hover-light': $fuchsia-hover-light,
  'active': $fuchsia-active,
  'active-light': $fuchsia-active-light,
  'focus': $fuchsia-focus,
  'focus-light': $fuchsia-focus-light,
  'disabled': $fuchsia-disabled
) !default;

$fuchsia-colors-rgb: map-loop($fuchsia-colors, to-rgb, '$value') !default;

.fuchsia {
  @each $color, $value in $fuchsia-colors {
    --nsw-#{$color}: #{$value};
  }

  @each $color, $value in $fuchsia-colors-rgb {
    --nsw-#{$color}-rgb: #{$value};
  }
}

.fuchsia-accent {
  --nsw-brand-accent: #{$fuchsia-02};
  --nsw-brand-accent-rgb: #{to-rgb($fuchsia-02)};
}
