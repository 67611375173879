.nsw-tag {
  @include font-size('xs');
  font-weight: var(--nsw-font-normal);
  padding: rem(2px) rem(16px);
  border: solid 1px var(--nsw-brand-dark);
  border-radius: rem(24px);
  color: var(--nsw-brand-dark);
  text-decoration: none;
  display: inline-block;

  &:visited {
    color: var(--nsw-brand-dark);
  }
  
  &:hover:not(.nsw-tag--checkbox, span) {
    @include nsw-hover;
    outline-width: 0;
  }

  &:is(span) {
    cursor: default;
  }

  &:focus {
    outline-width: 3px;
  }

  .nsw-section--invert & {
    color: var(--nsw-text-light);
    border-color: var(--nsw-text-light);

    &:not(span) {
      @include link-light;
    }
  }

  &--checkbox {
    padding: 0;
    border: 0;
    border-radius: 0;
    position: relative;

    &:hover {
      .nsw-section--invert & {    
        background-color: transparent;
      }
    }

    input {
      position: absolute;
      opacity: 0;

      &:hover + label {
        cursor: pointer;

        @include nsw-hover;
        
        .nsw-section--invert & {    
          background-color: var(--nsw-hover-light);
        }
      }

      &:focus + label {
        @include nsw-focus;
        outline-offset: 0;

        .nsw-section--invert & {    
          outline-color: var(--nsw-white);
        }
      }

      &:checked + label {
        background-color: var(--nsw-brand-dark);
        color: var(--nsw-text-light);

        .nsw-section--invert & {    
          background-color: var(--nsw-white);
          color: var(--nsw-text-dark);
        }
      }
    }

    label {
      padding: rem(2px) rem(16px);
      border: solid 1px var(--nsw-brand-dark);
      border-radius: rem(24px);
      display: inline-block;

      .nsw-section--invert & {    
        border-color: var(--nsw-text-light);
      }
    }
  }

  &.active {
    background-color: var(--nsw-brand-dark);
    color: var(--nsw-text-light);
  }
}
