.nsw-back-to-top {
  display: flex;
  flex-direction: column-reverse;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s;
  text-decoration: none;

  @include breakpoint('md') {
    flex-direction: row;
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }

  &.nsw-button {
    padding: 1rem;
    border: 0;

    @include breakpoint('md') {
      padding: 0.625rem 1.375rem;
    }

    .nsw-material-icons:last-child {
      margin-left: 0;

      @include breakpoint('md') {
        letter-spacing: -0.25rem;
      }
    }
  }
}
