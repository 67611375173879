.nsw-breadcrumbs {
  @include font-size('xs');
  margin-top: rem(24px);

  ol {
    margin: 0;
    padding-left: 0;
    list-style: none;
  }

  li:not(:first-child):not(:last-child):not(:nth-last-child(2)):not(.nsw-breadcrumbs__show-more-toggle) {
    display: none;

    @include breakpoint('md') {
      display: inline-block;
    }
  }

  li {
    display: inline-block;

    &:first-child {
      &::before {
        display: none;
      }
    }

    &::before {
      @include material-icons;
      content: 'keyboard_arrow_right';
      font-size: rem(map-get($nsw-icon-sizes, 20));
      line-height: rem(8px);
      display: inline-block;
      position: relative;
      top: rem(6px);
    }
  }

  .nsw-breadcrumbs__show-more-toggle {
    @include breakpoint('md') {
      display: none;
    }

    .nsw-breadcrumbs__toggle-button {
      @include link-button;
      font-size: var(--nsw-font-size-xs);
      font-weight: var(--nsw-font-normal);
      color: var(--nsw-text-dark);
      text-decoration: none;
      padding: 0 rem(4px);
      margin-left: 4px;
      line-height: 24px;
      height: 100%;
      cursor: pointer;

      .nsw-section--invert & {
        @include link-light;
        color: var(--nsw-text-light);
      }
    }
  }

  .nsw-breadcrumbs__show-all {
    li:not(:first-child):not(:last-child):not(:nth-last-child(2)):not(.nsw-breadcrumbs__show-more-toggle) {
      display: inline-block;
    }

    .nsw-breadcrumbs__show-more-toggle {
      display: none;
    }
  }
  
  a {
    display: inline-block;
    line-height: 24px;
    font-weight: var(--nsw-font-normal);

    &.current {
      text-decoration: none;
      pointer-events: none;
      color: var(--nsw-text-dark);

      .nsw-section--invert & {
        color: var(--nsw-text-light);
      }
    }
  }
}

@supports (content: 'x' / 'y') {
  .nsw-breadcrumbs {
    li {
      &::before {
        content: 'keyboard_arrow_right' / '';
      }
    }
  }
}

@media speech {
  .nsw-breadcrumbs {
    li {
      &::before {
        display: none;
        visibility: hidden;
      }
    }
  }
}
