$prefix: 'nsw-';
$enable-important-utilities: true !default;

// Breakpoints
$nsw-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

//Breakpoint to switch to desktop view (Layout and Navigation)
$nsw-desktop-breakpoint: lg;

// Font
$nsw-base-font-size: 16px;
$nsw-font-size-reponsive-breakpoint: lg !default;

$nsw-font-weight-lighter: lighter !default;
$nsw-font-weight-light: 300 !default;
$nsw-font-weight-normal: 400 !default;
$nsw-font-weight-medium: 500 !default;
$nsw-font-weight-semibold: 600 !default;
$nsw-font-weight-bold: 700 !default;
$nsw-font-weight-bolder: bolder !default;

// Grid
$grid-gutters: 8px;

// z-index
$nsw-z-index: (
  top: 900,
  upper: 300,
  middle: 200,
  base: 100,
  0:0,
  below: -100,
) !default;

// Base Colours
$nsw-base-colours: (
  brand-dark: var(--nsw-brand-dark),
  brand-light: var(--nsw-brand-light),
  brand-supplementary: var(--nsw-brand-supplementary),
  brand-accent: var(--nsw-brand-accent),
  black: var(--nsw-black),
  white: var(--nsw-white),
  off-white: var(--nsw-off-white),
  grey-01: var(--nsw-grey-01),
  grey-02: var(--nsw-grey-02),
  grey-03: var(--nsw-grey-03),
  grey-04: var(--nsw-grey-04)
);

$nsw-base-colours-rgb: (
  brand-dark: rgba(var(--nsw-brand-dark-rgb), var(--nsw-bg-opacity)),
  brand-light: rgba(var(--nsw-brand-light-rgb), var(--nsw-bg-opacity)),
  brand-supplementary: rgba(var(--nsw-brand-supplementary-rgb), var(--nsw-bg-opacity)),
  brand-accent: rgba(var(--nsw-brand-accent-rgb), var(--nsw-bg-opacity)),
  black: rgba(var(--nsw-black-rgb), var(--nsw-bg-opacity)),
  white: rgba(var(--nsw-white-rgb), var(--nsw-bg-opacity)),
  off-white: rgba(var(--nsw-off-white-rgb), var(--nsw-bg-opacity)),
  grey-01: rgba(var(--nsw-grey-01-rgb), var(--nsw-bg-opacity)),
  grey-02: rgba(var(--nsw-grey-02-rgb), var(--nsw-bg-opacity)),
  grey-03: rgba(var(--nsw-grey-03-rgb), var(--nsw-bg-opacity)),
  grey-04: rgba(var(--nsw-grey-04-rgb), var(--nsw-bg-opacity))
);


// Background Colours
$nsw-bg-colours: map-merge($nsw-base-colours-rgb, (
  transparent: transparent,
  info-dark: rgba(var(--nsw-status-info-rgb), var(--nsw-bg-opacity)),
  info-light: rgba(var(--nsw-status-info-bg-rgb), var(--nsw-bg-opacity)),
  success-dark: rgba(var(--nsw-status-success-rgb), var(--nsw-bg-opacity)),
  success-light: rgba(var(--nsw-status-success-bg-rgb), var(--nsw-bg-opacity)),
  warning-dark: rgba(var(--nsw-status-warning-rgb), var(--nsw-bg-opacity)),
  warning-light: rgba(var(--nsw-status-warning-bg-rgb), var(--nsw-bg-opacity)),
  error-dark: rgba(var(--nsw-status-error-rgb), var(--nsw-bg-opacity)),
  error-light: rgba(var(--nsw-status-error-bg-rgb), var(--nsw-bg-opacity))
));

// Text Colours
$nsw-text-colours: map-merge($nsw-base-colours, (
  dark: var(--nsw-text-dark),
  light: var(--nsw-text-light),
));

$nsw-border: var(--nsw-border-width) var(--nsw-border-style) var(--nsw-border-color);

$nsw-border-widths: (
  1: var(--nsw-border-width) var(--nsw-border-style) var(--nsw-border-color),
  2: 2px var(--nsw-border-style) var(--nsw-border-color),
  3: 3px var(--nsw-border-style) var(--nsw-border-color),
  4: 4px var(--nsw-border-style) var(--nsw-border-color),
  5: 5px var(--nsw-border-style) var(--nsw-border-color),
  6: 6px var(--nsw-border-style) var(--nsw-border-color)
) !default;

// Box shadow
$nsw-box-shadow: var(--nsw-box-shadow) !default;

// Position
$nsw-position-values: (
  0: 0,
  50: 50%,
  100: 100%
) !default;

// Spaces
$spacers: (
  0: (
    mobile: 0,
    desktop: 0
  ),
  xs: (
    mobile: 4px,
    desktop: 8px
  ),
  sm: (
    mobile: 8px,
    desktop: 16px
  ),
  md: (
    mobile: 16px,
    desktop: 32px
  ),
  lg: (
    mobile: 24px,
    desktop: 48px
  ),
  xl: (
    mobile: 32px,
    desktop: 64px
  ),
  xxl: (
    mobile: 40px,
    desktop: 80px
  )
);

// Width and height
$sizes: (
  4xs: 0.25rem,
  3xs: 0.5rem,
  2xs: 0.75rem,
  xs: 1rem,
  sm: 1.5rem,
  md: 2rem,
  lg: 3rem,
  xl: 4rem,
  2xl: 6rem,
  3xl: 8rem,
  4xl: 16rem,
  0: 0,
  10: 10%,
  20: 20%,
  25: 25%,
  30: 30%,
  33: 33%,
  40: 40%,
  50: 50%,
  60: 60%,
  70: 70%,
  75: 75%,
  80: 80%,
  90: 90%,
  100: 100%
) !default;

// Transitions
$nsw-transition-duration: 0.15s ease-in-out !default;
