$nsw-buttons: (
  dark: (
    text-color: var(--nsw-text-light),
    text-color-invert: var(--nsw-section-bg),
    text-color-hover: var(--nsw-text-light),
    text-color-hover-invert: var(--nsw-section-bg),
    background: var(--nsw-brand-dark),
    background-invert: var(--nsw-white),
    background-hover: var(--nsw-brand-dark),
    background-hover-invert: var(--nsw-white),
    border: transparent,
    border-hover: transparent,
    focus: var(--nsw-focus),
    focus-invert: var(--nsw-focus-light),
    hover: white,
    hover-invert: black
  ),
  dark-outline: (
    text-color: var(--nsw-brand-dark),
    text-color-invert: var(--nsw-white),
    text-color-hover: var(--nsw-text-light),
    text-color-hover-invert: var(--nsw-section-bg),
    background: transparent,
    background-invert: transparent,
    background-hover: var(--nsw-brand-dark),
    background-hover-invert: var(--nsw-white),
    border: var(--nsw-brand-dark),
    border-invert: var(--nsw-white),
    border-hover: transparent,
    border-hover-invert: transparent,
    focus: var(--nsw-focus),
    focus-invert: var(--nsw-focus-light),
  ),
  dark-outline-solid: (
    text-color: var(--nsw-brand-dark),
    text-color-invert: var(--nsw-white),
    text-color-hover: var(--nsw-text-light),
    text-color-hover-invert: var(--nsw-section-bg),
    background: var(--nsw-white),
    background-invert: var(--nsw-section-bg),
    background-hover: var(--nsw-brand-dark),
    background-hover-invert: var(--nsw-white),
    border: var(--nsw-brand-dark),
    border-invert: var(--nsw-white),
    border-hover: transparent,
    border-hover-invert: transparent,
    focus: var(--nsw-focus),
    focus-invert: var(--nsw-focus-light)
  ),
  light: (
    text-color: var(--nsw-text-dark),
    text-color-invert: var(--nsw-section-bg),
    text-color-hover: var(--nsw-text-dark),
    text-color-hover-invert: var(--nsw-section-bg),
    background: var(--nsw-brand-light),
    background-invert: var(--nsw-brand-light),
    background-hover: var(--nsw-brand-light),
    background-hover-invert: var(--nsw-brand-light),
    border: transparent,
    border-invert: transparent,
    border-hover: transparent,
    border-hover-invert: transparent,
    focus: var(--nsw-focus),
    focus-invert: var(--nsw-focus-light),
    hover: black,
    hover-invert: white
  ),
  light-outline: (
    text-color: var(--nsw-brand-light),
    text-color-invert: var(--nsw-brand-light),
    text-color-hover: var(--nsw-text-dark),
    text-color-hover-invert: var(--nsw-section-bg),
    background: transparent,
    background-invert: transparent,
    background-hover: var(--nsw-brand-light),
    background-hover-invert: var(--nsw-brand-light),
    border: var(--nsw-brand-light),
    border-invert: var(--nsw-brand-light),
    border-hover: transparent,
    border-hover-invert: transparent,
    focus: var(--nsw-focus),
    focus-invert: var(--nsw-focus-light)
  ),
  white: (
    text-color: var(--nsw-text-dark),
    text-color-invert: var(--nsw-section-bg),
    text-color-hover: var(--nsw-text-dark),
    text-color-hover-invert: var(--nsw-section-bg),
    background: var(--nsw-white),
    background-invert: var(--nsw-white),
    background-hover: var(--nsw-white),
    background-hover-invert: var(--nsw-white),
    border: transparent,
    border-invert: transparent,
    border-hover: transparent,
    border-hover-invert: transparent,
    focus: var(--nsw-white),
    focus-invert: var(--nsw-focus-light),
    hover: black,
    hover-invert: black
  ),
  white-outline: (
    text-color: var(--nsw-white),
    text-color-invert: var(--nsw-white),
    text-color-hover: var(--nsw-text-dark),
    text-color-hover-invert: var(--nsw-section-bg),
    background: transparent,
    background-invert: transparent,
    background-hover: var(--nsw-white),
    background-hover-invert: var(--nsw-white),
    border: var(--nsw-white),
    border-invert: var(--nsw-white),
    border-hover: transparent,
    border-hover-invert: transparent,
    focus: var(--nsw-white),
    focus-invert: var(--nsw-focus-light),
    hover: black,
    hover-invert: white
  ),
  danger: (
    text-color: var(--nsw-text-light),
    text-color-invert: var(--nsw-text-light),
    text-color-hover: var(--nsw-text-light),
    text-color-hover-invert: var(--nsw-text-light),
    background: var(--nsw-status-error),
    background-invert: var(--nsw-status-error),
    background-hover: var(--nsw-status-error),
    background-hover-invert: var(--nsw-status-error),
    border: transparent,
    border-invert: transparent,
    border-hover: transparent,
    border-hover-invert: transparent,
    focus: var(--nsw-focus),
    focus-invert: var(--nsw-focus-light),
    hover: white,
    hover-invert: white
  ),
  info: (
    text-color: var(--nsw-text-light),
    text-color-hover: var(--nsw-text-light),
    background: var(--nsw-status-info),
    background-hover: var(--nsw-status-info),
    border: transparent,
    border-hover: transparent,
    focus: var(--nsw-focus),
    focus-invert: var(--nsw-focus-light),
    hover: white
  ),  
);

.nsw-button {
  @include font-size('sm');
  border-radius: var(--nsw-border-radius);
  font-weight: var(--nsw-font-bold);
  border: solid 2px transparent;
  white-space: normal;
  text-decoration: none;
  padding: rem(10px) rem(22px);
  cursor: pointer;
  -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
  appearance: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  display: inline-block;

  &:not(.nsw-button--flex) {
    min-width: rem(124px);
  }

  &:hover {
    outline: none;
  }

  &:focus {
    @include nsw-focus;
  }

  &:disabled,
  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  .nsw-material-icons {
    font-size: rem(map-get($nsw-icon-sizes, 24));
    line-height: rem(10px);
    position: relative;
    bottom: rem(-7px);

    &:first-child {
      margin-right: rem(8px);
    }

    &:last-child {
      margin-left: rem(8px);
    }

    &:only-child {
      margin: 0;
    }
  }

  &--small {
    @include font-size('xs');
    padding: rem(5px);

    .nsw-material-icons {
      font-size: rem(map-get($nsw-icon-sizes, 20));

      &:first-child {
        margin-right: rem(4px);
      }

      &:last-child {
        margin-left: rem(4px);
      }

      &:only-child {
        margin: 0;
      }    
    }
  }

  &--flex {
    display: flex;
    justify-content: center;
    align-items: center;

    .nsw-material-icons {
      position: static;
    }
  }

  &--full-width {
    display: block;
    width: 100%;

    @include breakpoint($nsw-desktop-breakpoint) {
      display: inline-block;
      width: auto;
    }
  }
}

.nsw-button {
  @each $color, $property-map in $nsw-buttons {
    &--#{"" + $color} {
      color: map-get($property-map, text-color);
      background-color: map-get($property-map, background);
      border-color: map-get($property-map, border);

      &:visited {
        color: map-get($property-map, text-color);
      }

      &:focus {
        outline-color: map-get($property-map, focus);
      }

      &:hover {
        color: map-get($property-map, text-color-hover);
        background-color: map-get($property-map, background-hover);
        border-color: map-get($property-map, border-hover);
        @if map-get($property-map, hover) == white {
          background-image: linear-gradient(rgba(var(--nsw-white-rgb), 0.15), rgba(var(--nsw-white-rgb), 0.15));
        }
        @if map-get($property-map, hover) == black {
          background-image: linear-gradient(rgba(var(--nsw-black-rgb), 0.07), rgba(var(--nsw-black-rgb), 0.07));
        }

        .nsw-material-icons {
          color: map-get($property-map, text-color-hover);
        }
      }

      &:active {
        color: map-get($property-map, text-color-hover);
        background-color: map-get($property-map, background-hover);
        border-color: map-get($property-map, border-hover);
        @if map-get($property-map, hover) == white {
          background-image: linear-gradient(rgba(var(--nsw-white-rgb), 0.075), rgba(var(--nsw-white-rgb), 0.075));
        }
        @if map-get($property-map, hover) == black {
          background-image: linear-gradient(rgba(var(--nsw-black-rgb), 0.035), rgba(var(--nsw-black-rgb), 0.035));
        }

        .nsw-material-icons {
          color: map-get($property-map, text-color-hover);
        }
      }

      &:disabled,
      &.disabled {
        color: map-get($property-map, text-color);
        background-color: map-get($property-map, background);
        border-color: map-get($property-map, border);
      }

      .nsw-material-icons {
        color: map-get($property-map, text-color);
      }
    }
  }
}

.nsw-section--invert .nsw-button {
  @each $color, $property-map in $nsw-buttons {
    &--#{"" + $color} {
      color: map-get($property-map, text-color-invert);
      background-color: map-get($property-map, background-invert);
      border-color: map-get($property-map, border-invert);

      &:visited {
        color: map-get($property-map, text-color-invert);
      }

      &:focus {
        outline-color: map-get($property-map, focus-invert);
      }

      &:hover {
        color: map-get($property-map, text-color-hover-invert);
        background-color: map-get($property-map, background-hover-invert);
        border-color: map-get($property-map, border-hover-invert);
        @if map-get($property-map, hover-invert) == white {
          background-image: linear-gradient(rgba(var(--nsw-white-rgb), 0.15), rgba(var(--nsw-white-rgb), 0.15));
        }
        @if map-get($property-map, hover-invert) == black {
          background-image: linear-gradient(rgba(var(--nsw-black-rgb), 0.07), rgba(var(--nsw-black-rgb), 0.07));
        }

        .nsw-material-icons {
          color: map-get($property-map, text-color-hover-invert);
        }
      }

      &:active {
        color: map-get($property-map, text-color-hover-invert);
        background-color: map-get($property-map, background-hover-invert);
        border-color: map-get($property-map, border-hover-invert);
        @if map-get($property-map, hover) == white {
          background-image: linear-gradient(rgba(var(--nsw-white-rgb), 0.075), rgba(var(--nsw-white-rgb), 0.075));
        }
        @if map-get($property-map, hover) == black {
          background-image: linear-gradient(rgba(var(--nsw-black-rgb), 0.035), rgba(var(--nsw-black-rgb), 0.035));
        }

        .nsw-material-icons {
          color: map-get($property-map, text-color-hover-invert);
        }
      }

      &:disabled,
      &.disabled {
        color: map-get($property-map, text-color-invert);
        background-color: map-get($property-map, background-invert);
        border-color: map-get($property-map, border-invert);
      }

      .nsw-material-icons {
        color: map-get($property-map, text-color-invert);
      }
    }
  }
}

.nsw-dialog .nsw-button {
  @each $color, $property-map in $nsw-buttons {
    &--#{"" + $color} {
      color: map-get($property-map, text-color);
      background-color: map-get($property-map, background);
      border-color: map-get($property-map, border);

      &:visited {
        color: map-get($property-map, text-color);
      }

      &:focus {
        outline-color: map-get($property-map, focus);
      }

      &:hover {
        color: map-get($property-map, text-color-hover);
        background-color: map-get($property-map, background-hover);
        border-color: map-get($property-map, border-hover);
        @if map-get($property-map, hover) == white {
          background-image: linear-gradient(rgba(var(--nsw-white-rgb), 0.15), rgba(var(--nsw-white-rgb), 0.15));
        }
        @if map-get($property-map, hover) == black {
          background-image: linear-gradient(rgba(var(--nsw-black-rgb), 0.07), rgba(var(--nsw-black-rgb), 0.07));
        }

        .nsw-material-icons {
          color: map-get($property-map, text-color-hover);
        }
      }

      &:active {
        color: map-get($property-map, text-color-hover);
        background-color: map-get($property-map, background-hover);
        border-color: map-get($property-map, border-hover);
        @if map-get($property-map, hover) == white {
          background-image: linear-gradient(rgba(var(--nsw-white-rgb), 0.075), rgba(var(--nsw-white-rgb), 0.075));
        }
        @if map-get($property-map, hover) == black {
          background-image: linear-gradient(rgba(var(--nsw-black-rgb), 0.035), rgba(var(--nsw-black-rgb), 0.035));
        }

        .nsw-material-icons {
          color: map-get($property-map, text-color-hover);
        }
      }

      &:disabled,
      &.disabled {
        color: map-get($property-map, text-color);
        background-color: map-get($property-map, background);
        border-color: map-get($property-map, border);
      }

      .nsw-material-icons {
        color: map-get($property-map, text-color);
      }
    }
  }
}
