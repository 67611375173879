.nsw-in-page-nav {
  @include component-spacing();
  padding: rem(16px);
  border-left: solid 2px var(--nsw-brand-accent);

  &__title {
    @include font-size(md);
    font-weight: var(--nsw-font-bold);
    margin: 0;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    display: block;
    margin-top: rem(16px);
  }

  a:not(.nsw-icon-button) {
    line-height: 1.5;
    padding: rem(8px) rem(24px) rem(8px) 0;
    
    @include breakpoint('md') {
      padding: rem(4px) rem(4px) rem(4px) 0;
    }
  }
}
