.nsw-link-list {
  @include component-spacing();

  &__title {
    @include font-size('lg');
    font-weight: var(--nsw-font-bold);
    padding-bottom: rem(16px);
    
    @include breakpoint('md') {
      padding-bottom: rem(24px);
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    margin: 0;
    border-top: 1px solid var(--nsw-grey-04);

    &:last-child {
      border-bottom: 1px solid var(--nsw-grey-04);
    }
  }


  a {
    padding: rem(16px);
    color: var(--nsw-brand-dark);
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;

    &:hover {
      @include nsw-hover;
      outline-width: 0;
    }

    &:focus {
      outline-width: 3px;
    }

    span {
      text-decoration: underline;

      span.nsw-material-icons {
        bottom: rem(-5px);
      }
    }

    .nsw-material-icons {
      text-decoration: none;
      bottom: 0;
    }

    .nsw-section--invert & {    
      color: var(--nsw-text-light);

      &:hover {
        @include nsw-hover-light;
        outline-width: 0;
      }
    }
  }

  .nsw-material-icons {
    font-size: rem(map-get($nsw-icon-sizes, 20));
    margin-left: rem(16px);
  }
}
