.nsw-form {
  &__date {
    padding: 0;
    margin: 0;
    border: 0;

    legend {
      padding: 0;
    }

    &-wrapper {
      display: flex;
      width: 100%;
      margin: 0 rem(-8px);

      > div {
        padding: 0 rem(8px);
      }
    }

    &-input {
      width: rem(74px);

      &--large {
        width: rem(116px);
      }
    }
  }
}
