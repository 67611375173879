.nsw-pagination {
  @include component-spacing();

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;
  }

  li {
    margin: 0;

    > span {
      padding: rem(4px);
    }
  }

  a {
    display: block;
    text-decoration: none;
    text-align: center;
    padding: rem(4px);
    min-width: rem(30px);
    border-radius: var(--nsw-border-radius);

    &:hover {
      @include nsw-hover;
      outline-width: 0;

      .nsw-section--invert & {    
        @include nsw-hover-light;
        outline-width: 0;
      }
    }

    &:focus {
      outline-width: 3px;
    }

    &.active {
      position: relative;
      color: var(--nsw-text-dark);

      &:hover {
        background-color: transparent;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: rem(4px);
        right: rem(4px);
        height: 2px;
        background-color: var(--nsw-brand-accent);        
      }
      
      .nsw-section--invert & {    
        color: var(--nsw-text-light);
      }
    }
    
    &.disabled,
    .disabled > & {
      pointer-events: none;
      opacity: 0.4;
    }
  }
}
