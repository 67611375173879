

$white: #ffffff !default;
$grey-50: #f2f2f2 !default;
$grey-100: #ebebeb !default;
$grey-200: #dcdfe1 !default;
$grey-300: #cdd3d6 !default;
$grey-400: #acb3b7 !default;
$grey-500: #8b9296 !default;
$grey-600: #6a7175 !default;
$grey-700: #495054 !default;
$grey-800: #363c40 !default;
$grey-900: #22272b !default;
$grey-950: #151a1e !default;
$black: #000000 !default;

$grey-01: $grey-900 !default;
$grey-02: $grey-700 !default;
$grey-03: $grey-300 !default;
$grey-04: $grey-100 !default;

$green-01: #004000 !default;
$green-02: #00aa45 !default;
$green-03: #a8edb3 !default;
$green-04: #dbfadf !default;

$teal-01: #0b3f47 !default;
$teal-02: #2e808e !default;
$teal-03: #8cdbe5 !default;
$teal-04: #d1eeea !default;

$blue-01: #002664 !default;
$blue-02: #146cfd !default;
$blue-03: #8ce0ff !default;
$blue-04: #cbedfd !default;

$purple-01: #441170 !default;
$purple-02: #8055f1 !default;
$purple-03: #cebfff !default;
$purple-04: #e6e1fd !default;

$fuchsia-01: #65004d !default;
$fuchsia-02: #d912ae !default;
$fuchsia-03: #f4b5e6 !default;
$fuchsia-04: #fddef2 !default;

$red-01: #630019 !default;
$red-02: #d7153a !default;
$red-03: #ffb8c1 !default;
$red-04: #ffe6ea !default;

$orange-01: #941b00 !default;
$orange-02: #f3631b !default;
$orange-03: #ffce99 !default;
$orange-04: #fdeddf !default;

$yellow-01: #694800 !default;
$yellow-02: #faaf05 !default;
$yellow-03: #fde79a !default;
$yellow-04: #fff4cf !default;

$brown-01: #523719 !default;
$brown-02: #b68d5d !default;
$brown-03: #e8d0b5 !default;
$brown-04: #ede3d7 !default;

$brand-colors: (
  'blue-01': $blue-01,
  'blue-02': $blue-02,
  'blue-03': $blue-03,
  'blue-04': $blue-04,
  'purple-01': $purple-01,
  'purple-02': $purple-02,
  'purple-03': $purple-03,
  'purple-04': $purple-04,
  'fuchsia-01': $fuchsia-01,
  'fuchsia-02': $fuchsia-02,
  'fuchsia-03': $fuchsia-03,
  'fuchsia-04': $fuchsia-04,
  'red-01': $red-01,
  'red-02': $red-02,
  'red-03': $red-03,
  'red-04': $red-04,
  'orange-01': $orange-01,
  'orange-02': $orange-02,
  'orange-03': $orange-03,
  'orange-04': $orange-04,
  'brown-01': $brown-01,
  'brown-02': $brown-02,
  'brown-03': $brown-03,
  'brown-04': $brown-04,
  'yellow-01': $yellow-01,
  'yellow-02': $yellow-02,
  'yellow-03': $yellow-03,
  'yellow-04': $yellow-04,
  'green-01': $green-01,
  'green-02': $green-02,
  'green-03': $green-03,
  'green-04': $green-04,
  'teal-01': $teal-01,
  'teal-02': $teal-02,
  'teal-03': $teal-03,
  'teal-04': $teal-04,
  'black': $black,
  'grey-01': $grey-01,
  'grey-02': $grey-02,
  'grey-03': $grey-03,
  'grey-04': $grey-04,
  'off-white': $grey-50,
  'white': $white
) !default;

$brand-colors-rgb: map-loop($brand-colors, to-rgb, '$value') !default;

:root {
  @each $color, $value in $brand-colors {
    --nsw-palette-#{$color}: #{$value};
  }

  @each $color, $value in $brand-colors-rgb {
    --nsw-palette-#{$color}-rgb: #{$value};
  }
}
