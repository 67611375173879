//Variations
$nsw-loaders: (
  sm: (
    size: rem(24px),
    border: 3px,
  ),
  md: (
    size: rem(32px),
    border: 3px,
  ),
  lg: (
    size: rem(48px),
    border: 4px,
  )
);

.nsw-loader {
  text-align: center;
  width: fit-content;

  &__circle {
    position: relative;
    display: inline-flex;
    border-radius: 50%;
    border-style: solid;
    animation-name: nsw-loader-animation-rotate;
    animation-duration: 1.4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    width: rem(64px);
    height: rem(64px);
    border-width: 4px;
    transform-origin: 50% 50% calc((4rem / 2) + 4px);
    border-color: var(--nsw-grey-03);
    border-top-color: var(--nsw-brand-accent);
    
    @each $size, $variant in $nsw-loaders {
      &--#{"" + $size} {
        width: map-get($variant, size);
        height: map-get($variant, size);
        transform-origin: 50% 50% calc((#{map-get($variant, size)} / 2) + #{map-get($variant, border)});
        @if map-get($variant, border) == 3px {
          border-width: map-get($variant, border);
        }
      }
    }
  }

  &__label {
    display: block;    
  }
}

@keyframes nsw-loader-animation-rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
