.main-nav-active {
  overflow: hidden;
}

.nsw-main-nav {
  @include z-index('middle');
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--nsw-white);
  transition: all 0.25s ease-in-out;
  transform: translateX(-100%);
  visibility: hidden;
  overflow-y: auto;

  &.activating,
  &.active {
    transform: translateX(0);
    visibility: visible;
  }

  &.no-scroll {
    overflow-y: hidden;
  
    @include breakpoint($nsw-desktop-breakpoint) {
      overflow-y: visible;
    }
  }

  &.closing {
    transform: translateX(-100%);
  }

  @include breakpoint($nsw-desktop-breakpoint) {
    position: relative;
    transform: none;
    background-color: var(--nsw-off-white);
    top: auto;
    left: auto;
    transition: none;
    visibility: visible;
    overflow-y: visible;
    height: auto;
  }

  &__header {
    padding: rem(8px) rem(16px);
    border-bottom: solid 2px var(--nsw-brand-dark);
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint($nsw-desktop-breakpoint) {
      display: none;
    }

    .nsw-icon-button {
      &:first-child {
        margin-left: rem(-16px);
      }

      &:last-child {
        margin-right: rem(-16px);
      }
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;

    @include breakpoint($nsw-desktop-breakpoint) {
      @include container(0);
      display: flex;
      flex-direction: row;
      align-items: stretch;
      flex-wrap: wrap;
      position: relative;
    }

    > li {      
      @include breakpoint($nsw-desktop-breakpoint) {
        &.active {
          box-shadow: inset 0 -4px 0 var(--nsw-brand-dark);
        }
      }
    }

    > li > a {      
      @include breakpoint($nsw-desktop-breakpoint) {
        .nsw-material-icons {
          transform: rotate(90deg);
          margin-left: rem(6px);
          transition: transform $nsw-transition-duration, color $nsw-transition-duration;
        }

        &.active {
          background-color: var(--nsw-white);
          box-shadow: inset 0 -4px 0 var(--nsw-brand-dark);
        }

        &.active .nsw-material-icons {
          transform: rotate(270deg);
        }
      }
    }
  }

  &__sub-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {    
    margin: 0;
    border-bottom: solid 1px var(--nsw-grey-04);

    @include breakpoint($nsw-desktop-breakpoint) {
      border: 0;
    }

    a {
      padding: rem(16px);
      color: var(--nsw-text-dark);
      text-decoration: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;

      &:hover {
        @include nsw-hover;
      }

      .nsw-material-icons {
        color: var(--nsw-brand-dark);
        margin-right: rem(-8px);
      }

      @include breakpoint($nsw-desktop-breakpoint) {
        font-weight: var(--nsw-font-bold);
      }
    }
  }

  a {
    font-weight: var(--nsw-font-normal);

    @include breakpoint($nsw-desktop-breakpoint) {
      @include z-index('middle');
      position: relative;
    }

    &:hover {
      outline-width: 0;
    }

    &:focus {
      outline-width: 3px;
    }
  }

  &__title {
    font-weight: var(--nsw-font-bold);

    @include breakpoint($nsw-desktop-breakpoint) {
      @include font-size('md');
      display: inline-flex;
    }      

    .nsw-main-nav__sub-nav & {
      border-bottom: solid 1px var(--nsw-grey-04);

      @include breakpoint($nsw-desktop-breakpoint) {
        border-bottom: 0;
      }      
    }

    a {
      font-weight: var(--nsw-font-bold);

      .nsw-material-icons {
        display: none;

        @include breakpoint($nsw-desktop-breakpoint) {
          display: block;
        }  
      }
    }
  }

  &__description {
    @include font-size('xs');
    margin-top: rem(16px);
    display: none;

    @include breakpoint($nsw-desktop-breakpoint) {
      display: block;
    }
  }

  &__sub-nav {    
    @include z-index;
    background-color: var(--nsw-white);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.25s ease-in-out;
    transform: translateX(-100%);
    visibility: hidden;
    overflow-y: auto;

    &.active {
      transform: translateX(0);
      visibility: visible;
    }

    &.no-scroll {
      overflow-y: hidden;
    
      @include breakpoint($nsw-desktop-breakpoint) {
        overflow-y: visible;
      }
    }

    @include breakpoint($nsw-desktop-breakpoint) {
      @include container;
      padding: rem(48) rem(40);
      top: 100%;
      background-color: var(--nsw-white);
      box-shadow: 0 4px 9px -5px rgba(var(--nsw-black-rgb), 0.4);
      transition: none;
      transform: none;
      height: auto;
      overflow: auto;

      &.active {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    ul {      
      @include breakpoint($nsw-desktop-breakpoint) {
        margin-top: rem(32px);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .nsw-material-icons {
          display: none;
        }
      }
    }

    li {      
      @include breakpoint($nsw-desktop-breakpoint) {
        width: 32%;
        border-top: solid 1px var(--nsw-grey-04);

        &:nth-child(3n+2) {
          margin-left: 2%;
          margin-right: 2%;
        }

        &:nth-last-child(-n+3) {
          box-shadow: inset 0 -1px 0 var(--nsw-grey-04);
        }

        a {
          color: var(--nsw-brand-dark);
        }
      }
    }

    .nsw-main-nav__title {
      @include breakpoint($nsw-desktop-breakpoint) {
        a {      
          padding: 0;
          color: var(--nsw-brand-dark);
        }

        .nsw-material-icons {
          margin: 0 0 0 rem(12px);
        }
      }
    }

    .nsw-main-nav__sub-nav {
      @include breakpoint($nsw-desktop-breakpoint) {
        display: none;
      }
    }
  }
}
