@charset "UTF-8";
html {
  font-size: 100%;
  line-height: var(--nsw-line-height);
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
}

/* stylelint-disable */
/* stylelint-disable declaration-no-important  */
/* stylelint-enable declaration-no-important  */
/* stylelint-disable string-quotes, number-leading-zero */
/* stylelint-disable declaration-no-important, selector-no-qualifying-type  */
@media print {
  .nsw-infobar,
  .nsw-header,
  .nsw-footer,
  .nsw-pagination {
    display: none;
  }
  .nsw-accordion__content {
    display: block;
  }
  *,
  *::before,
  *::after {
    background: transparent !important;
    color: var(--nsw-black) !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]::after {
    content: " (" attr(href) ")";
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  a[href^="#"]::after,
  a[href^="javascript:"]::after {
    content: "";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid var(--nsw-black);
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}
/* stylelint-enable declaration-no-important, selector-no-qualifying-type  */
:root {
  --nsw-palette-blue-01: #002664;
  --nsw-palette-blue-02: #146cfd;
  --nsw-palette-blue-03: #8ce0ff;
  --nsw-palette-blue-04: #cbedfd;
  --nsw-palette-purple-01: #441170;
  --nsw-palette-purple-02: #8055f1;
  --nsw-palette-purple-03: #cebfff;
  --nsw-palette-purple-04: #e6e1fd;
  --nsw-palette-fuchsia-01: #65004d;
  --nsw-palette-fuchsia-02: #d912ae;
  --nsw-palette-fuchsia-03: #f4b5e6;
  --nsw-palette-fuchsia-04: #fddef2;
  --nsw-palette-red-01: #630019;
  --nsw-palette-red-02: #d7153a;
  --nsw-palette-red-03: #ffb8c1;
  --nsw-palette-red-04: #ffe6ea;
  --nsw-palette-orange-01: #941b00;
  --nsw-palette-orange-02: #f3631b;
  --nsw-palette-orange-03: #ffce99;
  --nsw-palette-orange-04: #fdeddf;
  --nsw-palette-brown-01: #523719;
  --nsw-palette-brown-02: #b68d5d;
  --nsw-palette-brown-03: #e8d0b5;
  --nsw-palette-brown-04: #ede3d7;
  --nsw-palette-yellow-01: #694800;
  --nsw-palette-yellow-02: #faaf05;
  --nsw-palette-yellow-03: #fde79a;
  --nsw-palette-yellow-04: #fff4cf;
  --nsw-palette-green-01: #004000;
  --nsw-palette-green-02: #00aa45;
  --nsw-palette-green-03: #a8edb3;
  --nsw-palette-green-04: #dbfadf;
  --nsw-palette-teal-01: #0b3f47;
  --nsw-palette-teal-02: #2e808e;
  --nsw-palette-teal-03: #8cdbe5;
  --nsw-palette-teal-04: #d1eeea;
  --nsw-palette-black: #000000;
  --nsw-palette-grey-01: #22272b;
  --nsw-palette-grey-02: #495054;
  --nsw-palette-grey-03: #cdd3d6;
  --nsw-palette-grey-04: #ebebeb;
  --nsw-palette-off-white: #f2f2f2;
  --nsw-palette-white: #ffffff;
  --nsw-palette-blue-01-rgb: 0, 38, 100;
  --nsw-palette-blue-02-rgb: 20, 108, 253;
  --nsw-palette-blue-03-rgb: 140, 224, 255;
  --nsw-palette-blue-04-rgb: 203, 237, 253;
  --nsw-palette-purple-01-rgb: 68, 17, 112;
  --nsw-palette-purple-02-rgb: 128, 85, 241;
  --nsw-palette-purple-03-rgb: 206, 191, 255;
  --nsw-palette-purple-04-rgb: 230, 225, 253;
  --nsw-palette-fuchsia-01-rgb: 101, 0, 77;
  --nsw-palette-fuchsia-02-rgb: 217, 18, 174;
  --nsw-palette-fuchsia-03-rgb: 244, 181, 230;
  --nsw-palette-fuchsia-04-rgb: 253, 222, 242;
  --nsw-palette-red-01-rgb: 99, 0, 25;
  --nsw-palette-red-02-rgb: 215, 21, 58;
  --nsw-palette-red-03-rgb: 255, 184, 193;
  --nsw-palette-red-04-rgb: 255, 230, 234;
  --nsw-palette-orange-01-rgb: 148, 27, 0;
  --nsw-palette-orange-02-rgb: 243, 99, 27;
  --nsw-palette-orange-03-rgb: 255, 206, 153;
  --nsw-palette-orange-04-rgb: 253, 237, 223;
  --nsw-palette-brown-01-rgb: 82, 55, 25;
  --nsw-palette-brown-02-rgb: 182, 141, 93;
  --nsw-palette-brown-03-rgb: 232, 208, 181;
  --nsw-palette-brown-04-rgb: 237, 227, 215;
  --nsw-palette-yellow-01-rgb: 105, 72, 0;
  --nsw-palette-yellow-02-rgb: 250, 175, 5;
  --nsw-palette-yellow-03-rgb: 253, 231, 154;
  --nsw-palette-yellow-04-rgb: 255, 244, 207;
  --nsw-palette-green-01-rgb: 0, 64, 0;
  --nsw-palette-green-02-rgb: 0, 170, 69;
  --nsw-palette-green-03-rgb: 168, 237, 179;
  --nsw-palette-green-04-rgb: 219, 250, 223;
  --nsw-palette-teal-01-rgb: 11, 63, 71;
  --nsw-palette-teal-02-rgb: 46, 128, 142;
  --nsw-palette-teal-03-rgb: 140, 219, 229;
  --nsw-palette-teal-04-rgb: 209, 238, 234;
  --nsw-palette-black-rgb: 0, 0, 0;
  --nsw-palette-grey-01-rgb: 34, 39, 43;
  --nsw-palette-grey-02-rgb: 73, 80, 84;
  --nsw-palette-grey-03-rgb: 205, 211, 214;
  --nsw-palette-grey-04-rgb: 235, 235, 235;
  --nsw-palette-off-white-rgb: 242, 242, 242;
  --nsw-palette-white-rgb: 255, 255, 255;
}

:root {
  --nsw-brand-dark: #002664;
  --nsw-brand-light: #cbedfd;
  --nsw-brand-supplementary: #146cfd;
  --nsw-brand-accent: #d7153a;
  --nsw-black: #000000;
  --nsw-grey-01: #22272b;
  --nsw-grey-02: #495054;
  --nsw-grey-03: #cdd3d6;
  --nsw-grey-04: #ebebeb;
  --nsw-off-white: #f2f2f2;
  --nsw-white: #ffffff;
  --nsw-brand-dark-rgb: 0, 38, 100;
  --nsw-brand-light-rgb: 203, 237, 253;
  --nsw-brand-supplementary-rgb: 20, 108, 253;
  --nsw-brand-accent-rgb: 215, 21, 58;
  --nsw-black-rgb: 0, 0, 0;
  --nsw-grey-01-rgb: 34, 39, 43;
  --nsw-grey-02-rgb: 73, 80, 84;
  --nsw-grey-03-rgb: 205, 211, 214;
  --nsw-grey-04-rgb: 235, 235, 235;
  --nsw-off-white-rgb: 242, 242, 242;
  --nsw-white-rgb: 255, 255, 255;
  --nsw-text-dark: #22272b;
  --nsw-text-light: #ffffff;
  --nsw-text-dark-rgb: 34, 39, 43;
  --nsw-text-light-rgb: 255, 255, 255;
  --nsw-link: #002664;
  --nsw-link-light: rgb(229.5, 233.3, 239.5);
  --nsw-visited: rgb(84.7726824, 26.00794, 139.01706);
  --nsw-visited-light: rgb(220.95453648, 209.201588, 231.803412);
  --nsw-hover: rgba(0, 38, 100, 0.1);
  --nsw-hover-light: rgba(229.5, 233.3, 239.5, 0.1);
  --nsw-active: rgba(0, 38, 100, 0.2);
  --nsw-active-light: rgba(229.5, 233.3, 239.5, 0.2);
  --nsw-focus: rgb(0, 133.6525866667, 178.999);
  --nsw-focus-light: rgb(204, 230.7305173333, 239.7998);
  --nsw-disabled: rgb(127.5, 146.5, 177.5);
  --nsw-link-rgb: 0, 38, 100;
  --nsw-link-light-rgb: 230, 233, 240;
  --nsw-visited-rgb: 85, 26, 139;
  --nsw-visited-light-rgb: 221, 209, 232;
  --nsw-hover-rgb: 0, 38, 100;
  --nsw-hover-light-rgb: 230, 233, 240;
  --nsw-active-rgb: 0, 38, 100;
  --nsw-active-light-rgb: 230, 233, 240;
  --nsw-focus-rgb: 0, 134, 179;
  --nsw-focus-light-rgb: 204, 231, 240;
  --nsw-disabled-rgb: 128, 147, 178;
  --nsw-status-success: #008a07;
  --nsw-status-success-bg: #e5f6e6;
  --nsw-status-info: #2e5299;
  --nsw-status-info-bg: #eaedf4;
  --nsw-status-warning: #c95000;
  --nsw-status-warning-bg: #fbeee5;
  --nsw-status-error: #b81237;
  --nsw-status-error-bg: #f7e7eb;
  --nsw-status-success-rgb: 0, 138, 7;
  --nsw-status-success-bg-rgb: 229, 246, 230;
  --nsw-status-info-rgb: 46, 82, 153;
  --nsw-status-info-bg-rgb: 234, 237, 244;
  --nsw-status-warning-rgb: 201, 80, 0;
  --nsw-status-warning-bg-rgb: 251, 238, 229;
  --nsw-status-error-rgb: 184, 18, 55;
  --nsw-status-error-bg-rgb: 247, 231, 235;
  /* Deprecated */
  --nsw-text-hover: var(--nsw-hover);
  --nsw-text-hover-light: var(--nsw-hover-light);
  --nsw-text-link: var(--nsw-link);
  --nsw-text-link-hover: var(--nsw-hover);
  --nsw-text-link-hover-light: var(--nsw-hover-light);
  --nsw-text-visited: var(--nsw-visited);
  /* Font */
  --nsw-font-family: "Public Sans", sans-serif;
  --nsw-font-size: 16px;
  --nsw-line-height: 1.5;
  --nsw-font-normal: 400;
  --nsw-font-bold: 700;
  --nsw-font-size-xxs-mobile: 0.75rem;
  --nsw-font-size-xxs-desktop: 0.75rem;
  --nsw-line-height-xxs-mobile: 1.5;
  --nsw-line-height-xxs-desktop: 1.5;
  --nsw-font-size-xs-mobile: 0.875rem;
  --nsw-font-size-xs-desktop: 0.875rem;
  --nsw-line-height-xs-mobile: 1.35;
  --nsw-line-height-xs-desktop: 1.35;
  --nsw-font-size-sm-mobile: 1rem;
  --nsw-font-size-sm-desktop: 1rem;
  --nsw-line-height-sm-mobile: 1.5;
  --nsw-line-height-sm-desktop: 1.5;
  --nsw-font-size-md-mobile: 1.125rem;
  --nsw-font-size-md-desktop: 1.25rem;
  --nsw-line-height-md-mobile: 1.33;
  --nsw-line-height-md-desktop: 1.4;
  --nsw-font-size-lg-mobile: 1.375rem;
  --nsw-font-size-lg-desktop: 1.5rem;
  --nsw-line-height-lg-mobile: 1.25;
  --nsw-line-height-lg-desktop: 1.33;
  --nsw-font-size-xl-mobile: 1.75rem;
  --nsw-font-size-xl-desktop: 2rem;
  --nsw-line-height-xl-mobile: 1.25;
  --nsw-line-height-xl-desktop: 1.25;
  --nsw-font-size-xxl-mobile: 2.25rem;
  --nsw-font-size-xxl-desktop: 3rem;
  --nsw-line-height-xxl-mobile: 1.25;
  --nsw-line-height-xxl-desktop: 1.25;
  /* Borders */
  --nsw-border-width: 1px;
  --nsw-border-style: solid;
  --nsw-border-color: var(--nsw-brand-accent);
  --nsw-border-radius: 0.25rem;
  /* Shadows */
  --nsw-box-shadow: 0 0.25rem 0.75rem 0 rgba(var(--nsw-black-rgb), 0.15);
  /* Maximum width of container */
  --nsw-container-width: 75rem;
}

.nsw-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.nsw-list--center {
  justify-content: center;
}
.nsw-list---8 {
  margin: 0.25rem;
}
.nsw-list---8 > * {
  margin: -0.25rem;
}
.nsw-list---16 {
  margin: 0.5rem;
}
.nsw-list---16 > * {
  margin: -0.5rem;
}
.nsw-list---32 {
  margin: 1rem;
}
.nsw-list---32 > * {
  margin: -1rem;
}
.nsw-list--8 {
  margin: -0.25rem;
}
.nsw-list--8 > * {
  margin: 0.25rem;
}
.nsw-list--16 {
  margin: -0.5rem;
}
.nsw-list--16 > * {
  margin: 0.5rem;
}
.nsw-list--32 {
  margin: -1rem;
}
.nsw-list--32 > * {
  margin: 1rem;
}

.nsw-word-break {
  word-break: break-all;
}
@media (min-width: 62rem) {
  .nsw-word-break--mobile {
    word-break: normal;
  }
}

.nsw-text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* stylelint-disable declaration-no-important */
.nsw-block {
  margin-top: 1.5rem;
}
.nsw-block:first-child {
  margin-top: 0;
}
@media (min-width: 48rem) {
  .nsw-block {
    margin-top: 2rem;
  }
}

.nsw-m-0 {
  margin: 0rem !important;
}

.nsw-m-top-0 {
  margin-top: 0rem !important;
}

.nsw-m-right-0 {
  margin-right: 0rem !important;
}

.nsw-m-bottom-0 {
  margin-bottom: 0rem !important;
}

.nsw-m-left-0 {
  margin-left: 0rem !important;
}

.nsw-m-x-0 {
  margin-left: 0rem !important;
  margin-right: 0rem !important;
}

.nsw-m-y-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}

@media (min-width: 62rem) {
  .nsw-m-0 {
    margin: 0rem !important;
  }
  .nsw-m-top-0 {
    margin-top: 0rem !important;
  }
  .nsw-m-right-0 {
    margin-right: 0rem !important;
  }
  .nsw-m-bottom-0 {
    margin-bottom: 0rem !important;
  }
  .nsw-m-left-0 {
    margin-left: 0rem !important;
  }
  .nsw-m-x-0 {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .nsw-m-y-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
}
.nsw-m-xs {
  margin: 0.25rem !important;
}

.nsw-m-top-xs {
  margin-top: 0.25rem !important;
}

.nsw-m-right-xs {
  margin-right: 0.25rem !important;
}

.nsw-m-bottom-xs {
  margin-bottom: 0.25rem !important;
}

.nsw-m-left-xs {
  margin-left: 0.25rem !important;
}

.nsw-m-x-xs {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.nsw-m-y-xs {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

@media (min-width: 62rem) {
  .nsw-m-xs {
    margin: 0.5rem !important;
  }
  .nsw-m-top-xs {
    margin-top: 0.5rem !important;
  }
  .nsw-m-right-xs {
    margin-right: 0.5rem !important;
  }
  .nsw-m-bottom-xs {
    margin-bottom: 0.5rem !important;
  }
  .nsw-m-left-xs {
    margin-left: 0.5rem !important;
  }
  .nsw-m-x-xs {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .nsw-m-y-xs {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
.nsw-m-sm {
  margin: 0.5rem !important;
}

.nsw-m-top-sm {
  margin-top: 0.5rem !important;
}

.nsw-m-right-sm {
  margin-right: 0.5rem !important;
}

.nsw-m-bottom-sm {
  margin-bottom: 0.5rem !important;
}

.nsw-m-left-sm {
  margin-left: 0.5rem !important;
}

.nsw-m-x-sm {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.nsw-m-y-sm {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

@media (min-width: 62rem) {
  .nsw-m-sm {
    margin: 1rem !important;
  }
  .nsw-m-top-sm {
    margin-top: 1rem !important;
  }
  .nsw-m-right-sm {
    margin-right: 1rem !important;
  }
  .nsw-m-bottom-sm {
    margin-bottom: 1rem !important;
  }
  .nsw-m-left-sm {
    margin-left: 1rem !important;
  }
  .nsw-m-x-sm {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .nsw-m-y-sm {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
.nsw-m-md {
  margin: 1rem !important;
}

.nsw-m-top-md {
  margin-top: 1rem !important;
}

.nsw-m-right-md {
  margin-right: 1rem !important;
}

.nsw-m-bottom-md {
  margin-bottom: 1rem !important;
}

.nsw-m-left-md {
  margin-left: 1rem !important;
}

.nsw-m-x-md {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.nsw-m-y-md {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

@media (min-width: 62rem) {
  .nsw-m-md {
    margin: 2rem !important;
  }
  .nsw-m-top-md {
    margin-top: 2rem !important;
  }
  .nsw-m-right-md {
    margin-right: 2rem !important;
  }
  .nsw-m-bottom-md {
    margin-bottom: 2rem !important;
  }
  .nsw-m-left-md {
    margin-left: 2rem !important;
  }
  .nsw-m-x-md {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .nsw-m-y-md {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
.nsw-m-lg {
  margin: 1.5rem !important;
}

.nsw-m-top-lg {
  margin-top: 1.5rem !important;
}

.nsw-m-right-lg {
  margin-right: 1.5rem !important;
}

.nsw-m-bottom-lg {
  margin-bottom: 1.5rem !important;
}

.nsw-m-left-lg {
  margin-left: 1.5rem !important;
}

.nsw-m-x-lg {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.nsw-m-y-lg {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

@media (min-width: 62rem) {
  .nsw-m-lg {
    margin: 3rem !important;
  }
  .nsw-m-top-lg {
    margin-top: 3rem !important;
  }
  .nsw-m-right-lg {
    margin-right: 3rem !important;
  }
  .nsw-m-bottom-lg {
    margin-bottom: 3rem !important;
  }
  .nsw-m-left-lg {
    margin-left: 3rem !important;
  }
  .nsw-m-x-lg {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .nsw-m-y-lg {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
}
.nsw-m-xl {
  margin: 2rem !important;
}

.nsw-m-top-xl {
  margin-top: 2rem !important;
}

.nsw-m-right-xl {
  margin-right: 2rem !important;
}

.nsw-m-bottom-xl {
  margin-bottom: 2rem !important;
}

.nsw-m-left-xl {
  margin-left: 2rem !important;
}

.nsw-m-x-xl {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.nsw-m-y-xl {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

@media (min-width: 62rem) {
  .nsw-m-xl {
    margin: 4rem !important;
  }
  .nsw-m-top-xl {
    margin-top: 4rem !important;
  }
  .nsw-m-right-xl {
    margin-right: 4rem !important;
  }
  .nsw-m-bottom-xl {
    margin-bottom: 4rem !important;
  }
  .nsw-m-left-xl {
    margin-left: 4rem !important;
  }
  .nsw-m-x-xl {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .nsw-m-y-xl {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
}
.nsw-m-xxl {
  margin: 2.5rem !important;
}

.nsw-m-top-xxl {
  margin-top: 2.5rem !important;
}

.nsw-m-right-xxl {
  margin-right: 2.5rem !important;
}

.nsw-m-bottom-xxl {
  margin-bottom: 2.5rem !important;
}

.nsw-m-left-xxl {
  margin-left: 2.5rem !important;
}

.nsw-m-x-xxl {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.nsw-m-y-xxl {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

@media (min-width: 62rem) {
  .nsw-m-xxl {
    margin: 5rem !important;
  }
  .nsw-m-top-xxl {
    margin-top: 5rem !important;
  }
  .nsw-m-right-xxl {
    margin-right: 5rem !important;
  }
  .nsw-m-bottom-xxl {
    margin-bottom: 5rem !important;
  }
  .nsw-m-left-xxl {
    margin-left: 5rem !important;
  }
  .nsw-m-x-xxl {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .nsw-m-y-xxl {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
}
.nsw-p-0 {
  padding: 0rem !important;
}

.nsw-p-top-0 {
  padding-top: 0rem !important;
}

.nsw-p-right-0 {
  padding-right: 0rem !important;
}

.nsw-p-bottom-0 {
  padding-bottom: 0rem !important;
}

.nsw-p-left-0 {
  padding-left: 0rem !important;
}

.nsw-p-x-0 {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}

.nsw-p-y-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

@media (min-width: 62rem) {
  .nsw-p-0 {
    padding: 0rem !important;
  }
  .nsw-p-top-0 {
    padding-top: 0rem !important;
  }
  .nsw-p-right-0 {
    padding-right: 0rem !important;
  }
  .nsw-p-bottom-0 {
    padding-bottom: 0rem !important;
  }
  .nsw-p-left-0 {
    padding-left: 0rem !important;
  }
  .nsw-p-x-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .nsw-p-y-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
}
.nsw-p-xs {
  padding: 0.25rem !important;
}

.nsw-p-top-xs {
  padding-top: 0.25rem !important;
}

.nsw-p-right-xs {
  padding-right: 0.25rem !important;
}

.nsw-p-bottom-xs {
  padding-bottom: 0.25rem !important;
}

.nsw-p-left-xs {
  padding-left: 0.25rem !important;
}

.nsw-p-x-xs {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.nsw-p-y-xs {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

@media (min-width: 62rem) {
  .nsw-p-xs {
    padding: 0.5rem !important;
  }
  .nsw-p-top-xs {
    padding-top: 0.5rem !important;
  }
  .nsw-p-right-xs {
    padding-right: 0.5rem !important;
  }
  .nsw-p-bottom-xs {
    padding-bottom: 0.5rem !important;
  }
  .nsw-p-left-xs {
    padding-left: 0.5rem !important;
  }
  .nsw-p-x-xs {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .nsw-p-y-xs {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
.nsw-p-sm {
  padding: 0.5rem !important;
}

.nsw-p-top-sm {
  padding-top: 0.5rem !important;
}

.nsw-p-right-sm {
  padding-right: 0.5rem !important;
}

.nsw-p-bottom-sm {
  padding-bottom: 0.5rem !important;
}

.nsw-p-left-sm {
  padding-left: 0.5rem !important;
}

.nsw-p-x-sm {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.nsw-p-y-sm {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

@media (min-width: 62rem) {
  .nsw-p-sm {
    padding: 1rem !important;
  }
  .nsw-p-top-sm {
    padding-top: 1rem !important;
  }
  .nsw-p-right-sm {
    padding-right: 1rem !important;
  }
  .nsw-p-bottom-sm {
    padding-bottom: 1rem !important;
  }
  .nsw-p-left-sm {
    padding-left: 1rem !important;
  }
  .nsw-p-x-sm {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .nsw-p-y-sm {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
.nsw-p-md {
  padding: 1rem !important;
}

.nsw-p-top-md {
  padding-top: 1rem !important;
}

.nsw-p-right-md {
  padding-right: 1rem !important;
}

.nsw-p-bottom-md {
  padding-bottom: 1rem !important;
}

.nsw-p-left-md {
  padding-left: 1rem !important;
}

.nsw-p-x-md {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.nsw-p-y-md {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

@media (min-width: 62rem) {
  .nsw-p-md {
    padding: 2rem !important;
  }
  .nsw-p-top-md {
    padding-top: 2rem !important;
  }
  .nsw-p-right-md {
    padding-right: 2rem !important;
  }
  .nsw-p-bottom-md {
    padding-bottom: 2rem !important;
  }
  .nsw-p-left-md {
    padding-left: 2rem !important;
  }
  .nsw-p-x-md {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .nsw-p-y-md {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
.nsw-p-lg {
  padding: 1.5rem !important;
}

.nsw-p-top-lg {
  padding-top: 1.5rem !important;
}

.nsw-p-right-lg {
  padding-right: 1.5rem !important;
}

.nsw-p-bottom-lg {
  padding-bottom: 1.5rem !important;
}

.nsw-p-left-lg {
  padding-left: 1.5rem !important;
}

.nsw-p-x-lg {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.nsw-p-y-lg {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

@media (min-width: 62rem) {
  .nsw-p-lg {
    padding: 3rem !important;
  }
  .nsw-p-top-lg {
    padding-top: 3rem !important;
  }
  .nsw-p-right-lg {
    padding-right: 3rem !important;
  }
  .nsw-p-bottom-lg {
    padding-bottom: 3rem !important;
  }
  .nsw-p-left-lg {
    padding-left: 3rem !important;
  }
  .nsw-p-x-lg {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .nsw-p-y-lg {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
}
.nsw-p-xl {
  padding: 2rem !important;
}

.nsw-p-top-xl {
  padding-top: 2rem !important;
}

.nsw-p-right-xl {
  padding-right: 2rem !important;
}

.nsw-p-bottom-xl {
  padding-bottom: 2rem !important;
}

.nsw-p-left-xl {
  padding-left: 2rem !important;
}

.nsw-p-x-xl {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.nsw-p-y-xl {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

@media (min-width: 62rem) {
  .nsw-p-xl {
    padding: 4rem !important;
  }
  .nsw-p-top-xl {
    padding-top: 4rem !important;
  }
  .nsw-p-right-xl {
    padding-right: 4rem !important;
  }
  .nsw-p-bottom-xl {
    padding-bottom: 4rem !important;
  }
  .nsw-p-left-xl {
    padding-left: 4rem !important;
  }
  .nsw-p-x-xl {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .nsw-p-y-xl {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
}
.nsw-p-xxl {
  padding: 2.5rem !important;
}

.nsw-p-top-xxl {
  padding-top: 2.5rem !important;
}

.nsw-p-right-xxl {
  padding-right: 2.5rem !important;
}

.nsw-p-bottom-xxl {
  padding-bottom: 2.5rem !important;
}

.nsw-p-left-xxl {
  padding-left: 2.5rem !important;
}

.nsw-p-x-xxl {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.nsw-p-y-xxl {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

@media (min-width: 62rem) {
  .nsw-p-xxl {
    padding: 5rem !important;
  }
  .nsw-p-top-xxl {
    padding-top: 5rem !important;
  }
  .nsw-p-right-xxl {
    padding-right: 5rem !important;
  }
  .nsw-p-bottom-xxl {
    padding-bottom: 5rem !important;
  }
  .nsw-p-left-xxl {
    padding-left: 5rem !important;
  }
  .nsw-p-x-xxl {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .nsw-p-y-xxl {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}
/* stylelint-disable declaration-no-important, string-quotes, number-leading-zero, scss/dollar-variable-default */
.nsw-vr {
  display: inline-block;
  align-self: stretch;
  width: var(--nsw-border-width);
  min-height: 1em;
  background-color: var(--nsw-text-dark);
  opacity: 0.5;
}

.sr-only,
.sr-only-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.sr-only:not(caption),
.sr-only-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.nsw-show-xs {
  display: none;
}
@media (min-width: 0rem) {
  .nsw-show-xs {
    display: block;
  }
}
.nsw-show-sm {
  display: none;
}
@media (min-width: 36rem) {
  .nsw-show-sm {
    display: block;
  }
}
.nsw-show-md {
  display: none;
}
@media (min-width: 48rem) {
  .nsw-show-md {
    display: block;
  }
}
.nsw-show-lg {
  display: none;
}
@media (min-width: 62rem) {
  .nsw-show-lg {
    display: block;
  }
}
.nsw-show-xl {
  display: none;
}
@media (min-width: 75rem) {
  .nsw-show-xl {
    display: block;
  }
}

@media (min-width: 0rem) {
  .nsw-hide-xs {
    display: none;
  }
}
@media (min-width: 36rem) {
  .nsw-hide-sm {
    display: none;
  }
}
@media (min-width: 48rem) {
  .nsw-hide-md {
    display: none;
  }
}
@media (min-width: 62rem) {
  .nsw-hide-lg {
    display: none;
  }
}
@media (min-width: 75rem) {
  .nsw-hide-xl {
    display: none;
  }
}

/* stylelint-disable string-quotes */
.nsw-align-baseline {
  vertical-align: baseline !important;
}

.nsw-align-top {
  vertical-align: top !important;
}

.nsw-align-middle {
  vertical-align: middle !important;
}

.nsw-align-bottom {
  vertical-align: bottom !important;
}

.nsw-align-text-bottom {
  vertical-align: text-bottom !important;
}

.nsw-align-text-top {
  vertical-align: text-top !important;
}

.nsw-float-left {
  float: left !important;
}

.nsw-float-right {
  float: right !important;
}

.nsw-float-none {
  float: none !important;
}

.nsw-object-fit-contain {
  object-fit: contain !important;
}

.nsw-object-fit-cover {
  object-fit: cover !important;
}

.nsw-object-fit-fill {
  object-fit: fill !important;
}

.nsw-object-fit-scale {
  object-fit: scale-down !important;
}

.nsw-object-fit-none {
  object-fit: none !important;
}

.nsw-opacity-0 {
  opacity: 0 !important;
}

.nsw-opacity-0-hover:hover {
  opacity: 0 !important;
}

.nsw-opacity-25 {
  opacity: 0.25 !important;
}

.nsw-opacity-25-hover:hover {
  opacity: 0.25 !important;
}

.nsw-opacity-50 {
  opacity: 0.5 !important;
}

.nsw-opacity-50-hover:hover {
  opacity: 0.5 !important;
}

.nsw-opacity-75 {
  opacity: 0.75 !important;
}

.nsw-opacity-75-hover:hover {
  opacity: 0.75 !important;
}

.nsw-opacity-100 {
  opacity: 1 !important;
}

.nsw-opacity-100-hover:hover {
  opacity: 1 !important;
}

.nsw-overflow-auto {
  overflow: auto !important;
}

.nsw-overflow-hidden {
  overflow: hidden !important;
}

.nsw-overflow-visible {
  overflow: visible !important;
}

.nsw-overflow-scroll {
  overflow: scroll !important;
}

.nsw-overflow-x-auto {
  overflow-x: auto !important;
}

.nsw-overflow-x-hidden {
  overflow-x: hidden !important;
}

.nsw-overflow-x-visible {
  overflow-x: visible !important;
}

.nsw-overflow-x-scroll {
  overflow-x: scroll !important;
}

.nsw-overflow-y-auto {
  overflow-y: auto !important;
}

.nsw-overflow-y-hidden {
  overflow-y: hidden !important;
}

.nsw-overflow-y-visible {
  overflow-y: visible !important;
}

.nsw-overflow-y-scroll {
  overflow-y: scroll !important;
}

.nsw-display-inline {
  display: inline !important;
}

.nsw-display-inline-block {
  display: inline-block !important;
}

.nsw-display-block {
  display: block !important;
}

.nsw-display-grid {
  display: grid !important;
}

.nsw-display-inline-grid {
  display: inline-grid !important;
}

.nsw-display-table {
  display: table !important;
}

.nsw-display-table-row {
  display: table-row !important;
}

.nsw-display-table-cell {
  display: table-cell !important;
}

.nsw-display-flex {
  display: flex !important;
}

.nsw-display-inline-flex {
  display: inline-flex !important;
}

.nsw-display-none {
  display: none !important;
}

.nsw-position-static {
  position: static !important;
}

.nsw-position-relative {
  position: relative !important;
}

.nsw-position-absolute {
  position: absolute !important;
}

.nsw-position-fixed {
  position: fixed !important;
}

.nsw-position-sticky {
  position: sticky !important;
}

.nsw-position-top-0 {
  top: 0 !important;
}

.nsw-position-top-50 {
  top: 50% !important;
}

.nsw-position-top-100 {
  top: 100% !important;
}

.nsw-position-bottom-0 {
  bottom: 0 !important;
}

.nsw-position-bottom-50 {
  bottom: 50% !important;
}

.nsw-position-bottom-100 {
  bottom: 100% !important;
}

.nsw-position-left-0 {
  left: 0 !important;
}

.nsw-position-left-50 {
  left: 50% !important;
}

.nsw-position-left-100 {
  left: 100% !important;
}

.nsw-position-right-0 {
  right: 0 !important;
}

.nsw-position-right-50 {
  right: 50% !important;
}

.nsw-position-right-100 {
  right: 100% !important;
}

.nsw-translate-middle {
  transform: translate(-50%, -50%) !important;
}

.nsw-translate-middle-x {
  transform: translateX(-50%) !important;
}

.nsw-translate-middle-y {
  transform: translateY(-50%) !important;
}

.nsw-border {
  border: var(--nsw-border-width) var(--nsw-border-style) var(--nsw-border-color) !important;
}

.nsw-border-0 {
  border: 0 !important;
}

.nsw-border--1 {
  border: var(--nsw-border-width) var(--nsw-border-style) var(--nsw-border-color) !important;
}

.nsw-border--2 {
  border: 2px var(--nsw-border-style) var(--nsw-border-color) !important;
}

.nsw-border--3 {
  border: 3px var(--nsw-border-style) var(--nsw-border-color) !important;
}

.nsw-border--4 {
  border: 4px var(--nsw-border-style) var(--nsw-border-color) !important;
}

.nsw-border--5 {
  border: 5px var(--nsw-border-style) var(--nsw-border-color) !important;
}

.nsw-border--6 {
  border: 6px var(--nsw-border-style) var(--nsw-border-color) !important;
}

.nsw-border--top {
  border-top: var(--nsw-border-width) var(--nsw-border-style) var(--nsw-border-color) !important;
}

.nsw-border--top-0 {
  border-top: 0 !important;
}

.nsw-border--right {
  border-right: var(--nsw-border-width) var(--nsw-border-style) var(--nsw-border-color) !important;
}

.nsw-border--right-0 {
  border-right: 0 !important;
}

.nsw-border--bottom {
  border-bottom: var(--nsw-border-width) var(--nsw-border-style) var(--nsw-border-color) !important;
}

.nsw-border--bottom-0 {
  border-bottom: 0 !important;
}

.nsw-border--left {
  border-left: var(--nsw-border-width) var(--nsw-border-style) var(--nsw-border-color) !important;
}

.nsw-border--left-0 {
  border-left: 0 !important;
}

.nsw-border--brand-dark {
  border-color: var(--nsw-brand-dark) !important;
}

.nsw-border--brand-dark-hover:hover {
  border-color: var(--nsw-brand-dark) !important;
}

.nsw-border--brand-light {
  border-color: var(--nsw-brand-light) !important;
}

.nsw-border--brand-light-hover:hover {
  border-color: var(--nsw-brand-light) !important;
}

.nsw-border--brand-supplementary {
  border-color: var(--nsw-brand-supplementary) !important;
}

.nsw-border--brand-supplementary-hover:hover {
  border-color: var(--nsw-brand-supplementary) !important;
}

.nsw-border--brand-accent {
  border-color: var(--nsw-brand-accent) !important;
}

.nsw-border--brand-accent-hover:hover {
  border-color: var(--nsw-brand-accent) !important;
}

.nsw-border--black {
  border-color: var(--nsw-black) !important;
}

.nsw-border--black-hover:hover {
  border-color: var(--nsw-black) !important;
}

.nsw-border--white {
  border-color: var(--nsw-white) !important;
}

.nsw-border--white-hover:hover {
  border-color: var(--nsw-white) !important;
}

.nsw-border--off-white {
  border-color: var(--nsw-off-white) !important;
}

.nsw-border--off-white-hover:hover {
  border-color: var(--nsw-off-white) !important;
}

.nsw-border--grey-01 {
  border-color: var(--nsw-grey-01) !important;
}

.nsw-border--grey-01-hover:hover {
  border-color: var(--nsw-grey-01) !important;
}

.nsw-border--grey-02 {
  border-color: var(--nsw-grey-02) !important;
}

.nsw-border--grey-02-hover:hover {
  border-color: var(--nsw-grey-02) !important;
}

.nsw-border--grey-03 {
  border-color: var(--nsw-grey-03) !important;
}

.nsw-border--grey-03-hover:hover {
  border-color: var(--nsw-grey-03) !important;
}

.nsw-border--grey-04 {
  border-color: var(--nsw-grey-04) !important;
}

.nsw-border--grey-04-hover:hover {
  border-color: var(--nsw-grey-04) !important;
}

.nsw-border-radius {
  border-radius: var(--nsw-border-radius) !important;
}

.nsw-border-radius-0 {
  border-radius: 0 !important;
}

.nsw-border-radius-circle {
  border-radius: 50% !important;
}

.nsw-box-shadow {
  box-shadow: var(--nsw-box-shadow) !important;
}

.nsw-box-shadow-none {
  box-shadow: none !important;
}

.nsw-width-4xs {
  width: 0.25rem !important;
}

.nsw-width-3xs {
  width: 0.5rem !important;
}

.nsw-width-2xs {
  width: 0.75rem !important;
}

.nsw-width-xs {
  width: 1rem !important;
}

.nsw-width-sm {
  width: 1.5rem !important;
}

.nsw-width-md {
  width: 2rem !important;
}

.nsw-width-lg {
  width: 3rem !important;
}

.nsw-width-xl {
  width: 4rem !important;
}

.nsw-width-2xl {
  width: 6rem !important;
}

.nsw-width-3xl {
  width: 8rem !important;
}

.nsw-width-4xl {
  width: 16rem !important;
}

.nsw-width-0 {
  width: 0 !important;
}

.nsw-width-10 {
  width: 10% !important;
}

.nsw-width-20 {
  width: 20% !important;
}

.nsw-width-25 {
  width: 25% !important;
}

.nsw-width-30 {
  width: 30% !important;
}

.nsw-width-33 {
  width: 33% !important;
}

.nsw-width-40 {
  width: 40% !important;
}

.nsw-width-50 {
  width: 50% !important;
}

.nsw-width-60 {
  width: 60% !important;
}

.nsw-width-70 {
  width: 70% !important;
}

.nsw-width-75 {
  width: 75% !important;
}

.nsw-width-80 {
  width: 80% !important;
}

.nsw-width-90 {
  width: 90% !important;
}

.nsw-width-100 {
  width: 100% !important;
}

.nsw-mw-100 {
  max-width: 100% !important;
}

.nsw-vw-100 {
  width: 100vw !important;
}

.nsw-min-vw-100 {
  min-width: 100vw !important;
}

.nsw-height-4xs {
  height: 0.25rem !important;
}

.nsw-height-3xs {
  height: 0.5rem !important;
}

.nsw-height-2xs {
  height: 0.75rem !important;
}

.nsw-height-xs {
  height: 1rem !important;
}

.nsw-height-sm {
  height: 1.5rem !important;
}

.nsw-height-md {
  height: 2rem !important;
}

.nsw-height-lg {
  height: 3rem !important;
}

.nsw-height-xl {
  height: 4rem !important;
}

.nsw-height-2xl {
  height: 6rem !important;
}

.nsw-height-3xl {
  height: 8rem !important;
}

.nsw-height-4xl {
  height: 16rem !important;
}

.nsw-height-0 {
  height: 0 !important;
}

.nsw-height-10 {
  height: 10% !important;
}

.nsw-height-20 {
  height: 20% !important;
}

.nsw-height-25 {
  height: 25% !important;
}

.nsw-height-30 {
  height: 30% !important;
}

.nsw-height-33 {
  height: 33% !important;
}

.nsw-height-40 {
  height: 40% !important;
}

.nsw-height-50 {
  height: 50% !important;
}

.nsw-height-60 {
  height: 60% !important;
}

.nsw-height-70 {
  height: 70% !important;
}

.nsw-height-75 {
  height: 75% !important;
}

.nsw-height-80 {
  height: 80% !important;
}

.nsw-height-90 {
  height: 90% !important;
}

.nsw-height-100 {
  height: 100% !important;
}

.nsw-mh-100 {
  max-height: 100% !important;
}

.nsw-vh-100 {
  height: 100vh !important;
}

.nsw-min-vh-100 {
  min-height: 100vh !important;
}

.nsw-flex-fill {
  flex: 1 1 auto !important;
}

.nsw-flex-row {
  flex-direction: row !important;
}

.nsw-flex-column {
  flex-direction: column !important;
}

.nsw-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.nsw-flex-column-reverse {
  flex-direction: column-reverse !important;
}

.nsw-flex-grow-0 {
  flex-grow: 0 !important;
}

.nsw-flex-grow-1 {
  flex-grow: 1 !important;
}

.nsw-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.nsw-flex-shrink-1 {
  flex-shrink: 1 !important;
}

.nsw-flex-wrap {
  flex-wrap: wrap !important;
}

.nsw-flex-nowrap {
  flex-wrap: nowrap !important;
}

.nsw-flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.nsw-justify-content-start {
  justify-content: flex-start !important;
}

.nsw-justify-content-end {
  justify-content: flex-end !important;
}

.nsw-justify-content-center {
  justify-content: center !important;
}

.nsw-justify-content-between {
  justify-content: space-between !important;
}

.nsw-justify-content-around {
  justify-content: space-around !important;
}

.nsw-justify-content-evenly {
  justify-content: space-evenly !important;
}

.nsw-align-items-start {
  align-items: flex-start !important;
}

.nsw-align-items-end {
  align-items: flex-end !important;
}

.nsw-align-items-center {
  align-items: center !important;
}

.nsw-align-items-baseline {
  align-items: baseline !important;
}

.nsw-align-items-stretch {
  align-items: stretch !important;
}

.nsw-align-content-start {
  align-content: flex-start !important;
}

.nsw-align-content-end {
  align-content: flex-end !important;
}

.nsw-align-content-center {
  align-content: center !important;
}

.nsw-align-content-between {
  align-content: space-between !important;
}

.nsw-align-content-around {
  align-content: space-around !important;
}

.nsw-align-content-stretch {
  align-content: stretch !important;
}

.nsw-align-self-auto {
  align-self: auto !important;
}

.nsw-align-self-start {
  align-self: flex-start !important;
}

.nsw-align-self-end {
  align-self: flex-end !important;
}

.nsw-align-self-center {
  align-self: center !important;
}

.nsw-align-self-baseline {
  align-self: baseline !important;
}

.nsw-align-self-stretch {
  align-self: stretch !important;
}

.nsw-order-first {
  order: -1 !important;
}

.nsw-order-0 {
  order: 0 !important;
}

.nsw-order-1 {
  order: 1 !important;
}

.nsw-order-2 {
  order: 2 !important;
}

.nsw-order-3 {
  order: 3 !important;
}

.nsw-order-4 {
  order: 4 !important;
}

.nsw-order-5 {
  order: 5 !important;
}

.nsw-order-last {
  order: 6 !important;
}

.nsw-text-italic {
  font-style: italic !important;
}

.nsw-text-normal {
  font-style: normal !important;
}

.nsw-text-lighter {
  font-weight: lighter !important;
}

.nsw-text-light {
  font-weight: 300 !important;
}

.nsw-text-normal {
  font-weight: 400 !important;
}

.nsw-text-medium {
  font-weight: 500 !important;
}

.nsw-text-semibold {
  font-weight: 600 !important;
}

.nsw-text-bold {
  font-weight: 700 !important;
}

.nsw-text-bolder {
  font-weight: bolder !important;
}

.nsw-text--brand-dark {
  color: var(--nsw-brand-dark) !important;
}

.nsw-text--brand-dark-hover:hover {
  color: var(--nsw-brand-dark) !important;
}

.nsw-text--brand-light {
  color: var(--nsw-brand-light) !important;
}

.nsw-text--brand-light-hover:hover {
  color: var(--nsw-brand-light) !important;
}

.nsw-text--brand-supplementary {
  color: var(--nsw-brand-supplementary) !important;
}

.nsw-text--brand-supplementary-hover:hover {
  color: var(--nsw-brand-supplementary) !important;
}

.nsw-text--brand-accent {
  color: var(--nsw-brand-accent) !important;
}

.nsw-text--brand-accent-hover:hover {
  color: var(--nsw-brand-accent) !important;
}

.nsw-text--black {
  color: var(--nsw-black) !important;
}

.nsw-text--black-hover:hover {
  color: var(--nsw-black) !important;
}

.nsw-text--white {
  color: var(--nsw-white) !important;
}

.nsw-text--white-hover:hover {
  color: var(--nsw-white) !important;
}

.nsw-text--off-white {
  color: var(--nsw-off-white) !important;
}

.nsw-text--off-white-hover:hover {
  color: var(--nsw-off-white) !important;
}

.nsw-text--grey-01 {
  color: var(--nsw-grey-01) !important;
}

.nsw-text--grey-01-hover:hover {
  color: var(--nsw-grey-01) !important;
}

.nsw-text--grey-02 {
  color: var(--nsw-grey-02) !important;
}

.nsw-text--grey-02-hover:hover {
  color: var(--nsw-grey-02) !important;
}

.nsw-text--grey-03 {
  color: var(--nsw-grey-03) !important;
}

.nsw-text--grey-03-hover:hover {
  color: var(--nsw-grey-03) !important;
}

.nsw-text--grey-04 {
  color: var(--nsw-grey-04) !important;
}

.nsw-text--grey-04-hover:hover {
  color: var(--nsw-grey-04) !important;
}

.nsw-text--dark {
  color: var(--nsw-text-dark) !important;
}

.nsw-text--dark-hover:hover {
  color: var(--nsw-text-dark) !important;
}

.nsw-text--light {
  color: var(--nsw-text-light) !important;
}

.nsw-text--light-hover:hover {
  color: var(--nsw-text-light) !important;
}

.nsw-text-left {
  text-align: left !important;
}

.nsw-text-right {
  text-align: right !important;
}

.nsw-text-center {
  text-align: center !important;
}

.nsw-text-none {
  text-decoration: none !important;
}

.nsw-text-underline {
  text-decoration: underline !important;
}

.nsw-text-line-through {
  text-decoration: line-through !important;
}

.nsw-text-lowercase {
  text-transform: lowercase !important;
}

.nsw-text-uppercase {
  text-transform: uppercase !important;
}

.nsw-text-capitalize {
  text-transform: capitalize !important;
}

.nsw-text-wrap {
  white-space: normal !important;
}

.nsw-text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.nsw-overflow-break {
  overflow-wrap: break-word !important;
}

/* rtl:end:remove */
/* rtl:begin:remove */
.nsw-word-break-normal {
  word-break: normal !important;
}

/* rtl:end:remove */
/* rtl:begin:remove */
.nsw-word-break-break-all {
  word-break: break-all !important;
}

/* rtl:end:remove */
/* rtl:begin:remove */
.nsw-word-break-keep-all {
  word-break: keep-all !important;
}

/* rtl:end:remove */
.nsw-bg--brand-dark {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-brand-dark-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--brand-dark-hover:hover {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-brand-dark-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--brand-light {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-brand-light-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--brand-light-hover:hover {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-brand-light-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--brand-supplementary {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-brand-supplementary-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--brand-supplementary-hover:hover {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-brand-supplementary-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--brand-accent {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-brand-accent-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--brand-accent-hover:hover {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-brand-accent-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--black {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-black-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--black-hover:hover {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-black-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--white {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-white-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--white-hover:hover {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-white-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--off-white {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-off-white-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--off-white-hover:hover {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-off-white-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--grey-01 {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-grey-01-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--grey-01-hover:hover {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-grey-01-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--grey-02 {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-grey-02-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--grey-02-hover:hover {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-grey-02-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--grey-03 {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-grey-03-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--grey-03-hover:hover {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-grey-03-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--grey-04 {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-grey-04-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--grey-04-hover:hover {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-grey-04-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--transparent {
  --nsw-bg-opacity: 1;
  background-color: transparent !important;
}

.nsw-bg--transparent-hover:hover {
  --nsw-bg-opacity: 1;
  background-color: transparent !important;
}

.nsw-bg--info-dark {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-status-info-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--info-dark-hover:hover {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-status-info-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--info-light {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-status-info-bg-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--info-light-hover:hover {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-status-info-bg-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--success-dark {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-status-success-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--success-dark-hover:hover {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-status-success-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--success-light {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-status-success-bg-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--success-light-hover:hover {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-status-success-bg-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--warning-dark {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-status-warning-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--warning-dark-hover:hover {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-status-warning-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--warning-light {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-status-warning-bg-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--warning-light-hover:hover {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-status-warning-bg-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--error-dark {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-status-error-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--error-dark-hover:hover {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-status-error-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--error-light {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-status-error-bg-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg--error-light-hover:hover {
  --nsw-bg-opacity: 1;
  background-color: rgba(var(--nsw-status-error-bg-rgb), var(--nsw-bg-opacity)) !important;
}

.nsw-bg-opacity--10 {
  --nsw-bg-opacity: 0.1;
}

.nsw-bg-opacity--25 {
  --nsw-bg-opacity: 0.25;
}

.nsw-bg-opacity--50 {
  --nsw-bg-opacity: 0.5;
}

.nsw-bg-opacity--75 {
  --nsw-bg-opacity: 0.75;
}

.nsw-bg-opacity--100 {
  --nsw-bg-opacity: 1;
}

.nsw-fill-brand-dark {
  fill: var(--nsw-brand-dark) !important;
}

.nsw-fill-brand-dark-hover:hover {
  fill: var(--nsw-brand-dark) !important;
}

.nsw-fill-brand-light {
  fill: var(--nsw-brand-light) !important;
}

.nsw-fill-brand-light-hover:hover {
  fill: var(--nsw-brand-light) !important;
}

.nsw-fill-brand-supplementary {
  fill: var(--nsw-brand-supplementary) !important;
}

.nsw-fill-brand-supplementary-hover:hover {
  fill: var(--nsw-brand-supplementary) !important;
}

.nsw-fill-brand-accent {
  fill: var(--nsw-brand-accent) !important;
}

.nsw-fill-brand-accent-hover:hover {
  fill: var(--nsw-brand-accent) !important;
}

.nsw-fill-black {
  fill: var(--nsw-black) !important;
}

.nsw-fill-black-hover:hover {
  fill: var(--nsw-black) !important;
}

.nsw-fill-white {
  fill: var(--nsw-white) !important;
}

.nsw-fill-white-hover:hover {
  fill: var(--nsw-white) !important;
}

.nsw-fill-off-white {
  fill: var(--nsw-off-white) !important;
}

.nsw-fill-off-white-hover:hover {
  fill: var(--nsw-off-white) !important;
}

.nsw-fill-grey-01 {
  fill: var(--nsw-grey-01) !important;
}

.nsw-fill-grey-01-hover:hover {
  fill: var(--nsw-grey-01) !important;
}

.nsw-fill-grey-02 {
  fill: var(--nsw-grey-02) !important;
}

.nsw-fill-grey-02-hover:hover {
  fill: var(--nsw-grey-02) !important;
}

.nsw-fill-grey-03 {
  fill: var(--nsw-grey-03) !important;
}

.nsw-fill-grey-03-hover:hover {
  fill: var(--nsw-grey-03) !important;
}

.nsw-fill-grey-04 {
  fill: var(--nsw-grey-04) !important;
}

.nsw-fill-grey-04-hover:hover {
  fill: var(--nsw-grey-04) !important;
}

.nsw-stroke-brand-dark {
  stroke: var(--nsw-brand-dark) !important;
}

.nsw-stroke-brand-dark-hover:hover {
  stroke: var(--nsw-brand-dark) !important;
}

.nsw-stroke-brand-light {
  stroke: var(--nsw-brand-light) !important;
}

.nsw-stroke-brand-light-hover:hover {
  stroke: var(--nsw-brand-light) !important;
}

.nsw-stroke-brand-supplementary {
  stroke: var(--nsw-brand-supplementary) !important;
}

.nsw-stroke-brand-supplementary-hover:hover {
  stroke: var(--nsw-brand-supplementary) !important;
}

.nsw-stroke-brand-accent {
  stroke: var(--nsw-brand-accent) !important;
}

.nsw-stroke-brand-accent-hover:hover {
  stroke: var(--nsw-brand-accent) !important;
}

.nsw-stroke-black {
  stroke: var(--nsw-black) !important;
}

.nsw-stroke-black-hover:hover {
  stroke: var(--nsw-black) !important;
}

.nsw-stroke-white {
  stroke: var(--nsw-white) !important;
}

.nsw-stroke-white-hover:hover {
  stroke: var(--nsw-white) !important;
}

.nsw-stroke-off-white {
  stroke: var(--nsw-off-white) !important;
}

.nsw-stroke-off-white-hover:hover {
  stroke: var(--nsw-off-white) !important;
}

.nsw-stroke-grey-01 {
  stroke: var(--nsw-grey-01) !important;
}

.nsw-stroke-grey-01-hover:hover {
  stroke: var(--nsw-grey-01) !important;
}

.nsw-stroke-grey-02 {
  stroke: var(--nsw-grey-02) !important;
}

.nsw-stroke-grey-02-hover:hover {
  stroke: var(--nsw-grey-02) !important;
}

.nsw-stroke-grey-03 {
  stroke: var(--nsw-grey-03) !important;
}

.nsw-stroke-grey-03-hover:hover {
  stroke: var(--nsw-grey-03) !important;
}

.nsw-stroke-grey-04 {
  stroke: var(--nsw-grey-04) !important;
}

.nsw-stroke-grey-04-hover:hover {
  stroke: var(--nsw-grey-04) !important;
}

.nsw-visible {
  visibility: visible !important;
}

.nsw-hidden {
  visibility: hidden !important;
}

.nsw-z-top {
  z-index: 900 !important;
}

.nsw-z-upper {
  z-index: 300 !important;
}

.nsw-z-middle {
  z-index: 200 !important;
}

.nsw-z-base {
  z-index: 100 !important;
}

.nsw-z-0 {
  z-index: 0 !important;
}

.nsw-z-below {
  z-index: -100 !important;
}

@media (min-width: 576px) {
  .nsw-float-sm-left {
    float: left !important;
  }
  .nsw-float-sm-right {
    float: right !important;
  }
  .nsw-float-sm-none {
    float: none !important;
  }
  .nsw-object-fit-sm-contain {
    object-fit: contain !important;
  }
  .nsw-object-fit-sm-cover {
    object-fit: cover !important;
  }
  .nsw-object-fit-sm-fill {
    object-fit: fill !important;
  }
  .nsw-object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .nsw-object-fit-sm-none {
    object-fit: none !important;
  }
  .nsw-display-sm-inline {
    display: inline !important;
  }
  .nsw-display-sm-inline-block {
    display: inline-block !important;
  }
  .nsw-display-sm-block {
    display: block !important;
  }
  .nsw-display-sm-grid {
    display: grid !important;
  }
  .nsw-display-sm-inline-grid {
    display: inline-grid !important;
  }
  .nsw-display-sm-table {
    display: table !important;
  }
  .nsw-display-sm-table-row {
    display: table-row !important;
  }
  .nsw-display-sm-table-cell {
    display: table-cell !important;
  }
  .nsw-display-sm-flex {
    display: flex !important;
  }
  .nsw-display-sm-inline-flex {
    display: inline-flex !important;
  }
  .nsw-display-sm-none {
    display: none !important;
  }
  .nsw-width-sm-4xs {
    width: 0.25rem !important;
  }
  .nsw-width-sm-3xs {
    width: 0.5rem !important;
  }
  .nsw-width-sm-2xs {
    width: 0.75rem !important;
  }
  .nsw-width-sm-xs {
    width: 1rem !important;
  }
  .nsw-width-sm-sm {
    width: 1.5rem !important;
  }
  .nsw-width-sm-md {
    width: 2rem !important;
  }
  .nsw-width-sm-lg {
    width: 3rem !important;
  }
  .nsw-width-sm-xl {
    width: 4rem !important;
  }
  .nsw-width-sm-2xl {
    width: 6rem !important;
  }
  .nsw-width-sm-3xl {
    width: 8rem !important;
  }
  .nsw-width-sm-4xl {
    width: 16rem !important;
  }
  .nsw-width-sm-0 {
    width: 0 !important;
  }
  .nsw-width-sm-10 {
    width: 10% !important;
  }
  .nsw-width-sm-20 {
    width: 20% !important;
  }
  .nsw-width-sm-25 {
    width: 25% !important;
  }
  .nsw-width-sm-30 {
    width: 30% !important;
  }
  .nsw-width-sm-33 {
    width: 33% !important;
  }
  .nsw-width-sm-40 {
    width: 40% !important;
  }
  .nsw-width-sm-50 {
    width: 50% !important;
  }
  .nsw-width-sm-60 {
    width: 60% !important;
  }
  .nsw-width-sm-70 {
    width: 70% !important;
  }
  .nsw-width-sm-75 {
    width: 75% !important;
  }
  .nsw-width-sm-80 {
    width: 80% !important;
  }
  .nsw-width-sm-90 {
    width: 90% !important;
  }
  .nsw-width-sm-100 {
    width: 100% !important;
  }
  .nsw-flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .nsw-flex-sm-row {
    flex-direction: row !important;
  }
  .nsw-flex-sm-column {
    flex-direction: column !important;
  }
  .nsw-flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .nsw-flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .nsw-flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .nsw-flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .nsw-flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .nsw-flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .nsw-flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .nsw-flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .nsw-flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .nsw-justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .nsw-justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .nsw-justify-content-sm-center {
    justify-content: center !important;
  }
  .nsw-justify-content-sm-between {
    justify-content: space-between !important;
  }
  .nsw-justify-content-sm-around {
    justify-content: space-around !important;
  }
  .nsw-justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .nsw-align-items-sm-start {
    align-items: flex-start !important;
  }
  .nsw-align-items-sm-end {
    align-items: flex-end !important;
  }
  .nsw-align-items-sm-center {
    align-items: center !important;
  }
  .nsw-align-items-sm-baseline {
    align-items: baseline !important;
  }
  .nsw-align-items-sm-stretch {
    align-items: stretch !important;
  }
  .nsw-align-content-sm-start {
    align-content: flex-start !important;
  }
  .nsw-align-content-sm-end {
    align-content: flex-end !important;
  }
  .nsw-align-content-sm-center {
    align-content: center !important;
  }
  .nsw-align-content-sm-between {
    align-content: space-between !important;
  }
  .nsw-align-content-sm-around {
    align-content: space-around !important;
  }
  .nsw-align-content-sm-stretch {
    align-content: stretch !important;
  }
  .nsw-align-self-sm-auto {
    align-self: auto !important;
  }
  .nsw-align-self-sm-start {
    align-self: flex-start !important;
  }
  .nsw-align-self-sm-end {
    align-self: flex-end !important;
  }
  .nsw-align-self-sm-center {
    align-self: center !important;
  }
  .nsw-align-self-sm-baseline {
    align-self: baseline !important;
  }
  .nsw-align-self-sm-stretch {
    align-self: stretch !important;
  }
  .nsw-order-sm-first {
    order: -1 !important;
  }
  .nsw-order-sm-0 {
    order: 0 !important;
  }
  .nsw-order-sm-1 {
    order: 1 !important;
  }
  .nsw-order-sm-2 {
    order: 2 !important;
  }
  .nsw-order-sm-3 {
    order: 3 !important;
  }
  .nsw-order-sm-4 {
    order: 4 !important;
  }
  .nsw-order-sm-5 {
    order: 5 !important;
  }
  .nsw-order-sm-last {
    order: 6 !important;
  }
  .nsw-text-sm-left {
    text-align: left !important;
  }
  .nsw-text-sm-right {
    text-align: right !important;
  }
  .nsw-text-sm-center {
    text-align: center !important;
  }
  /* rtl:begin:remove */
  .nsw-overflow-sm-break {
    overflow-wrap: break-word !important;
  }
  /* rtl:end:remove */
  /* rtl:begin:remove */
  .nsw-word-break-sm-normal {
    word-break: normal !important;
  }
  /* rtl:end:remove */
  /* rtl:begin:remove */
  .nsw-word-break-sm-break-all {
    word-break: break-all !important;
  }
  /* rtl:end:remove */
  /* rtl:begin:remove */
  .nsw-word-break-sm-keep-all {
    word-break: keep-all !important;
  }
  /* rtl:end:remove */
}
@media (min-width: 768px) {
  .nsw-float-md-left {
    float: left !important;
  }
  .nsw-float-md-right {
    float: right !important;
  }
  .nsw-float-md-none {
    float: none !important;
  }
  .nsw-object-fit-md-contain {
    object-fit: contain !important;
  }
  .nsw-object-fit-md-cover {
    object-fit: cover !important;
  }
  .nsw-object-fit-md-fill {
    object-fit: fill !important;
  }
  .nsw-object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .nsw-object-fit-md-none {
    object-fit: none !important;
  }
  .nsw-display-md-inline {
    display: inline !important;
  }
  .nsw-display-md-inline-block {
    display: inline-block !important;
  }
  .nsw-display-md-block {
    display: block !important;
  }
  .nsw-display-md-grid {
    display: grid !important;
  }
  .nsw-display-md-inline-grid {
    display: inline-grid !important;
  }
  .nsw-display-md-table {
    display: table !important;
  }
  .nsw-display-md-table-row {
    display: table-row !important;
  }
  .nsw-display-md-table-cell {
    display: table-cell !important;
  }
  .nsw-display-md-flex {
    display: flex !important;
  }
  .nsw-display-md-inline-flex {
    display: inline-flex !important;
  }
  .nsw-display-md-none {
    display: none !important;
  }
  .nsw-width-md-4xs {
    width: 0.25rem !important;
  }
  .nsw-width-md-3xs {
    width: 0.5rem !important;
  }
  .nsw-width-md-2xs {
    width: 0.75rem !important;
  }
  .nsw-width-md-xs {
    width: 1rem !important;
  }
  .nsw-width-md-sm {
    width: 1.5rem !important;
  }
  .nsw-width-md-md {
    width: 2rem !important;
  }
  .nsw-width-md-lg {
    width: 3rem !important;
  }
  .nsw-width-md-xl {
    width: 4rem !important;
  }
  .nsw-width-md-2xl {
    width: 6rem !important;
  }
  .nsw-width-md-3xl {
    width: 8rem !important;
  }
  .nsw-width-md-4xl {
    width: 16rem !important;
  }
  .nsw-width-md-0 {
    width: 0 !important;
  }
  .nsw-width-md-10 {
    width: 10% !important;
  }
  .nsw-width-md-20 {
    width: 20% !important;
  }
  .nsw-width-md-25 {
    width: 25% !important;
  }
  .nsw-width-md-30 {
    width: 30% !important;
  }
  .nsw-width-md-33 {
    width: 33% !important;
  }
  .nsw-width-md-40 {
    width: 40% !important;
  }
  .nsw-width-md-50 {
    width: 50% !important;
  }
  .nsw-width-md-60 {
    width: 60% !important;
  }
  .nsw-width-md-70 {
    width: 70% !important;
  }
  .nsw-width-md-75 {
    width: 75% !important;
  }
  .nsw-width-md-80 {
    width: 80% !important;
  }
  .nsw-width-md-90 {
    width: 90% !important;
  }
  .nsw-width-md-100 {
    width: 100% !important;
  }
  .nsw-flex-md-fill {
    flex: 1 1 auto !important;
  }
  .nsw-flex-md-row {
    flex-direction: row !important;
  }
  .nsw-flex-md-column {
    flex-direction: column !important;
  }
  .nsw-flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .nsw-flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .nsw-flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .nsw-flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .nsw-flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .nsw-flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .nsw-flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .nsw-flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .nsw-flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .nsw-justify-content-md-start {
    justify-content: flex-start !important;
  }
  .nsw-justify-content-md-end {
    justify-content: flex-end !important;
  }
  .nsw-justify-content-md-center {
    justify-content: center !important;
  }
  .nsw-justify-content-md-between {
    justify-content: space-between !important;
  }
  .nsw-justify-content-md-around {
    justify-content: space-around !important;
  }
  .nsw-justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .nsw-align-items-md-start {
    align-items: flex-start !important;
  }
  .nsw-align-items-md-end {
    align-items: flex-end !important;
  }
  .nsw-align-items-md-center {
    align-items: center !important;
  }
  .nsw-align-items-md-baseline {
    align-items: baseline !important;
  }
  .nsw-align-items-md-stretch {
    align-items: stretch !important;
  }
  .nsw-align-content-md-start {
    align-content: flex-start !important;
  }
  .nsw-align-content-md-end {
    align-content: flex-end !important;
  }
  .nsw-align-content-md-center {
    align-content: center !important;
  }
  .nsw-align-content-md-between {
    align-content: space-between !important;
  }
  .nsw-align-content-md-around {
    align-content: space-around !important;
  }
  .nsw-align-content-md-stretch {
    align-content: stretch !important;
  }
  .nsw-align-self-md-auto {
    align-self: auto !important;
  }
  .nsw-align-self-md-start {
    align-self: flex-start !important;
  }
  .nsw-align-self-md-end {
    align-self: flex-end !important;
  }
  .nsw-align-self-md-center {
    align-self: center !important;
  }
  .nsw-align-self-md-baseline {
    align-self: baseline !important;
  }
  .nsw-align-self-md-stretch {
    align-self: stretch !important;
  }
  .nsw-order-md-first {
    order: -1 !important;
  }
  .nsw-order-md-0 {
    order: 0 !important;
  }
  .nsw-order-md-1 {
    order: 1 !important;
  }
  .nsw-order-md-2 {
    order: 2 !important;
  }
  .nsw-order-md-3 {
    order: 3 !important;
  }
  .nsw-order-md-4 {
    order: 4 !important;
  }
  .nsw-order-md-5 {
    order: 5 !important;
  }
  .nsw-order-md-last {
    order: 6 !important;
  }
  .nsw-text-md-left {
    text-align: left !important;
  }
  .nsw-text-md-right {
    text-align: right !important;
  }
  .nsw-text-md-center {
    text-align: center !important;
  }
  /* rtl:begin:remove */
  .nsw-overflow-md-break {
    overflow-wrap: break-word !important;
  }
  /* rtl:end:remove */
  /* rtl:begin:remove */
  .nsw-word-break-md-normal {
    word-break: normal !important;
  }
  /* rtl:end:remove */
  /* rtl:begin:remove */
  .nsw-word-break-md-break-all {
    word-break: break-all !important;
  }
  /* rtl:end:remove */
  /* rtl:begin:remove */
  .nsw-word-break-md-keep-all {
    word-break: keep-all !important;
  }
  /* rtl:end:remove */
}
@media (min-width: 992px) {
  .nsw-float-lg-left {
    float: left !important;
  }
  .nsw-float-lg-right {
    float: right !important;
  }
  .nsw-float-lg-none {
    float: none !important;
  }
  .nsw-object-fit-lg-contain {
    object-fit: contain !important;
  }
  .nsw-object-fit-lg-cover {
    object-fit: cover !important;
  }
  .nsw-object-fit-lg-fill {
    object-fit: fill !important;
  }
  .nsw-object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .nsw-object-fit-lg-none {
    object-fit: none !important;
  }
  .nsw-display-lg-inline {
    display: inline !important;
  }
  .nsw-display-lg-inline-block {
    display: inline-block !important;
  }
  .nsw-display-lg-block {
    display: block !important;
  }
  .nsw-display-lg-grid {
    display: grid !important;
  }
  .nsw-display-lg-inline-grid {
    display: inline-grid !important;
  }
  .nsw-display-lg-table {
    display: table !important;
  }
  .nsw-display-lg-table-row {
    display: table-row !important;
  }
  .nsw-display-lg-table-cell {
    display: table-cell !important;
  }
  .nsw-display-lg-flex {
    display: flex !important;
  }
  .nsw-display-lg-inline-flex {
    display: inline-flex !important;
  }
  .nsw-display-lg-none {
    display: none !important;
  }
  .nsw-width-lg-4xs {
    width: 0.25rem !important;
  }
  .nsw-width-lg-3xs {
    width: 0.5rem !important;
  }
  .nsw-width-lg-2xs {
    width: 0.75rem !important;
  }
  .nsw-width-lg-xs {
    width: 1rem !important;
  }
  .nsw-width-lg-sm {
    width: 1.5rem !important;
  }
  .nsw-width-lg-md {
    width: 2rem !important;
  }
  .nsw-width-lg-lg {
    width: 3rem !important;
  }
  .nsw-width-lg-xl {
    width: 4rem !important;
  }
  .nsw-width-lg-2xl {
    width: 6rem !important;
  }
  .nsw-width-lg-3xl {
    width: 8rem !important;
  }
  .nsw-width-lg-4xl {
    width: 16rem !important;
  }
  .nsw-width-lg-0 {
    width: 0 !important;
  }
  .nsw-width-lg-10 {
    width: 10% !important;
  }
  .nsw-width-lg-20 {
    width: 20% !important;
  }
  .nsw-width-lg-25 {
    width: 25% !important;
  }
  .nsw-width-lg-30 {
    width: 30% !important;
  }
  .nsw-width-lg-33 {
    width: 33% !important;
  }
  .nsw-width-lg-40 {
    width: 40% !important;
  }
  .nsw-width-lg-50 {
    width: 50% !important;
  }
  .nsw-width-lg-60 {
    width: 60% !important;
  }
  .nsw-width-lg-70 {
    width: 70% !important;
  }
  .nsw-width-lg-75 {
    width: 75% !important;
  }
  .nsw-width-lg-80 {
    width: 80% !important;
  }
  .nsw-width-lg-90 {
    width: 90% !important;
  }
  .nsw-width-lg-100 {
    width: 100% !important;
  }
  .nsw-flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .nsw-flex-lg-row {
    flex-direction: row !important;
  }
  .nsw-flex-lg-column {
    flex-direction: column !important;
  }
  .nsw-flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .nsw-flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .nsw-flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .nsw-flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .nsw-flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .nsw-flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .nsw-flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .nsw-flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .nsw-flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .nsw-justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .nsw-justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .nsw-justify-content-lg-center {
    justify-content: center !important;
  }
  .nsw-justify-content-lg-between {
    justify-content: space-between !important;
  }
  .nsw-justify-content-lg-around {
    justify-content: space-around !important;
  }
  .nsw-justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .nsw-align-items-lg-start {
    align-items: flex-start !important;
  }
  .nsw-align-items-lg-end {
    align-items: flex-end !important;
  }
  .nsw-align-items-lg-center {
    align-items: center !important;
  }
  .nsw-align-items-lg-baseline {
    align-items: baseline !important;
  }
  .nsw-align-items-lg-stretch {
    align-items: stretch !important;
  }
  .nsw-align-content-lg-start {
    align-content: flex-start !important;
  }
  .nsw-align-content-lg-end {
    align-content: flex-end !important;
  }
  .nsw-align-content-lg-center {
    align-content: center !important;
  }
  .nsw-align-content-lg-between {
    align-content: space-between !important;
  }
  .nsw-align-content-lg-around {
    align-content: space-around !important;
  }
  .nsw-align-content-lg-stretch {
    align-content: stretch !important;
  }
  .nsw-align-self-lg-auto {
    align-self: auto !important;
  }
  .nsw-align-self-lg-start {
    align-self: flex-start !important;
  }
  .nsw-align-self-lg-end {
    align-self: flex-end !important;
  }
  .nsw-align-self-lg-center {
    align-self: center !important;
  }
  .nsw-align-self-lg-baseline {
    align-self: baseline !important;
  }
  .nsw-align-self-lg-stretch {
    align-self: stretch !important;
  }
  .nsw-order-lg-first {
    order: -1 !important;
  }
  .nsw-order-lg-0 {
    order: 0 !important;
  }
  .nsw-order-lg-1 {
    order: 1 !important;
  }
  .nsw-order-lg-2 {
    order: 2 !important;
  }
  .nsw-order-lg-3 {
    order: 3 !important;
  }
  .nsw-order-lg-4 {
    order: 4 !important;
  }
  .nsw-order-lg-5 {
    order: 5 !important;
  }
  .nsw-order-lg-last {
    order: 6 !important;
  }
  .nsw-text-lg-left {
    text-align: left !important;
  }
  .nsw-text-lg-right {
    text-align: right !important;
  }
  .nsw-text-lg-center {
    text-align: center !important;
  }
  /* rtl:begin:remove */
  .nsw-overflow-lg-break {
    overflow-wrap: break-word !important;
  }
  /* rtl:end:remove */
  /* rtl:begin:remove */
  .nsw-word-break-lg-normal {
    word-break: normal !important;
  }
  /* rtl:end:remove */
  /* rtl:begin:remove */
  .nsw-word-break-lg-break-all {
    word-break: break-all !important;
  }
  /* rtl:end:remove */
  /* rtl:begin:remove */
  .nsw-word-break-lg-keep-all {
    word-break: keep-all !important;
  }
  /* rtl:end:remove */
}
@media (min-width: 1200px) {
  .nsw-float-xl-left {
    float: left !important;
  }
  .nsw-float-xl-right {
    float: right !important;
  }
  .nsw-float-xl-none {
    float: none !important;
  }
  .nsw-object-fit-xl-contain {
    object-fit: contain !important;
  }
  .nsw-object-fit-xl-cover {
    object-fit: cover !important;
  }
  .nsw-object-fit-xl-fill {
    object-fit: fill !important;
  }
  .nsw-object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .nsw-object-fit-xl-none {
    object-fit: none !important;
  }
  .nsw-display-xl-inline {
    display: inline !important;
  }
  .nsw-display-xl-inline-block {
    display: inline-block !important;
  }
  .nsw-display-xl-block {
    display: block !important;
  }
  .nsw-display-xl-grid {
    display: grid !important;
  }
  .nsw-display-xl-inline-grid {
    display: inline-grid !important;
  }
  .nsw-display-xl-table {
    display: table !important;
  }
  .nsw-display-xl-table-row {
    display: table-row !important;
  }
  .nsw-display-xl-table-cell {
    display: table-cell !important;
  }
  .nsw-display-xl-flex {
    display: flex !important;
  }
  .nsw-display-xl-inline-flex {
    display: inline-flex !important;
  }
  .nsw-display-xl-none {
    display: none !important;
  }
  .nsw-width-xl-4xs {
    width: 0.25rem !important;
  }
  .nsw-width-xl-3xs {
    width: 0.5rem !important;
  }
  .nsw-width-xl-2xs {
    width: 0.75rem !important;
  }
  .nsw-width-xl-xs {
    width: 1rem !important;
  }
  .nsw-width-xl-sm {
    width: 1.5rem !important;
  }
  .nsw-width-xl-md {
    width: 2rem !important;
  }
  .nsw-width-xl-lg {
    width: 3rem !important;
  }
  .nsw-width-xl-xl {
    width: 4rem !important;
  }
  .nsw-width-xl-2xl {
    width: 6rem !important;
  }
  .nsw-width-xl-3xl {
    width: 8rem !important;
  }
  .nsw-width-xl-4xl {
    width: 16rem !important;
  }
  .nsw-width-xl-0 {
    width: 0 !important;
  }
  .nsw-width-xl-10 {
    width: 10% !important;
  }
  .nsw-width-xl-20 {
    width: 20% !important;
  }
  .nsw-width-xl-25 {
    width: 25% !important;
  }
  .nsw-width-xl-30 {
    width: 30% !important;
  }
  .nsw-width-xl-33 {
    width: 33% !important;
  }
  .nsw-width-xl-40 {
    width: 40% !important;
  }
  .nsw-width-xl-50 {
    width: 50% !important;
  }
  .nsw-width-xl-60 {
    width: 60% !important;
  }
  .nsw-width-xl-70 {
    width: 70% !important;
  }
  .nsw-width-xl-75 {
    width: 75% !important;
  }
  .nsw-width-xl-80 {
    width: 80% !important;
  }
  .nsw-width-xl-90 {
    width: 90% !important;
  }
  .nsw-width-xl-100 {
    width: 100% !important;
  }
  .nsw-flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .nsw-flex-xl-row {
    flex-direction: row !important;
  }
  .nsw-flex-xl-column {
    flex-direction: column !important;
  }
  .nsw-flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .nsw-flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .nsw-flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .nsw-flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .nsw-flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .nsw-flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .nsw-flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .nsw-flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .nsw-flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .nsw-justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .nsw-justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .nsw-justify-content-xl-center {
    justify-content: center !important;
  }
  .nsw-justify-content-xl-between {
    justify-content: space-between !important;
  }
  .nsw-justify-content-xl-around {
    justify-content: space-around !important;
  }
  .nsw-justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .nsw-align-items-xl-start {
    align-items: flex-start !important;
  }
  .nsw-align-items-xl-end {
    align-items: flex-end !important;
  }
  .nsw-align-items-xl-center {
    align-items: center !important;
  }
  .nsw-align-items-xl-baseline {
    align-items: baseline !important;
  }
  .nsw-align-items-xl-stretch {
    align-items: stretch !important;
  }
  .nsw-align-content-xl-start {
    align-content: flex-start !important;
  }
  .nsw-align-content-xl-end {
    align-content: flex-end !important;
  }
  .nsw-align-content-xl-center {
    align-content: center !important;
  }
  .nsw-align-content-xl-between {
    align-content: space-between !important;
  }
  .nsw-align-content-xl-around {
    align-content: space-around !important;
  }
  .nsw-align-content-xl-stretch {
    align-content: stretch !important;
  }
  .nsw-align-self-xl-auto {
    align-self: auto !important;
  }
  .nsw-align-self-xl-start {
    align-self: flex-start !important;
  }
  .nsw-align-self-xl-end {
    align-self: flex-end !important;
  }
  .nsw-align-self-xl-center {
    align-self: center !important;
  }
  .nsw-align-self-xl-baseline {
    align-self: baseline !important;
  }
  .nsw-align-self-xl-stretch {
    align-self: stretch !important;
  }
  .nsw-order-xl-first {
    order: -1 !important;
  }
  .nsw-order-xl-0 {
    order: 0 !important;
  }
  .nsw-order-xl-1 {
    order: 1 !important;
  }
  .nsw-order-xl-2 {
    order: 2 !important;
  }
  .nsw-order-xl-3 {
    order: 3 !important;
  }
  .nsw-order-xl-4 {
    order: 4 !important;
  }
  .nsw-order-xl-5 {
    order: 5 !important;
  }
  .nsw-order-xl-last {
    order: 6 !important;
  }
  .nsw-text-xl-left {
    text-align: left !important;
  }
  .nsw-text-xl-right {
    text-align: right !important;
  }
  .nsw-text-xl-center {
    text-align: center !important;
  }
  /* rtl:begin:remove */
  .nsw-overflow-xl-break {
    overflow-wrap: break-word !important;
  }
  /* rtl:end:remove */
  /* rtl:begin:remove */
  .nsw-word-break-xl-normal {
    word-break: normal !important;
  }
  /* rtl:end:remove */
  /* rtl:begin:remove */
  .nsw-word-break-xl-break-all {
    word-break: break-all !important;
  }
  /* rtl:end:remove */
  /* rtl:begin:remove */
  .nsw-word-break-xl-keep-all {
    word-break: keep-all !important;
  }
  /* rtl:end:remove */
}
.nsw-container {
  max-width: var(--nsw-container-width);
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
}
.nsw-container--flush {
  padding-left: 0;
  padding-right: 0;
}

.nsw-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
@media (min-width: 48rem) {
  .nsw-grid {
    margin-left: -1rem;
    margin-right: -1rem;
  }
}
.nsw-grid--spaced {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}
@media (min-width: 48rem) {
  .nsw-grid--spaced {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
}
.nsw-grid--spaced .nsw-col {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media (min-width: 48rem) {
  .nsw-grid--spaced .nsw-col {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
.nsw-grid--flush {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}
@media (min-width: 48rem) {
  .nsw-grid--flush {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
}
.nsw-grid-overlay {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}
.nsw-grid-overlay .nsw-container,
.nsw-grid-overlay .nsw-grid {
  height: 100%;
}
.nsw-grid-overlay .nsw-col {
  padding-top: 0;
  padding-bottom: 0;
}
.nsw-grid-overlay .nsw-col > div {
  height: 100%;
  background-color: var(--nsw-brand-accent);
  opacity: 0.1;
}

.nsw-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 62rem) {
  .nsw-row--reverse {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

.nsw-col {
  width: 100%;
  padding: 0.5rem;
}
@media (min-width: 48rem) {
  .nsw-col {
    padding: 1rem;
  }
}
.nsw-col-1 {
  width: 8.3333333333%;
  max-width: 8.3333333333%;
}
.nsw-col-2 {
  width: 16.6666666667%;
  max-width: 16.6666666667%;
}
.nsw-col-3 {
  width: 25%;
  max-width: 25%;
}
.nsw-col-4 {
  width: 33.3333333333%;
  max-width: 33.3333333333%;
}
.nsw-col-5 {
  width: 41.6666666667%;
  max-width: 41.6666666667%;
}
.nsw-col-6 {
  width: 50%;
  max-width: 50%;
}
.nsw-col-7 {
  width: 58.3333333333%;
  max-width: 58.3333333333%;
}
.nsw-col-8 {
  width: 66.6666666667%;
  max-width: 66.6666666667%;
}
.nsw-col-9 {
  width: 75%;
  max-width: 75%;
}
.nsw-col-10 {
  width: 83.3333333333%;
  max-width: 83.3333333333%;
}
.nsw-col-11 {
  width: 91.6666666667%;
  max-width: 91.6666666667%;
}
.nsw-col-12 {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 0rem) {
  .nsw-col-xs-1 {
    width: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .nsw-col-xs-2 {
    width: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .nsw-col-xs-3 {
    width: 25%;
    max-width: 25%;
  }
  .nsw-col-xs-4 {
    width: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .nsw-col-xs-5 {
    width: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .nsw-col-xs-6 {
    width: 50%;
    max-width: 50%;
  }
  .nsw-col-xs-7 {
    width: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .nsw-col-xs-8 {
    width: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .nsw-col-xs-9 {
    width: 75%;
    max-width: 75%;
  }
  .nsw-col-xs-10 {
    width: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .nsw-col-xs-11 {
    width: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .nsw-col-xs-12 {
    width: 100%;
    max-width: 100%;
  }
}
@media (min-width: 36rem) {
  .nsw-col-sm-1 {
    width: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .nsw-col-sm-2 {
    width: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .nsw-col-sm-3 {
    width: 25%;
    max-width: 25%;
  }
  .nsw-col-sm-4 {
    width: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .nsw-col-sm-5 {
    width: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .nsw-col-sm-6 {
    width: 50%;
    max-width: 50%;
  }
  .nsw-col-sm-7 {
    width: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .nsw-col-sm-8 {
    width: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .nsw-col-sm-9 {
    width: 75%;
    max-width: 75%;
  }
  .nsw-col-sm-10 {
    width: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .nsw-col-sm-11 {
    width: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .nsw-col-sm-12 {
    width: 100%;
    max-width: 100%;
  }
}
@media (min-width: 48rem) {
  .nsw-col-md-1 {
    width: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .nsw-col-md-2 {
    width: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .nsw-col-md-3 {
    width: 25%;
    max-width: 25%;
  }
  .nsw-col-md-4 {
    width: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .nsw-col-md-5 {
    width: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .nsw-col-md-6 {
    width: 50%;
    max-width: 50%;
  }
  .nsw-col-md-7 {
    width: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .nsw-col-md-8 {
    width: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .nsw-col-md-9 {
    width: 75%;
    max-width: 75%;
  }
  .nsw-col-md-10 {
    width: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .nsw-col-md-11 {
    width: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .nsw-col-md-12 {
    width: 100%;
    max-width: 100%;
  }
}
@media (min-width: 62rem) {
  .nsw-col-lg-1 {
    width: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .nsw-col-lg-2 {
    width: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .nsw-col-lg-3 {
    width: 25%;
    max-width: 25%;
  }
  .nsw-col-lg-4 {
    width: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .nsw-col-lg-5 {
    width: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .nsw-col-lg-6 {
    width: 50%;
    max-width: 50%;
  }
  .nsw-col-lg-7 {
    width: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .nsw-col-lg-8 {
    width: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .nsw-col-lg-9 {
    width: 75%;
    max-width: 75%;
  }
  .nsw-col-lg-10 {
    width: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .nsw-col-lg-11 {
    width: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .nsw-col-lg-12 {
    width: 100%;
    max-width: 100%;
  }
}
@media (min-width: 75rem) {
  .nsw-col-xl-1 {
    width: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .nsw-col-xl-2 {
    width: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .nsw-col-xl-3 {
    width: 25%;
    max-width: 25%;
  }
  .nsw-col-xl-4 {
    width: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .nsw-col-xl-5 {
    width: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .nsw-col-xl-6 {
    width: 50%;
    max-width: 50%;
  }
  .nsw-col-xl-7 {
    width: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .nsw-col-xl-8 {
    width: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .nsw-col-xl-9 {
    width: 75%;
    max-width: 75%;
  }
  .nsw-col-xl-10 {
    width: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .nsw-col-xl-11 {
    width: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .nsw-col-xl-12 {
    width: 100%;
    max-width: 100%;
  }
}

.nsw-offset-1 {
  margin-left: 8.3333333333%;
}
.nsw-offset-2 {
  margin-left: 16.6666666667%;
}
.nsw-offset-3 {
  margin-left: 25%;
}
.nsw-offset-4 {
  margin-left: 33.3333333333%;
}
.nsw-offset-5 {
  margin-left: 41.6666666667%;
}
.nsw-offset-6 {
  margin-left: 50%;
}
.nsw-offset-7 {
  margin-left: 58.3333333333%;
}
.nsw-offset-8 {
  margin-left: 66.6666666667%;
}
.nsw-offset-9 {
  margin-left: 75%;
}
.nsw-offset-10 {
  margin-left: 83.3333333333%;
}
.nsw-offset-11 {
  margin-left: 91.6666666667%;
}
.nsw-offset-12 {
  margin-left: 100%;
}
@media (min-width: 0rem) {
  .nsw-offset-xs-1 {
    margin-left: 8.3333333333%;
  }
  .nsw-offset-xs-2 {
    margin-left: 16.6666666667%;
  }
  .nsw-offset-xs-3 {
    margin-left: 25%;
  }
  .nsw-offset-xs-4 {
    margin-left: 33.3333333333%;
  }
  .nsw-offset-xs-5 {
    margin-left: 41.6666666667%;
  }
  .nsw-offset-xs-6 {
    margin-left: 50%;
  }
  .nsw-offset-xs-7 {
    margin-left: 58.3333333333%;
  }
  .nsw-offset-xs-8 {
    margin-left: 66.6666666667%;
  }
  .nsw-offset-xs-9 {
    margin-left: 75%;
  }
  .nsw-offset-xs-10 {
    margin-left: 83.3333333333%;
  }
  .nsw-offset-xs-11 {
    margin-left: 91.6666666667%;
  }
  .nsw-offset-xs-12 {
    margin-left: 100%;
  }
}
@media (min-width: 36rem) {
  .nsw-offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .nsw-offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .nsw-offset-sm-3 {
    margin-left: 25%;
  }
  .nsw-offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .nsw-offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .nsw-offset-sm-6 {
    margin-left: 50%;
  }
  .nsw-offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .nsw-offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .nsw-offset-sm-9 {
    margin-left: 75%;
  }
  .nsw-offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .nsw-offset-sm-11 {
    margin-left: 91.6666666667%;
  }
  .nsw-offset-sm-12 {
    margin-left: 100%;
  }
}
@media (min-width: 48rem) {
  .nsw-offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .nsw-offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .nsw-offset-md-3 {
    margin-left: 25%;
  }
  .nsw-offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .nsw-offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .nsw-offset-md-6 {
    margin-left: 50%;
  }
  .nsw-offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .nsw-offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .nsw-offset-md-9 {
    margin-left: 75%;
  }
  .nsw-offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .nsw-offset-md-11 {
    margin-left: 91.6666666667%;
  }
  .nsw-offset-md-12 {
    margin-left: 100%;
  }
}
@media (min-width: 62rem) {
  .nsw-offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .nsw-offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .nsw-offset-lg-3 {
    margin-left: 25%;
  }
  .nsw-offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .nsw-offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .nsw-offset-lg-6 {
    margin-left: 50%;
  }
  .nsw-offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .nsw-offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .nsw-offset-lg-9 {
    margin-left: 75%;
  }
  .nsw-offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .nsw-offset-lg-11 {
    margin-left: 91.6666666667%;
  }
  .nsw-offset-lg-12 {
    margin-left: 100%;
  }
}
@media (min-width: 75rem) {
  .nsw-offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .nsw-offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .nsw-offset-xl-3 {
    margin-left: 25%;
  }
  .nsw-offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .nsw-offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .nsw-offset-xl-6 {
    margin-left: 50%;
  }
  .nsw-offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .nsw-offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .nsw-offset-xl-9 {
    margin-left: 75%;
  }
  .nsw-offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .nsw-offset-xl-11 {
    margin-left: 91.6666666667%;
  }
  .nsw-offset-xl-12 {
    margin-left: 100%;
  }
}

.nsw-material-icons--20 {
  font-size: 1.25rem;
}
.nsw-material-icons--24 {
  font-size: 1.5rem;
}
.nsw-material-icons--30 {
  font-size: 1.875rem;
}
.nsw-material-icons--36 {
  font-size: 2.25rem;
}
.nsw-material-icons--brand {
  color: var(--nsw-brand-dark);
}
.nsw-material-icons--accent {
  color: var(--nsw-brand-accent);
}
.nsw-material-icons--error {
  color: var(--nsw-status-error);
}
.nsw-material-icons--success {
  color: var(--nsw-status-success);
}
.nsw-material-icons--info {
  color: var(--nsw-status-info);
}
.nsw-material-icons--warning {
  color: var(--nsw-status-warning);
}
.nsw-material-icons--rotate-90 {
  transform: rotate(90deg);
}
.nsw-material-icons--rotate-180 {
  transform: rotate(180deg);
}
.nsw-material-icons--rotate-270 {
  transform: rotate(270deg);
}
.nsw-material-icons--inline {
  font-size: 1.25rem;
  line-height: 0.625rem;
  position: relative;
  bottom: -0.3125rem;
  margin-right: 0.25rem;
}

.nsw-icon-button {
  font-size: var(--nsw-font-size-sm-mobile);
  line-height: var(--nsw-line-height-sm-mobile);
  border-radius: var(--nsw-border-radius);
  font-weight: var(--nsw-font-bold);
  color: var(--nsw-brand-dark);
  background: none;
  border: 0;
  text-decoration: none;
  padding: 0.5rem;
  display: inline-block;
}
@media (min-width: 62rem) {
  .nsw-icon-button {
    font-size: var(--nsw-font-size-sm-desktop);
    line-height: var(--nsw-line-height-sm-desktop);
  }
}
.nsw-icon-button:hover {
  background-color: var(--nsw-hover);
  outline-width: 0;
}
.nsw-icon-button:focus {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
}
.nsw-icon-button .nsw-material-icons,
.nsw-icon-button svg {
  display: block;
}
a.nsw-icon-button {
  cursor: pointer;
}

.nsw-icon-button--flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nsw-section--invert .nsw-icon-button {
  color: var(--nsw-link-light);
}
.nsw-section--invert .nsw-icon-button:visited {
  color: var(--nsw-visited-light);
}
.nsw-section--invert .nsw-icon-button:focus {
  outline-color: var(--nsw-focus-light);
}
.nsw-section--invert .nsw-icon-button:hover {
  background-color: var(--nsw-hover-light);
  outline-color: var(--nsw-hover-light);
}
.nsw-section--invert .nsw-icon-button:active {
  background-color: var(--nsw-active-light);
  outline-color: var(--nsw-active-light);
}

.nsw-layout {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
@media (min-width: 48rem) {
  .nsw-layout {
    margin-left: -1rem;
    margin-right: -1rem;
  }
}
.nsw-layout__main {
  width: 100%;
  padding: 0.5rem;
}
@media (min-width: 48rem) {
  .nsw-layout__main {
    padding: 1rem;
  }
}
@media (min-width: 62rem) {
  .nsw-layout__main:not(:first-child) {
    width: 66.6666666667%;
    max-width: 66.6666666667%;
  }
}
@media (min-width: 62rem) {
  .nsw-layout__main:not(:last-child) {
    width: 66.6666666667%;
    max-width: 66.6666666667%;
  }
}
.nsw-layout__sidebar {
  width: 100%;
  padding: 0.5rem;
}
@media (min-width: 48rem) {
  .nsw-layout__sidebar {
    padding: 1rem;
  }
}
@media (min-width: 62rem) {
  .nsw-layout__sidebar {
    width: 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
@media (min-width: 62rem) {
  .nsw-layout__sidebar:first-child {
    padding-right: 3rem;
  }
}
@media (min-width: 62rem) {
  .nsw-layout__sidebar:last-child {
    padding-left: 3rem;
  }
}
.nsw-layout__sidebar--desktop {
  display: none;
}
@media (min-width: 62rem) {
  .nsw-layout__sidebar--desktop {
    display: block;
  }
}

.nsw-section {
  padding: 1rem 0;
}
@media (min-width: 48rem) {
  .nsw-section {
    padding: 2rem 0;
  }
}
.nsw-section--half-padding {
  padding: 0;
}
@media (min-width: 48rem) {
  .nsw-section--half-padding {
    padding: 1rem 0;
  }
}
.nsw-section--no-padding {
  padding: 0;
}
@media (min-width: 48rem) {
  .nsw-section--no-padding {
    padding: 0;
  }
}
.nsw-section--image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0;
}
@media (min-width: 48rem) {
  .nsw-section--image {
    padding: 2rem 0;
  }
}
.nsw-section--image-html {
  position: relative;
  padding: 0;
}
.nsw-section--image-html img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (min-width: 48rem) {
  .nsw-section--image-html {
    padding: 2rem 0;
  }
}
.nsw-section--box {
  border-radius: var(--nsw-border-radius);
  border: 1px solid var(--nsw-grey-04);
  padding: 1.5rem;
}
@media (min-width: 48rem) {
  .nsw-section--box {
    padding: 1.5rem;
  }
}
.nsw-section--brand-dark {
  --nsw-section-bg: var(--nsw-brand-dark);
  background-color: var(--nsw-brand-dark);
  color: var(--nsw-text-light);
}
.nsw-section--brand-supplementary {
  --nsw-section-bg: var(--nsw-brand-supplementary);
  background-color: var(--nsw-brand-supplementary);
  color: var(--nsw-text-light);
}
.nsw-section--black {
  --nsw-section-bg: var(--nsw-black);
  background-color: var(--nsw-black);
  color: var(--nsw-text-light);
}
.nsw-section--grey-01 {
  --nsw-section-bg: var(--nsw-grey-01);
  background-color: var(--nsw-grey-01);
  color: var(--nsw-text-light);
}
.nsw-section--grey-02 {
  --nsw-section-bg: var(--nsw-grey-02);
  background-color: var(--nsw-grey-02);
  color: var(--nsw-text-light);
}
.nsw-section--brand-light {
  --nsw-section-bg: var(--nsw-brand-light);
  background-color: var(--nsw-brand-light);
  color: var(--nsw-text-dark);
}
.nsw-section--white {
  --nsw-section-bg: var(--nsw-white);
  background-color: var(--nsw-white);
  color: var(--nsw-text-dark);
}
.nsw-section--off-white {
  --nsw-section-bg: var(--nsw-off-white);
  background-color: var(--nsw-off-white);
  color: var(--nsw-text-dark);
}
.nsw-section--grey-03 {
  --nsw-section-bg: var(--nsw-grey-03);
  background-color: var(--nsw-grey-03);
  color: var(--nsw-text-dark);
}
.nsw-section--grey-04 {
  --nsw-section-bg: var(--nsw-grey-04);
  background-color: var(--nsw-grey-04);
  color: var(--nsw-text-dark);
}
.nsw-section--invert {
  color: var(--nsw-text-light);
}

body {
  font-size: var(--nsw-font-size-sm-mobile);
  line-height: var(--nsw-line-height-sm-mobile);
  font-family: var(--nsw-font-family);
  font-weight: var(--nsw-font-normal);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--nsw-text-dark);
}
@media (min-width: 62rem) {
  body {
    font-size: var(--nsw-font-size-sm-desktop);
    line-height: var(--nsw-line-height-sm-desktop);
  }
}

button,
input,
textarea,
textfield,
select {
  font-family: var(--nsw-font-family);
}

p,
ul,
ol,
dl {
  margin: 0;
}

* + p,
* + ul,
* + ol,
* + dl {
  margin-top: 1rem;
}

ul li,
ol li {
  margin-top: 0.5rem;
}

dt {
  margin-top: 0.5rem;
}

small,
.nsw-small {
  font-size: var(--nsw-font-size-xs-mobile);
  line-height: var(--nsw-line-height-xs-mobile);
}
@media (min-width: 62rem) {
  small,
  .nsw-small {
    font-size: var(--nsw-font-size-xs-desktop);
    line-height: var(--nsw-line-height-xs-desktop);
  }
}

/* stylelint-disable selector-no-qualifying-type */
abbr,
abbr[title],
acronym {
  text-decoration: none;
  border-bottom: 1px var(--nsw-brand-dark) dashed;
}

/* stylelint-enable selector-no-qualifying-type */
blockquote {
  font-size: var(--nsw-font-size-lg-mobile);
  line-height: var(--nsw-line-height-lg-mobile);
  margin-top: 1.5rem;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  border-left: solid 2px var(--nsw-brand-accent);
  padding: 1.25rem 1.5rem;
}
@media (min-width: 62rem) {
  blockquote {
    font-size: var(--nsw-font-size-lg-desktop);
    line-height: var(--nsw-line-height-lg-desktop);
  }
}
blockquote:first-child {
  margin-top: 0;
}
@media (min-width: 48rem) {
  blockquote {
    margin-top: 2rem;
  }
}
@media (min-width: 48rem) {
  blockquote {
    margin-left: auto;
    margin-right: auto;
    max-width: 75%;
  }
}

table {
  margin-top: 1.5rem;
  border: 0;
  border-collapse: collapse;
  width: 100%;
  caption-side: bottom;
}
table:first-child {
  margin-top: 0;
}
@media (min-width: 48rem) {
  table {
    margin-top: 2rem;
  }
}
table caption {
  padding: 0.75rem 0.5rem;
  text-align: left;
}
table th,
table td {
  padding: 0.75rem 0.5rem;
  text-align: left;
  vertical-align: baseline;
  border-bottom: solid 1px var(--nsw-grey-01);
}
.nsw-section--invert table th,
.nsw-section--invert table td {
  border-color: var(--nsw-white);
}
table th,
table thead td,
table tfoot td {
  font-weight: var(--nsw-font-bold);
}
table thead {
  border-bottom: solid 2px var(--nsw-grey-01);
}
.nsw-section--invert table thead {
  border-color: var(--nsw-white);
}

cite {
  font-size: var(--nsw-font-size-sm-mobile);
  line-height: var(--nsw-line-height-sm-mobile);
  margin-top: 1rem;
  display: block;
  font-style: normal;
}
@media (min-width: 62rem) {
  cite {
    font-size: var(--nsw-font-size-sm-desktop);
    line-height: var(--nsw-line-height-sm-desktop);
  }
}

cite::before {
  content: "— ";
}

hr {
  border: 0;
  height: 1px;
  background-color: var(--nsw-grey-04);
  margin: 1rem 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.nsw-h1,
.nsw-h2,
.nsw-h3,
.nsw-h4,
.nsw-h5,
.nsw-h6 {
  font-weight: var(--nsw-font-bold);
  margin: 0;
}

h1,
.nsw-h1 {
  font-size: var(--nsw-font-size-xxl-mobile);
  line-height: var(--nsw-line-height-xxl-mobile);
}
@media (min-width: 62rem) {
  h1,
  .nsw-h1 {
    font-size: var(--nsw-font-size-xxl-desktop);
    line-height: var(--nsw-line-height-xxl-desktop);
  }
}

h2,
.nsw-h2 {
  font-size: var(--nsw-font-size-xl-mobile);
  line-height: var(--nsw-line-height-xl-mobile);
}
@media (min-width: 62rem) {
  h2,
  .nsw-h2 {
    font-size: var(--nsw-font-size-xl-desktop);
    line-height: var(--nsw-line-height-xl-desktop);
  }
}

h3,
.nsw-h3 {
  font-size: var(--nsw-font-size-lg-mobile);
  line-height: var(--nsw-line-height-lg-mobile);
}
@media (min-width: 62rem) {
  h3,
  .nsw-h3 {
    font-size: var(--nsw-font-size-lg-desktop);
    line-height: var(--nsw-line-height-lg-desktop);
  }
}

h4,
.nsw-h4 {
  font-size: var(--nsw-font-size-md-mobile);
  line-height: var(--nsw-line-height-md-mobile);
}
@media (min-width: 62rem) {
  h4,
  .nsw-h4 {
    font-size: var(--nsw-font-size-md-desktop);
    line-height: var(--nsw-line-height-md-desktop);
  }
}

h5,
h6,
.nsw-h5,
.nsw-h6 {
  font-size: var(--nsw-font-size-sm-mobile);
  line-height: var(--nsw-line-height-sm-mobile);
}
@media (min-width: 62rem) {
  h5,
  h6,
  .nsw-h5,
  .nsw-h6 {
    font-size: var(--nsw-font-size-sm-desktop);
    line-height: var(--nsw-line-height-sm-desktop);
  }
}

* + h1,
* + .nsw-h1 {
  margin-top: 4rem;
}

* + h2,
* + .nsw-h2 {
  margin-top: 3rem;
}

* + h3,
* + .nsw-h3 {
  margin-top: 2rem;
}

* + h4,
* + h5,
* + h6,
* + .nsw-h4,
* + .nsw-h5,
* + .nsw-h6 {
  margin-top: 1.5rem;
}

.nsw-intro {
  font-size: var(--nsw-font-size-md-mobile);
  line-height: var(--nsw-line-height-md-mobile);
  margin: 1rem 0 0 0;
}
@media (min-width: 62rem) {
  .nsw-intro {
    font-size: var(--nsw-font-size-md-desktop);
    line-height: var(--nsw-line-height-md-desktop);
  }
}

a {
  font-weight: var(--nsw-font-bold);
  color: var(--nsw-link);
  text-decoration: underline;
}
a:visited {
  color: var(--nsw-visited);
}
a:focus {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
}
a:hover {
  background-color: var(--nsw-hover);
  outline: 2px solid var(--nsw-hover);
}
a:active {
  background-color: var(--nsw-active);
  outline-color: var(--nsw-active);
}
.nsw-section--invert a {
  color: var(--nsw-link-light);
}
.nsw-section--invert a:visited {
  color: var(--nsw-visited-light);
}
.nsw-section--invert a:focus {
  outline-color: var(--nsw-focus-light);
}
.nsw-section--invert a:hover {
  background-color: var(--nsw-hover-light);
  outline-color: var(--nsw-hover-light);
}
.nsw-section--invert a:active {
  background-color: var(--nsw-active-light);
  outline-color: var(--nsw-active-light);
}

.nsw-section-separator {
  border: 0;
  height: 1px;
  background-color: var(--nsw-grey-04);
  margin: 2rem 0;
}
@media (min-width: 48rem) {
  .nsw-section-separator {
    margin: 3rem 0;
  }
}

.nsw-section-text {
  margin-top: -0.5rem;
  padding-bottom: 0.5rem;
}

.nsw-section-title {
  margin: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
@media (min-width: 48rem) {
  .nsw-section-title {
    padding-top: 1rem;
  }
}

:root {
  --nsw-font-size-xxs: var(--nsw-font-size-xxs-mobile);
  --nsw-font-height-xxs: var(--nsw-font-height-xxs-mobile);
  --nsw-font-size-xs: var(--nsw-font-size-xs-mobile);
  --nsw-font-height-xs: var(--nsw-font-height-xs-mobile);
  --nsw-font-size-sm: var(--nsw-font-size-sm-mobile);
  --nsw-font-height-sm: var(--nsw-font-height-sm-mobile);
  --nsw-font-size-md: var(--nsw-font-size-md-mobile);
  --nsw-font-height-md: var(--nsw-font-height-md-mobile);
  --nsw-font-size-lg: var(--nsw-font-size-lg-mobile);
  --nsw-font-height-lg: var(--nsw-font-height-lg-mobile);
  --nsw-font-size-xl: var(--nsw-font-size-xl-mobile);
  --nsw-font-height-xl: var(--nsw-font-height-xl-mobile);
  --nsw-font-size-xxl: var(--nsw-font-size-xxl-mobile);
  --nsw-font-height-xxl: var(--nsw-font-height-xxl-mobile);
}
@media (min-width: 62rem) {
  :root {
    --nsw-font-size-xxs: var(--nsw-font-size-xxs-desktop);
    --nsw-font-height-xxs: var(--nsw-font-height-xxs-desktop);
  }
}
@media (min-width: 62rem) {
  :root {
    --nsw-font-size-xs: var(--nsw-font-size-xs-desktop);
    --nsw-font-height-xs: var(--nsw-font-height-xs-desktop);
  }
}
@media (min-width: 62rem) {
  :root {
    --nsw-font-size-sm: var(--nsw-font-size-sm-desktop);
    --nsw-font-height-sm: var(--nsw-font-height-sm-desktop);
  }
}
@media (min-width: 62rem) {
  :root {
    --nsw-font-size-md: var(--nsw-font-size-md-desktop);
    --nsw-font-height-md: var(--nsw-font-height-md-desktop);
  }
}
@media (min-width: 62rem) {
  :root {
    --nsw-font-size-lg: var(--nsw-font-size-lg-desktop);
    --nsw-font-height-lg: var(--nsw-font-height-lg-desktop);
  }
}
@media (min-width: 62rem) {
  :root {
    --nsw-font-size-xl: var(--nsw-font-size-xl-desktop);
    --nsw-font-height-xl: var(--nsw-font-height-xl-desktop);
  }
}
@media (min-width: 62rem) {
  :root {
    --nsw-font-size-xxl: var(--nsw-font-size-xxl-desktop);
    --nsw-font-height-xxl: var(--nsw-font-height-xxl-desktop);
  }
}

.nsw-accordion {
  margin-top: 1.5rem;
}
.nsw-accordion:first-child {
  margin-top: 0;
}
@media (min-width: 48rem) {
  .nsw-accordion {
    margin-top: 2rem;
  }
}
.nsw-accordion__title, .nsw-accordion__button {
  font-size: var(--nsw-font-size-md-mobile);
  line-height: var(--nsw-line-height-md-mobile);
  font-weight: var(--nsw-font-bold);
  padding: 1rem 3rem 1rem 1rem;
  margin-top: 0.5rem;
  background-color: var(--nsw-off-white);
  display: block;
  width: 100%;
  text-align: left;
  position: relative;
  transition: background 0.15s ease-in-out, color 0.15s ease-in-out;
}
@media (min-width: 62rem) {
  .nsw-accordion__title, .nsw-accordion__button {
    font-size: var(--nsw-font-size-md-desktop);
    line-height: var(--nsw-line-height-md-desktop);
  }
}
.nsw-accordion__title .active, .nsw-accordion__title:hover .nsw-accordion__title, .nsw-accordion__button .active, .nsw-accordion__title:hover .nsw-accordion__button, .nsw-accordion__button:hover .nsw-accordion__title, .nsw-accordion__button:hover .nsw-accordion__button {
  background-color: var(--nsw-brand-dark);
  color: var(--nsw-text-light);
  cursor: pointer;
}
.nsw-section--invert .nsw-accordion__title .active, .nsw-section--invert .nsw-accordion__title:hover .nsw-accordion__title, .nsw-section--invert .nsw-accordion__button .active, .nsw-section--invert .nsw-accordion__title:hover .nsw-accordion__button, .nsw-section--invert .nsw-accordion__button:hover .nsw-accordion__title, .nsw-section--invert .nsw-accordion__button:hover .nsw-accordion__button {
  background-color: var(--nsw-brand-light);
  color: var(--nsw-text-dark);
}
.nsw-section--invert .nsw-accordion__title .active .nsw-material-icons, .nsw-section--invert .nsw-accordion__title:hover .nsw-accordion__title .nsw-material-icons, .nsw-section--invert .nsw-accordion__button .active .nsw-material-icons, .nsw-section--invert .nsw-accordion__title:hover .nsw-accordion__button .nsw-material-icons, .nsw-section--invert .nsw-accordion__button:hover .nsw-accordion__title .nsw-material-icons, .nsw-section--invert .nsw-accordion__button:hover .nsw-accordion__button .nsw-material-icons {
  color: var(--nsw-brand-dark);
}
.nsw-accordion__title .active .nsw-material-icons, .nsw-accordion__title:hover .nsw-accordion__title .nsw-material-icons, .nsw-accordion__button .active .nsw-material-icons, .nsw-accordion__title:hover .nsw-accordion__button .nsw-material-icons, .nsw-accordion__button:hover .nsw-accordion__title .nsw-material-icons, .nsw-accordion__button:hover .nsw-accordion__button .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-accordion__toggle {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}
.nsw-accordion__toggle button {
  font-size: var(--nsw-font-size-sm-mobile);
  line-height: var(--nsw-line-height-sm-mobile);
  color: var(--nsw-link);
  text-decoration: underline;
  text-decoration-skip-ink: auto;
  font-weight: var(--nsw-font-bold);
  border: 0;
  padding: 0;
  background: none;
}
@media (min-width: 62rem) {
  .nsw-accordion__toggle button {
    font-size: var(--nsw-font-size-sm-desktop);
    line-height: var(--nsw-line-height-sm-desktop);
  }
}
.nsw-accordion__toggle button:hover:enabled {
  background-color: var(--nsw-hover);
  outline: 2px solid var(--nsw-hover);
  cursor: pointer;
}
.nsw-section--invert .nsw-accordion__toggle button:hover:enabled {
  background-color: var(--nsw-hover-light);
  outline-color: var(--nsw-hover-light);
}
.nsw-accordion__toggle button:focus {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
}
.nsw-accordion__toggle button:first-child {
  margin-right: 1.5rem;
}
.nsw-accordion__toggle button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.nsw-section--invert .nsw-accordion__toggle button {
  color: var(--nsw-text-light);
}
.nsw-section--invert .nsw-accordion__toggle button:focus {
  outline-color: var(--nsw-white);
}
.ready .nsw-accordion__title {
  padding: 0;
  margin-top: 0;
}
.nsw-accordion__title .nsw-material-icons {
  font-size: 1.875rem;
  color: var(--nsw-brand-dark);
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transition: transform 0.15s ease-in-out, color 0.15s ease-in-out;
}
.nsw-accordion__button {
  border: 0;
  color: var(--nsw-text-dark);
}
.nsw-accordion__button:focus {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
  outline-offset: 3px;
  z-index: 100;
  position: relative;
}
.nsw-section--invert .nsw-accordion__button:focus {
  outline: solid 3px var(--nsw-focus-light);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-accordion__button.active .nsw-material-icons {
  transform: translateY(-50%) rotate(180deg);
}
.nsw-accordion__content[hidden=until-found] {
  padding: 0;
}
.nsw-accordion__content {
  padding: 1rem;
}

.nsw-back-to-top {
  display: flex;
  flex-direction: column-reverse;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s;
  text-decoration: none;
}
@media (min-width: 48rem) {
  .nsw-back-to-top {
    flex-direction: row;
  }
}
.nsw-back-to-top.active {
  visibility: visible;
  opacity: 1;
}
.nsw-back-to-top.nsw-button {
  padding: 1rem;
  border: 0;
}
@media (min-width: 48rem) {
  .nsw-back-to-top.nsw-button {
    padding: 0.625rem 1.375rem;
  }
}
.nsw-back-to-top.nsw-button .nsw-material-icons:last-child {
  margin-left: 0;
}
@media (min-width: 48rem) {
  .nsw-back-to-top.nsw-button .nsw-material-icons:last-child {
    letter-spacing: -0.25rem;
  }
}

.nsw-form__date {
  padding: 0;
  margin: 0;
  border: 0;
}
.nsw-form__date legend {
  padding: 0;
}
.nsw-form__date-wrapper {
  display: flex;
  width: 100%;
  margin: 0 -0.5rem;
}
.nsw-form__date-wrapper > div {
  padding: 0 0.5rem;
}
.nsw-form__date-input {
  width: 4.625rem;
}
.nsw-form__date-input--large {
  width: 7.25rem;
}

:root {
  --date-picker-calendar-gap: 4px;
  --date-picker-calendar-item-size: 2.6em;
}

@media (min-width: 48rem) {
  :root {
    --date-picker-calendar-item-size: 3em;
  }
}
.nsw-date-input {
  position: relative;
}
.nsw-date-input__button {
  display: flex;
}
.nsw-date-input__button button {
  height: 3rem;
  align-self: flex-end;
}
.nsw-date-input__wrapper {
  position: relative;
}
.nsw-date-input__wrapper button .nsw-material-icons {
  font-size: 1.25rem;
}

.nsw-date-picker {
  display: inline-block;
  position: absolute;
  left: 0;
  top: calc(var(--date-picker-calendar-gap) + 100%);
  background-color: var(--nsw-white);
  border-radius: var(--nsw-border-radius);
  box-shadow: var(--nsw-box-shadow);
  padding: 0.375rem;
  z-index: 5;
  user-select: none;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.2s, opacity 0.2s;
}
@media (min-width: 62rem) {
  .nsw-date-picker {
    padding: 0.5625rem;
  }
}
.nsw-date-picker * {
  margin: 0;
  padding: 0;
  border: 0;
}
.nsw-date-picker ol,
.nsw-date-picker ul {
  list-style: none;
}
.nsw-section--invert .nsw-date-picker .nsw-icon-button {
  color: rgba(var(--nsw-palette-grey-01-rgb), 0.6);
}
.nsw-section--invert .nsw-date-picker .nsw-icon-button:hover {
  background-color: var(--nsw-hover);
  outline-width: 0;
}
.nsw-section--invert .nsw-date-picker .nsw-icon-button:focus {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-section--invert .nsw-date-picker .nsw-button--dark-outline-solid {
  background-color: var(--nsw-white);
  border-color: var(--nsw-brand-dark);
  color: var(--nsw-brand-dark);
}
.nsw-section--invert .nsw-date-picker .nsw-button--dark-outline-solid:focus {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-section--invert .nsw-date-picker .nsw-button--dark-outline-solid:hover {
  background-color: var(--nsw-brand-dark);
  border-color: transparent;
  color: var(--nsw-text-light);
}
.nsw-section--invert .nsw-date-picker .nsw-button--dark {
  background-color: var(--nsw-brand-dark);
  color: var(--nsw-text-light);
}
.nsw-section--invert .nsw-date-picker .nsw-button--dark:focus {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-section--invert .nsw-date-picker .nsw-button--dark:hover {
  color: var(--nsw-text-light);
  background-color: var(--nsw-brand-dark);
  background-image: linear-gradient(rgba(var(--nsw-white-rgb), 0.15), rgba(var(--nsw-white-rgb), 0.15));
  border-color: transparent;
}
.nsw-section--invert .nsw-date-picker .nsw-button--dark:active {
  background-color: var(--nsw-brand-dark);
  background-image: linear-gradient(rgba(var(--nsw-white-rgb), 0.075), rgba(var(--nsw-white-rgb), 0.075));
  border-color: transparent;
}
.nsw-date-picker--is-visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s;
}
.nsw-date-picker__title {
  position: relative;
}
.nsw-date-picker__title-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: var(--nsw-text-dark);
}
.nsw-date-picker__title-nav {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  justify-content: space-between;
}
.nsw-date-picker__title-nav li {
  display: flex;
}
.nsw-date-picker__title-nav-btn {
  width: var(--date-picker-calendar-item-size);
  height: var(--date-picker-calendar-item-size);
  border-radius: var(--nsw-border-radius);
  color: rgba(var(--nsw-palette-grey-01-rgb), 0.6);
  transition: transform 0.2s;
}
.nsw-date-picker__title-nav-btn:hover {
  background-color: rgba(var(--nsw-palette-grey-01-rgb), 0.075);
  color: var(--nsw-text-dark);
}
.nsw-date-picker__week, .nsw-date-picker__dates {
  display: flex;
  flex-wrap: wrap;
}
.nsw-date-picker__week li, .nsw-date-picker__dates li {
  width: var(--date-picker-calendar-item-size);
  height: var(--date-picker-calendar-item-size);
}
.nsw-date-picker__day {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.833rem;
  color: rgba(var(--nsw-palette-grey-01-rgb), 0.6);
}
.nsw-date-picker__dates {
  width: calc(var(--date-picker-calendar-item-size) * 7);
}
.nsw-date-picker__date {
  background-color: transparent;
  padding: 0;
  border: 0;
  border-radius: 0;
  color: var(--nsw-text-dark);
  line-height: inherit;
  appearance: none;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 1rem;
}
.nsw-date-picker__date:focus {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
}
.nsw-date-picker__date:focus, .nsw-date-picker__date:hover {
  border-radius: var(--nsw-border-radius);
}
.nsw-date-picker__date:hover {
  box-shadow: inset 0 0 0 2px var(--nsw-focus);
}
.nsw-date-picker__date:focus:not(:hover) {
  box-shadow: 0 0 0 2px rgba(var(--nsw-palette-blue-01-rgb), 0.2), 0 2px 4px rgba(var(--nsw-palette-blue-01-rgb), 0.3);
}
.nsw-date-picker__date--today {
  color: var(--nsw-brand-dark);
  border-radius: var(--nsw-border-radius);
  background-color: var(--nsw-hover);
}
.nsw-date-picker__date--today::after {
  content: "";
  background-color: var(--nsw-brand-dark);
  border-radius: 4px;
  bottom: 6px;
  height: 4px;
  left: 50%;
  margin-left: -2px;
  position: absolute;
  width: 4px;
}
.nsw-date-picker__date--keyboard-focus {
  background-color: rgba(var(--nsw-palette-grey-01-rgb), 0.2);
  border-radius: var(--nsw-border-radius);
}
.nsw-date-picker__date::-moz-focus-inner {
  border: 0;
}
.nsw-date-picker__date--selected {
  border-radius: var(--nsw-border-radius);
  background-color: var(--nsw-brand-dark);
  box-shadow: 0 2px 4px rgba(var(--nsw-palette-blue-01-rgb), 0.3);
  color: var(--nsw-white);
  z-index: 2;
}
.nsw-date-picker__date[disabled=true], .nsw-date-picker__date[aria-disabled=true] {
  background-color: rgba(var(--nsw-palette-grey-03-rgb), 0.5);
  color: rgba(var(--nsw-palette-grey-01-rgb), 0.7);
  border-radius: var(--nsw-border-radius);
  pointer-events: none;
}
.nsw-date-picker__date.nsw-date-picker__date--range {
  background-color: rgba(var(--nsw-palette-blue-01-rgb), 0.2);
  color: var(--nsw-text-dark);
}
.nsw-date-picker__date.nsw-date-picker__date--range:focus, .nsw-date-picker__date.nsw-date-picker__date--range:hover {
  border-radius: 0;
}
.nsw-date-picker__date.nsw-date-picker__date--range:focus {
  background-color: var(--nsw-focus);
}
.nsw-date-picker__date.nsw-date-picker__date--range-start, .nsw-date-picker__date.nsw-date-picker__date--range-end {
  background-color: var(--nsw-brand-dark);
  box-shadow: 0 2px 4px rgba(var(--nsw-palette-blue-01-rgb), 0.3);
  color: var(--nsw-white);
  z-index: 2;
}
.nsw-date-picker__date.nsw-date-picker__date--range-start:focus:not(:hover), .nsw-date-picker__date.nsw-date-picker__date--range-end:focus:not(:hover) {
  box-shadow: 0 0 0 2px rgba(var(--nsw-palette-blue-01-rgb), 0.2), 0 2px 4px rgba(var(--nsw-palette-blue-01-rgb), 0.3);
}
.nsw-date-picker__date.nsw-date-picker__date--range-start {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.nsw-date-picker__date.nsw-date-picker__date--range-end {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.nsw-date-picker__buttongroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.375rem 0;
  gap: 0.5rem;
}
@media (min-width: 62rem) {
  .nsw-date-picker__buttongroup {
    margin: 0.5625rem 0;
  }
}
.nsw-date-picker__buttongroup button {
  height: var(--date-picker-calendar-item-size);
  line-height: 1;
  padding: 0;
  flex-basis: 100%;
  flex: 1;
}

.dialog-active {
  overflow: hidden;
}

.nsw-dialog {
  z-index: 300;
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(var(--nsw-black-rgb), 0.7);
  justify-content: center;
  align-items: flex-end;
}
.nsw-section--invert .nsw-dialog {
  color: var(--nsw-text-dark);
}
.nsw-section--invert .nsw-dialog .nsw-icon-button {
  color: var(--nsw-link);
}
.nsw-section--invert .nsw-dialog .nsw-icon-button:visited {
  color: var(--nsw-visited);
}
.nsw-section--invert .nsw-dialog .nsw-icon-button:focus {
  outline-color: var(--nsw-focus);
}
.nsw-section--invert .nsw-dialog .nsw-icon-button:hover {
  background-color: var(--nsw-hover);
  outline-color: var(--nsw-hover);
}
.nsw-section--invert .nsw-dialog .nsw-icon-button:active {
  background-color: var(--nsw-active);
  outline-color: var(--nsw-active);
}
@media (min-width: 36rem) {
  .nsw-dialog {
    align-items: center;
    padding: 0 1rem;
  }
}
.nsw-dialog.active {
  display: flex;
}
.nsw-dialog__wrapper {
  background-color: var(--nsw-white);
  width: 100%;
  max-width: 46rem;
  position: relative;
  padding-bottom: 9.0625rem;
}
@media (min-width: 36rem) {
  .nsw-dialog__wrapper {
    border-radius: var(--nsw-border-radius);
    align-items: center;
    max-height: 90%;
  }
}
@media (min-width: 48rem) {
  .nsw-dialog__wrapper {
    padding-bottom: 5.0625rem;
  }
}
@media (min-width: 62rem) {
  .nsw-dialog__wrapper {
    padding-bottom: 7.0625rem;
  }
}
.nsw-dialog--single-action .nsw-dialog__wrapper {
  padding-bottom: 5.0625rem;
}
@media (min-width: 62rem) {
  .nsw-dialog--single-action .nsw-dialog__wrapper {
    padding-bottom: 7.0625rem;
  }
}
.nsw-dialog__container {
  height: 100%;
  max-height: calc(100vh - 9.0625rem);
  overflow-y: auto;
}
@media (min-width: 36rem) {
  .nsw-dialog__container {
    max-height: calc(90vh - 9.0625rem);
  }
}
@media (min-width: 48rem) {
  .nsw-dialog__container {
    max-height: calc(90vh - 5.0625rem);
  }
}
@media (min-width: 62rem) {
  .nsw-dialog__container {
    max-height: calc(90vh - 7.0625rem);
  }
}
.nsw-dialog--single-action .nsw-dialog__container {
  max-height: calc(100vh - 5.0625rem);
}
@media (min-width: 36rem) {
  .nsw-dialog--single-action .nsw-dialog__container {
    max-height: calc(90vh - 5.0625rem);
  }
}
@media (min-width: 62rem) {
  .nsw-dialog--single-action .nsw-dialog__container {
    max-height: calc(90vh - 7.0625rem);
  }
}
.nsw-dialog__top {
  border-bottom: 1px solid var(--nsw-grey-04);
  display: flex;
}
.nsw-dialog__title {
  padding: 1rem;
  width: 100%;
}
@media (min-width: 62rem) {
  .nsw-dialog__title {
    padding: 2rem;
  }
}
.nsw-dialog__close {
  min-width: 3rem;
  max-width: 3rem;
  padding: 0.5rem 0.5rem 0.5rem 0;
}
@media (min-width: 62rem) {
  .nsw-dialog__close {
    min-width: 4rem;
    max-width: 4rem;
    padding: 1.5rem 1.5rem 1.5rem 0;
  }
}
.nsw-dialog__content {
  padding: 1rem;
}
@media (min-width: 62rem) {
  .nsw-dialog__content {
    padding: 2rem;
  }
}
.nsw-dialog__bottom {
  border-top: 1px solid var(--nsw-grey-04);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
@media (min-width: 48rem) {
  .nsw-dialog__bottom {
    flex-direction: row-reverse;
    justify-content: flex-start;
  }
}
@media (min-width: 62rem) {
  .nsw-dialog__bottom {
    padding: 2rem;
  }
}
.nsw-dialog__bottom button {
  margin-bottom: 1rem;
}
@media (min-width: 48rem) {
  .nsw-dialog__bottom button {
    margin-left: 1rem;
    margin-bottom: 0;
  }
}
.nsw-dialog__bottom button:last-child {
  margin-bottom: 0;
}
@media (min-width: 48rem) {
  .nsw-dialog__bottom button:last-child {
    margin-left: 0;
  }
}

.nsw-breadcrumbs {
  font-size: var(--nsw-font-size-xs-mobile);
  line-height: var(--nsw-line-height-xs-mobile);
  margin-top: 1.5rem;
}
@media (min-width: 62rem) {
  .nsw-breadcrumbs {
    font-size: var(--nsw-font-size-xs-desktop);
    line-height: var(--nsw-line-height-xs-desktop);
  }
}
.nsw-breadcrumbs ol {
  margin: 0;
  padding-left: 0;
  list-style: none;
}
.nsw-breadcrumbs li:not(:first-child):not(:last-child):not(:nth-last-child(2)):not(.nsw-breadcrumbs__show-more-toggle) {
  display: none;
}
@media (min-width: 48rem) {
  .nsw-breadcrumbs li:not(:first-child):not(:last-child):not(:nth-last-child(2)):not(.nsw-breadcrumbs__show-more-toggle) {
    display: inline-block;
  }
}
.nsw-breadcrumbs li {
  display: inline-block;
}
.nsw-breadcrumbs li:first-child::before {
  display: none;
}
.nsw-breadcrumbs li::before {
  font-family: "Material Icons";
  -webkit-font-feature-settings: "liga"; /* stylelint-disable-line property-no-vendor-prefix */
  content: "keyboard_arrow_right";
  font-size: 1.25rem;
  line-height: 0.5rem;
  display: inline-block;
  position: relative;
  top: 0.375rem;
}
@media (min-width: 48rem) {
  .nsw-breadcrumbs .nsw-breadcrumbs__show-more-toggle {
    display: none;
  }
}
.nsw-breadcrumbs .nsw-breadcrumbs__show-more-toggle .nsw-breadcrumbs__toggle-button {
  font-weight: var(--nsw-font-bold);
  color: var(--nsw-link);
  text-decoration: underline;
  font-size: var(--nsw-font-size-sm-mobile);
  line-height: var(--nsw-line-height-sm-mobile);
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 2px;
  -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
  font-size: var(--nsw-font-size-xs);
  font-weight: var(--nsw-font-normal);
  color: var(--nsw-text-dark);
  text-decoration: none;
  padding: 0 0.25rem;
  margin-left: 4px;
  line-height: 24px;
  height: 100%;
  cursor: pointer;
}
.nsw-breadcrumbs .nsw-breadcrumbs__show-more-toggle .nsw-breadcrumbs__toggle-button:visited {
  color: var(--nsw-visited);
}
.nsw-breadcrumbs .nsw-breadcrumbs__show-more-toggle .nsw-breadcrumbs__toggle-button:focus {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
}
.nsw-breadcrumbs .nsw-breadcrumbs__show-more-toggle .nsw-breadcrumbs__toggle-button:hover {
  background-color: var(--nsw-hover);
  outline: 2px solid var(--nsw-hover);
}
.nsw-breadcrumbs .nsw-breadcrumbs__show-more-toggle .nsw-breadcrumbs__toggle-button:active {
  background-color: var(--nsw-active);
  outline-color: var(--nsw-active);
}
@media (min-width: 62rem) {
  .nsw-breadcrumbs .nsw-breadcrumbs__show-more-toggle .nsw-breadcrumbs__toggle-button {
    font-size: var(--nsw-font-size-sm-desktop);
    line-height: var(--nsw-line-height-sm-desktop);
  }
}
.nsw-section--invert .nsw-breadcrumbs .nsw-breadcrumbs__show-more-toggle .nsw-breadcrumbs__toggle-button {
  color: var(--nsw-link-light);
  color: var(--nsw-text-light);
}
.nsw-section--invert .nsw-breadcrumbs .nsw-breadcrumbs__show-more-toggle .nsw-breadcrumbs__toggle-button:visited {
  color: var(--nsw-visited-light);
}
.nsw-section--invert .nsw-breadcrumbs .nsw-breadcrumbs__show-more-toggle .nsw-breadcrumbs__toggle-button:focus {
  outline-color: var(--nsw-focus-light);
}
.nsw-section--invert .nsw-breadcrumbs .nsw-breadcrumbs__show-more-toggle .nsw-breadcrumbs__toggle-button:hover {
  background-color: var(--nsw-hover-light);
  outline-color: var(--nsw-hover-light);
}
.nsw-section--invert .nsw-breadcrumbs .nsw-breadcrumbs__show-more-toggle .nsw-breadcrumbs__toggle-button:active {
  background-color: var(--nsw-active-light);
  outline-color: var(--nsw-active-light);
}
.nsw-breadcrumbs .nsw-breadcrumbs__show-all li:not(:first-child):not(:last-child):not(:nth-last-child(2)):not(.nsw-breadcrumbs__show-more-toggle) {
  display: inline-block;
}
.nsw-breadcrumbs .nsw-breadcrumbs__show-all .nsw-breadcrumbs__show-more-toggle {
  display: none;
}
.nsw-breadcrumbs a {
  display: inline-block;
  line-height: 24px;
  font-weight: var(--nsw-font-normal);
}
.nsw-breadcrumbs a.current {
  text-decoration: none;
  pointer-events: none;
  color: var(--nsw-text-dark);
}
.nsw-section--invert .nsw-breadcrumbs a.current {
  color: var(--nsw-text-light);
}

@supports (content: "x"/"y") {
  .nsw-breadcrumbs li::before {
    content: "keyboard_arrow_right"/"";
  }
}
@media speech {
  .nsw-breadcrumbs li::before {
    display: none;
    visibility: hidden;
  }
}
.nsw-button {
  font-size: var(--nsw-font-size-sm-mobile);
  line-height: var(--nsw-line-height-sm-mobile);
  border-radius: var(--nsw-border-radius);
  font-weight: var(--nsw-font-bold);
  border: solid 2px transparent;
  white-space: normal;
  text-decoration: none;
  padding: 0.625rem 1.375rem;
  cursor: pointer;
  -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
  appearance: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  display: inline-block;
}
@media (min-width: 62rem) {
  .nsw-button {
    font-size: var(--nsw-font-size-sm-desktop);
    line-height: var(--nsw-line-height-sm-desktop);
  }
}
.nsw-button:not(.nsw-button--flex) {
  min-width: 7.75rem;
}
.nsw-button:hover {
  outline: none;
}
.nsw-button:focus {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-button:disabled, .nsw-button.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.nsw-button .nsw-material-icons {
  font-size: 1.5rem;
  line-height: 0.625rem;
  position: relative;
  bottom: -0.4375rem;
}
.nsw-button .nsw-material-icons:first-child {
  margin-right: 0.5rem;
}
.nsw-button .nsw-material-icons:last-child {
  margin-left: 0.5rem;
}
.nsw-button .nsw-material-icons:only-child {
  margin: 0;
}
.nsw-button--small {
  font-size: var(--nsw-font-size-xs-mobile);
  line-height: var(--nsw-line-height-xs-mobile);
  padding: 0.3125rem;
}
@media (min-width: 62rem) {
  .nsw-button--small {
    font-size: var(--nsw-font-size-xs-desktop);
    line-height: var(--nsw-line-height-xs-desktop);
  }
}
.nsw-button--small .nsw-material-icons {
  font-size: 1.25rem;
}
.nsw-button--small .nsw-material-icons:first-child {
  margin-right: 0.25rem;
}
.nsw-button--small .nsw-material-icons:last-child {
  margin-left: 0.25rem;
}
.nsw-button--small .nsw-material-icons:only-child {
  margin: 0;
}
.nsw-button--flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nsw-button--flex .nsw-material-icons {
  position: static;
}
.nsw-button--full-width {
  display: block;
  width: 100%;
}
@media (min-width: 62rem) {
  .nsw-button--full-width {
    display: inline-block;
    width: auto;
  }
}

.nsw-button--dark {
  color: var(--nsw-text-light);
  background-color: var(--nsw-brand-dark);
  border-color: transparent;
}
.nsw-button--dark:visited {
  color: var(--nsw-text-light);
}
.nsw-button--dark:focus {
  outline-color: var(--nsw-focus);
}
.nsw-button--dark:hover {
  color: var(--nsw-text-light);
  background-color: var(--nsw-brand-dark);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-white-rgb), 0.15), rgba(var(--nsw-white-rgb), 0.15));
}
.nsw-button--dark:hover .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-button--dark:active {
  color: var(--nsw-text-light);
  background-color: var(--nsw-brand-dark);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-white-rgb), 0.075), rgba(var(--nsw-white-rgb), 0.075));
}
.nsw-button--dark:active .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-button--dark:disabled, .nsw-button--dark.disabled {
  color: var(--nsw-text-light);
  background-color: var(--nsw-brand-dark);
  border-color: transparent;
}
.nsw-button--dark .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-button--dark-outline {
  color: var(--nsw-brand-dark);
  background-color: transparent;
  border-color: var(--nsw-brand-dark);
}
.nsw-button--dark-outline:visited {
  color: var(--nsw-brand-dark);
}
.nsw-button--dark-outline:focus {
  outline-color: var(--nsw-focus);
}
.nsw-button--dark-outline:hover {
  color: var(--nsw-text-light);
  background-color: var(--nsw-brand-dark);
  border-color: transparent;
}
.nsw-button--dark-outline:hover .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-button--dark-outline:active {
  color: var(--nsw-text-light);
  background-color: var(--nsw-brand-dark);
  border-color: transparent;
}
.nsw-button--dark-outline:active .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-button--dark-outline:disabled, .nsw-button--dark-outline.disabled {
  color: var(--nsw-brand-dark);
  background-color: transparent;
  border-color: var(--nsw-brand-dark);
}
.nsw-button--dark-outline .nsw-material-icons {
  color: var(--nsw-brand-dark);
}
.nsw-button--dark-outline-solid {
  color: var(--nsw-brand-dark);
  background-color: var(--nsw-white);
  border-color: var(--nsw-brand-dark);
}
.nsw-button--dark-outline-solid:visited {
  color: var(--nsw-brand-dark);
}
.nsw-button--dark-outline-solid:focus {
  outline-color: var(--nsw-focus);
}
.nsw-button--dark-outline-solid:hover {
  color: var(--nsw-text-light);
  background-color: var(--nsw-brand-dark);
  border-color: transparent;
}
.nsw-button--dark-outline-solid:hover .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-button--dark-outline-solid:active {
  color: var(--nsw-text-light);
  background-color: var(--nsw-brand-dark);
  border-color: transparent;
}
.nsw-button--dark-outline-solid:active .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-button--dark-outline-solid:disabled, .nsw-button--dark-outline-solid.disabled {
  color: var(--nsw-brand-dark);
  background-color: var(--nsw-white);
  border-color: var(--nsw-brand-dark);
}
.nsw-button--dark-outline-solid .nsw-material-icons {
  color: var(--nsw-brand-dark);
}
.nsw-button--light {
  color: var(--nsw-text-dark);
  background-color: var(--nsw-brand-light);
  border-color: transparent;
}
.nsw-button--light:visited {
  color: var(--nsw-text-dark);
}
.nsw-button--light:focus {
  outline-color: var(--nsw-focus);
}
.nsw-button--light:hover {
  color: var(--nsw-text-dark);
  background-color: var(--nsw-brand-light);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-black-rgb), 0.07), rgba(var(--nsw-black-rgb), 0.07));
}
.nsw-button--light:hover .nsw-material-icons {
  color: var(--nsw-text-dark);
}
.nsw-button--light:active {
  color: var(--nsw-text-dark);
  background-color: var(--nsw-brand-light);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-black-rgb), 0.035), rgba(var(--nsw-black-rgb), 0.035));
}
.nsw-button--light:active .nsw-material-icons {
  color: var(--nsw-text-dark);
}
.nsw-button--light:disabled, .nsw-button--light.disabled {
  color: var(--nsw-text-dark);
  background-color: var(--nsw-brand-light);
  border-color: transparent;
}
.nsw-button--light .nsw-material-icons {
  color: var(--nsw-text-dark);
}
.nsw-button--light-outline {
  color: var(--nsw-brand-light);
  background-color: transparent;
  border-color: var(--nsw-brand-light);
}
.nsw-button--light-outline:visited {
  color: var(--nsw-brand-light);
}
.nsw-button--light-outline:focus {
  outline-color: var(--nsw-focus);
}
.nsw-button--light-outline:hover {
  color: var(--nsw-text-dark);
  background-color: var(--nsw-brand-light);
  border-color: transparent;
}
.nsw-button--light-outline:hover .nsw-material-icons {
  color: var(--nsw-text-dark);
}
.nsw-button--light-outline:active {
  color: var(--nsw-text-dark);
  background-color: var(--nsw-brand-light);
  border-color: transparent;
}
.nsw-button--light-outline:active .nsw-material-icons {
  color: var(--nsw-text-dark);
}
.nsw-button--light-outline:disabled, .nsw-button--light-outline.disabled {
  color: var(--nsw-brand-light);
  background-color: transparent;
  border-color: var(--nsw-brand-light);
}
.nsw-button--light-outline .nsw-material-icons {
  color: var(--nsw-brand-light);
}
.nsw-button--white {
  color: var(--nsw-text-dark);
  background-color: var(--nsw-white);
  border-color: transparent;
}
.nsw-button--white:visited {
  color: var(--nsw-text-dark);
}
.nsw-button--white:focus {
  outline-color: var(--nsw-white);
}
.nsw-button--white:hover {
  color: var(--nsw-text-dark);
  background-color: var(--nsw-white);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-black-rgb), 0.07), rgba(var(--nsw-black-rgb), 0.07));
}
.nsw-button--white:hover .nsw-material-icons {
  color: var(--nsw-text-dark);
}
.nsw-button--white:active {
  color: var(--nsw-text-dark);
  background-color: var(--nsw-white);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-black-rgb), 0.035), rgba(var(--nsw-black-rgb), 0.035));
}
.nsw-button--white:active .nsw-material-icons {
  color: var(--nsw-text-dark);
}
.nsw-button--white:disabled, .nsw-button--white.disabled {
  color: var(--nsw-text-dark);
  background-color: var(--nsw-white);
  border-color: transparent;
}
.nsw-button--white .nsw-material-icons {
  color: var(--nsw-text-dark);
}
.nsw-button--white-outline {
  color: var(--nsw-white);
  background-color: transparent;
  border-color: var(--nsw-white);
}
.nsw-button--white-outline:visited {
  color: var(--nsw-white);
}
.nsw-button--white-outline:focus {
  outline-color: var(--nsw-white);
}
.nsw-button--white-outline:hover {
  color: var(--nsw-text-dark);
  background-color: var(--nsw-white);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-black-rgb), 0.07), rgba(var(--nsw-black-rgb), 0.07));
}
.nsw-button--white-outline:hover .nsw-material-icons {
  color: var(--nsw-text-dark);
}
.nsw-button--white-outline:active {
  color: var(--nsw-text-dark);
  background-color: var(--nsw-white);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-black-rgb), 0.035), rgba(var(--nsw-black-rgb), 0.035));
}
.nsw-button--white-outline:active .nsw-material-icons {
  color: var(--nsw-text-dark);
}
.nsw-button--white-outline:disabled, .nsw-button--white-outline.disabled {
  color: var(--nsw-white);
  background-color: transparent;
  border-color: var(--nsw-white);
}
.nsw-button--white-outline .nsw-material-icons {
  color: var(--nsw-white);
}
.nsw-button--danger {
  color: var(--nsw-text-light);
  background-color: var(--nsw-status-error);
  border-color: transparent;
}
.nsw-button--danger:visited {
  color: var(--nsw-text-light);
}
.nsw-button--danger:focus {
  outline-color: var(--nsw-focus);
}
.nsw-button--danger:hover {
  color: var(--nsw-text-light);
  background-color: var(--nsw-status-error);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-white-rgb), 0.15), rgba(var(--nsw-white-rgb), 0.15));
}
.nsw-button--danger:hover .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-button--danger:active {
  color: var(--nsw-text-light);
  background-color: var(--nsw-status-error);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-white-rgb), 0.075), rgba(var(--nsw-white-rgb), 0.075));
}
.nsw-button--danger:active .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-button--danger:disabled, .nsw-button--danger.disabled {
  color: var(--nsw-text-light);
  background-color: var(--nsw-status-error);
  border-color: transparent;
}
.nsw-button--danger .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-button--info {
  color: var(--nsw-text-light);
  background-color: var(--nsw-status-info);
  border-color: transparent;
}
.nsw-button--info:visited {
  color: var(--nsw-text-light);
}
.nsw-button--info:focus {
  outline-color: var(--nsw-focus);
}
.nsw-button--info:hover {
  color: var(--nsw-text-light);
  background-color: var(--nsw-status-info);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-white-rgb), 0.15), rgba(var(--nsw-white-rgb), 0.15));
}
.nsw-button--info:hover .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-button--info:active {
  color: var(--nsw-text-light);
  background-color: var(--nsw-status-info);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-white-rgb), 0.075), rgba(var(--nsw-white-rgb), 0.075));
}
.nsw-button--info:active .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-button--info:disabled, .nsw-button--info.disabled {
  color: var(--nsw-text-light);
  background-color: var(--nsw-status-info);
  border-color: transparent;
}
.nsw-button--info .nsw-material-icons {
  color: var(--nsw-text-light);
}

.nsw-section--invert .nsw-button--dark {
  color: var(--nsw-section-bg);
  background-color: var(--nsw-white);
}
.nsw-section--invert .nsw-button--dark:visited {
  color: var(--nsw-section-bg);
}
.nsw-section--invert .nsw-button--dark:focus {
  outline-color: var(--nsw-focus-light);
}
.nsw-section--invert .nsw-button--dark:hover {
  color: var(--nsw-section-bg);
  background-color: var(--nsw-white);
  background-image: linear-gradient(rgba(var(--nsw-black-rgb), 0.07), rgba(var(--nsw-black-rgb), 0.07));
}
.nsw-section--invert .nsw-button--dark:hover .nsw-material-icons {
  color: var(--nsw-section-bg);
}
.nsw-section--invert .nsw-button--dark:active {
  color: var(--nsw-section-bg);
  background-color: var(--nsw-white);
  background-image: linear-gradient(rgba(var(--nsw-white-rgb), 0.075), rgba(var(--nsw-white-rgb), 0.075));
}
.nsw-section--invert .nsw-button--dark:active .nsw-material-icons {
  color: var(--nsw-section-bg);
}
.nsw-section--invert .nsw-button--dark:disabled, .nsw-section--invert .nsw-button--dark.disabled {
  color: var(--nsw-section-bg);
  background-color: var(--nsw-white);
}
.nsw-section--invert .nsw-button--dark .nsw-material-icons {
  color: var(--nsw-section-bg);
}
.nsw-section--invert .nsw-button--dark-outline {
  color: var(--nsw-white);
  background-color: transparent;
  border-color: var(--nsw-white);
}
.nsw-section--invert .nsw-button--dark-outline:visited {
  color: var(--nsw-white);
}
.nsw-section--invert .nsw-button--dark-outline:focus {
  outline-color: var(--nsw-focus-light);
}
.nsw-section--invert .nsw-button--dark-outline:hover {
  color: var(--nsw-section-bg);
  background-color: var(--nsw-white);
  border-color: transparent;
}
.nsw-section--invert .nsw-button--dark-outline:hover .nsw-material-icons {
  color: var(--nsw-section-bg);
}
.nsw-section--invert .nsw-button--dark-outline:active {
  color: var(--nsw-section-bg);
  background-color: var(--nsw-white);
  border-color: transparent;
}
.nsw-section--invert .nsw-button--dark-outline:active .nsw-material-icons {
  color: var(--nsw-section-bg);
}
.nsw-section--invert .nsw-button--dark-outline:disabled, .nsw-section--invert .nsw-button--dark-outline.disabled {
  color: var(--nsw-white);
  background-color: transparent;
  border-color: var(--nsw-white);
}
.nsw-section--invert .nsw-button--dark-outline .nsw-material-icons {
  color: var(--nsw-white);
}
.nsw-section--invert .nsw-button--dark-outline-solid {
  color: var(--nsw-white);
  background-color: var(--nsw-section-bg);
  border-color: var(--nsw-white);
}
.nsw-section--invert .nsw-button--dark-outline-solid:visited {
  color: var(--nsw-white);
}
.nsw-section--invert .nsw-button--dark-outline-solid:focus {
  outline-color: var(--nsw-focus-light);
}
.nsw-section--invert .nsw-button--dark-outline-solid:hover {
  color: var(--nsw-section-bg);
  background-color: var(--nsw-white);
  border-color: transparent;
}
.nsw-section--invert .nsw-button--dark-outline-solid:hover .nsw-material-icons {
  color: var(--nsw-section-bg);
}
.nsw-section--invert .nsw-button--dark-outline-solid:active {
  color: var(--nsw-section-bg);
  background-color: var(--nsw-white);
  border-color: transparent;
}
.nsw-section--invert .nsw-button--dark-outline-solid:active .nsw-material-icons {
  color: var(--nsw-section-bg);
}
.nsw-section--invert .nsw-button--dark-outline-solid:disabled, .nsw-section--invert .nsw-button--dark-outline-solid.disabled {
  color: var(--nsw-white);
  background-color: var(--nsw-section-bg);
  border-color: var(--nsw-white);
}
.nsw-section--invert .nsw-button--dark-outline-solid .nsw-material-icons {
  color: var(--nsw-white);
}
.nsw-section--invert .nsw-button--light {
  color: var(--nsw-section-bg);
  background-color: var(--nsw-brand-light);
  border-color: transparent;
}
.nsw-section--invert .nsw-button--light:visited {
  color: var(--nsw-section-bg);
}
.nsw-section--invert .nsw-button--light:focus {
  outline-color: var(--nsw-focus-light);
}
.nsw-section--invert .nsw-button--light:hover {
  color: var(--nsw-section-bg);
  background-color: var(--nsw-brand-light);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-white-rgb), 0.15), rgba(var(--nsw-white-rgb), 0.15));
}
.nsw-section--invert .nsw-button--light:hover .nsw-material-icons {
  color: var(--nsw-section-bg);
}
.nsw-section--invert .nsw-button--light:active {
  color: var(--nsw-section-bg);
  background-color: var(--nsw-brand-light);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-black-rgb), 0.035), rgba(var(--nsw-black-rgb), 0.035));
}
.nsw-section--invert .nsw-button--light:active .nsw-material-icons {
  color: var(--nsw-section-bg);
}
.nsw-section--invert .nsw-button--light:disabled, .nsw-section--invert .nsw-button--light.disabled {
  color: var(--nsw-section-bg);
  background-color: var(--nsw-brand-light);
  border-color: transparent;
}
.nsw-section--invert .nsw-button--light .nsw-material-icons {
  color: var(--nsw-section-bg);
}
.nsw-section--invert .nsw-button--light-outline {
  color: var(--nsw-brand-light);
  background-color: transparent;
  border-color: var(--nsw-brand-light);
}
.nsw-section--invert .nsw-button--light-outline:visited {
  color: var(--nsw-brand-light);
}
.nsw-section--invert .nsw-button--light-outline:focus {
  outline-color: var(--nsw-focus-light);
}
.nsw-section--invert .nsw-button--light-outline:hover {
  color: var(--nsw-section-bg);
  background-color: var(--nsw-brand-light);
  border-color: transparent;
}
.nsw-section--invert .nsw-button--light-outline:hover .nsw-material-icons {
  color: var(--nsw-section-bg);
}
.nsw-section--invert .nsw-button--light-outline:active {
  color: var(--nsw-section-bg);
  background-color: var(--nsw-brand-light);
  border-color: transparent;
}
.nsw-section--invert .nsw-button--light-outline:active .nsw-material-icons {
  color: var(--nsw-section-bg);
}
.nsw-section--invert .nsw-button--light-outline:disabled, .nsw-section--invert .nsw-button--light-outline.disabled {
  color: var(--nsw-brand-light);
  background-color: transparent;
  border-color: var(--nsw-brand-light);
}
.nsw-section--invert .nsw-button--light-outline .nsw-material-icons {
  color: var(--nsw-brand-light);
}
.nsw-section--invert .nsw-button--white {
  color: var(--nsw-section-bg);
  background-color: var(--nsw-white);
  border-color: transparent;
}
.nsw-section--invert .nsw-button--white:visited {
  color: var(--nsw-section-bg);
}
.nsw-section--invert .nsw-button--white:focus {
  outline-color: var(--nsw-focus-light);
}
.nsw-section--invert .nsw-button--white:hover {
  color: var(--nsw-section-bg);
  background-color: var(--nsw-white);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-black-rgb), 0.07), rgba(var(--nsw-black-rgb), 0.07));
}
.nsw-section--invert .nsw-button--white:hover .nsw-material-icons {
  color: var(--nsw-section-bg);
}
.nsw-section--invert .nsw-button--white:active {
  color: var(--nsw-section-bg);
  background-color: var(--nsw-white);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-black-rgb), 0.035), rgba(var(--nsw-black-rgb), 0.035));
}
.nsw-section--invert .nsw-button--white:active .nsw-material-icons {
  color: var(--nsw-section-bg);
}
.nsw-section--invert .nsw-button--white:disabled, .nsw-section--invert .nsw-button--white.disabled {
  color: var(--nsw-section-bg);
  background-color: var(--nsw-white);
  border-color: transparent;
}
.nsw-section--invert .nsw-button--white .nsw-material-icons {
  color: var(--nsw-section-bg);
}
.nsw-section--invert .nsw-button--white-outline {
  color: var(--nsw-white);
  background-color: transparent;
  border-color: var(--nsw-white);
}
.nsw-section--invert .nsw-button--white-outline:visited {
  color: var(--nsw-white);
}
.nsw-section--invert .nsw-button--white-outline:focus {
  outline-color: var(--nsw-focus-light);
}
.nsw-section--invert .nsw-button--white-outline:hover {
  color: var(--nsw-section-bg);
  background-color: var(--nsw-white);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-white-rgb), 0.15), rgba(var(--nsw-white-rgb), 0.15));
}
.nsw-section--invert .nsw-button--white-outline:hover .nsw-material-icons {
  color: var(--nsw-section-bg);
}
.nsw-section--invert .nsw-button--white-outline:active {
  color: var(--nsw-section-bg);
  background-color: var(--nsw-white);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-black-rgb), 0.035), rgba(var(--nsw-black-rgb), 0.035));
}
.nsw-section--invert .nsw-button--white-outline:active .nsw-material-icons {
  color: var(--nsw-section-bg);
}
.nsw-section--invert .nsw-button--white-outline:disabled, .nsw-section--invert .nsw-button--white-outline.disabled {
  color: var(--nsw-white);
  background-color: transparent;
  border-color: var(--nsw-white);
}
.nsw-section--invert .nsw-button--white-outline .nsw-material-icons {
  color: var(--nsw-white);
}
.nsw-section--invert .nsw-button--danger {
  color: var(--nsw-text-light);
  background-color: var(--nsw-status-error);
  border-color: transparent;
}
.nsw-section--invert .nsw-button--danger:visited {
  color: var(--nsw-text-light);
}
.nsw-section--invert .nsw-button--danger:focus {
  outline-color: var(--nsw-focus-light);
}
.nsw-section--invert .nsw-button--danger:hover {
  color: var(--nsw-text-light);
  background-color: var(--nsw-status-error);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-white-rgb), 0.15), rgba(var(--nsw-white-rgb), 0.15));
}
.nsw-section--invert .nsw-button--danger:hover .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-section--invert .nsw-button--danger:active {
  color: var(--nsw-text-light);
  background-color: var(--nsw-status-error);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-white-rgb), 0.075), rgba(var(--nsw-white-rgb), 0.075));
}
.nsw-section--invert .nsw-button--danger:active .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-section--invert .nsw-button--danger:disabled, .nsw-section--invert .nsw-button--danger.disabled {
  color: var(--nsw-text-light);
  background-color: var(--nsw-status-error);
  border-color: transparent;
}
.nsw-section--invert .nsw-button--danger .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-section--invert .nsw-button--info:focus {
  outline-color: var(--nsw-focus-light);
}
.nsw-section--invert .nsw-button--info:active {
  background-image: linear-gradient(rgba(var(--nsw-white-rgb), 0.075), rgba(var(--nsw-white-rgb), 0.075));
}
.nsw-dialog .nsw-button--dark {
  color: var(--nsw-text-light);
  background-color: var(--nsw-brand-dark);
  border-color: transparent;
}
.nsw-dialog .nsw-button--dark:visited {
  color: var(--nsw-text-light);
}
.nsw-dialog .nsw-button--dark:focus {
  outline-color: var(--nsw-focus);
}
.nsw-dialog .nsw-button--dark:hover {
  color: var(--nsw-text-light);
  background-color: var(--nsw-brand-dark);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-white-rgb), 0.15), rgba(var(--nsw-white-rgb), 0.15));
}
.nsw-dialog .nsw-button--dark:hover .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-dialog .nsw-button--dark:active {
  color: var(--nsw-text-light);
  background-color: var(--nsw-brand-dark);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-white-rgb), 0.075), rgba(var(--nsw-white-rgb), 0.075));
}
.nsw-dialog .nsw-button--dark:active .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-dialog .nsw-button--dark:disabled, .nsw-dialog .nsw-button--dark.disabled {
  color: var(--nsw-text-light);
  background-color: var(--nsw-brand-dark);
  border-color: transparent;
}
.nsw-dialog .nsw-button--dark .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-dialog .nsw-button--dark-outline {
  color: var(--nsw-brand-dark);
  background-color: transparent;
  border-color: var(--nsw-brand-dark);
}
.nsw-dialog .nsw-button--dark-outline:visited {
  color: var(--nsw-brand-dark);
}
.nsw-dialog .nsw-button--dark-outline:focus {
  outline-color: var(--nsw-focus);
}
.nsw-dialog .nsw-button--dark-outline:hover {
  color: var(--nsw-text-light);
  background-color: var(--nsw-brand-dark);
  border-color: transparent;
}
.nsw-dialog .nsw-button--dark-outline:hover .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-dialog .nsw-button--dark-outline:active {
  color: var(--nsw-text-light);
  background-color: var(--nsw-brand-dark);
  border-color: transparent;
}
.nsw-dialog .nsw-button--dark-outline:active .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-dialog .nsw-button--dark-outline:disabled, .nsw-dialog .nsw-button--dark-outline.disabled {
  color: var(--nsw-brand-dark);
  background-color: transparent;
  border-color: var(--nsw-brand-dark);
}
.nsw-dialog .nsw-button--dark-outline .nsw-material-icons {
  color: var(--nsw-brand-dark);
}
.nsw-dialog .nsw-button--dark-outline-solid {
  color: var(--nsw-brand-dark);
  background-color: var(--nsw-white);
  border-color: var(--nsw-brand-dark);
}
.nsw-dialog .nsw-button--dark-outline-solid:visited {
  color: var(--nsw-brand-dark);
}
.nsw-dialog .nsw-button--dark-outline-solid:focus {
  outline-color: var(--nsw-focus);
}
.nsw-dialog .nsw-button--dark-outline-solid:hover {
  color: var(--nsw-text-light);
  background-color: var(--nsw-brand-dark);
  border-color: transparent;
}
.nsw-dialog .nsw-button--dark-outline-solid:hover .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-dialog .nsw-button--dark-outline-solid:active {
  color: var(--nsw-text-light);
  background-color: var(--nsw-brand-dark);
  border-color: transparent;
}
.nsw-dialog .nsw-button--dark-outline-solid:active .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-dialog .nsw-button--dark-outline-solid:disabled, .nsw-dialog .nsw-button--dark-outline-solid.disabled {
  color: var(--nsw-brand-dark);
  background-color: var(--nsw-white);
  border-color: var(--nsw-brand-dark);
}
.nsw-dialog .nsw-button--dark-outline-solid .nsw-material-icons {
  color: var(--nsw-brand-dark);
}
.nsw-dialog .nsw-button--light {
  color: var(--nsw-text-dark);
  background-color: var(--nsw-brand-light);
  border-color: transparent;
}
.nsw-dialog .nsw-button--light:visited {
  color: var(--nsw-text-dark);
}
.nsw-dialog .nsw-button--light:focus {
  outline-color: var(--nsw-focus);
}
.nsw-dialog .nsw-button--light:hover {
  color: var(--nsw-text-dark);
  background-color: var(--nsw-brand-light);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-black-rgb), 0.07), rgba(var(--nsw-black-rgb), 0.07));
}
.nsw-dialog .nsw-button--light:hover .nsw-material-icons {
  color: var(--nsw-text-dark);
}
.nsw-dialog .nsw-button--light:active {
  color: var(--nsw-text-dark);
  background-color: var(--nsw-brand-light);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-black-rgb), 0.035), rgba(var(--nsw-black-rgb), 0.035));
}
.nsw-dialog .nsw-button--light:active .nsw-material-icons {
  color: var(--nsw-text-dark);
}
.nsw-dialog .nsw-button--light:disabled, .nsw-dialog .nsw-button--light.disabled {
  color: var(--nsw-text-dark);
  background-color: var(--nsw-brand-light);
  border-color: transparent;
}
.nsw-dialog .nsw-button--light .nsw-material-icons {
  color: var(--nsw-text-dark);
}
.nsw-dialog .nsw-button--light-outline {
  color: var(--nsw-brand-light);
  background-color: transparent;
  border-color: var(--nsw-brand-light);
}
.nsw-dialog .nsw-button--light-outline:visited {
  color: var(--nsw-brand-light);
}
.nsw-dialog .nsw-button--light-outline:focus {
  outline-color: var(--nsw-focus);
}
.nsw-dialog .nsw-button--light-outline:hover {
  color: var(--nsw-text-dark);
  background-color: var(--nsw-brand-light);
  border-color: transparent;
}
.nsw-dialog .nsw-button--light-outline:hover .nsw-material-icons {
  color: var(--nsw-text-dark);
}
.nsw-dialog .nsw-button--light-outline:active {
  color: var(--nsw-text-dark);
  background-color: var(--nsw-brand-light);
  border-color: transparent;
}
.nsw-dialog .nsw-button--light-outline:active .nsw-material-icons {
  color: var(--nsw-text-dark);
}
.nsw-dialog .nsw-button--light-outline:disabled, .nsw-dialog .nsw-button--light-outline.disabled {
  color: var(--nsw-brand-light);
  background-color: transparent;
  border-color: var(--nsw-brand-light);
}
.nsw-dialog .nsw-button--light-outline .nsw-material-icons {
  color: var(--nsw-brand-light);
}
.nsw-dialog .nsw-button--white {
  color: var(--nsw-text-dark);
  background-color: var(--nsw-white);
  border-color: transparent;
}
.nsw-dialog .nsw-button--white:visited {
  color: var(--nsw-text-dark);
}
.nsw-dialog .nsw-button--white:focus {
  outline-color: var(--nsw-white);
}
.nsw-dialog .nsw-button--white:hover {
  color: var(--nsw-text-dark);
  background-color: var(--nsw-white);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-black-rgb), 0.07), rgba(var(--nsw-black-rgb), 0.07));
}
.nsw-dialog .nsw-button--white:hover .nsw-material-icons {
  color: var(--nsw-text-dark);
}
.nsw-dialog .nsw-button--white:active {
  color: var(--nsw-text-dark);
  background-color: var(--nsw-white);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-black-rgb), 0.035), rgba(var(--nsw-black-rgb), 0.035));
}
.nsw-dialog .nsw-button--white:active .nsw-material-icons {
  color: var(--nsw-text-dark);
}
.nsw-dialog .nsw-button--white:disabled, .nsw-dialog .nsw-button--white.disabled {
  color: var(--nsw-text-dark);
  background-color: var(--nsw-white);
  border-color: transparent;
}
.nsw-dialog .nsw-button--white .nsw-material-icons {
  color: var(--nsw-text-dark);
}
.nsw-dialog .nsw-button--white-outline {
  color: var(--nsw-white);
  background-color: transparent;
  border-color: var(--nsw-white);
}
.nsw-dialog .nsw-button--white-outline:visited {
  color: var(--nsw-white);
}
.nsw-dialog .nsw-button--white-outline:focus {
  outline-color: var(--nsw-white);
}
.nsw-dialog .nsw-button--white-outline:hover {
  color: var(--nsw-text-dark);
  background-color: var(--nsw-white);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-black-rgb), 0.07), rgba(var(--nsw-black-rgb), 0.07));
}
.nsw-dialog .nsw-button--white-outline:hover .nsw-material-icons {
  color: var(--nsw-text-dark);
}
.nsw-dialog .nsw-button--white-outline:active {
  color: var(--nsw-text-dark);
  background-color: var(--nsw-white);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-black-rgb), 0.035), rgba(var(--nsw-black-rgb), 0.035));
}
.nsw-dialog .nsw-button--white-outline:active .nsw-material-icons {
  color: var(--nsw-text-dark);
}
.nsw-dialog .nsw-button--white-outline:disabled, .nsw-dialog .nsw-button--white-outline.disabled {
  color: var(--nsw-white);
  background-color: transparent;
  border-color: var(--nsw-white);
}
.nsw-dialog .nsw-button--white-outline .nsw-material-icons {
  color: var(--nsw-white);
}
.nsw-dialog .nsw-button--danger {
  color: var(--nsw-text-light);
  background-color: var(--nsw-status-error);
  border-color: transparent;
}
.nsw-dialog .nsw-button--danger:visited {
  color: var(--nsw-text-light);
}
.nsw-dialog .nsw-button--danger:focus {
  outline-color: var(--nsw-focus);
}
.nsw-dialog .nsw-button--danger:hover {
  color: var(--nsw-text-light);
  background-color: var(--nsw-status-error);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-white-rgb), 0.15), rgba(var(--nsw-white-rgb), 0.15));
}
.nsw-dialog .nsw-button--danger:hover .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-dialog .nsw-button--danger:active {
  color: var(--nsw-text-light);
  background-color: var(--nsw-status-error);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-white-rgb), 0.075), rgba(var(--nsw-white-rgb), 0.075));
}
.nsw-dialog .nsw-button--danger:active .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-dialog .nsw-button--danger:disabled, .nsw-dialog .nsw-button--danger.disabled {
  color: var(--nsw-text-light);
  background-color: var(--nsw-status-error);
  border-color: transparent;
}
.nsw-dialog .nsw-button--danger .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-dialog .nsw-button--info {
  color: var(--nsw-text-light);
  background-color: var(--nsw-status-info);
  border-color: transparent;
}
.nsw-dialog .nsw-button--info:visited {
  color: var(--nsw-text-light);
}
.nsw-dialog .nsw-button--info:focus {
  outline-color: var(--nsw-focus);
}
.nsw-dialog .nsw-button--info:hover {
  color: var(--nsw-text-light);
  background-color: var(--nsw-status-info);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-white-rgb), 0.15), rgba(var(--nsw-white-rgb), 0.15));
}
.nsw-dialog .nsw-button--info:hover .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-dialog .nsw-button--info:active {
  color: var(--nsw-text-light);
  background-color: var(--nsw-status-info);
  border-color: transparent;
  background-image: linear-gradient(rgba(var(--nsw-white-rgb), 0.075), rgba(var(--nsw-white-rgb), 0.075));
}
.nsw-dialog .nsw-button--info:active .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-dialog .nsw-button--info:disabled, .nsw-dialog .nsw-button--info.disabled {
  color: var(--nsw-text-light);
  background-color: var(--nsw-status-info);
  border-color: transparent;
}
.nsw-dialog .nsw-button--info .nsw-material-icons {
  color: var(--nsw-text-light);
}

.nsw-callout {
  margin-top: 1.5rem;
  padding: 1rem;
  border-left: solid 4px var(--nsw-brand-dark);
}
.nsw-callout:first-child {
  margin-top: 0;
}
@media (min-width: 48rem) {
  .nsw-callout {
    margin-top: 2rem;
  }
}
.nsw-section--invert .nsw-callout {
  border-color: var(--nsw-brand-light);
}

.nsw-card {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  color: var(--nsw-text-dark);
  border-radius: var(--nsw-border-radius);
}
.nsw-card:hover {
  color: var(--nsw-text-light);
}
.nsw-card:hover .nsw-card__content {
  background-color: var(--nsw-brand-dark);
  border-color: transparent;
}
.nsw-card:hover .nsw-card__content::after {
  background-color: transparent;
}
.nsw-card:hover .nsw-card__image::after {
  background-color: transparent;
}
.nsw-card:hover a {
  color: var(--nsw-text-light);
}
.nsw-card:hover .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-section--invert .nsw-card:hover {
  color: var(--nsw-text-dark);
}
.nsw-section--invert .nsw-card:hover .nsw-card__content {
  background-color: var(--nsw-brand-light);
}
.nsw-section--invert .nsw-card:hover .nsw-card__content::after {
  background-color: transparent;
}
.nsw-card:focus-within {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-card:focus-within a {
  text-decoration: none;
}
.nsw-section--invert .nsw-card:focus-within {
  outline: solid 3px var(--nsw-focus-light);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-card__content {
  font-size: var(--nsw-font-size-sm-mobile);
  line-height: var(--nsw-line-height-sm-mobile);
  padding: 1rem 1rem 3.875rem 1rem;
  background-color: var(--nsw-white);
  border-radius: var(--nsw-border-radius);
  border: 1px solid var(--nsw-grey-03);
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: background 0.15s ease-in-out, border 0.15s ease-in-out;
}
@media (min-width: 62rem) {
  .nsw-card__content {
    font-size: var(--nsw-font-size-sm-desktop);
    line-height: var(--nsw-line-height-sm-desktop);
  }
}
@media (min-width: 62rem) {
  .nsw-card__content {
    padding: 2rem 2rem 5.875rem 2rem;
  }
}
.nsw-section--invert .nsw-card__content {
  border-color: transparent;
}
.nsw-card__title {
  font-size: var(--nsw-font-size-md-mobile);
  line-height: var(--nsw-line-height-md-mobile);
  font-weight: var(--nsw-font-bold);
}
@media (min-width: 62rem) {
  .nsw-card__title {
    font-size: var(--nsw-font-size-md-desktop);
    line-height: var(--nsw-line-height-md-desktop);
  }
}
.nsw-card__title a {
  text-decoration: none;
  color: var(--nsw-text-dark);
  transition: color 0.15s ease-in-out;
}
.nsw-card__title a::after {
  z-index: 100;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.nsw-card__title a:focus {
  outline: none;
}
.nsw-card__title a:hover {
  background-color: transparent;
  outline: none;
}
.nsw-section--invert .nsw-card__title a {
  color: var(--nsw-text-dark);
}
.nsw-card__copy {
  margin-top: 1rem;
  transition: color 0.15s ease-in-out;
}
.nsw-card__tag, .nsw-card__date {
  font-size: var(--nsw-font-size-xs-mobile);
  line-height: var(--nsw-line-height-xs-mobile);
  transition: color 0.15s ease-in-out;
  margin-bottom: 1rem;
}
@media (min-width: 62rem) {
  .nsw-card__tag, .nsw-card__date {
    font-size: var(--nsw-font-size-xs-desktop);
    line-height: var(--nsw-line-height-xs-desktop);
  }
}
.nsw-card__tag {
  font-weight: var(--nsw-font-bold);
}
.nsw-card__tag + .nsw-card__date {
  margin-top: -0.5rem;
}
.nsw-card .nsw-material-icons {
  font-size: 1.875rem;
  color: var(--nsw-brand-dark);
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  transition: color 0.15s ease-in-out;
}
@media (min-width: 62rem) {
  .nsw-card .nsw-material-icons {
    bottom: 2rem;
    left: 2rem;
  }
}
.nsw-section--invert .nsw-card .nsw-material-icons {
  color: var(--nsw-brand-dark);
}
.nsw-card__image {
  min-height: 12.5rem;
  height: 12.5rem;
  position: relative;
}
.nsw-card__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.nsw-card__image + .nsw-card__content {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top-width: 0;
}
.nsw-card__image + .nsw-card__content::after {
  display: none;
}
.nsw-section--invert .nsw-card--light:hover .nsw-card__content {
  background-color: var(--nsw-white);
}
.nsw-card--light .nsw-card__content {
  background-color: var(--nsw-brand-light);
  border-color: transparent;
}
.nsw-card--light .nsw-card__content::before, .nsw-card--light .nsw-card__content::after {
  display: none;
}
.nsw-card--light .nsw-card__image::after {
  display: none;
}
.nsw-card--dark {
  color: var(--nsw-text-light);
}
.nsw-card--dark:hover {
  color: var(--nsw-text-dark);
}
.nsw-card--dark:hover .nsw-card__content {
  background-color: var(--nsw-white);
  border-color: var(--nsw-grey-03);
}
.nsw-card--dark:hover .nsw-card__title a {
  color: var(--nsw-text-dark);
}
.nsw-card--dark:hover .nsw-material-icons {
  color: var(--nsw-brand-dark);
}
.nsw-section--invert .nsw-card--dark:hover .nsw-card__content {
  background-color: var(--nsw-white);
}
.nsw-section--invert .nsw-card--dark:hover .nsw-material-icons {
  color: var(--nsw-brand-dark);
}
.nsw-card--dark .nsw-card__content {
  background-color: var(--nsw-brand-dark);
  border-color: transparent;
}
.nsw-card--dark .nsw-card__content::before, .nsw-card--dark .nsw-card__content::after {
  display: none;
}
.nsw-section--invert .nsw-card--dark .nsw-card__content {
  border-color: var(--nsw-white);
}
.nsw-card--dark .nsw-card__image::after {
  display: none;
}
.nsw-card--dark .nsw-card__title a {
  color: var(--nsw-text-light);
}
.nsw-card--dark .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-section--invert .nsw-card--dark .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-card--headline .nsw-card__title {
  font-size: var(--nsw-font-size-lg-mobile);
  line-height: var(--nsw-line-height-lg-mobile);
}
@media (min-width: 62rem) {
  .nsw-card--headline .nsw-card__title {
    font-size: var(--nsw-font-size-lg-desktop);
    line-height: var(--nsw-line-height-lg-desktop);
  }
}
.nsw-card--highlight .nsw-card__content::before {
  content: "";
  height: 4px;
}
.nsw-card--highlight .nsw-card__content::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background-color: var(--nsw-brand-accent);
  transition: background 0.15s ease-in-out;
}
.nsw-card--highlight .nsw-card__image::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  right: 0;
  height: 4px;
  background-color: var(--nsw-brand-accent);
  transition: background 0.15s ease-in-out;
}
@media (min-width: 48rem) {
  .nsw-card--horizontal {
    flex-direction: row;
    align-items: stretch;
  }
  .nsw-card--horizontal .nsw-card__content {
    flex: 1 1 50%;
    height: auto;
  }
  .nsw-card--horizontal .nsw-card__content > .nsw-material-icons {
    left: calc(50% + 1rem);
  }
  .nsw-card--horizontal .nsw-card__image {
    flex: 1 1 50%;
    position: relative;
    height: auto;
  }
  .nsw-card--horizontal .nsw-card__image + .nsw-card__content {
    border-top-right-radius: var(--nsw-border-radius);
    border-bottom-left-radius: 0;
    border-top-width: 1px;
    border-left-width: 0;
  }
  .nsw-card--horizontal.nsw-card--highlight .nsw-card__image::after {
    top: 0;
    bottom: 0;
    left: auto;
    right: -4px;
    width: 4px;
    height: auto;
  }
}
@media (min-width: 62rem) {
  .nsw-card--horizontal .nsw-card__content > .nsw-material-icons {
    left: calc(50% + 2rem);
  }
}

/* stylelint-disable max-nesting-depth */
.nsw-carousel {
  --carousel-item-auto-size: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem 3rem;
}
.nsw-carousel-container {
  margin: 0 -0.5rem;
  overflow: hidden;
}
.nsw-carousel * {
  box-sizing: border-box;
}
.nsw-carousel *::after, .nsw-carousel *::before {
  box-sizing: border-box;
}
.nsw-carousel ol,
.nsw-carousel ul,
.nsw-carousel menu {
  list-style: none;
  padding: 0;
}
.nsw-carousel button,
.nsw-carousel input,
.nsw-carousel textarea,
.nsw-carousel select {
  color: inherit;
  line-height: inherit;
  appearance: none;
}
.nsw-carousel button {
  padding: 0;
}
.nsw-carousel img,
.nsw-carousel video,
.nsw-carousel svg {
  display: block;
  max-width: 100%;
}
.nsw-carousel .nsw-icon {
  height: var(--size, 1em);
  width: var(--size, 1em);
  display: inline-block;
  color: var(--nsw-brand-dark);
  fill: currentColor;
  line-height: 1;
  flex-shrink: 0;
  max-width: initial;
}
.nsw-carousel > nav, .nsw-carousel__counter > nav {
  order: 1;
  margin-bottom: 0.5rem;
  margin-right: 0.25rem;
}
@media (min-width: 48rem) {
  .nsw-carousel > nav, .nsw-carousel__counter > nav {
    margin-bottom: 0.75rem;
    margin-right: 0;
  }
}
.nsw-carousel > nav ul, .nsw-carousel__counter > nav ul {
  display: flex;
  justify-content: flex-end;
  gap: 0.25rem;
}
@media (min-width: 48rem) {
  .nsw-carousel > nav ul, .nsw-carousel__counter > nav ul {
    gap: 0.375rem;
  }
}
.nsw-carousel__wrapper {
  order: 2;
  width: calc(100% - 6rem);
  margin: 0 auto;
}
@media (min-width: 48rem) {
  .nsw-carousel__wrapper {
    width: 100%;
  }
}
.nsw-carousel__counter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 48rem) {
  .nsw-carousel__counter {
    width: 100%;
  }
}
.nsw-carousel__list {
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.nsw-carousel__list--animating {
  transition-property: transform;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.nsw-carousel__item {
  flex-shrink: 0;
  width: var(--carousel-item-auto-size);
  margin-right: 1.5rem;
  margin-bottom: 0.5rem;
  opacity: 0;
  margin-bottom: 0;
}
@media (min-width: 48rem) {
  .nsw-carousel__item {
    margin-right: 2rem;
    margin-bottom: 0.75rem;
  }
}
.nsw-carousel.nsw-carousel--loaded .nsw-carousel__item {
  opacity: 1;
}
.nsw-carousel[data-drag=on] .nsw-carousel__item {
  user-select: none;
}
.nsw-carousel[data-drag=on] .nsw-carousel__item img {
  pointer-events: none;
}
.nsw-carousel:not(.nsw-carousel--is-dragging) .nsw-carousel__list:not(.nsw-carousel__list--animating) .nsw-carousel__item[tabindex="-1"] > * {
  visibility: visible;
}
@media (min-width: 48rem) {
  .nsw-carousel:not(.nsw-carousel--is-dragging) .nsw-carousel__list:not(.nsw-carousel__list--animating) .nsw-carousel__item[tabindex="-1"] > * {
    visibility: hidden;
  }
}
.nsw-carousel__control {
  --size: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  background-color: var(--nsw-white);
  border-radius: 50%;
  z-index: 1;
  transition: 0.2s;
  border: 2px solid var(--nsw-brand-dark);
  cursor: pointer;
}
.nsw-carousel__control:hover {
  background-color: var(--nsw-hover);
}
.nsw-carousel__control:active {
  transform: translateY(1px);
}
.nsw-carousel__control:focus {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
  outline-offset: 1px;
}
@media (min-width: 48rem) {
  .nsw-carousel__control:focus {
    outline: solid 3px var(--nsw-focus);
    outline-offset: 2px;
  }
}
.nsw-carousel__control[disabled] {
  pointer-events: none;
  opacity: 0.5;
  box-shadow: none;
  border: 2px solid rgba(var(--nsw-palette-blue-01-rgb), 0.2);
}
.nsw-carousel__control .nsw-icon {
  --size: 20px;
  display: block;
  margin: auto;
}
.nsw-section--invert .nsw-carousel__control {
  border: 2px solid var(--nsw-white);
}
.nsw-section--invert .nsw-carousel__control:hover {
  background-color: var(--nsw-hover-light);
}
.nsw-section--invert .nsw-carousel__control:hover .nsw-icon {
  color: var(--nsw-white);
}
.nsw-section--invert .nsw-carousel__control:focus {
  outline: solid 3px var(--nsw-focus-light);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-section--invert .nsw-carousel__control .nsw-icon:hover {
  color: var(--nsw-white);
}
.nsw-carousel__navigation {
  order: 3;
  position: absolute;
  bottom: 0.5em;
  left: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 2;
  transform: translateX(-50%);
}
@media (min-width: 48rem) {
  .nsw-carousel__navigation {
    bottom: 0.8em;
  }
}
.nsw-carousel__navigation li {
  margin-top: 0;
  line-height: 0;
}
.nsw-carousel__navigation--pagination {
  bottom: 0.3em;
}
@media (min-width: 48rem) {
  .nsw-carousel__navigation--pagination {
    bottom: 0.5em;
  }
}
.nsw-carousel__navigation--pagination .nsw-carousel__nav-item button {
  background-color: transparent;
  width: 1.6rem;
  height: 1.6rem;
  color: var(--nsw-brand-dark);
  font-size: 12px;
  border-radius: var(--nsw-border-radius);
  text-align: center;
  margin: 0 0.35em;
}
.nsw-carousel__navigation--pagination .nsw-carousel__nav-item button:focus {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
  outline-offset: 2px;
}
.nsw-section--invert .nsw-carousel__navigation--pagination .nsw-carousel__nav-item button {
  background-color: var(--nsw-white);
  border: 1px solid var(--nsw-white);
}
.nsw-section--invert .nsw-carousel__navigation--pagination .nsw-carousel__nav-item button:focus {
  outline: solid 3px var(--nsw-focus-light);
  outline-offset: 0;
  outline-offset: 3px;
  outline-offset: 2px;
}
.nsw-carousel__navigation--pagination .nsw-carousel__nav-item--selected button {
  background-color: var(--nsw-brand-dark);
  color: var(--nsw-white);
}
.nsw-section--invert .nsw-carousel__navigation--pagination .nsw-carousel__nav-item--selected button {
  background-color: transparent;
  border: 2px solid var(--nsw-white);
}
.nsw-carousel__nav-item button {
  background-color: transparent;
  width: 1.6em;
  height: 1.6em;
  padding: 4px;
  border-radius: 50%;
  border: 2px solid var(--nsw-brand-dark);
  transition: all 300ms ease-in-out;
  cursor: pointer;
  line-height: 0;
  margin: 0 0.25em;
}
.nsw-carousel__nav-item button:focus {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
  outline-offset: 2px;
}
.nsw-section--invert .nsw-carousel__nav-item button {
  border: 2px solid var(--nsw-white);
}
.nsw-section--invert .nsw-carousel__nav-item button:focus {
  outline: solid 3px var(--nsw-focus-light);
  outline-offset: 0;
  outline-offset: 3px;
  outline-offset: 2px;
}
.nsw-carousel__nav-item--selected button {
  background-color: var(--nsw-brand-dark);
}
.nsw-section--invert .nsw-carousel__nav-item--selected button {
  background-color: var(--nsw-white);
}
.nsw-carousel.nsw-carousel--hide-controls .nsw-carousel__navigation,
.nsw-carousel.nsw-carousel--hide-controls .nsw-carousel__control {
  display: none;
}

.nsw-content-block {
  --nsw-icon-colour-dark: var(--nsw-text-dark);
  --nsw-icon-colour-accent: var(--nsw-brand-supplementary);
}
.nsw-content-block__title {
  font-size: var(--nsw-font-size-lg-mobile);
  line-height: var(--nsw-line-height-lg-mobile);
  font-weight: var(--nsw-font-bold);
}
@media (min-width: 62rem) {
  .nsw-content-block__title {
    font-size: var(--nsw-font-size-lg-desktop);
    line-height: var(--nsw-line-height-lg-desktop);
  }
}
.nsw-content-block__list {
  padding: 0;
  list-style-type: none;
}
.nsw-content-block__list li {
  margin: 0;
  padding: 0.5rem 0 0.5rem 0.875rem;
  position: relative;
}
.nsw-content-block__list li::before {
  content: "";
  position: absolute;
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 100%;
  background-color: var(--nsw-text-dark);
  top: 1.125rem;
  left: 0;
}
.nsw-section--invert .nsw-content-block__list li::before {
  background-color: var(--nsw-text-light);
}
.nsw-content-block__link {
  margin-top: 1rem;
}
.nsw-content-block__image {
  max-height: 12.5rem;
  margin-bottom: 1.5rem;
}
.nsw-content-block__image img {
  width: 100%;
  height: 12.5rem;
  object-fit: cover;
}
.nsw-content-block__icon {
  max-width: 4rem;
  max-height: 4rem;
}
.nsw-content-block__icon svg {
  max-width: 4rem;
  max-height: 4rem;
}
.nsw-section--invert .nsw-content-block__icon svg {
  --nsw-icon-colour-dark: var(--nsw-text-light);
}

.nsw-cookie-banner {
  padding: 1.5rem 0;
  background-color: var(--nsw-brand-light);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}
.nsw-cookie-banner .nsw-icon-button {
  position: absolute;
  top: -0.5rem;
  right: 0.25rem;
}
.nsw-cookie-banner .nsw-icon-button:hover {
  background-color: var(--nsw-hover);
  background-color: var(--nsw-hover-light);
}
.nsw-cookie-banner .nsw-icon-button:focus {
  outline: solid 3px var(--nsw-text-light);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-cookie-banner__wrapper {
  max-width: var(--nsw-container-width);
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.nsw-cookie-banner__title {
  font-size: var(--nsw-font-size-md-mobile);
  line-height: var(--nsw-line-height-md-mobile);
  font-weight: var(--nsw-font-bold);
}
@media (min-width: 62rem) {
  .nsw-cookie-banner__title {
    font-size: var(--nsw-font-size-md-desktop);
    line-height: var(--nsw-line-height-md-desktop);
  }
}
.nsw-cookie-banner__content {
  margin: 1rem 2.25rem 1.5rem 0;
}
.nsw-cookie-banner__buttons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
  flex-direction: column;
}
@media (min-width: 48rem) {
  .nsw-cookie-banner__buttons-container {
    flex-direction: row;
  }
}
.nsw-cookie-banner__cta-group {
  display: flex;
  gap: 1rem;
}
.nsw-cookie-banner__cta-group button {
  flex: 1 auto;
}
@media (min-width: 48rem) {
  .nsw-cookie-banner__cta-group button {
    flex: unset;
  }
}
.nsw-cookie-banner--light {
  background-color: var(--nsw-status-info-bg);
  color: var(--nsw-text-dark);
}
.nsw-cookie-banner--light .nsw-cookie-banner__content a {
  color: var(--nsw-link);
}
.nsw-cookie-banner--light .nsw-cookie-banner__content a:visited {
  color: var(--nsw-visited);
}
.nsw-cookie-banner--light .nsw-cookie-banner__content a:focus {
  outline-color: var(--nsw-focus);
}
.nsw-cookie-banner--light .nsw-cookie-banner__content a:hover {
  background-color: var(--nsw-hover);
  outline-color: var(--nsw-hover);
}
.nsw-cookie-banner--light .nsw-cookie-banner__content a:active {
  background-color: var(--nsw-active);
  outline-color: var(--nsw-active);
}
.nsw-cookie-banner--light .nsw-icon-button {
  color: var(--nsw-brand-dark);
}
.nsw-cookie-banner--light .nsw-icon-button:hover {
  background-color: var(--nsw-hover);
}
.nsw-cookie-banner--light .nsw-icon-button:focus {
  outline-color: var(--nsw-focus);
}

.nsw-cookie-dialog {
  z-index: 1100;
}
.nsw-cookie-dialog .nsw-dialog__container {
  overflow-y: hidden;
}
.nsw-cookie-dialog .nsw-dialog__top {
  z-index: 1101;
  position: sticky;
  top: 0;
  background: var(--nsw-white);
  border-radius: var(--nsw-border-radius);
}
.nsw-cookie-dialog .nsw-dialog__content {
  padding-top: 1rem;
  padding-bottom: 4.5rem;
}
@media (min-width: 48rem) {
  .nsw-cookie-dialog .nsw-dialog__content {
    padding-top: 1rem;
    padding-bottom: 0.25rem;
  }
}
.nsw-cookie-dialog__bottom {
  border-top: 1px solid var(--nsw-grey-04);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
}
@media (min-width: 48rem) {
  .nsw-cookie-dialog__bottom {
    flex-direction: row;
    justify-content: space-between;
  }
}
@media (min-width: 62rem) {
  .nsw-cookie-dialog__bottom {
    padding: 2rem;
  }
}
@media (min-width: 48rem) {
  .nsw-cookie-dialog__bottom button {
    margin-bottom: 0;
  }
}
.nsw-cookie-dialog__bottom button:last-child {
  margin-bottom: 0;
}
@media (min-width: 48rem) {
  .nsw-cookie-dialog__bottom button:last-child {
    margin-left: 0;
  }
}
.nsw-cookie-dialog__cta-group {
  display: flex;
  gap: 1rem;
}
@media (min-width: 48rem) {
  .nsw-cookie-dialog__cta-group {
    flex-direction: row;
  }
}
.nsw-cookie-dialog__cta-group button {
  flex: 1;
}
@media (min-width: 48rem) {
  .nsw-cookie-dialog__cta-group button {
    flex: unset;
  }
}
.nsw-cookie-dialog__content-wrapper {
  padding: 0.5rem;
  overflow: scroll;
  max-height: 280px;
}
.nsw-cookie-dialog__content-wrapper::-webkit-scrollbar {
  width: 8px;
}
.nsw-cookie-dialog__content-wrapper::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--nsw-grey-03);
  box-shadow: 0 0 1px var(--nsw-grey-03);
}
.nsw-cookie-dialog .nsw-tabs__content {
  -webkit-overflow-scrolling: auto;
  padding: 0.75rem 0 0.5rem 0.5rem;
  border-bottom: 0;
}
@media (min-width: 48rem) {
  .nsw-cookie-dialog .nsw-tabs__content {
    max-height: 320px;
  }
}
.nsw-cookie-dialog .nsw-tabs__content:focus-visible {
  outline: 2px solid var(--nsw-brand-dark);
}
.nsw-cookie-dialog .nsw-tabs__content:focus {
  outline: none;
}
.nsw-cookie-dialog__list {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  gap: 0.5rem;
}
.nsw-cookie-dialog__list-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.nsw-cookie-dialog__list-item label {
  font-size: var(--nsw-font-size-md-mobile);
  line-height: var(--nsw-line-height-md-mobile);
  font-weight: var(--nsw-font-bold);
  cursor: pointer;
}
@media (min-width: 62rem) {
  .nsw-cookie-dialog__list-item label {
    font-size: var(--nsw-font-size-md-desktop);
    line-height: var(--nsw-line-height-md-desktop);
  }
}
.nsw-cookie-dialog__list-item:not(:nth-child(1)) {
  border-top: 1px solid var(--nsw-grey-04);
  padding-top: 1.5rem;
}
.nsw-cookie-dialog__cookie-details {
  margin-left: 48px;
}

.nsw-file-upload {
  margin-top: 0.5rem;
  display: block;
}
.nsw-file-upload__label {
  margin: 0;
}
.nsw-file-upload__input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  z-index: -1;
}
.nsw-file-upload__input:disabled + .nsw-file-upload__label {
  opacity: 0.4;
  cursor: not-allowed;
}
.nsw-file-upload__input:focus + .nsw-file-upload__label {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-file-upload__list {
  display: none;
  margin: 0;
  padding: 0;
  border: 0;
}
.nsw-file-upload__list.active {
  display: block;
}
.nsw-file-upload__item {
  display: flex;
  max-width: 31.25rem;
  align-items: center;
  justify-content: space-between;
  background-color: var(--nsw-off-white);
  border-radius: var(--nsw-border-radius);
  padding: 0.5rem;
}
.nsw-file-upload__item:not(:last-child) {
  margin-bottom: 0.5rem;
}
.nsw-section--invert .nsw-file-upload__item {
  background-color: var(--nsw-white);
  color: var(--nsw-section-bg);
}
.nsw-file-upload__item .nsw-icon-button {
  color: var(--nsw-link);
  cursor: pointer;
}
.nsw-file-upload__item .nsw-icon-button:visited {
  color: var(--nsw-visited);
}
.nsw-file-upload__item .nsw-icon-button:focus {
  outline-color: var(--nsw-focus);
}
.nsw-file-upload__item .nsw-icon-button:hover {
  background-color: var(--nsw-hover);
  outline-color: var(--nsw-hover);
}
.nsw-file-upload__item .nsw-icon-button:active {
  background-color: var(--nsw-active);
  outline-color: var(--nsw-active);
}
.nsw-section--invert .nsw-file-upload__item .nsw-icon-button:hover {
  background-color: var(--nsw-hover);
}
.nsw-file-upload__item-filename {
  margin-left: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nsw-layout__sidebar .nsw-filters {
  margin: -0.5rem 0 -1rem 0;
}
@media (min-width: 62rem) {
  .nsw-layout__sidebar .nsw-filters {
    margin: 0;
  }
}
.nsw-filters__title {
  font-size: var(--nsw-font-size-lg-mobile);
  line-height: var(--nsw-line-height-lg-mobile);
  font-weight: var(--nsw-font-bold);
  padding: 1.5rem 0;
}
@media (min-width: 62rem) {
  .nsw-filters__title {
    font-size: var(--nsw-font-size-lg-desktop);
    line-height: var(--nsw-line-height-lg-desktop);
  }
}
@media (min-width: 62rem) {
  .nsw-filters__title {
    font-size: var(--nsw-font-size-md-mobile);
    line-height: var(--nsw-line-height-md-mobile);
    margin-top: 1.5rem;
  }
}
@media (min-width: 62rem) and (min-width: 62rem) {
  .nsw-filters__title {
    font-size: var(--nsw-font-size-md-desktop);
    line-height: var(--nsw-line-height-md-desktop);
  }
}
.nsw-filters--down .nsw-filters__title {
  display: none;
}
@media (min-width: 62rem) {
  .nsw-filters--down .nsw-filters__title {
    display: block;
  }
}
@media (min-width: 62rem) {
  .nsw-filters__controls {
    display: none;
  }
}
.nsw-filters__controls button {
  font-size: var(--nsw-font-size-md-mobile);
  line-height: var(--nsw-line-height-md-mobile);
  font-weight: var(--nsw-font-bold);
  background-color: var(--nsw-off-white);
  width: 100%;
  border: 0;
  padding: 1rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  text-align: left;
  cursor: pointer;
}
@media (min-width: 62rem) {
  .nsw-filters__controls button {
    font-size: var(--nsw-font-size-md-desktop);
    line-height: var(--nsw-line-height-md-desktop);
  }
}
.nsw-section--invert .nsw-filters__controls button {
  color: var(--nsw-text-dark);
  background-color: var(--nsw-white);
}
.nsw-filters__controls button span {
  text-decoration: none;
  padding-left: 0.5rem;
}
.nsw-filters__controls button:hover:enabled {
  background-color: var(--nsw-brand-dark);
  color: var(--nsw-text-light);
}
.nsw-section--invert .nsw-filters__controls button:hover:enabled {
  background-color: var(--nsw-brand-light);
  color: var(--nsw-brand-dark);
}
.nsw-filters__controls button:hover:enabled .nsw-material-icons {
  color: var(--nsw-text-light);
  outline: 2px solid var(--nsw-white);
}
.nsw-filters__controls button:hover:enabled .nsw-material-icons:last-child {
  color: var(--nsw-text-light);
  outline: 0;
}
.nsw-section--invert .nsw-filters__controls button:hover:enabled .nsw-material-icons {
  background-color: var(--nsw-brand-light);
}
.nsw-section--invert.nsw-section--grey-01 .nsw-filters__controls button:hover:enabled .nsw-material-icons {
  color: var(--nsw-grey-01);
  outline: 2px solid var(--nsw-grey-01);
}
.nsw-section--invert.nsw-section--brand-dark .nsw-filters__controls button:hover:enabled .nsw-material-icons {
  color: var(--nsw-brand-dark);
  outline: 2px solid var(--nsw-brand-dark);
}
.nsw-section--invert .nsw-filters__controls button:hover:enabled .nsw-material-icons:last-child {
  background-color: var(--nsw-brand-light);
  outline: 0;
}
.nsw-section--invert.nsw-section--grey-01 .nsw-filters__controls button:hover:enabled .nsw-material-icons:last-child {
  color: var(--nsw-grey-01);
}
.nsw-section--invert.nsw-section--brand-dark .nsw-filters__controls button:hover:enabled .nsw-material-icons:last-child {
  color: var(--nsw-brand-dark);
}
.nsw-filters__controls button:focus {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
}
.nsw-section--invert .nsw-filters__controls button:focus {
  outline: solid 3px var(--nsw-focus-light);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-filters__controls .nsw-material-icons {
  font-size: 1.875rem;
  border-radius: var(--nsw-border-radius);
  padding: 0;
  text-decoration: none;
  outline: 2px solid var(--nsw-brand-dark);
  transition: transform 0.15s ease-in-out, color 0.15s ease-in-out;
}
.nsw-filters__controls .nsw-material-icons:last-child {
  border: 0;
  margin-left: auto;
  outline: 0;
}
.nsw-filters__controls.active .nsw-material-icons {
  background-color: var(--nsw-brand-dark);
  color: var(--nsw-text-light);
}
.nsw-filters__controls.active .nsw-material-icons:last-child {
  background-color: transparent;
  color: var(--nsw-brand-dark);
  outline: 0;
}
.active .nsw-filters__controls button {
  background-color: var(--nsw-brand-dark);
  color: var(--nsw-text-light);
}
.nsw-section--invert .active .nsw-filters__controls button {
  background-color: var(--nsw-brand-light);
  color: var(--nsw-brand-dark);
}
.active .nsw-filters__controls .nsw-material-icons {
  color: var(--nsw-text-light);
  outline: 2px solid var(--nsw-white);
  transition: transform 0.15s ease-in-out, color 0.15s ease-in-out;
}
.nsw-section--invert .active .nsw-filters__controls .nsw-material-icons {
  background-color: var(--nsw-brand-light);
  color: var(--nsw-brand-dark);
  outline: 2px solid var(--nsw-brand-dark);
}
.active .nsw-filters__controls .nsw-material-icons:last-child {
  transform: rotate(180deg);
}
.nsw-section--invert .active .nsw-filters__controls .nsw-material-icons:last-child {
  background-color: var(--nsw-brand-light);
  color: var(--nsw-brand-dark);
  outline: 0;
}
.nsw-filters--fixed .nsw-filters__back {
  z-index: 300;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--nsw-white);
  padding: 1rem 0.5rem;
  border-bottom: 1px solid var(--nsw-grey-04);
}
@media (min-width: 62rem) {
  .nsw-filters--fixed .nsw-filters__back {
    display: none;
  }
}
.nsw-section--invert.nsw-section--grey-01 .nsw-filters--fixed .nsw-filters__back {
  background-color: var(--nsw-grey-01);
}
.nsw-section--invert.nsw-section--brand-dark .nsw-filters--fixed .nsw-filters__back {
  background-color: var(--nsw-brand-dark);
}
.nsw-filters--right .nsw-filters__back {
  z-index: 300;
  position: absolute;
  top: 1rem;
  padding-right: 1rem;
  background-color: var(--nsw-white);
  transition: 0.15s ease-in-out;
  right: -100vw;
}
@media (min-width: 62rem) {
  .nsw-filters--right .nsw-filters__back {
    display: none;
  }
}
.nsw-filters--right .nsw-filters__back .nsw-material-icons {
  font-size: 1.875rem;
}
.nsw-section--invert.nsw-section--grey-01 .nsw-filters--right .nsw-filters__back {
  background-color: var(--nsw-grey-01);
}
.nsw-section--invert.nsw-section--brand-dark .nsw-filters--right .nsw-filters__back {
  background-color: var(--nsw-brand-dark);
}
.nsw-filters--right.active .nsw-filters__back {
  right: 0;
}
.nsw-filters--fixed .nsw-filters__wrapper, .nsw-filters--right .nsw-filters__wrapper {
  z-index: 200;
  position: fixed;
  overflow-y: auto;
  background-color: var(--nsw-white);
  scroll-padding-bottom: 7.625rem;
}
.nsw-section--invert.nsw-section--grey-01 .nsw-filters--fixed .nsw-filters__wrapper, .nsw-section--invert.nsw-section--grey-01 .nsw-filters--right .nsw-filters__wrapper {
  background-color: var(--nsw-grey-01);
}
.nsw-section--invert.nsw-section--brand-dark .nsw-filters--fixed .nsw-filters__wrapper, .nsw-section--invert.nsw-section--brand-dark .nsw-filters--right .nsw-filters__wrapper {
  background-color: var(--nsw-brand-dark);
}
@media (min-width: 62rem) {
  .nsw-filters--fixed .nsw-filters__wrapper, .nsw-filters--right .nsw-filters__wrapper {
    position: static;
    background-color: transparent;
    overflow-y: visible;
  }
  .nsw-section--invert .nsw-filters--fixed .nsw-filters__wrapper, .nsw-section--invert .nsw-filters--right .nsw-filters__wrapper {
    background-color: transparent;
  }
}
.nsw-filters--fixed .nsw-filters__wrapper {
  display: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 4.5625rem 1rem 6.0625rem 1rem;
}
@media (min-width: 62rem) {
  .nsw-filters--fixed .nsw-filters__wrapper {
    display: block;
    padding: 0;
  }
}
.nsw-filters--right .nsw-filters__wrapper {
  top: 0;
  bottom: 0;
  width: 100%;
  transition: 0.15s ease-in-out;
  padding: 0 1rem 6.0625rem 1rem;
  right: -100vw;
  visibility: hidden;
}
@media (min-width: 62rem) {
  .nsw-filters--right .nsw-filters__wrapper {
    display: block;
    visibility: visible;
    padding: 0;
  }
}
.nsw-filters--right.active .nsw-filters__wrapper {
  right: 0;
  visibility: visible;
}
.nsw-filters--fixed.nsw-filters--instant .nsw-filters__wrapper {
  padding-bottom: 0;
}
.nsw-filters--down .nsw-filters__wrapper {
  z-index: 200;
  display: none;
  background-color: var(--nsw-off-white);
  padding: 0 1rem;
}
.nsw-section--invert .nsw-filters--down .nsw-filters__wrapper {
  background-color: transparent;
}
@media (min-width: 62rem) {
  .nsw-filters--down .nsw-filters__wrapper {
    display: block;
    position: static;
    background-color: transparent;
    padding: 0;
  }
  .nsw-section--invert .nsw-filters--down .nsw-filters__wrapper {
    background-color: transparent;
  }
}
.active .nsw-filters__wrapper {
  display: block;
}
.nsw-filters__item {
  border-top: 1px solid var(--nsw-grey-04);
  padding: 1.5rem 0;
}
.nsw-filters__item-button {
  font-size: var(--nsw-font-size-sm-mobile);
  line-height: var(--nsw-line-height-sm-mobile);
  font-weight: var(--nsw-font-bold);
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
}
@media (min-width: 62rem) {
  .nsw-filters__item-button {
    font-size: var(--nsw-font-size-sm-desktop);
    line-height: var(--nsw-line-height-sm-desktop);
  }
}
.nsw-filters__item-button .nsw-material-icons {
  font-size: 1.875rem;
}
.nsw-filters__item-button .nsw-material-icons.nsw-material-icons--valid {
  font-size: 1.25rem;
}
.nsw-filters__item-button:focus {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-section--invert .nsw-filters__item-button:focus {
  outline: solid 3px var(--nsw-focus-light);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-filters__item-button.active > .nsw-material-icons:last-child {
  transform: rotate(180deg);
}
.nsw-section--invert .nsw-filters__item-button {
  background-color: transparent;
  color: var(--nsw-off-white);
}
.nsw-filters__item-button ~ .nsw-filters__item-content {
  padding: 0.375rem 0 0 0;
}
.nsw-filters__item span:not(.nsw-material-icons) {
  display: flex;
  align-items: center;
}
.nsw-filters__item .nsw-material-icons {
  margin-left: 0.5rem;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.15s ease-in-out, color 0.15s ease-in-out;
}
.nsw-filters__item .nsw-material-icons--error {
  background-color: var(--nsw-status-error-bg);
  color: var(--nsw-status-error);
}
.nsw-filters__item .nsw-material-icons--valid {
  background-color: var(--nsw-status-success-bg);
  color: var(--nsw-status-success);
}
.nsw-filters--down .nsw-filters__item {
  border-top: 1px solid var(--nsw-grey-03);
}
@media (min-width: 62rem) {
  .nsw-filters--down .nsw-filters__item {
    border-top: 1px solid var(--nsw-grey-04);
    display: block;
  }
}
.nsw-filters--down .nsw-filters__item:first-child {
  border: 0;
}
@media (min-width: 62rem) {
  .nsw-filters--down .nsw-filters__item:first-child {
    border-top: 1px solid var(--nsw-grey-04);
  }
}
.nsw-filters__all {
  padding-top: 1rem;
}
.nsw-filters__cancel {
  padding-bottom: 2rem;
  border-top: 1px solid var(--nsw-grey-04);
  padding: 2rem 0;
}
.nsw-filters--down .nsw-filters__cancel {
  border-top: 1px solid var(--nsw-grey-03);
}
@media (min-width: 62rem) {
  .nsw-filters--down .nsw-filters__cancel {
    border-top: 1px solid var(--nsw-grey-04);
  }
}
.nsw-filters__cancel button {
  font-weight: var(--nsw-font-bold);
  color: var(--nsw-link);
  text-decoration: underline;
  font-size: var(--nsw-font-size-sm-mobile);
  line-height: var(--nsw-line-height-sm-mobile);
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 2px;
  -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
}
.nsw-filters__cancel button:visited {
  color: var(--nsw-visited);
}
.nsw-filters__cancel button:focus {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
}
.nsw-filters__cancel button:hover {
  background-color: var(--nsw-hover);
  outline: 2px solid var(--nsw-hover);
}
.nsw-filters__cancel button:active {
  background-color: var(--nsw-active);
  outline-color: var(--nsw-active);
}
@media (min-width: 62rem) {
  .nsw-filters__cancel button {
    font-size: var(--nsw-font-size-sm-desktop);
    line-height: var(--nsw-line-height-sm-desktop);
  }
}
.nsw-section--invert .nsw-filters__cancel button {
  color: var(--nsw-link-light);
}
.nsw-section--invert .nsw-filters__cancel button:visited {
  color: var(--nsw-visited-light);
}
.nsw-section--invert .nsw-filters__cancel button:focus {
  outline-color: var(--nsw-focus-light);
}
.nsw-section--invert .nsw-filters__cancel button:hover {
  background-color: var(--nsw-hover-light);
  outline-color: var(--nsw-hover-light);
}
.nsw-section--invert .nsw-filters__cancel button:active {
  background-color: var(--nsw-active-light);
  outline-color: var(--nsw-active-light);
}
.nsw-filters__accept {
  padding: 2rem 0;
  border-top: 1px solid var(--nsw-grey-04);
}
@media (min-width: 62rem) {
  .nsw-filters__accept ~ .nsw-filters__cancel {
    border: 0;
    padding-top: 0;
  }
}
.nsw-section--invert .nsw-filters__accept button {
  background-color: var(--nsw-white);
}
.nsw-section--invert .nsw-filters__accept button:disabled {
  background-color: var(--nsw-white);
}
.nsw-section--invert.nsw-section--grey-01 .nsw-filters__accept button {
  color: var(--nsw-grey-01);
}
.nsw-section--invert.nsw-section--grey-01 .nsw-filters__accept button:hover {
  background-color: var(--nsw-grey-04);
  background-image: linear-gradient(rgba(var(--nsw-white-rgb), 0.15), rgba(var(--nsw-white-rgb), 0.15));
}
.nsw-section--invert.nsw-section--brand-dark .nsw-filters__accept button {
  color: var(--nsw-brand-dark);
}
.nsw-section--invert.nsw-section--brand-dark .nsw-filters__accept button:disabled {
  color: var(--nsw-brand-dark);
}
.nsw-section--invert.nsw-section--brand-dark .nsw-filters__accept button:hover {
  color: var(--nsw-text-light);
  background-image: linear-gradient(rgba(var(--nsw-white-rgb), 0.15), rgba(var(--nsw-white-rgb), 0.15));
}
.nsw-filters--fixed .nsw-filters__accept, .nsw-filters--right .nsw-filters__accept {
  z-index: 300;
  position: fixed;
  background-color: var(--nsw-white);
  padding: 1.5rem 1rem;
}
.nsw-section--invert.nsw-section--grey-01 .nsw-filters--fixed .nsw-filters__accept, .nsw-section--invert.nsw-section--grey-01 .nsw-filters--right .nsw-filters__accept {
  background-color: var(--nsw-grey-01);
}
.nsw-section--invert.nsw-section--brand-dark .nsw-filters--fixed .nsw-filters__accept, .nsw-section--invert.nsw-section--brand-dark .nsw-filters--right .nsw-filters__accept {
  background-color: var(--nsw-brand-dark);
}
@media (min-width: 62rem) {
  .nsw-filters--fixed .nsw-filters__accept, .nsw-filters--right .nsw-filters__accept {
    position: static;
    padding: 2rem 0;
    background-color: transparent;
  }
  .nsw-section--invert .nsw-filters--fixed .nsw-filters__accept, .nsw-section--invert .nsw-filters--right .nsw-filters__accept {
    background-color: transparent;
  }
}
.nsw-filters--fixed .nsw-filters__accept {
  bottom: 0;
  left: 0;
  right: 0;
}
.nsw-filters--right .nsw-filters__accept {
  border-top: 1px solid var(--nsw-grey-04);
  bottom: 0;
  width: 100%;
  transition: 0.15s ease-in-out;
  right: -100vw;
}
.nsw-filters--right.active .nsw-filters__accept {
  right: 0;
}
.nsw-filters--down .nsw-filters__accept {
  border-top: 1px solid var(--nsw-grey-03);
}
@media (min-width: 62rem) {
  .nsw-filters--down .nsw-filters__accept {
    border-top: 1px solid var(--nsw-grey-04);
  }
}
.nsw-filters--down .nsw-filters__accept ~ .nsw-filters__cancel {
  border: 0;
  padding-top: 0;
}
.nsw-filters__more, .nsw-filters__less {
  font-weight: var(--nsw-font-bold);
  color: var(--nsw-link);
  text-decoration: underline;
  font-size: var(--nsw-font-size-sm-mobile);
  line-height: var(--nsw-line-height-sm-mobile);
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 2px;
  -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
  margin-top: 1.5rem;
}
.nsw-filters__more:visited, .nsw-filters__less:visited {
  color: var(--nsw-visited);
}
.nsw-filters__more:focus, .nsw-filters__less:focus {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
}
.nsw-filters__more:hover, .nsw-filters__less:hover {
  background-color: var(--nsw-hover);
  outline: 2px solid var(--nsw-hover);
}
.nsw-filters__more:active, .nsw-filters__less:active {
  background-color: var(--nsw-active);
  outline-color: var(--nsw-active);
}
@media (min-width: 62rem) {
  .nsw-filters__more, .nsw-filters__less {
    font-size: var(--nsw-font-size-sm-desktop);
    line-height: var(--nsw-line-height-sm-desktop);
  }
}
.nsw-section--invert .nsw-filters__more, .nsw-section--invert .nsw-filters__less {
  color: var(--nsw-link-light);
}
.nsw-section--invert .nsw-filters__more:visited, .nsw-section--invert .nsw-filters__less:visited {
  color: var(--nsw-visited-light);
}
.nsw-section--invert .nsw-filters__more:focus, .nsw-section--invert .nsw-filters__less:focus {
  outline-color: var(--nsw-focus-light);
}
.nsw-section--invert .nsw-filters__more:hover, .nsw-section--invert .nsw-filters__less:hover {
  background-color: var(--nsw-hover-light);
  outline-color: var(--nsw-hover-light);
}
.nsw-section--invert .nsw-filters__more:active, .nsw-section--invert .nsw-filters__less:active {
  background-color: var(--nsw-active-light);
  outline-color: var(--nsw-active-light);
}
.nsw-section--invert .nsw-filters.nsw-filters--down {
  background-color: var(--nsw-white);
  color: var(--nsw-text-dark);
}
@media (min-width: 62rem) {
  .nsw-section--invert .nsw-filters.nsw-filters--down {
    color: var(--nsw-text-light);
    background-color: transparent;
  }
}
.nsw-section--invert .nsw-filters.nsw-filters--down .nsw-filters__item-button {
  color: var(--nsw-text-dark);
}
@media (min-width: 62rem) {
  .nsw-section--invert .nsw-filters.nsw-filters--down .nsw-filters__item-button {
    color: var(--nsw-text-light);
  }
}
.nsw-filters.nsw-filters--down .nsw-filters__cancel button {
  font-weight: var(--nsw-font-bold);
  color: var(--nsw-link);
  text-decoration: underline;
  font-size: var(--nsw-font-size-sm-mobile);
  line-height: var(--nsw-line-height-sm-mobile);
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 2px;
  -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
}
.nsw-filters.nsw-filters--down .nsw-filters__cancel button:visited {
  color: var(--nsw-visited);
}
.nsw-filters.nsw-filters--down .nsw-filters__cancel button:focus {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
}
.nsw-filters.nsw-filters--down .nsw-filters__cancel button:hover {
  background-color: var(--nsw-hover);
  outline: 2px solid var(--nsw-hover);
}
.nsw-filters.nsw-filters--down .nsw-filters__cancel button:active {
  background-color: var(--nsw-active);
  outline-color: var(--nsw-active);
}
@media (min-width: 62rem) {
  .nsw-filters.nsw-filters--down .nsw-filters__cancel button {
    font-size: var(--nsw-font-size-sm-desktop);
    line-height: var(--nsw-line-height-sm-desktop);
  }
}
@media (min-width: 62rem) {
  .nsw-section--invert .nsw-filters.nsw-filters--down .nsw-filters__cancel button {
    color: var(--nsw-link-light);
  }
  .nsw-section--invert .nsw-filters.nsw-filters--down .nsw-filters__cancel button:visited {
    color: var(--nsw-visited-light);
  }
  .nsw-section--invert .nsw-filters.nsw-filters--down .nsw-filters__cancel button:focus {
    outline-color: var(--nsw-focus-light);
  }
  .nsw-section--invert .nsw-filters.nsw-filters--down .nsw-filters__cancel button:hover {
    background-color: var(--nsw-hover-light);
    outline-color: var(--nsw-hover-light);
  }
  .nsw-section--invert .nsw-filters.nsw-filters--down .nsw-filters__cancel button:active {
    background-color: var(--nsw-active-light);
    outline-color: var(--nsw-active-light);
  }
}
.nsw-section--invert.nsw-section--grey-01 .nsw-filters.nsw-filters--down .nsw-filters__accept button {
  background-color: var(--nsw-grey-01);
  color: var(--nsw-text-light);
}
@media (min-width: 62rem) {
  .nsw-section--invert.nsw-section--grey-01 .nsw-filters.nsw-filters--down .nsw-filters__accept button {
    background-color: var(--nsw-white);
    color: var(--nsw-grey-01);
  }
}
.nsw-section--invert.nsw-section--brand-dark .nsw-filters.nsw-filters--down .nsw-filters__accept button {
  background-color: var(--nsw-brand-dark);
  color: var(--nsw-text-light);
}
@media (min-width: 62rem) {
  .nsw-section--invert.nsw-section--brand-dark .nsw-filters.nsw-filters--down .nsw-filters__accept button {
    background-color: var(--nsw-white);
    color: var(--nsw-brand-dark);
  }
}

.filters-open {
  overflow: hidden;
}
@media (min-width: 62rem) {
  .filters-open {
    overflow: unset;
  }
}

.nsw-footer hr {
  margin: 1rem 0;
  height: 1px;
  border: 0;
  background-color: var(--nsw-grey-03);
}
.nsw-footer a:not(.nsw-icon-button) {
  line-height: 1.5;
  padding: 0.5rem 1.5rem 0.5rem 0;
}
@media (min-width: 48rem) {
  .nsw-footer a:not(.nsw-icon-button) {
    padding: 0.25rem 0.25rem 0.25rem 0;
  }
}
.nsw-footer__upper {
  background-color: var(--nsw-white);
  border-top: solid 1px var(--nsw-grey-03);
  padding-top: 1.5rem;
}
.nsw-footer__upper .nsw-container {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media (min-width: 62rem) {
  .nsw-footer__upper .nsw-container {
    padding-left: 0;
    padding-right: 0;
  }
}
.nsw-footer__group {
  padding: 0 0.5rem 1rem 0.5rem;
  flex: 1 0 auto;
  min-width: 16rem;
  max-width: 50%;
}
@media (min-width: 48rem) {
  .nsw-footer__group {
    flex-basis: 50%;
    min-width: auto;
  }
}
@media (min-width: 62rem) {
  .nsw-footer__group {
    padding: 0 1rem 1.5rem 1rem;
    flex-basis: 25%;
  }
}
.nsw-footer__group ul {
  font-size: var(--nsw-font-size-xs-mobile);
  line-height: var(--nsw-line-height-xs-mobile);
  list-style: none;
  margin: 0.25rem 0 0 0;
  padding: 0;
}
@media (min-width: 62rem) {
  .nsw-footer__group ul {
    font-size: var(--nsw-font-size-xs-desktop);
    line-height: var(--nsw-line-height-xs-desktop);
  }
}
.nsw-footer__group ul a {
  font-weight: var(--nsw-font-normal);
}
.nsw-footer__group li:first-child {
  margin-top: 0;
}
.nsw-footer__heading {
  font-size: var(--nsw-font-size-sm-mobile);
  line-height: var(--nsw-line-height-sm-mobile);
}
@media (min-width: 62rem) {
  .nsw-footer__heading {
    font-size: var(--nsw-font-size-sm-desktop);
    line-height: var(--nsw-line-height-sm-desktop);
  }
}
.nsw-footer__lower {
  font-size: var(--nsw-font-size-xs-mobile);
  line-height: var(--nsw-line-height-xs-mobile);
  background-color: var(--nsw-grey-01);
  color: var(--nsw-text-light);
  padding: 1rem 0 1.5rem 0;
}
@media (min-width: 62rem) {
  .nsw-footer__lower {
    font-size: var(--nsw-font-size-xs-desktop);
    line-height: var(--nsw-line-height-xs-desktop);
  }
}
.nsw-footer__lower a {
  color: var(--nsw-link-light);
}
.nsw-footer__lower a:visited {
  color: var(--nsw-visited-light);
}
.nsw-footer__lower a:focus {
  outline-color: var(--nsw-focus-light);
}
.nsw-footer__lower a:hover {
  background-color: var(--nsw-hover-light);
  outline-color: var(--nsw-hover-light);
}
.nsw-footer__lower a:active {
  background-color: var(--nsw-active-light);
  outline-color: var(--nsw-active-light);
}
.nsw-footer__lower p {
  margin: 0.75rem 0;
}
.nsw-footer__lower ul {
  list-style: none;
  margin: 1rem -0.5rem 0 -0.5rem;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media (min-width: 48rem) {
  .nsw-footer__lower ul {
    margin-left: 0;
    margin-right: 0;
  }
}
.nsw-footer__lower li {
  flex: 1 0 50%;
  max-width: 50%;
  padding: 0 0.5rem;
  margin: 0 0 1rem 0;
}
@media (min-width: 48rem) {
  .nsw-footer__lower li {
    margin-right: 1.5rem;
    padding: 0;
    flex: 0 0 auto;
    max-width: none;
  }
}
@media (min-width: 48rem) {
  .nsw-footer__links {
    display: flex;
    justify-content: space-between;
    margin-top: -1rem;
  }
}
.nsw-footer__acknowledgement {
  font-size: var(--nsw-font-size-sm-mobile);
  line-height: var(--nsw-line-height-sm-mobile);
  padding-bottom: 0.75rem;
}
@media (min-width: 62rem) {
  .nsw-footer__acknowledgement {
    font-size: var(--nsw-font-size-sm-desktop);
    line-height: var(--nsw-line-height-sm-desktop);
  }
}
.nsw-footer__social {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -0.75rem;
  padding: 0.5rem 0 0.75rem 0;
}
@media (min-width: 48rem) {
  .nsw-footer__social {
    padding-bottom: 0.5rem;
  }
}
.nsw-footer__social a {
  margin: 0 0.25rem;
}
@media (min-width: 48rem) {
  .nsw-footer__info {
    display: flex;
    flex-wrap: nowrap;
  }
}
.nsw-footer__copyright {
  flex: 1 1 auto;
  margin: 0.75rem 0;
}
.nsw-footer__built {
  flex: 0 0 12.5rem;
  margin: 0.75rem 0;
}
@media (min-width: 48rem) {
  .nsw-footer__built {
    text-align: right;
  }
}
.nsw-footer--dark .nsw-footer__lower {
  background-color: var(--nsw-brand-dark);
}
.nsw-footer--light hr {
  background-color: var(--nsw-grey-02);
}
.nsw-footer--light .nsw-footer__lower {
  background-color: var(--nsw-brand-light);
  color: var(--nsw-text-dark);
}
.nsw-footer--light .nsw-footer__lower a {
  color: var(--nsw-link);
}
.nsw-footer--light .nsw-footer__lower a:visited {
  color: var(--nsw-visited);
}
.nsw-footer--light .nsw-footer__lower a:focus {
  outline-color: var(--nsw-focus);
}
.nsw-footer--light .nsw-footer__lower a:hover {
  background-color: var(--nsw-hover);
  outline-color: var(--nsw-hover);
}
.nsw-footer--light .nsw-footer__lower a:active {
  background-color: var(--nsw-active);
  outline-color: var(--nsw-active);
}

/* stylelint-disable max-nesting-depth */
.nsw-form {
  margin-top: 3rem;
}
.nsw-form:first-child {
  margin-top: 0;
}
.nsw-form__group {
  margin-top: 1rem;
}
.nsw-form__group:first-child {
  margin-top: 0;
}
.nsw-form__helper {
  font-size: var(--nsw-font-size-xs-mobile);
  line-height: var(--nsw-line-height-xs-mobile);
  display: block;
  margin-bottom: 0.25rem;
}
@media (min-width: 62rem) {
  .nsw-form__helper {
    font-size: var(--nsw-font-size-xs-desktop);
    line-height: var(--nsw-line-height-xs-desktop);
  }
}
.nsw-form__helper--error, .nsw-form__helper--valid {
  margin-top: 0.5rem;
  padding: 0.5rem;
  font-weight: var(--nsw-font-bold);
  color: var(--nsw-text-dark);
  background-repeat: no-repeat;
  background-position: left 0.5rem top 0.5rem;
  background-size: 1rem auto;
  display: flex;
  align-items: center;
}
.nsw-form__helper--error .nsw-material-icons, .nsw-form__helper--valid .nsw-material-icons {
  font-size: 1.25rem;
  margin-right: 0.25rem;
}
.nsw-form__helper--error {
  background-color: var(--nsw-status-error-bg);
}
.nsw-form__helper--error .nsw-material-icons {
  color: var(--nsw-status-error);
}
.nsw-form__helper--valid {
  background-color: var(--nsw-status-success-bg);
}
.nsw-form__helper--valid .nsw-material-icons {
  color: var(--nsw-status-success);
}
.nsw-form__label {
  font-size: var(--nsw-font-size-sm-mobile);
  line-height: var(--nsw-line-height-sm-mobile);
  font-weight: var(--nsw-font-bold);
  display: block;
}
@media (min-width: 62rem) {
  .nsw-form__label {
    font-size: var(--nsw-font-size-sm-desktop);
    line-height: var(--nsw-line-height-sm-desktop);
  }
}
.nsw-form__label--small {
  font-weight: var(--nsw-font-normal);
}
.nsw-form__label + select {
  cursor: pointer;
}
.nsw-form__label + input,
.nsw-form__label + textarea,
.nsw-form__label + select,
.nsw-form__label + .nsw-form__input-group {
  margin-top: 0.25rem;
}
.nsw-form__required::after {
  content: " *";
  color: var(--nsw-status-error);
}
.nsw-form__input {
  font-size: var(--nsw-font-size-sm-mobile);
  line-height: var(--nsw-line-height-sm-mobile);
  padding: 0.6875rem 1rem;
  border-radius: var(--nsw-border-radius);
  background-color: var(--nsw-white);
  color: var(--nsw-text-dark);
  border: 1px solid var(--nsw-grey-01);
  vertical-align: middle;
  appearance: none;
  -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
  width: 100%;
}
@media (min-width: 62rem) {
  .nsw-form__input {
    font-size: var(--nsw-font-size-sm-desktop);
    line-height: var(--nsw-line-height-sm-desktop);
  }
}
.nsw-form__input:focus {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-section--invert .nsw-form__input:focus {
  outline: solid 3px var(--nsw-focus-light);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-form__input:disabled {
  border-color: var(--nsw-grey-03);
  color: var(--nsw-grey-03);
  cursor: not-allowed;
  opacity: 1;
}
.nsw-form__input[aria-invalid=true], .nsw-form__input.has-error {
  border-color: var(--nsw-status-error);
  border-width: 2px;
}
.nsw-form__input:hover {
  background-image: linear-gradient(var(--nsw-hover), var(--nsw-hover));
}
.nsw-form__input:disabled {
  background: var(--nsw-off-white);
}
.nsw-form__input[aria-invalid=true]:hover, .nsw-form__input.has-error:hover {
  background: var(--nsw-status-error-bg);
}
.nsw-form__input-group {
  display: flex;
}
.nsw-form__input-group .nsw-form__input {
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.nsw-form__input-group .nsw-form__input:focus {
  position: relative;
}
.nsw-form__input-group .nsw-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
  margin: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-width: 1px;
  white-space: nowrap;
  border-color: var(--nsw-grey-01);
}
.nsw-section--invert .nsw-form__input-group .nsw-button {
  color: var(--nsw-text-light);
  background-color: var(--nsw-brand-dark);
  border-color: var(--nsw-white);
}
.nsw-form__input-group .nsw-button:focus {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-section--invert .nsw-form__input-group .nsw-button:focus {
  outline: solid 3px var(--nsw-focus-light);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-form__input-group .nsw-material-icons {
  display: block;
  font-size: 2.25rem;
  position: static;
}
.nsw-section--invert .nsw-form__input-group .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-section--invert .nsw-form__input-group .nsw-material-icons:hover {
  color: var(--nsw-text-light);
}
.nsw-form__input-group--icon .nsw-button {
  padding: 0;
  min-width: 3.75rem;
  border-color: var(--nsw-grey-01);
  border-left-width: 0;
}
.nsw-form__input-group--icon .nsw-button .nsw-material-icons:first-child {
  margin-right: 0;
}
.nsw-section--invert .nsw-form__input-group--icon .nsw-button {
  color: var(--nsw-white);
  border-color: var(--nsw-white);
}
.nsw-section--invert .nsw-form__input-group--icon .nsw-button:hover {
  background-color: var(--nsw-hover-light);
}
.nsw-section--invert .nsw-form__input-group--icon .nsw-button .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-form__input-group--icon .nsw-button--white .nsw-material-icons {
  color: var(--nsw-brand-dark);
}
.nsw-section--invert .nsw-form__input-group--icon .nsw-button--white .nsw-material-icons {
  color: var(--nsw-text-light);
}
.nsw-form__input-group--large .nsw-form__input {
  padding: 1rem;
}
.nsw-form__predictive {
  position: relative;
}
.nsw-form__predictive-list {
  z-index: 100;
  border-radius: var(--nsw-border-radius);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: var(--nsw-white);
  box-shadow: 0 4px 12px 0 rgba(var(--nsw-black-rgb), 0.15);
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 3rem;
  right: 0;
  left: 0;
  overflow: hidden;
}
.nsw-form__predictive-list--large {
  top: 3.625rem;
}
.nsw-form__predictive-list li {
  padding: 0;
  margin: 0;
}
.nsw-form__predictive-list a {
  display: block;
  padding: 0.625rem;
  color: var(--nsw-text-dark);
  font-weight: var(--nsw-font-normal);
}
.nsw-form__predictive-list a:hover {
  background-color: var(--nsw-hover);
  outline-width: 0;
}
.nsw-form__predictive-list a:focus {
  outline-width: 3px;
  outline-offset: -3px;
}
.nsw-form__predictive-list a span {
  font-weight: var(--nsw-font-bold);
}
.nsw-form__fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  /* autoprefixer: ignore next */
  display: contents;
}
.nsw-form__fieldset legend {
  padding: 0;
  margin: 0 0 1rem 0;
  display: block;
}
.nsw-form__legend {
  font-size: var(--nsw-font-size-sm-mobile);
  line-height: var(--nsw-line-height-sm-mobile);
  font-weight: var(--nsw-font-bold);
  width: 100%;
  display: block;
}
@media (min-width: 62rem) {
  .nsw-form__legend {
    font-size: var(--nsw-font-size-sm-desktop);
    line-height: var(--nsw-line-height-sm-desktop);
  }
}
.nsw-form__checkbox-input, .nsw-form__radio-input {
  position: absolute;
  opacity: 0;
}
.nsw-form__checkbox-input:first-child + .nsw-form__checkbox-label, .nsw-form__checkbox-input:first-child + .nsw-form__radio-label, .nsw-form__radio-input:first-child + .nsw-form__checkbox-label, .nsw-form__radio-input:first-child + .nsw-form__radio-label {
  margin-top: 0;
}
.nsw-form__checkbox-input:hover + .nsw-form__checkbox-label::before, .nsw-form__checkbox-input:hover + .nsw-form__radio-label::before, .nsw-form__radio-input:hover + .nsw-form__checkbox-label::before, .nsw-form__radio-input:hover + .nsw-form__radio-label::before {
  background-image: linear-gradient(var(--nsw-hover), var(--nsw-hover));
}
.nsw-form__checkbox-input:checked + .nsw-form__checkbox-label::after, .nsw-form__radio-input:checked + .nsw-form__checkbox-label::after {
  background-image: url("data:image/svg+xml,%3Csvg fill='%23ffffff' version='1.1' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cpath d='M29.362 3.742l-18.867 19.204-7.857-7.997-2.638 2.685 9.175 9.34 1.319 1.284 1.318-1.284 20.187-20.547z'%3E%3C/path%3E%3C/svg%3E");
  background-color: var(--nsw-brand-dark);
}
.nsw-form__checkbox-input:checked + .nsw-form__radio-label::after, .nsw-form__radio-input:checked + .nsw-form__radio-label::after {
  background-color: var(--nsw-brand-dark);
}
.nsw-form__checkbox-input:disabled + .nsw-form__checkbox-label,
.nsw-form__checkbox-input:disabled + .nsw-form__radio-label, .nsw-form__radio-input:disabled + .nsw-form__checkbox-label,
.nsw-form__radio-input:disabled + .nsw-form__radio-label {
  cursor: not-allowed;
}
.nsw-form__checkbox-input:disabled + .nsw-form__checkbox-label::before,
.nsw-form__checkbox-input:disabled + .nsw-form__radio-label::before, .nsw-form__radio-input:disabled + .nsw-form__checkbox-label::before,
.nsw-form__radio-input:disabled + .nsw-form__radio-label::before {
  border-color: var(--nsw-grey-03);
  background-color: var(--nsw-off-white);
}
.nsw-form__checkbox-input:disabled:checked + .nsw-form__checkbox-label::after, .nsw-form__checkbox-input:disabled:checked + .nsw-form__radio-label::after, .nsw-form__radio-input:disabled:checked + .nsw-form__checkbox-label::after, .nsw-form__radio-input:disabled:checked + .nsw-form__radio-label::after {
  background-color: var(--nsw-grey-03);
}
.nsw-form__checkbox-input:focus + .nsw-form__checkbox-label::before, .nsw-form__radio-input:focus + .nsw-form__checkbox-label::before {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-section--invert .nsw-form__checkbox-input:focus + .nsw-form__checkbox-label::before, .nsw-section--invert .nsw-form__radio-input:focus + .nsw-form__checkbox-label::before {
  outline: solid 3px var(--nsw-focus-light);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-form__checkbox-input:focus + .nsw-form__radio-label::before, .nsw-form__radio-input:focus + .nsw-form__radio-label::before {
  box-shadow: 0 0 0 3px var(--nsw-text-light), 0 0 0 6px var(--nsw-focus);
}
.nsw-section--invert .nsw-form__checkbox-input:focus + .nsw-form__radio-label::before, .nsw-section--invert .nsw-form__radio-input:focus + .nsw-form__radio-label::before {
  box-shadow: 0 0 0 3px var(--nsw-text-dark), 0 0 0 6px var(--nsw-focus-light);
}
.nsw-form__checkbox-input[aria-invalid=true] + .nsw-form__checkbox-label:hover::before, .nsw-form__checkbox-input.has-error + .nsw-form__checkbox-label:hover::before, .nsw-form__radio-input[aria-invalid=true] + .nsw-form__checkbox-label:hover::before, .nsw-form__radio-input.has-error + .nsw-form__checkbox-label:hover::before {
  background-image: linear-gradient(var(--nsw-status-error-bg), var(--nsw-status-error-bg));
}
.nsw-form__checkbox-input[aria-invalid=true] + .nsw-form__checkbox-label::before, .nsw-form__checkbox-input.has-error + .nsw-form__checkbox-label::before, .nsw-form__radio-input[aria-invalid=true] + .nsw-form__checkbox-label::before, .nsw-form__radio-input.has-error + .nsw-form__checkbox-label::before {
  border-width: 2px;
  border-color: var(--nsw-status-error);
}
.nsw-form__checkbox-label, .nsw-form__radio-label {
  font-size: var(--nsw-font-size-sm-mobile);
  line-height: var(--nsw-line-height-sm-mobile);
  margin: 1rem 0 0 0;
  padding: 0.25rem 0 0.25rem 3rem;
  min-height: 2rem;
  display: block;
  position: relative;
  cursor: pointer;
}
@media (min-width: 62rem) {
  .nsw-form__checkbox-label, .nsw-form__radio-label {
    font-size: var(--nsw-font-size-sm-desktop);
    line-height: var(--nsw-line-height-sm-desktop);
  }
}
.nsw-form__checkbox-label::before, .nsw-form__radio-label::before {
  content: "";
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  border: 1px var(--nsw-grey-01) solid;
  top: 0;
  left: 0;
  display: inline-block;
  vertical-align: middle;
  background-color: var(--nsw-white);
  position: absolute;
}
.nsw-form__checkbox-label::after, .nsw-form__radio-label::after {
  content: "";
  position: absolute;
  width: 1.375rem;
  height: 1.375rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1rem auto;
  left: 0.3125rem;
  top: 0.3125rem;
}
.nsw-form__checkbox-label::before {
  border-radius: var(--nsw-border-radius);
}
.nsw-form__radio-label::before {
  border-radius: 50%;
}
.nsw-form__radio-label::after {
  border-radius: 50%;
}

.nsw-global-alert {
  padding: 1.5rem 0;
  background-color: var(--nsw-status-info);
  color: var(--nsw-text-light);
}
.nsw-global-alert .nsw-icon-button {
  position: absolute;
  top: -0.5rem;
  right: 0.25rem;
  color: var(--nsw-text-light);
}
.nsw-global-alert .nsw-icon-button:hover {
  background-color: var(--nsw-hover);
  background-color: var(--nsw-hover-light);
}
.nsw-global-alert .nsw-icon-button:focus {
  outline: solid 3px var(--nsw-text-light);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-global-alert__wrapper {
  max-width: var(--nsw-container-width);
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.nsw-global-alert__title {
  font-size: var(--nsw-font-size-md-mobile);
  line-height: var(--nsw-line-height-md-mobile);
  font-weight: var(--nsw-font-bold);
}
@media (min-width: 62rem) {
  .nsw-global-alert__title {
    font-size: var(--nsw-font-size-md-desktop);
    line-height: var(--nsw-line-height-md-desktop);
  }
}
.nsw-global-alert__content {
  margin-right: 2.25rem;
}
.nsw-global-alert__content a {
  color: var(--nsw-link-light);
}
.nsw-global-alert__content a:visited {
  color: var(--nsw-visited-light);
}
.nsw-global-alert__content a:focus {
  outline-color: var(--nsw-focus-light);
}
.nsw-global-alert__content a:hover {
  background-color: var(--nsw-hover-light);
  outline-color: var(--nsw-hover-light);
}
.nsw-global-alert__content a:active {
  background-color: var(--nsw-active-light);
  outline-color: var(--nsw-active-light);
}
.nsw-global-alert .nsw-button {
  margin-right: 0;
}
.nsw-global-alert--critical {
  background-color: var(--nsw-status-error);
}
.nsw-global-alert--light {
  background-color: var(--nsw-status-info-bg);
  color: var(--nsw-text-dark);
}
.nsw-global-alert--light .nsw-global-alert__content a {
  color: var(--nsw-link);
}
.nsw-global-alert--light .nsw-global-alert__content a:visited {
  color: var(--nsw-visited);
}
.nsw-global-alert--light .nsw-global-alert__content a:focus {
  outline-color: var(--nsw-focus);
}
.nsw-global-alert--light .nsw-global-alert__content a:hover {
  background-color: var(--nsw-hover);
  outline-color: var(--nsw-hover);
}
.nsw-global-alert--light .nsw-global-alert__content a:active {
  background-color: var(--nsw-active);
  outline-color: var(--nsw-active);
}
.nsw-global-alert--light .nsw-icon-button {
  color: var(--nsw-brand-dark);
}
.nsw-global-alert--light .nsw-icon-button:hover {
  background-color: var(--nsw-hover);
}
.nsw-global-alert--light .nsw-icon-button:focus {
  outline-color: var(--nsw-focus);
}

.nsw-header {
  background-color: var(--nsw-white);
  position: relative;
  border-bottom: solid 1px var(--nsw-grey-04);
}
@media (min-width: 62rem) {
  .nsw-header {
    border-bottom: 0;
  }
}
@media (min-width: 62rem) {
  .nsw-header--simple {
    border-bottom: solid 1px var(--nsw-grey-04);
  }
}
.nsw-header__container {
  max-width: var(--nsw-container-width);
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.nsw-header__inner {
  position: relative;
}
@media (min-width: 62rem) {
  .nsw-header__inner {
    display: flex;
  }
}
.nsw-header__menu button,
.nsw-header__menu a, .nsw-header__search button,
.nsw-header__search a {
  font-size: var(--nsw-font-size-xxs-mobile);
  line-height: var(--nsw-line-height-xxs-mobile);
  font-weight: var(--nsw-font-bold);
  border-radius: var(--nsw-border-radius);
  color: var(--nsw-brand-dark);
  width: 3rem;
  height: 3rem;
  background: none;
  border: 0;
  padding: 0;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
@media (min-width: 62rem) {
  .nsw-header__menu button,
  .nsw-header__menu a, .nsw-header__search button,
  .nsw-header__search a {
    font-size: var(--nsw-font-size-xxs-desktop);
    line-height: var(--nsw-line-height-xxs-desktop);
  }
}
.nsw-header__menu button:hover,
.nsw-header__menu a:hover, .nsw-header__search button:hover,
.nsw-header__search a:hover {
  background-color: var(--nsw-hover);
}
.nsw-header__menu button:focus,
.nsw-header__menu a:focus, .nsw-header__search button:focus,
.nsw-header__search a:focus {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-header__menu button .nsw-material-icons,
.nsw-header__menu a .nsw-material-icons, .nsw-header__search button .nsw-material-icons,
.nsw-header__search a .nsw-material-icons {
  font-size: 1.5rem;
}
.nsw-header__menu {
  position: absolute;
  top: 0.75rem;
  left: -0.375rem;
}
@media (min-width: 62rem) {
  .nsw-header__menu button {
    display: none;
  }
}
.nsw-header__search {
  position: absolute;
  top: 0.75rem;
  right: -0.25rem;
}
@media (min-width: 62rem) {
  .nsw-header__search {
    right: 0;
    top: 50%;
    margin-top: -1.5rem;
  }
}
@media (min-width: 62rem) {
  .nsw-header__search button > span:last-child {
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }
  .nsw-header__search button > span:last-child:not(caption) {
    position: absolute !important;
  }
  .nsw-header__search button .nsw-material-icons {
    font-size: 2.25rem;
  }
}
@media (min-width: 62rem) {
  .nsw-header__main {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1.25rem 4.5rem 1.25rem 0;
  }
  .nsw-header__main:focus-within {
    outline: solid 3px var(--nsw-focus);
    outline-offset: 0;
    outline-offset: 3px;
  }
}
@media (min-width: 62rem) {
  .nsw-header__main .nsw-header__rectangular-logo {
    height: 3.75rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
.nsw-header__waratah {
  padding: 0.75rem 3rem;
  display: flex;
  justify-content: center;
}
@media (min-width: 62rem) {
  .nsw-header__waratah {
    padding: 0;
  }
}
.nsw-header__waratah svg {
  height: 3rem;
  display: block;
}
@media (min-width: 62rem) {
  .nsw-header__waratah svg {
    height: 4.75rem;
  }
}
.nsw-header__waratah svg.nsw-header__waratah-gov {
  height: 3.4375rem;
}
@media (min-width: 62rem) {
  .nsw-header__waratah svg.nsw-header__waratah-gov {
    height: 4.75rem;
  }
}
.nsw-header__waratah a {
  display: flex;
  color: var(--nsw-brand-dark);
  text-decoration: none;
  align-items: center;
}
.nsw-header__waratah a:hover {
  background: transparent;
  outline-width: 0;
}
.nsw-header__waratah a:focus {
  outline-width: 3px;
}
@media (min-width: 62rem) {
  .nsw-header__waratah a:focus {
    outline: none;
  }
}
@media (min-width: 62rem) {
  .nsw-header__waratah a::after {
    z-index: 100;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
.nsw-header__waratah a svg:not(:first-child) {
  margin-left: 1.5rem;
}
@media (min-width: 62rem) {
  .nsw-header__waratah a svg:not(:first-child) {
    margin-left: 2.5rem;
  }
}
.nsw-header__waratah-gov {
  margin-bottom: -0.4375rem;
}
@media (min-width: 62rem) {
  .nsw-header__waratah-gov {
    margin-bottom: 0;
  }
}
.nsw-header__waratah-gov .hide {
  display: none;
}
@media (min-width: 62rem) {
  .nsw-header__waratah-gov .hide {
    display: inline;
  }
}
.nsw-header__name {
  padding: 0.75rem 0;
  color: var(--nsw-brand-dark);
}
@media (min-width: 62rem) {
  .nsw-header__name {
    padding: 0;
    margin-left: 2.5rem;
  }
}
.nsw-header__title {
  font-size: var(--nsw-font-size-lg-mobile);
  line-height: var(--nsw-line-height-lg-mobile);
  font-weight: var(--nsw-font-bold);
  color: var(--nsw-brand-dark);
  display: flex;
  align-items: center;
}
@media (min-width: 62rem) {
  .nsw-header__title {
    font-size: var(--nsw-font-size-lg-desktop);
    line-height: var(--nsw-line-height-lg-desktop);
  }
}
@media (min-width: 62rem) {
  .nsw-header__title {
    line-height: 1.75rem;
  }
}
.nsw-header__title .nsw-tag {
  margin-left: 1.5rem;
}
.nsw-header__description {
  font-size: var(--nsw-font-size-xs-mobile);
  line-height: var(--nsw-line-height-xs-mobile);
  color: var(--nsw-brand-dark);
}
@media (min-width: 62rem) {
  .nsw-header__description {
    font-size: var(--nsw-font-size-xs-desktop);
    line-height: var(--nsw-line-height-xs-desktop);
  }
}
.nsw-header__logo {
  display: flex;
  justify-content: center;
}
@media (min-width: 62rem) {
  .nsw-header__logo {
    justify-content: flex-start;
  }
}
.nsw-header__logo svg {
  height: 3rem;
  display: block;
}
@media (min-width: 62rem) {
  .nsw-header__logo svg {
    height: 4.75rem;
  }
}
.nsw-header__search-area {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
@media (min-width: 62rem) {
  .nsw-header__search-area {
    width: 24.625rem;
    left: auto;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}
.nsw-header__search-area button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}
.nsw-header__search-area > button {
  border-radius: 0;
}
@media (min-width: 62rem) {
  .nsw-header__search-area > button {
    display: none;
  }
}
.nsw-header__search-area form button,
.nsw-header__search-area div button {
  display: none;
  padding: 0;
  width: 3rem;
}
@media (min-width: 62rem) {
  .nsw-header__search-area form button,
  .nsw-header__search-area div button {
    display: flex;
  }
}
.nsw-header__search-area form .nsw-material-icons,
.nsw-header__search-area div .nsw-material-icons {
  font-size: 2.25rem;
}
.nsw-header__search-area input {
  font-size: var(--nsw-font-size-md-mobile);
  line-height: var(--nsw-line-height-md-mobile);
  background: var(--nsw-off-white);
  padding: 0.5rem 4.5rem 0.5rem 1rem;
  color: var(--nsw-text-dark);
  width: 100%;
  appearance: none;
  border: 0;
  height: 4.5rem;
}
@media (min-width: 62rem) {
  .nsw-header__search-area input {
    font-size: var(--nsw-font-size-md-desktop);
    line-height: var(--nsw-line-height-md-desktop);
  }
}
.nsw-header__search-area input:focus {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
}
@media (min-width: 62rem) {
  .nsw-header__search-area input {
    border-radius: var(--nsw-border-radius);
    height: 3rem;
    padding: 0 1rem;
  }
}

.nsw-hero-banner {
  background-color: var(--nsw-white);
  position: relative;
}
.nsw-hero-banner::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: rgba(var(--nsw-black-rgb), 0.1);
}
.nsw-hero-banner__container {
  max-width: var(--nsw-container-width);
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
}
.nsw-hero-banner__wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
@media (min-width: 48rem) {
  .nsw-hero-banner__wrapper {
    margin-left: -1rem;
    margin-right: -1rem;
  }
}
.nsw-hero-banner__content {
  width: 100%;
  padding: 0.5rem;
}
@media (min-width: 48rem) {
  .nsw-hero-banner__content {
    padding: 1rem;
  }
}
@media (min-width: 48rem) {
  .nsw-hero-banner__content {
    width: 58.3333333333%;
    max-width: 58.3333333333%;
  }
}
@media (min-width: 75rem) {
  .nsw-hero-banner__content {
    width: 50%;
    max-width: 50%;
  }
}
@media (min-width: 0rem) {
  .nsw-hero-banner__content {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
@media (min-width: 48rem) {
  .nsw-hero-banner__content {
    min-height: 16.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media (min-width: 62rem) {
  .nsw-hero-banner__content {
    padding-top: 5.5rem;
    padding-bottom: 5.5rem;
  }
}
@media (min-width: 48rem) {
  .nsw-hero-banner--wide .nsw-hero-banner__content {
    width: 66.6666666667%;
    max-width: 66.6666666667%;
  }
}
@media (min-width: 75rem) {
  .nsw-hero-banner--wide .nsw-hero-banner__content {
    width: 58.3333333333%;
    max-width: 58.3333333333%;
  }
}
@media (min-width: 48rem) {
  .nsw-hero-banner--featured .nsw-hero-banner__content {
    min-height: 0;
    display: block;
  }
}
.nsw-hero-banner__content .nsw-button {
  margin: 1.5rem 0 0 0;
}
.nsw-hero-banner__content .nsw-intro {
  margin: 0.5rem 0 0 0;
}
@media (min-width: 48rem) {
  .nsw-hero-banner__content .nsw-intro {
    margin-top: 1rem;
  }
}
.nsw-hero-banner__links {
  width: 100%;
  padding: 0.5rem;
}
@media (min-width: 48rem) {
  .nsw-hero-banner__links {
    padding: 1rem;
  }
}
@media (min-width: 48rem) {
  .nsw-hero-banner__links {
    width: 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
@media (min-width: 75rem) {
  .nsw-hero-banner__links {
    width: 33.3333333333%;
    max-width: 33.3333333333%;
    margin-left: 8.3333333333%;
  }
}
@media (min-width: 0rem) {
  .nsw-hero-banner__links {
    margin-top: -0.5rem;
    padding-top: 0;
    padding-bottom: 2rem;
  }
}
@media (min-width: 48rem) {
  .nsw-hero-banner__links {
    margin-top: 0;
    padding-top: 2rem;
  }
}
@media (min-width: 62rem) {
  .nsw-hero-banner__links {
    padding-top: 5.5rem;
    padding-bottom: 5.5rem;
  }
}
.nsw-hero-banner__list {
  z-index: 100;
  border-left: solid 2px var(--nsw-brand-accent);
  padding-left: 1rem;
  position: relative;
}
.nsw-hero-banner__list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.nsw-hero-banner__list li {
  display: block;
  margin-top: 1rem;
}
.nsw-hero-banner__list li:first-child {
  margin-top: 0;
}
.nsw-hero-banner__list a:not(.nsw-icon-button) {
  line-height: 1.5;
  padding: 0.5rem 1.5rem 0.5rem 0;
}
@media (min-width: 48rem) {
  .nsw-hero-banner__list a:not(.nsw-icon-button) {
    padding: 0.25rem 0.25rem 0.25rem 0;
  }
}
.nsw-hero-banner__sub-title {
  font-size: var(--nsw-font-size-md-mobile);
  line-height: var(--nsw-line-height-md-mobile);
  margin: 0 0 1rem 0;
  font-weight: var(--nsw-font-bold);
}
@media (min-width: 62rem) {
  .nsw-hero-banner__sub-title {
    font-size: var(--nsw-font-size-md-desktop);
    line-height: var(--nsw-line-height-md-desktop);
  }
}
.nsw-hero-banner__box {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 41.66667%;
  padding-left: 1rem;
  overflow: hidden;
}
@media (min-width: 48rem) {
  .nsw-hero-banner__box {
    display: flex;
  }
}
@media (min-width: 75rem) {
  .nsw-hero-banner__box {
    width: 50%;
    padding-left: calc(var(--nsw-container-width) / 12 + 0.5rem * 2);
  }
}
.nsw-hero-banner__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.nsw-hero-banner__lines {
  display: none;
  background-color: var(--nsw-brand-light);
  position: relative;
}
@media (min-width: 48rem) {
  .nsw-hero-banner__lines {
    display: block;
    width: 100%;
  }
}
.nsw-hero-banner__lines::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1rem;
  width: 2rem;
  background-color: var(--nsw-brand-supplementary);
}
.nsw-hero-banner--light {
  background-color: var(--nsw-brand-light);
}
.nsw-hero-banner--light .nsw-hero-banner__lines {
  background-color: var(--nsw-brand-dark);
}
.nsw-hero-banner--off-white {
  background-color: var(--nsw-off-white);
}
.nsw-hero-banner--dark {
  background-color: var(--nsw-brand-dark);
}
.nsw-hero-banner--dark .nsw-hero-banner__content {
  color: var(--nsw-text-light);
}
.nsw-hero-banner--dark .nsw-hero-banner__links {
  color: var(--nsw-text-light);
}
.nsw-hero-banner--dark .nsw-hero-banner__links a {
  color: var(--nsw-link-light);
}
.nsw-hero-banner--dark .nsw-hero-banner__links a:visited {
  color: var(--nsw-visited-light);
}
.nsw-hero-banner--dark .nsw-hero-banner__links a:focus {
  outline-color: var(--nsw-focus-light);
}
.nsw-hero-banner--dark .nsw-hero-banner__links a:hover {
  background-color: var(--nsw-hover-light);
  outline-color: var(--nsw-hover-light);
}
.nsw-hero-banner--dark .nsw-hero-banner__links a:active {
  background-color: var(--nsw-active-light);
  outline-color: var(--nsw-active-light);
}
.nsw-hero-banner--dark .nsw-hero-banner__list {
  border-color: var(--nsw-brand-light);
}
.nsw-hero-banner--lines {
  border-bottom: 2rem solid var(--nsw-brand-light);
}
@media (min-width: 48rem) {
  .nsw-hero-banner--lines {
    border: 0;
  }
}
.nsw-hero-banner--lines.nsw-hero-banner--light {
  border-color: var(--nsw-brand-dark);
}
.nsw-hero-banner--lines::after {
  visibility: hidden;
}
@media (min-width: 48rem) {
  .nsw-hero-banner--lines::after {
    visibility: visible;
  }
}
.nsw-hero-banner--lines .nsw-hero-banner__container {
  border-bottom: 1rem solid var(--nsw-brand-supplementary);
}
@media (min-width: 48rem) {
  .nsw-hero-banner--lines .nsw-hero-banner__container {
    border: 0;
  }
}
.nsw-hero-banner--lines .nsw-hero-banner__box {
  width: 25%;
}
@media (min-width: 75rem) {
  .nsw-hero-banner--lines .nsw-hero-banner__box {
    width: 50%;
    padding-left: calc(var(--nsw-container-width) / 4 + 0.5rem * 2);
  }
}

.hero-search {
  position: relative;
  z-index: 1;
}
@media (min-width: 48rem) {
  .hero-search {
    padding: 2rem 1rem;
  }
}
.hero-search__form {
  padding: 1.5rem 0;
  text-align: left;
}
.hero-search__form .nsw-material-icons:first-child {
  margin: 0;
}
.hero-search__wrapper {
  padding: 2rem 1rem;
  background-color: var(--nsw-off-white);
  border-bottom: solid 4px var(--nsw-brand-accent);
}
@media (min-width: 48rem) {
  .hero-search__wrapper {
    border-top-left-radius: var(--nsw-border-radius);
    border-top-right-radius: var(--nsw-border-radius);
    padding: 2rem 5rem;
  }
}
.hero-search__content {
  max-width: 43.75rem;
  margin: 0 auto;
  text-align: center;
}
.hero-search__suggested ul {
  list-style: none;
  text-align: center;
  margin-top: -0.5rem;
  padding: 0;
}
.hero-search__suggested li {
  margin: 0.5rem 0.75rem 0 0.75rem;
  display: inline-block;
}
.hero-search__suggested a:not(.nsw-icon-button) {
  line-height: 1.5;
  padding: 0.25rem 0.25rem;
}
.hero-search .nsw-container {
  padding: 0;
}

.nsw-in-page-alert {
  margin-top: 1.5rem;
  padding: 1rem;
  position: relative;
  border-left: solid 4px var(--nsw-brand-accent);
  background-color: var(--nsw-grey-04);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.nsw-in-page-alert:first-child {
  margin-top: 0;
}
@media (min-width: 48rem) {
  .nsw-in-page-alert {
    margin-top: 2rem;
  }
}
@media (min-width: 48rem) {
  .nsw-in-page-alert {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
  }
}
.nsw-section--invert .nsw-in-page-alert {
  color: var(--nsw-text-dark);
}
.nsw-section--invert .nsw-in-page-alert a {
  color: var(--nsw-link);
}
.nsw-section--invert .nsw-in-page-alert a:visited {
  color: var(--nsw-visited);
}
.nsw-section--invert .nsw-in-page-alert a:focus {
  outline-color: var(--nsw-focus);
}
.nsw-section--invert .nsw-in-page-alert a:hover {
  background-color: var(--nsw-hover);
  outline-color: var(--nsw-hover);
}
.nsw-section--invert .nsw-in-page-alert a:active {
  background-color: var(--nsw-active);
  outline-color: var(--nsw-active);
}
.nsw-in-page-alert__icon {
  font-size: 1.875rem;
  margin-right: 1rem;
  margin-top: -0.125rem;
}
.nsw-in-page-alert--info {
  background-color: var(--nsw-status-info-bg);
  border-color: var(--nsw-status-info);
}
.nsw-in-page-alert--info .nsw-in-page-alert__icon {
  color: var(--nsw-status-info);
}
.nsw-in-page-alert--success {
  background-color: var(--nsw-status-success-bg);
  border-color: var(--nsw-status-success);
}
.nsw-in-page-alert--success .nsw-in-page-alert__icon {
  color: var(--nsw-status-success);
}
.nsw-in-page-alert--warning {
  background-color: var(--nsw-status-warning-bg);
  border-color: var(--nsw-status-warning);
}
.nsw-in-page-alert--warning .nsw-in-page-alert__icon {
  color: var(--nsw-status-warning);
}
.nsw-in-page-alert--error {
  background-color: var(--nsw-status-error-bg);
  border-color: var(--nsw-status-error);
}
.nsw-in-page-alert--error .nsw-in-page-alert__icon {
  color: var(--nsw-status-error);
}
.nsw-in-page-alert--compact {
  padding: 0.5rem;
  border: 0;
}
.nsw-in-page-alert--compact .nsw-in-page-alert__icon {
  font-size: 1.25rem;
  margin-right: 0.5rem;
  margin-top: 0;
}

.nsw-in-page-nav {
  margin-top: 1.5rem;
  padding: 1rem;
  border-left: solid 2px var(--nsw-brand-accent);
}
.nsw-in-page-nav:first-child {
  margin-top: 0;
}
@media (min-width: 48rem) {
  .nsw-in-page-nav {
    margin-top: 2rem;
  }
}
.nsw-in-page-nav__title {
  font-size: var(--nsw-font-size-md-mobile);
  line-height: var(--nsw-line-height-md-mobile);
  font-weight: var(--nsw-font-bold);
  margin: 0;
}
@media (min-width: 62rem) {
  .nsw-in-page-nav__title {
    font-size: var(--nsw-font-size-md-desktop);
    line-height: var(--nsw-line-height-md-desktop);
  }
}
.nsw-in-page-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.nsw-in-page-nav li {
  display: block;
  margin-top: 1rem;
}
.nsw-in-page-nav a:not(.nsw-icon-button) {
  line-height: 1.5;
  padding: 0.5rem 1.5rem 0.5rem 0;
}
@media (min-width: 48rem) {
  .nsw-in-page-nav a:not(.nsw-icon-button) {
    padding: 0.25rem 0.25rem 0.25rem 0;
  }
}

.nsw-link--button {
  font-size: var(--nsw-font-size-sm-mobile);
  line-height: var(--nsw-line-height-sm-mobile);
  font-weight: var(--nsw-font-bold);
  color: var(--nsw-link);
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  padding: 0;
}
@media (min-width: 62rem) {
  .nsw-link--button {
    font-size: var(--nsw-font-size-sm-desktop);
    line-height: var(--nsw-line-height-sm-desktop);
  }
}
.nsw-link--button:visited {
  color: var(--nsw-visited);
}
.nsw-link--button:focus {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
}
.nsw-link--button:hover {
  background-color: var(--nsw-hover);
  outline: 2px solid var(--nsw-hover);
}
.nsw-link--button:active {
  background-color: var(--nsw-active);
  outline-color: var(--nsw-active);
}
.nsw-link--button.nsw-small {
  font-size: var(--nsw-font-size-xs-mobile);
  line-height: var(--nsw-line-height-xs-mobile);
}
@media (min-width: 62rem) {
  .nsw-link--button.nsw-small {
    font-size: var(--nsw-font-size-xs-desktop);
    line-height: var(--nsw-line-height-xs-desktop);
  }
}
.nsw-link--icon {
  display: inline-block;
  font-weight: var(--nsw-font-bold);
  text-decoration: none;
}
.nsw-link--icon span {
  text-decoration: underline;
}
.nsw-link--icon .nsw-material-icons,
.nsw-link--icon svg {
  font-size: 1.25rem;
  line-height: 0.625rem;
  position: relative;
  bottom: -0.3125rem;
  text-decoration: none;
}
.nsw-link--icon .nsw-material-icons:first-child,
.nsw-link--icon svg:first-child {
  margin-right: 0.25rem;
}
.nsw-link--icon .nsw-material-icons:last-child,
.nsw-link--icon svg:last-child {
  margin-left: 0.25rem;
}

.nsw-link-list {
  margin-top: 1.5rem;
}
.nsw-link-list:first-child {
  margin-top: 0;
}
@media (min-width: 48rem) {
  .nsw-link-list {
    margin-top: 2rem;
  }
}
.nsw-link-list__title {
  font-size: var(--nsw-font-size-lg-mobile);
  line-height: var(--nsw-line-height-lg-mobile);
  font-weight: var(--nsw-font-bold);
  padding-bottom: 1rem;
}
@media (min-width: 62rem) {
  .nsw-link-list__title {
    font-size: var(--nsw-font-size-lg-desktop);
    line-height: var(--nsw-line-height-lg-desktop);
  }
}
@media (min-width: 48rem) {
  .nsw-link-list__title {
    padding-bottom: 1.5rem;
  }
}
.nsw-link-list ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.nsw-link-list li {
  margin: 0;
  border-top: 1px solid var(--nsw-grey-04);
}
.nsw-link-list li:last-child {
  border-bottom: 1px solid var(--nsw-grey-04);
}
.nsw-link-list a {
  padding: 1rem;
  color: var(--nsw-brand-dark);
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
}
.nsw-link-list a:hover {
  background-color: var(--nsw-hover);
  outline-width: 0;
}
.nsw-link-list a:focus {
  outline-width: 3px;
}
.nsw-link-list a span {
  text-decoration: underline;
}
.nsw-link-list a span span.nsw-material-icons {
  bottom: -0.3125rem;
}
.nsw-link-list a .nsw-material-icons {
  text-decoration: none;
  bottom: 0;
}
.nsw-section--invert .nsw-link-list a {
  color: var(--nsw-text-light);
}
.nsw-section--invert .nsw-link-list a:hover {
  background-color: var(--nsw-hover-light);
  outline-width: 0;
}
.nsw-link-list .nsw-material-icons {
  font-size: 1.25rem;
  margin-left: 1rem;
}

.nsw-list-item {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 1.5rem 0;
  border-bottom: 1px solid var(--nsw-grey-04);
}
.nsw-list-item:first-child {
  border-top: 1px solid var(--nsw-grey-04);
}
.nsw-list-item__title {
  font-size: var(--nsw-font-size-md-mobile);
  line-height: var(--nsw-line-height-md-mobile);
  font-weight: var(--nsw-font-bold);
}
@media (min-width: 62rem) {
  .nsw-list-item__title {
    font-size: var(--nsw-font-size-md-desktop);
    line-height: var(--nsw-line-height-md-desktop);
  }
}
.nsw-list-item__content {
  width: 100%;
}
.nsw-list-item__label, .nsw-list-item__info, .nsw-list-item__title, .nsw-list-item__copy, .nsw-list-item__tags {
  margin-top: 0.5rem;
}
.nsw-list-item__label:first-child, .nsw-list-item__info:first-child, .nsw-list-item__title:first-child, .nsw-list-item__copy:first-child, .nsw-list-item__tags:first-child {
  margin: 0;
}
.nsw-list-item__label, .nsw-list-item__info {
  font-size: var(--nsw-font-size-xs-mobile);
  line-height: var(--nsw-line-height-xs-mobile);
}
@media (min-width: 62rem) {
  .nsw-list-item__label, .nsw-list-item__info {
    font-size: var(--nsw-font-size-xs-desktop);
    line-height: var(--nsw-line-height-xs-desktop);
  }
}
.nsw-list-item__label {
  font-weight: var(--nsw-font-bold);
}
.nsw-list-item__image {
  min-width: 15.625rem;
  width: 15.625rem;
  margin: 0 0 0 1.5rem;
  display: none;
}
@media (min-width: 62rem) {
  .nsw-list-item__image {
    display: block;
  }
}
.nsw-list-item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.nsw-list-item--block:focus-within {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
}
.nsw-section--invert .nsw-list-item--block:focus-within {
  outline: solid 3px var(--nsw-focus-light);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-list-item--block .nsw-list-item__title a::after {
  z-index: 100;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.nsw-list-item--block .nsw-list-item__title a:focus {
  outline: none;
}
.nsw-list-item--reversed {
  flex-direction: row-reverse;
}
.nsw-list-item--reversed .nsw-list-item__image {
  margin: 0 1.5rem 0 0;
}

.nsw-loader {
  text-align: center;
  width: fit-content;
}
.nsw-loader__circle {
  position: relative;
  display: inline-flex;
  border-radius: 50%;
  border-style: solid;
  animation-name: nsw-loader-animation-rotate;
  animation-duration: 1.4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  width: 4rem;
  height: 4rem;
  border-width: 4px;
  transform-origin: 50% 50% calc(2rem + 4px);
  border-color: var(--nsw-grey-03);
  border-top-color: var(--nsw-brand-accent);
}
.nsw-loader__circle--sm {
  width: 1.5rem;
  height: 1.5rem;
  transform-origin: 50% 50% calc(1.5rem / 2 + 3px);
  border-width: 3px;
}
.nsw-loader__circle--md {
  width: 2rem;
  height: 2rem;
  transform-origin: 50% 50% calc(2rem / 2 + 3px);
  border-width: 3px;
}
.nsw-loader__circle--lg {
  width: 3rem;
  height: 3rem;
  transform-origin: 50% 50% calc(3rem / 2 + 4px);
}
.nsw-loader__label {
  display: block;
}

@keyframes nsw-loader-animation-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.main-nav-active {
  overflow: hidden;
}

.nsw-main-nav {
  z-index: 200;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--nsw-white);
  transition: all 0.25s ease-in-out;
  transform: translateX(-100%);
  visibility: hidden;
  overflow-y: auto;
}
.nsw-main-nav.activating, .nsw-main-nav.active {
  transform: translateX(0);
  visibility: visible;
}
.nsw-main-nav.no-scroll {
  overflow-y: hidden;
}
@media (min-width: 62rem) {
  .nsw-main-nav.no-scroll {
    overflow-y: visible;
  }
}
.nsw-main-nav.closing {
  transform: translateX(-100%);
}
@media (min-width: 62rem) {
  .nsw-main-nav {
    position: relative;
    transform: none;
    background-color: var(--nsw-off-white);
    top: auto;
    left: auto;
    transition: none;
    visibility: visible;
    overflow-y: visible;
    height: auto;
  }
}
.nsw-main-nav__header {
  padding: 0.5rem 1rem;
  border-bottom: solid 2px var(--nsw-brand-dark);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 62rem) {
  .nsw-main-nav__header {
    display: none;
  }
}
.nsw-main-nav__header .nsw-icon-button:first-child {
  margin-left: -1rem;
}
.nsw-main-nav__header .nsw-icon-button:last-child {
  margin-right: -1rem;
}
.nsw-main-nav__list {
  list-style: none;
  padding: 0;
  margin: 0;
}
@media (min-width: 62rem) {
  .nsw-main-nav__list {
    max-width: var(--nsw-container-width);
    padding-left: 0rem;
    padding-right: 0rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
    position: relative;
  }
}
@media (min-width: 62rem) {
  .nsw-main-nav__list > li.active {
    box-shadow: inset 0 -4px 0 var(--nsw-brand-dark);
  }
}
@media (min-width: 62rem) {
  .nsw-main-nav__list > li > a .nsw-material-icons {
    transform: rotate(90deg);
    margin-left: 0.375rem;
    transition: transform 0.15s ease-in-out, color 0.15s ease-in-out;
  }
  .nsw-main-nav__list > li > a.active {
    background-color: var(--nsw-white);
    box-shadow: inset 0 -4px 0 var(--nsw-brand-dark);
  }
  .nsw-main-nav__list > li > a.active .nsw-material-icons {
    transform: rotate(270deg);
  }
}
.nsw-main-nav__sub-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.nsw-main-nav li {
  margin: 0;
  border-bottom: solid 1px var(--nsw-grey-04);
}
@media (min-width: 62rem) {
  .nsw-main-nav li {
    border: 0;
  }
}
.nsw-main-nav li a {
  padding: 1rem;
  color: var(--nsw-text-dark);
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.nsw-main-nav li a:hover {
  background-color: var(--nsw-hover);
}
.nsw-main-nav li a .nsw-material-icons {
  color: var(--nsw-brand-dark);
  margin-right: -0.5rem;
}
@media (min-width: 62rem) {
  .nsw-main-nav li a {
    font-weight: var(--nsw-font-bold);
  }
}
.nsw-main-nav a {
  font-weight: var(--nsw-font-normal);
}
@media (min-width: 62rem) {
  .nsw-main-nav a {
    z-index: 200;
    position: relative;
  }
}
.nsw-main-nav a:hover {
  outline-width: 0;
}
.nsw-main-nav a:focus {
  outline-width: 3px;
}
.nsw-main-nav__title {
  font-weight: var(--nsw-font-bold);
}
@media (min-width: 62rem) {
  .nsw-main-nav__title {
    font-size: var(--nsw-font-size-md-mobile);
    line-height: var(--nsw-line-height-md-mobile);
    display: inline-flex;
  }
}
@media (min-width: 62rem) and (min-width: 62rem) {
  .nsw-main-nav__title {
    font-size: var(--nsw-font-size-md-desktop);
    line-height: var(--nsw-line-height-md-desktop);
  }
}
.nsw-main-nav__sub-nav .nsw-main-nav__title {
  border-bottom: solid 1px var(--nsw-grey-04);
}
@media (min-width: 62rem) {
  .nsw-main-nav__sub-nav .nsw-main-nav__title {
    border-bottom: 0;
  }
}
.nsw-main-nav__title a {
  font-weight: var(--nsw-font-bold);
}
.nsw-main-nav__title a .nsw-material-icons {
  display: none;
}
@media (min-width: 62rem) {
  .nsw-main-nav__title a .nsw-material-icons {
    display: block;
  }
}
.nsw-main-nav__description {
  font-size: var(--nsw-font-size-xs-mobile);
  line-height: var(--nsw-line-height-xs-mobile);
  margin-top: 1rem;
  display: none;
}
@media (min-width: 62rem) {
  .nsw-main-nav__description {
    font-size: var(--nsw-font-size-xs-desktop);
    line-height: var(--nsw-line-height-xs-desktop);
  }
}
@media (min-width: 62rem) {
  .nsw-main-nav__description {
    display: block;
  }
}
.nsw-main-nav__sub-nav {
  z-index: 100;
  background-color: var(--nsw-white);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.25s ease-in-out;
  transform: translateX(-100%);
  visibility: hidden;
  overflow-y: auto;
}
.nsw-main-nav__sub-nav.active {
  transform: translateX(0);
  visibility: visible;
}
.nsw-main-nav__sub-nav.no-scroll {
  overflow-y: hidden;
}
@media (min-width: 62rem) {
  .nsw-main-nav__sub-nav.no-scroll {
    overflow-y: visible;
  }
}
@media (min-width: 62rem) {
  .nsw-main-nav__sub-nav {
    max-width: var(--nsw-container-width);
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: auto;
    margin-right: auto;
    padding: 3rem 2.5rem;
    top: 100%;
    background-color: var(--nsw-white);
    box-shadow: 0 4px 9px -5px rgba(var(--nsw-black-rgb), 0.4);
    transition: none;
    transform: none;
    height: auto;
    overflow: auto;
  }
  .nsw-main-nav__sub-nav.active {
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (min-width: 62rem) {
  .nsw-main-nav__sub-nav ul {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .nsw-main-nav__sub-nav ul .nsw-material-icons {
    display: none;
  }
}
@media (min-width: 62rem) {
  .nsw-main-nav__sub-nav li {
    width: 32%;
    border-top: solid 1px var(--nsw-grey-04);
  }
  .nsw-main-nav__sub-nav li:nth-child(3n+2) {
    margin-left: 2%;
    margin-right: 2%;
  }
  .nsw-main-nav__sub-nav li:nth-last-child(-n+3) {
    box-shadow: inset 0 -1px 0 var(--nsw-grey-04);
  }
  .nsw-main-nav__sub-nav li a {
    color: var(--nsw-brand-dark);
  }
}
@media (min-width: 62rem) {
  .nsw-main-nav__sub-nav .nsw-main-nav__title a {
    padding: 0;
    color: var(--nsw-brand-dark);
  }
  .nsw-main-nav__sub-nav .nsw-main-nav__title .nsw-material-icons {
    margin: 0 0 0 0.75rem;
  }
}
@media (min-width: 62rem) {
  .nsw-main-nav__sub-nav .nsw-main-nav__sub-nav {
    display: none;
  }
}

.nsw-skip {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.nsw-skip a {
  font-size: var(--nsw-font-size-xxs-mobile);
  line-height: var(--nsw-line-height-xxs-mobile);
  z-index: 200;
  background-color: var(--nsw-brand-dark);
  color: var(--nsw-white);
  width: 100%;
  text-decoration: none;
  padding: 0.5rem 1rem;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-101%);
}
@media (min-width: 62rem) {
  .nsw-skip a {
    font-size: var(--nsw-font-size-xxs-desktop);
    line-height: var(--nsw-line-height-xxs-desktop);
  }
}
.nsw-skip a:focus {
  transform: translateY(0);
  outline: none;
}
.nsw-skip a:focus span {
  outline: solid 3px var(--nsw-white);
  outline-offset: 0;
  outline-offset: 3px;
}
@media (min-width: 62rem) {
  .nsw-skip a {
    text-align: left;
  }
}
.nsw-skip--light a {
  background-color: var(--nsw-off-white);
  color: var(--nsw-palette-grey-900);
}
.nsw-skip--light a:focus span {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
  outline-offset: 3px;
}

.nsw-masthead {
  font-size: var(--nsw-font-size-xxs-mobile);
  line-height: var(--nsw-line-height-xxs-mobile);
  padding: 0.5rem 0;
  background-color: var(--nsw-brand-dark);
  color: var(--nsw-white);
}
@media (min-width: 62rem) {
  .nsw-masthead {
    font-size: var(--nsw-font-size-xxs-desktop);
    line-height: var(--nsw-line-height-xxs-desktop);
  }
}
.nsw-masthead--light {
  background-color: var(--nsw-off-white);
  color: var(--nsw-palette-grey-900);
}

.nsw-media {
  width: 100%;
  margin: 1rem auto;
}
.nsw-section--invert .nsw-media {
  color: var(--nsw-text-dark);
}
@media (min-width: 48rem) {
  .nsw-media {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .nsw-media--90 {
    width: 90%;
  }
  .nsw-media--80 {
    width: 80%;
  }
  .nsw-media--70 {
    width: 70%;
  }
  .nsw-media--60 {
    width: 60%;
  }
  .nsw-media--left-50, .nsw-media--left-40, .nsw-media--left-30 {
    margin-left: 0;
    padding-right: 1rem;
    margin-right: 1rem;
    float: left;
  }
  .nsw-media--right-50, .nsw-media--right-40, .nsw-media--right-30 {
    padding-left: 1rem;
    margin-left: 1rem;
    margin-right: 0;
    float: right;
  }
  .nsw-media--right-50, .nsw-media--left-50 {
    width: 50%;
  }
  .nsw-media--right-40, .nsw-media--left-40 {
    width: 40%;
  }
  .nsw-media--right-30, .nsw-media--left-30 {
    width: 30%;
  }
}
.nsw-media__video {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.nsw-media__video::after {
  content: "";
  display: block;
  padding-top: 56.25%;
}
.nsw-media__video iframe,
.nsw-media__video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.nsw-media img {
  display: block;
  border: 0;
  width: 100%;
}
.nsw-media figcaption {
  font-size: var(--nsw-font-size-xs-mobile);
  line-height: var(--nsw-line-height-xs-mobile);
  background-color: var(--nsw-off-white);
  padding: 1rem;
}
@media (min-width: 62rem) {
  .nsw-media figcaption {
    font-size: var(--nsw-font-size-xs-desktop);
    line-height: var(--nsw-line-height-xs-desktop);
  }
}
.nsw-media--light figcaption {
  background-color: var(--nsw-brand-light);
}
.nsw-media--dark figcaption {
  background-color: var(--nsw-brand-dark);
  color: var(--nsw-text-light);
}
.nsw-media--transparent figcaption {
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}
.nsw-section--invert .nsw-media--transparent figcaption {
  color: var(--nsw-text-light);
}

.nsw-pagination {
  margin-top: 1.5rem;
}
.nsw-pagination:first-child {
  margin-top: 0;
}
@media (min-width: 48rem) {
  .nsw-pagination {
    margin-top: 2rem;
  }
}
.nsw-pagination ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
}
.nsw-pagination li {
  margin: 0;
}
.nsw-pagination li > span {
  padding: 0.25rem;
}
.nsw-pagination a {
  display: block;
  text-decoration: none;
  text-align: center;
  padding: 0.25rem;
  min-width: 1.875rem;
  border-radius: var(--nsw-border-radius);
}
.nsw-pagination a:hover {
  background-color: var(--nsw-hover);
  outline-width: 0;
}
.nsw-section--invert .nsw-pagination a:hover {
  background-color: var(--nsw-hover-light);
  outline-width: 0;
}
.nsw-pagination a:focus {
  outline-width: 3px;
}
.nsw-pagination a.active {
  position: relative;
  color: var(--nsw-text-dark);
}
.nsw-pagination a.active:hover {
  background-color: transparent;
}
.nsw-pagination a.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0.25rem;
  right: 0.25rem;
  height: 2px;
  background-color: var(--nsw-brand-accent);
}
.nsw-section--invert .nsw-pagination a.active {
  color: var(--nsw-text-light);
}
.nsw-pagination a.disabled, .disabled > .nsw-pagination a {
  pointer-events: none;
  opacity: 0.4;
}

.nsw-popover {
  display: none;
  width: max-content;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 17.5rem;
  max-width: 17.5rem;
  border-radius: var(--nsw-border-radius);
  box-shadow: var(--nsw-box-shadow);
  background-color: var(--nsw-white);
  z-index: 300;
}
.nsw-popover:focus, .nsw-popover:active {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
}
.nsw-popover.active {
  display: inline-block;
}

.nsw-progress-indicator {
  margin-top: 1.5rem;
}
.nsw-progress-indicator:first-child {
  margin-top: 0;
}
@media (min-width: 48rem) {
  .nsw-progress-indicator {
    margin-top: 2rem;
  }
}
.nsw-progress-indicator__count {
  font-size: var(--nsw-font-size-sm-mobile);
  line-height: var(--nsw-line-height-sm-mobile);
}
@media (min-width: 62rem) {
  .nsw-progress-indicator__count {
    font-size: var(--nsw-font-size-sm-desktop);
    line-height: var(--nsw-line-height-sm-desktop);
  }
}
.nsw-progress-indicator__bar {
  display: flex;
  padding-top: 0.5rem;
}
.nsw-progress-indicator__bar div {
  height: 0.25rem;
  width: 100%;
  background-color: var(--nsw-grey-04);
}
.nsw-progress-indicator__bar div:not(:first-child) {
  margin-left: 0.25rem;
}
.nsw-progress-indicator__bar div.active {
  background-color: var(--nsw-brand-accent);
}

.nsw-results-bar {
  margin: 1.5rem 0;
}
@media (min-width: 62rem) {
  .nsw-results-bar {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
  }
}
.nsw-results-bar__sorting {
  display: flex;
  align-items: center;
  padding-top: 1.5rem;
}
@media (min-width: 62rem) {
  .nsw-results-bar__sorting {
    padding-top: 0;
  }
}
.nsw-results-bar__sorting label {
  white-space: nowrap;
  margin-right: 0.5rem;
}
.nsw-results-bar__sorting select {
  margin-top: 0;
  min-width: 12.5rem;
}

.nsw-form__select {
  font-size: var(--nsw-font-size-sm-mobile);
  line-height: var(--nsw-line-height-sm-mobile);
  padding: 0.6875rem 1rem;
  border-radius: var(--nsw-border-radius);
  background-color: var(--nsw-white);
  color: var(--nsw-text-dark);
  border: 1px solid var(--nsw-grey-01);
  vertical-align: middle;
  appearance: none;
  width: 100%;
  padding-right: 3rem;
  background: var(--nsw-white) url("data:image/svg+xml,%3Csvg fill='%23333333' version='1.1' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cpath d='M16 26l16-16-4-4-12 12-12-12-4 4 16 16z'%3E%3C/path%3E%3C/svg%3E") no-repeat right 1rem center;
  background-size: 1rem auto;
  cursor: pointer;
}
@media (min-width: 62rem) {
  .nsw-form__select {
    font-size: var(--nsw-font-size-sm-desktop);
    line-height: var(--nsw-line-height-sm-desktop);
  }
}
.nsw-form__select:hover:not(.nsw-multi-select__button) {
  background: url("data:image/svg+xml,%3Csvg fill='%23333333' version='1.1' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cpath d='M16 26l16-16-4-4-12 12-12-12-4 4 16 16z'%3E%3C/path%3E%3C/svg%3E") no-repeat right 1rem center, linear-gradient(var(--nsw-hover), var(--nsw-hover));
  background-size: 1rem auto, auto;
  background-color: var(--nsw-white);
}
.nsw-form__select:focus {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-section--invert .nsw-form__select:focus {
  outline: solid 3px var(--nsw-focus-light);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-form__select:disabled {
  background: var(--nsw-off-white) url("data:image/svg+xml,%3Csvg fill='%23333333' version='1.1' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cpath d='M16 26l16-16-4-4-12 12-12-12-4 4 16 16z'%3E%3C/path%3E%3C/svg%3E") no-repeat right 1rem center;
  background-size: 1rem auto;
  border-color: var(--nsw-grey-03);
  color: var(--nsw-grey-03);
  cursor: not-allowed;
  opacity: 1;
}
.nsw-form__select[aria-invalid=true], .nsw-form__select.has-error {
  border-color: var(--nsw-status-error);
  border-width: 2px;
}
.nsw-form__select[aria-invalid=true]:hover, .nsw-form__select.has-error:hover {
  background: var(--nsw-status-error-bg) url("data:image/svg+xml,%3Csvg fill='%23333333' version='1.1' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cpath d='M16 26l16-16-4-4-12 12-12-12-4 4 16 16z'%3E%3C/path%3E%3C/svg%3E") no-repeat right 1rem center;
  background-size: 1rem auto;
}
.nsw-form__select option {
  background-color: var(--nsw-white);
}
.nsw-form__label + .nsw-multi-select {
  margin-top: 0.35rem;
}

.nsw-multi-select {
  position: relative;
}
.nsw-multi-select__button {
  text-align: left;
  background: var(--nsw-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.5rem;
}
.nsw-multi-select__button .nsw-material-icons {
  font-size: 2rem;
  line-height: 0.8;
  transform: rotate(0deg);
  transition: transform 0.15s ease-in-out, color 0.15s ease-in-out;
  width: 2rem;
}
.nsw-multi-select__button:hover {
  background: linear-gradient(var(--nsw-hover), var(--nsw-hover));
  background-color: var(--nsw-white);
}
.nsw-multi-select__button[aria-invalid=true], .nsw-multi-select__button.has-error {
  border-color: var(--nsw-status-error);
  border-width: 2px;
}
.nsw-multi-select__button[aria-invalid=true]:hover, .nsw-multi-select__button.has-error:hover {
  background: var(--nsw-status-error-bg);
  background-size: revert;
}
.nsw-multi-select__button[aria-expanded=true] + .nsw-multi-select__dropdown {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.2s;
}
.nsw-multi-select__button[aria-expanded=true] .nsw-material-icons {
  transform: rotate(180deg);
}
.nsw-multi-select__list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.nsw-section--invert .nsw-multi-select__list {
  color: var(--nsw-text-dark);
}
.nsw-multi-select__list > li:first-child {
  margin-top: 0;
}
.nsw-multi-select__list:not(:first-of-type) {
  padding-top: 0.25rem;
}
.nsw-multi-select__list:not(:last-of-type) {
  padding-bottom: 0.25rem;
}
.nsw-multi-select__label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nsw-multi-select__dropdown {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  min-width: 12.5rem;
  max-height: 1px;
  background-color: var(--nsw-white);
  box-shadow: var(--nsw-box-shadow);
  padding: 1rem;
  border-radius: 0.25em;
  z-index: 5;
  overflow: auto;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.2s, opacity 0.2s;
}
.nsw-multi-select__dropdown--right {
  right: 0;
  left: auto;
}
.nsw-multi-select__dropdown--up {
  bottom: 100%;
  top: auto;
}
.nsw-multi-select__term + .nsw-multi-select__details::before {
  content: ": ";
}
.nsw-multi-select__all {
  font-size: var(--nsw-font-size-sm-mobile);
  line-height: var(--nsw-line-height-sm-mobile);
  padding: 0.25rem 0 0.25rem 3rem;
  min-height: 2rem;
  display: block;
  position: relative;
  border: 0;
  background: transparent;
  appearance: none;
}
@media (min-width: 62rem) {
  .nsw-multi-select__all {
    font-size: var(--nsw-font-size-sm-desktop);
    line-height: var(--nsw-line-height-sm-desktop);
  }
}
.nsw-multi-select__all:focus {
  border: 0;
  outline: 0;
}
.nsw-multi-select__all::before {
  content: "";
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  border: 1px var(--nsw-grey-01) solid;
  border-radius: var(--nsw-border-radius);
  background-color: var(--nsw-white);
  top: 0;
  left: 0;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
}
.nsw-multi-select__all::after {
  content: "";
  position: absolute;
  width: 1.375rem;
  height: 1.375rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1rem auto;
  left: 0.3125rem;
  top: 0.3125rem;
}
.nsw-multi-select__all:focus::before {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-multi-select__all.active:hover::before {
  background-image: linear-gradient(var(--nsw-hover), var(--nsw-hover));
}
.nsw-multi-select__all.active::after {
  background-image: url("data:image/svg+xml,%3Csvg fill='%23ffffff' version='1.1' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cpath d='M29.362 3.742l-18.867 19.204-7.857-7.997-2.638 2.685 9.175 9.34 1.319 1.284 1.318-1.284 20.187-20.547z'%3E%3C/path%3E%3C/svg%3E");
  background-color: var(--nsw-brand-dark);
}
.nsw-multi-select__all.active:disabled {
  cursor: not-allowed;
}
.nsw-multi-select__all.active:disabled::before, .nsw-multi-select__all.active:disabled::before {
  border-color: var(--nsw-grey-03);
  background-color: var(--nsw-off-white);
}
.nsw-multi-select__all.active:disabled::after, .nsw-multi-select__all.active:disabled::after {
  background-color: var(--nsw-grey-03);
}
.nsw-multi-select__all.active[aria-invalid=true]:hover::before, .nsw-multi-select__all.active.has-error:hover::before {
  background-image: linear-gradient(var(--nsw-status-error-bg), var(--nsw-status-error-bg));
}
.nsw-multi-select__all.active[aria-invalid=true]::before, .nsw-multi-select__all.active.has-error::before {
  border-width: 2px;
  border-color: var(--nsw-status-error);
}

.nsw-side-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.nsw-side-nav > ul > li.active {
  border-left: solid 2px var(--nsw-brand-accent);
}
.nsw-side-nav li {
  margin: 0;
  border-top: solid 1px var(--nsw-grey-04);
}
.nsw-side-nav li li a { /* stylelint-disable-line selector-max-compound-selectors */
  padding-left: 1.5rem;
}
.nsw-side-nav li li li a { /* stylelint-disable-line selector-max-compound-selectors */
  padding-left: 2rem;
}
.nsw-side-nav li li li li a { /* stylelint-disable-line selector-max-compound-selectors */
  padding-left: 3rem;
}
.nsw-side-nav li.active > a {
  font-weight: var(--nsw-font-bold);
}
.nsw-side-nav a {
  font-weight: var(--nsw-font-normal);
  display: block;
  text-decoration: none;
  color: var(--nsw-text-dark);
  padding: 1rem;
}
.nsw-side-nav a:hover {
  background-color: var(--nsw-hover);
  outline-width: 0;
}
.nsw-section--invert .nsw-side-nav a:hover {
  background-color: var(--nsw-hover-light);
  outline-width: 0;
}
.nsw-side-nav a:focus {
  outline-width: 3px;
}
.nsw-side-nav a.current {
  background-color: var(--nsw-off-white);
}
.nsw-section--invert .nsw-side-nav a.current {
  background-color: var(--nsw-hover-light);
}
.nsw-section--invert .nsw-side-nav a {
  color: var(--nsw-text-light);
}
.nsw-side-nav__header {
  border-bottom: solid 2px var(--nsw-brand-dark);
}
.nsw-section--invert .nsw-side-nav__header {
  border-color: var(--nsw-brand-light);
}
.nsw-side-nav__header a {
  font-weight: var(--nsw-font-bold);
  padding: 0.5rem 0;
}
.nsw-side-nav__header + ul > li:first-child {
  border-top: 0;
}

.nsw-status-label {
  font-size: var(--nsw-font-size-xs-mobile);
  line-height: var(--nsw-line-height-xs-mobile);
  padding: 0.125rem 1rem;
  border: solid 1px var(--nsw-off-white);
  background-color: var(--nsw-off-white);
  border-radius: 1.5rem;
  color: var(--nsw-text-dark);
  display: inline-block;
}
@media (min-width: 62rem) {
  .nsw-status-label {
    font-size: var(--nsw-font-size-xs-desktop);
    line-height: var(--nsw-line-height-xs-desktop);
  }
}
.nsw-status-label--info {
  border-color: var(--nsw-status-info);
  background-color: var(--nsw-status-info);
  color: var(--nsw-white);
}
.nsw-status-label--success {
  border-color: var(--nsw-status-success);
  background-color: var(--nsw-status-success);
  color: var(--nsw-white);
}
.nsw-status-label--warning {
  border-color: var(--nsw-status-warning);
  background-color: var(--nsw-status-warning);
  color: var(--nsw-white);
}
.nsw-status-label--error {
  border-color: var(--nsw-status-error);
  background-color: var(--nsw-status-error);
  color: var(--nsw-white);
}

.nsw-steps {
  margin-top: 1.5rem;
  counter-reset: step;
}
.nsw-steps:first-child {
  margin-top: 0;
}
@media (min-width: 48rem) {
  .nsw-steps {
    margin-top: 2rem;
  }
}
.nsw-steps__item {
  position: relative;
}
.nsw-steps__item:last-child .nsw-steps__content {
  padding-bottom: 0;
}
.nsw-steps__item:last-child .nsw-steps__content::before {
  bottom: 0;
}
.nsw-steps__item::before {
  content: "";
  position: absolute;
  top: 0.0625rem;
  left: 0;
  width: 2rem;
  height: 2rem;
  border: 0.25rem solid var(--nsw-brand-accent);
  border-radius: 100%;
}
@media (min-width: 62rem) {
  .nsw-steps__item::before {
    width: 3rem;
    height: 3rem;
    top: -0.25rem;
  }
}
.nsw-steps__item::after {
  font-size: var(--nsw-font-size-md-mobile);
  line-height: var(--nsw-line-height-md-mobile);
  counter-increment: step;
  content: counter(step);
  position: absolute;
  top: 0.0625rem;
  left: 0;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--nsw-brand-accent);
  font-weight: var(--nsw-font-bold);
  visibility: hidden;
}
@media (min-width: 62rem) {
  .nsw-steps__item::after {
    font-size: var(--nsw-font-size-md-desktop);
    line-height: var(--nsw-line-height-md-desktop);
  }
}
@media (min-width: 62rem) {
  .nsw-steps__item::after {
    width: 3rem;
    height: 3rem;
    top: -0.25rem;
  }
}
.nsw-steps__item--fill::before {
  background-color: var(--nsw-brand-accent);
}
.nsw-steps__item--fill::after {
  color: var(--nsw-text-light);
}
.nsw-steps--dark .nsw-steps__item--fill::before {
  background-color: var(--nsw-brand-dark);
}
.nsw-steps--light .nsw-steps__item--fill::before {
  background-color: var(--nsw-brand-light);
}
.nsw-steps--supplementary .nsw-steps__item--fill::before {
  background-color: var(--nsw-brand-supplementary);
}
.nsw-steps__content {
  position: relative;
  padding: 0 0 1.5rem 3rem;
}
@media (min-width: 62rem) {
  .nsw-steps__content {
    padding: 0 0 2rem 4.5rem;
  }
}
.nsw-steps__content::before {
  content: "";
  position: absolute;
  top: 2.0625rem;
  bottom: -0.0625rem;
  left: 0.875rem;
  width: 0.25rem;
  background-color: var(--nsw-brand-accent);
}
@media (min-width: 62rem) {
  .nsw-steps__content::before {
    top: 2.75rem;
    left: 1.375rem;
    bottom: 0.25rem;
  }
}
.nsw-steps--dark .nsw-steps__item::before {
  border-color: var(--nsw-brand-dark);
}
.nsw-steps--dark .nsw-steps__item::after {
  color: var(--nsw-brand-dark);
}
.nsw-steps--dark .nsw-steps__item--fill::after {
  color: var(--nsw-text-light);
}
.nsw-steps--dark .nsw-steps__content::before {
  background-color: var(--nsw-brand-dark);
}
.nsw-steps--light .nsw-steps__item::before {
  border-color: var(--nsw-brand-light);
}
.nsw-steps--light .nsw-steps__item::after {
  color: var(--nsw-brand-light);
}
.nsw-steps--light .nsw-steps__item--fill::after {
  color: var(--nsw-text-dark);
}
.nsw-steps--light .nsw-steps__content::before {
  background-color: var(--nsw-brand-light);
}
.nsw-steps--supplementary .nsw-steps__item::before {
  border-color: var(--nsw-brand-supplementary);
}
.nsw-steps--supplementary .nsw-steps__item::after {
  color: var(--nsw-brand-supplementary);
}
.nsw-steps--supplementary .nsw-steps__item--fill::after {
  color: var(--nsw-text-light);
}
.nsw-steps--supplementary .nsw-steps__content::before {
  background-color: var(--nsw-brand-supplementary);
}
.nsw-steps--counters .nsw-steps__item::after {
  visibility: visible;
}
.nsw-steps--fill .nsw-steps__item::before {
  background-color: var(--nsw-brand-accent);
}
.nsw-steps--fill .nsw-steps__item::after {
  color: var(--nsw-text-light);
}
.nsw-steps--fill.nsw-steps--dark .nsw-steps__item::before {
  background-color: var(--nsw-brand-dark);
}
.nsw-steps--fill.nsw-steps--light .nsw-steps__item::before {
  background-color: var(--nsw-brand-light);
}
.nsw-steps--fill.nsw-steps--light .nsw-steps__item::after {
  color: var(--nsw-text-dark);
}
.nsw-steps--fill.nsw-steps--supplementary .nsw-steps__item::before {
  background-color: var(--nsw-brand-supplementary);
}
.nsw-steps--medium .nsw-steps__item::before {
  top: -0.125rem;
}
@media (min-width: 62rem) {
  .nsw-steps--medium .nsw-steps__item::before {
    width: 2.5rem;
    height: 2.5rem;
    top: -0.25rem;
  }
}
.nsw-steps--medium .nsw-steps__item::after {
  top: -0.125rem;
}
@media (min-width: 62rem) {
  .nsw-steps--medium .nsw-steps__item::after {
    font-size: var(--nsw-font-size-md-mobile);
    width: 2.5rem;
    height: 2.5rem;
    top: -0.25rem;
  }
}
@media (min-width: 62rem) {
  .nsw-steps--medium .nsw-steps__content {
    padding-left: 4rem;
  }
}
.nsw-steps--medium .nsw-steps__content::before {
  top: 1.875rem;
  bottom: 0.125rem;
}
@media (min-width: 62rem) {
  .nsw-steps--medium .nsw-steps__content::before {
    top: 2.25rem;
    bottom: 0.25rem;
    left: 1.125rem;
  }
}
.nsw-steps--small .nsw-steps__item::before {
  top: -0.25rem;
}
@media (min-width: 62rem) {
  .nsw-steps--small .nsw-steps__item::before {
    width: 2rem;
    height: 2rem;
    top: -0.125rem;
  }
}
.nsw-steps--small .nsw-steps__item::after {
  top: -0.25rem;
}
@media (min-width: 62rem) {
  .nsw-steps--small .nsw-steps__item::after {
    font-size: var(--nsw-font-size-md-mobile);
    width: 2rem;
    height: 2rem;
    top: -0.125rem;
  }
}
@media (min-width: 62rem) {
  .nsw-steps--small .nsw-steps__content {
    padding-left: 3.5rem;
  }
}
.nsw-steps--small .nsw-steps__content::before {
  top: 1.75rem;
  bottom: 0.25rem;
}
@media (min-width: 62rem) {
  .nsw-steps--small .nsw-steps__content::before {
    top: 1.875rem;
    bottom: 0.125rem;
    left: 0.875rem;
  }
}

.nsw-support-list {
  margin: 1.25rem;
}
@media (min-width: 62rem) {
  .nsw-support-list {
    margin: 2.5rem;
  }
}
.nsw-support-list__header {
  padding-bottom: 0.5rem;
  border-bottom: solid 2px var(--nsw-brand-dark);
}
.nsw-support-list__container {
  margin-top: 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.25rem;
  flex-wrap: wrap;
}
@media (min-width: 62rem) {
  .nsw-support-list__container {
    margin-top: 2.5rem;
    gap: 2.5rem;
  }
}
.nsw-support-list__container-no-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.25rem;
}
@media (min-width: 62rem) {
  .nsw-support-list__container-no-text {
    gap: 2.5rem;
  }
}
.nsw-support-list__gov-departments {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
.nsw-support-list__gov-departments__labels {
  margin-top: 0.625rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.625rem;
}
.nsw-support-list__gov-departments__empty {
  display: contents;
}
@media (min-width: 62rem) {
  .nsw-support-list__gov-departments {
    flex-direction: row;
    gap: 2.5rem;
  }
  .nsw-support-list__gov-departments__labels {
    gap: 1.25rem;
  }
}
.nsw-support-list__waratah {
  display: flex;
  align-items: center;
}
@media (min-width: 62rem) {
  .nsw-support-list__waratah {
    padding: 0;
  }
}
.nsw-support-list__waratah svg {
  height: 3rem;
  display: block;
}
@media (min-width: 62rem) {
  .nsw-support-list__waratah svg {
    height: 4.75rem;
  }
}
.nsw-support-list__logo-grid {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.25rem;
  flex-wrap: wrap;
}
@media (min-width: 62rem) {
  .nsw-support-list__logo-grid {
    gap: 2.5rem;
  }
}
.nsw-support-list__logo-grid__item,
.nsw-support-list__logo-grid svg {
  height: 3rem;
}
@media (min-width: 62rem) {
  .nsw-support-list__logo-grid__item,
  .nsw-support-list__logo-grid svg {
    height: 4.75rem;
  }
}
.nsw-section--invert .nsw-support-list__header {
  border-color: var(--nsw-white);
}
.nsw-section--invert .nsw-support-list .waratah-logo-text {
  fill: var(--nsw-white);
}

.nsw-table {
  margin-top: 1.5rem;
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.nsw-table:first-child {
  margin-top: 0;
}
@media (min-width: 48rem) {
  .nsw-table {
    margin-top: 2rem;
  }
}
.nsw-table:focus {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-section--invert .nsw-table:focus {
  outline: solid 3px var(--nsw-focus-light);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-table table {
  margin: 0;
  min-width: 37.5rem;
}
.nsw-table--caption-top table {
  caption-side: top;
}
.nsw-table--striped th,
.nsw-table--striped td {
  border-bottom: 0;
}
.nsw-table--striped tbody tr:nth-of-type(even) {
  background-color: var(--nsw-off-white);
}
.nsw-section--invert .nsw-table--striped tbody tr:nth-of-type(even) {
  background-color: var(--nsw-hover-light);
}
.nsw-table--bordered th,
.nsw-table--bordered td {
  border: solid 1px var(--nsw-grey-01);
}

.nsw-table--valign-baseline th,
.nsw-table--valign-baseline td {
  vertical-align: baseline;
}

.nsw-table--valign-top th,
.nsw-table--valign-top td {
  vertical-align: top;
}

.nsw-table--valign-middle th,
.nsw-table--valign-middle td {
  vertical-align: middle;
}

.nsw-table--valign-bottom th,
.nsw-table--valign-bottom td {
  vertical-align: bottom;
}

.nsw-tabs {
  margin-top: 1.5rem;
}
.nsw-tabs:first-child {
  margin-top: 0;
}
@media (min-width: 48rem) {
  .nsw-tabs {
    margin-top: 2rem;
  }
}
.nsw-tabs__list-wrapper {
  overflow: hidden;
  height: 3rem;
  background-color: var(--nsw-off-white);
}
@media (min-width: 48rem) {
  .nsw-tabs__list-wrapper {
    background-color: transparent;
  }
}
.nsw-tabs__list {
  margin: 0;
  padding: 0 0 1.25rem 0;
  list-style: none;
  display: flex;
  position: relative;
  overflow-x: auto;
  white-space: nowrap;
}
.nsw-tabs__list li {
  margin: 0;
}
.nsw-tabs__list a {
  z-index: 100;
  display: block;
  padding: 0.75rem 1rem;
  position: relative;
  text-decoration: none;
  color: var(--nsw-brand-dark);
  height: 100%;
}
.nsw-tabs__list a:hover {
  background-color: var(--nsw-hover);
  outline-width: 0;
}
.nsw-tabs__list a:focus {
  outline-width: 3px;
  outline-offset: -3px;
}
.nsw-tabs__list a.active {
  color: var(--nsw-text-dark);
}
.nsw-tabs__list a.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  border-bottom: solid 2px var(--nsw-brand-accent);
}
.nsw-section--invert .nsw-tabs__list a.active {
  color: var(--nsw-text-light);
}
.nsw-section--invert .nsw-tabs__list a {
  color: var(--nsw-text-light);
}
.nsw-tabs__content {
  padding: 1.5rem 1rem;
  border-bottom: solid 1px var(--nsw-grey-04);
  border-top: solid 2px var(--nsw-grey-04);
  margin-top: -2px;
}
.nsw-tabs__content:focus {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-section--invert .nsw-tabs__content:focus {
  outline-color: var(--nsw-white);
}
.nsw-tabs__content--flush {
  padding: 0;
}
.nsw-tabs__content--side-flush {
  padding-left: 0;
  padding-right: 0;
}
.nsw-tabs__content--no-border {
  border-bottom: 0;
}

.nsw-tag {
  font-size: var(--nsw-font-size-xs-mobile);
  line-height: var(--nsw-line-height-xs-mobile);
  font-weight: var(--nsw-font-normal);
  padding: 0.125rem 1rem;
  border: solid 1px var(--nsw-brand-dark);
  border-radius: 1.5rem;
  color: var(--nsw-brand-dark);
  text-decoration: none;
  display: inline-block;
}
@media (min-width: 62rem) {
  .nsw-tag {
    font-size: var(--nsw-font-size-xs-desktop);
    line-height: var(--nsw-line-height-xs-desktop);
  }
}
.nsw-tag:visited {
  color: var(--nsw-brand-dark);
}
.nsw-tag:hover:not(.nsw-tag--checkbox, span) {
  background-color: var(--nsw-hover);
  outline-width: 0;
}
.nsw-tag:is(span) {
  cursor: default;
}
.nsw-tag:focus {
  outline-width: 3px;
}
.nsw-section--invert .nsw-tag {
  color: var(--nsw-text-light);
  border-color: var(--nsw-text-light);
}
.nsw-section--invert .nsw-tag:not(span) {
  color: var(--nsw-link-light);
}
.nsw-section--invert .nsw-tag:not(span):visited {
  color: var(--nsw-visited-light);
}
.nsw-section--invert .nsw-tag:not(span):focus {
  outline-color: var(--nsw-focus-light);
}
.nsw-section--invert .nsw-tag:not(span):hover {
  background-color: var(--nsw-hover-light);
  outline-color: var(--nsw-hover-light);
}
.nsw-section--invert .nsw-tag:not(span):active {
  background-color: var(--nsw-active-light);
  outline-color: var(--nsw-active-light);
}
.nsw-tag--checkbox {
  padding: 0;
  border: 0;
  border-radius: 0;
  position: relative;
}
.nsw-section--invert .nsw-tag--checkbox:hover {
  background-color: transparent;
}
.nsw-tag--checkbox input {
  position: absolute;
  opacity: 0;
}
.nsw-tag--checkbox input:hover + label {
  cursor: pointer;
  background-color: var(--nsw-hover);
}
.nsw-section--invert .nsw-tag--checkbox input:hover + label {
  background-color: var(--nsw-hover-light);
}
.nsw-tag--checkbox input:focus + label {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
  outline-offset: 3px;
  outline-offset: 0;
}
.nsw-section--invert .nsw-tag--checkbox input:focus + label {
  outline-color: var(--nsw-white);
}
.nsw-tag--checkbox input:checked + label {
  background-color: var(--nsw-brand-dark);
  color: var(--nsw-text-light);
}
.nsw-section--invert .nsw-tag--checkbox input:checked + label {
  background-color: var(--nsw-white);
  color: var(--nsw-text-dark);
}
.nsw-tag--checkbox label {
  padding: 0.125rem 1rem;
  border: solid 1px var(--nsw-brand-dark);
  border-radius: 1.5rem;
  display: inline-block;
}
.nsw-section--invert .nsw-tag--checkbox label {
  border-color: var(--nsw-text-light);
}
.nsw-tag.active {
  background-color: var(--nsw-brand-dark);
  color: var(--nsw-text-light);
}

.nsw-tooltip,
.nsw-toggletip {
  white-space: nowrap;
  border-bottom: 2px dotted var(--nsw-brand-dark);
}
.nsw-tooltip:focus, .nsw-tooltip:active,
.nsw-toggletip:focus,
.nsw-toggletip:active {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-tooltip__element,
.nsw-toggletip__element {
  display: none;
  width: max-content;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 260px;
  border-radius: var(--nsw-border-radius);
  box-shadow: var(--nsw-box-shadow);
  line-height: var(--nsw-line-height-md-desktop);
  z-index: 300;
  font-size: var(--nsw-font-size-xs-mobile);
  line-height: var(--nsw-line-height-xs-mobile);
}
@media (min-width: 62rem) {
  .nsw-tooltip__element,
  .nsw-toggletip__element {
    font-size: var(--nsw-font-size-xs-desktop);
    line-height: var(--nsw-line-height-xs-desktop);
  }
}
.nsw-tooltip__element:focus, .nsw-tooltip__element:active,
.nsw-toggletip__element:focus,
.nsw-toggletip__element:active {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-tooltip__element--dark,
.nsw-toggletip__element--dark {
  background-color: var(--nsw-grey-02);
  color: var(--nsw-text-light);
}
.nsw-tooltip__element--dark a,
.nsw-toggletip__element--dark a {
  color: var(--nsw-link-light);
}
.nsw-tooltip__element--dark a:visited,
.nsw-toggletip__element--dark a:visited {
  color: var(--nsw-visited-light);
}
.nsw-tooltip__element--dark a:focus,
.nsw-toggletip__element--dark a:focus {
  outline-color: var(--nsw-focus-light);
}
.nsw-tooltip__element--dark a:hover,
.nsw-toggletip__element--dark a:hover {
  background-color: var(--nsw-hover-light);
  outline-color: var(--nsw-hover-light);
}
.nsw-tooltip__element--dark a:active,
.nsw-toggletip__element--dark a:active {
  background-color: var(--nsw-active-light);
  outline-color: var(--nsw-active-light);
}
.nsw-tooltip__element--dark a:focus, .nsw-tooltip__element--dark a:active,
.nsw-toggletip__element--dark a:focus,
.nsw-toggletip__element--dark a:active {
  outline: solid 3px var(--nsw-text-light);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-tooltip__element--dark .nsw-icon-button,
.nsw-toggletip__element--dark .nsw-icon-button {
  color: var(--nsw-text-light);
  text-decoration: none;
  color: var(--nsw-link-light);
}
.nsw-tooltip__element--dark .nsw-icon-button:visited,
.nsw-toggletip__element--dark .nsw-icon-button:visited {
  color: var(--nsw-visited-light);
}
.nsw-tooltip__element--dark .nsw-icon-button:focus,
.nsw-toggletip__element--dark .nsw-icon-button:focus {
  outline-color: var(--nsw-focus-light);
}
.nsw-tooltip__element--dark .nsw-icon-button:hover,
.nsw-toggletip__element--dark .nsw-icon-button:hover {
  background-color: var(--nsw-hover-light);
  outline-color: var(--nsw-hover-light);
}
.nsw-tooltip__element--dark .nsw-icon-button:active,
.nsw-toggletip__element--dark .nsw-icon-button:active {
  background-color: var(--nsw-active-light);
  outline-color: var(--nsw-active-light);
}
.nsw-tooltip__element--light,
.nsw-toggletip__element--light {
  background-color: var(--nsw-white);
  color: var(--nsw-text-dark);
}
.nsw-tooltip__element--light a,
.nsw-toggletip__element--light a {
  font-weight: var(--nsw-font-bold);
  color: var(--nsw-link);
  text-decoration: underline;
}
.nsw-tooltip__element--light a:visited,
.nsw-toggletip__element--light a:visited {
  color: var(--nsw-visited);
}
.nsw-tooltip__element--light a:focus,
.nsw-toggletip__element--light a:focus {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
}
.nsw-tooltip__element--light a:hover,
.nsw-toggletip__element--light a:hover {
  background-color: var(--nsw-hover);
  outline: 2px solid var(--nsw-hover);
}
.nsw-tooltip__element--light a:active,
.nsw-toggletip__element--light a:active {
  background-color: var(--nsw-active);
  outline-color: var(--nsw-active);
}
.nsw-tooltip__element--light a:focus, .nsw-tooltip__element--light a:active,
.nsw-toggletip__element--light a:focus,
.nsw-toggletip__element--light a:active {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-tooltip__element--light .nsw-icon-button,
.nsw-toggletip__element--light .nsw-icon-button {
  color: var(--nsw-brand-dark);
  text-decoration: none;
  color: var(--nsw-link);
}
.nsw-tooltip__element--light .nsw-icon-button:visited,
.nsw-toggletip__element--light .nsw-icon-button:visited {
  color: var(--nsw-visited);
}
.nsw-tooltip__element--light .nsw-icon-button:focus,
.nsw-toggletip__element--light .nsw-icon-button:focus {
  outline-color: var(--nsw-focus);
}
.nsw-tooltip__element--light .nsw-icon-button:hover,
.nsw-toggletip__element--light .nsw-icon-button:hover {
  background-color: var(--nsw-hover);
  outline-color: var(--nsw-hover);
}
.nsw-tooltip__element--light .nsw-icon-button:active,
.nsw-toggletip__element--light .nsw-icon-button:active {
  background-color: var(--nsw-active);
  outline-color: var(--nsw-active);
}
.nsw-tooltip__element--light .nsw-tooltip__arrow,
.nsw-tooltip__element--light .nsw-toggletip__arrow,
.nsw-toggletip__element--light .nsw-tooltip__arrow,
.nsw-toggletip__element--light .nsw-toggletip__arrow {
  background-color: var(--nsw-white);
}
.nsw-tooltip__element.active,
.nsw-toggletip__element.active {
  display: inline-block;
}
.nsw-tooltip__arrow,
.nsw-toggletip__arrow {
  position: absolute;
  background-color: var(--nsw-grey-02);
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
}
.nsw-section--invert .nsw-tooltip,
.nsw-section--invert .nsw-toggletip {
  border-bottom: 2px dotted var(--nsw-text-light);
}

.nsw-tooltip {
  cursor: help;
}
.nsw-tooltip__element {
  padding: 0.5rem;
}
@media (min-width: 62rem) {
  .nsw-tooltip__element {
    padding: 1rem;
  }
}

.nsw-toggletip {
  cursor: pointer;
}
.nsw-toggletip__header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.nsw-toggletip__header .nsw-icon-button {
  margin: 0.375rem 0.375rem 0 0.5rem;
}
.nsw-toggletip__header .nsw-icon-button .nsw-material-icons {
  font-size: var(--nsw-font-size-sm-mobile);
}
@media (min-width: 62rem) {
  .nsw-toggletip__header .nsw-icon-button .nsw-material-icons {
    font-size: var(--nsw-font-size-sm-desktop);
  }
}
.nsw-toggletip__content {
  padding: 0 1rem 1rem;
}
.nsw-toggletip__content:first-child {
  margin-top: 0;
}

.nsw-utility-list {
  background-color: var(--nsw-off-white);
  margin-top: 1.5rem;
}
.nsw-section--invert .nsw-utility-list {
  background-color: transparent;
}
.nsw-utility-list:first-child {
  margin-top: 0;
}
@media (min-width: 48rem) {
  .nsw-utility-list {
    margin-top: 2rem;
  }
}
.nsw-utility-list__title {
  font-size: var(--nsw-font-size-lg-mobile);
  line-height: var(--nsw-line-height-lg-mobile);
  font-weight: var(--nsw-font-bold);
  padding-bottom: 1rem;
}
@media (min-width: 62rem) {
  .nsw-utility-list__title {
    font-size: var(--nsw-font-size-lg-desktop);
    line-height: var(--nsw-line-height-lg-desktop);
  }
}
@media (min-width: 48rem) {
  .nsw-utility-list__title {
    padding-bottom: 1.5rem;
  }
}
.nsw-utility-list ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.nsw-utility-list__item {
  margin: 0;
  padding: 1rem;
  display: flex;
  gap: 8px;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  font-weight: var(--nsw-font-bold);
  border-bottom: 1px solid var(--nsw-grey-04);
  color: var(--nsw-brand-dark);
  font-size: var(--nsw-font-size-sm-mobile);
  line-height: var(--nsw-line-height-sm-mobile);
}
@media (min-width: 62rem) {
  .nsw-utility-list__item {
    font-size: var(--nsw-font-size-sm-desktop);
    line-height: var(--nsw-line-height-sm-desktop);
  }
}
.nsw-utility-list__item:hover {
  background-color: var(--nsw-hover);
  outline-width: 0;
}
.nsw-utility-list__item:focus {
  outline: solid 3px var(--nsw-focus);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-utility-list__item:last-child {
  border-bottom: unset;
}
.nsw-section--invert .nsw-utility-list__item {
  color: var(--nsw-text-light);
  background-color: transparent;
  color: var(--nsw-text-light);
}
.nsw-section--invert .nsw-utility-list__item span:not(.nsw-toggletip__element .nsw-material-icons) {
  color: var(--nsw-text-light);
}
.nsw-section--invert .nsw-utility-list__item svg path {
  fill: var(--nsw-brand-dark);
}
.nsw-section--invert .nsw-utility-list__item:hover {
  background-color: var(--nsw-hover-light);
}
.nsw-section--invert .nsw-utility-list__item:focus {
  outline: solid 3px var(--nsw-focus-light);
  outline-offset: 0;
  outline-offset: 3px;
}
.nsw-section--invert .nsw-utility-list__item:visited {
  color: var(--nsw-visited);
}
.nsw-utility-list__item svg {
  width: 1.5rem;
  height: 1.5rem;
}
.nsw-utility-list__item svg path {
  fill: var(--nsw-brand-dark);
}
.nsw-utility-list__item span {
  color: var(--nsw-brand-dark);
}
.nsw-utility-list .nsw-toggletip {
  border-bottom: unset;
}
.nsw-utility-list .nsw-toggletip__header {
  display: none;
}
.nsw-utility-list .nsw-toggletip__content {
  display: flex;
  gap: 8px;
  padding: 1rem;
}
.nsw-utility-list .nsw-toggletip__content a {
  display: flex;
  padding: 0.5rem;
  border-radius: var(--nsw-border-radius);
  text-decoration: none;
}
.nsw-utility-list .nsw-toggletip__content span:not(.nsw-toggletip__element .nsw-material-icons) {
  color: var(--nsw-brand-dark);
}
@media (min-width: 48rem) {
  .nsw-utility-list--horizontal {
    display: flex;
  }
  .nsw-utility-list--horizontal ul {
    display: flex;
    width: 100%;
  }
  .nsw-utility-list--horizontal li {
    border-bottom: unset;
  }
}

.brown {
  --nsw-brand-dark: #523719;
  --nsw-brand-light: #ede3d7;
  --nsw-brand-supplementary: #b68d5d;
  --nsw-brand-accent: #2e808e;
  --nsw-link: #523719;
  --nsw-link-light: rgb(237.7, 235, 232);
  --nsw-visited: rgb(145.3122718121, 65.366600628, 50.1785921149);
  --nsw-visited-light: rgb(233.0624543624, 217.0733201256, 214.035718423);
  --nsw-hover: rgba(82, 55, 25, 0.1);
  --nsw-hover-light: rgba(237.7, 235, 232, 0.1);
  --nsw-active: rgba(82, 55, 25, 0.2);
  --nsw-active-light: rgba(237.7, 235, 232, 0.2);
  --nsw-focus: rgb(142.5412897196, 59.2763168224, 43.4577102804);
  --nsw-focus-light: rgb(232.5082579439, 215.8552633645, 212.6915420561);
  --nsw-disabled: rgb(168.5, 155, 140);
  --nsw-brand-dark-rgb: 82, 55, 25;
  --nsw-brand-light-rgb: 237, 227, 215;
  --nsw-brand-supplementary-rgb: 182, 141, 93;
  --nsw-brand-accent-rgb: 46, 128, 142;
  --nsw-link-rgb: 82, 55, 25;
  --nsw-link-light-rgb: 238, 235, 232;
  --nsw-visited-rgb: 145, 65, 50;
  --nsw-visited-light-rgb: 233, 217, 214;
  --nsw-hover-rgb: 82, 55, 25;
  --nsw-hover-light-rgb: 238, 235, 232;
  --nsw-active-rgb: 82, 55, 25;
  --nsw-active-light-rgb: 238, 235, 232;
  --nsw-focus-rgb: 143, 59, 43;
  --nsw-focus-light-rgb: 233, 216, 213;
  --nsw-disabled-rgb: 169, 155, 140;
}

.brown-accent {
  --nsw-brand-accent: #b68d5d;
  --nsw-brand-accent-rgb: 182, 141, 93;
}

.fuchsia {
  --nsw-brand-dark: #65004d;
  --nsw-brand-light: #fddef2;
  --nsw-brand-supplementary: #d912ae;
  --nsw-brand-accent: #f3631b;
  --nsw-link: #65004d;
  --nsw-link-light: rgb(239.6, 229.5, 237.2);
  --nsw-visited: rgb(151.6386949701, 51.0537873119, 121.0316702636);
  --nsw-visited-light: rgb(234.327738994, 214.2107574624, 228.2063340527);
  --nsw-hover: rgba(101, 0, 77, 0.1);
  --nsw-hover-light: rgba(239.6, 229.5, 237.2, 0.1);
  --nsw-active: rgba(101, 0, 77, 0.2);
  --nsw-active-light: rgba(239.6, 229.5, 237.2, 0.2);
  --nsw-focus: rgb(156.7714062706, 0, 179.999);
  --nsw-focus-light: rgb(235.3542812541, 204, 239.9998);
  --nsw-disabled: rgb(178, 127.5, 166);
  --nsw-brand-dark-rgb: 101, 0, 77;
  --nsw-brand-light-rgb: 253, 222, 242;
  --nsw-brand-supplementary-rgb: 217, 18, 174;
  --nsw-brand-accent-rgb: 243, 99, 27;
  --nsw-link-rgb: 101, 0, 77;
  --nsw-link-light-rgb: 240, 230, 237;
  --nsw-visited-rgb: 152, 51, 121;
  --nsw-visited-light-rgb: 234, 214, 228;
  --nsw-hover-rgb: 101, 0, 77;
  --nsw-hover-light-rgb: 240, 230, 237;
  --nsw-active-rgb: 101, 0, 77;
  --nsw-active-light-rgb: 240, 230, 237;
  --nsw-focus-rgb: 157, 0, 180;
  --nsw-focus-light-rgb: 235, 204, 240;
  --nsw-disabled-rgb: 178, 128, 166;
}

.fuchsia-accent {
  --nsw-brand-accent: #d912ae;
  --nsw-brand-accent-rgb: 217, 18, 174;
}

.green {
  --nsw-brand-dark: #004000;
  --nsw-brand-light: #dbfadf;
  --nsw-brand-supplementary: #00aa45;
  --nsw-brand-accent: #146cfd;
  --nsw-link: #004000;
  --nsw-link-light: rgb(229.5, 235.9, 229.5);
  --nsw-visited: rgb(0.5197698, 103.4341902, 63.98366238);
  --nsw-visited-light: rgb(204.10395396, 224.68683804, 216.796732476);
  --nsw-hover: rgba(0, 64, 0, 0.1);
  --nsw-hover-light: rgba(229.5, 235.9, 229.5, 0.1);
  --nsw-active: rgba(0, 64, 0, 0.2);
  --nsw-active-light: rgba(229.5, 235.9, 229.5, 0.2);
  --nsw-focus: rgb(52.4329666667, 142.999, 0);
  --nsw-focus-light: rgb(214.4865933333, 232.5998, 204);
  --nsw-disabled: rgb(127.5, 159.5, 127.5);
  --nsw-brand-dark-rgb: 0, 64, 0;
  --nsw-brand-light-rgb: 219, 250, 223;
  --nsw-brand-supplementary-rgb: 0, 170, 69;
  --nsw-brand-accent-rgb: 20, 108, 253;
  --nsw-link-rgb: 0, 64, 0;
  --nsw-link-light-rgb: 230, 236, 230;
  --nsw-visited-rgb: 1, 103, 64;
  --nsw-visited-light-rgb: 204, 225, 217;
  --nsw-hover-rgb: 0, 64, 0;
  --nsw-hover-light-rgb: 230, 236, 230;
  --nsw-active-rgb: 0, 64, 0;
  --nsw-active-light-rgb: 230, 236, 230;
  --nsw-focus-rgb: 52, 143, 0;
  --nsw-focus-light-rgb: 214, 233, 204;
  --nsw-disabled-rgb: 128, 160, 128;
}

.green-accent {
  --nsw-brand-accent: #00aa45;
  --nsw-brand-accent-rgb: 0, 170, 69;
}

.orange {
  --nsw-brand-dark: #941b00;
  --nsw-brand-light: #fdeddf;
  --nsw-brand-supplementary: #f3631b;
  --nsw-brand-accent: #8055f1;
  --nsw-link: #941b00;
  --nsw-link-light: rgb(244.3, 232.2, 229.5);
  --nsw-visited: rgb(124.6857844411, 77.3561451883, 38.7726207934);
  --nsw-visited-light: rgb(228.9371568882, 219.4712290377, 211.7545241587);
  --nsw-hover: rgba(148, 27, 0, 0.1);
  --nsw-hover-light: rgba(244.3, 232.2, 229.5, 0.1);
  --nsw-active: rgba(148, 27, 0, 0.2);
  --nsw-active-light: rgba(244.3, 232.2, 229.5, 0.2);
  --nsw-focus: rgb(226.999, 0, 41.8209869369);
  --nsw-focus-light: rgb(249.3998, 204, 212.3641973874);
  --nsw-disabled: rgb(201.5, 141, 127.5);
  --nsw-brand-dark-rgb: 148, 27, 0;
  --nsw-brand-light-rgb: 253, 237, 223;
  --nsw-brand-supplementary-rgb: 243, 99, 27;
  --nsw-brand-accent-rgb: 128, 85, 241;
  --nsw-link-rgb: 148, 27, 0;
  --nsw-link-light-rgb: 244, 232, 230;
  --nsw-visited-rgb: 125, 77, 39;
  --nsw-visited-light-rgb: 229, 219, 212;
  --nsw-hover-rgb: 148, 27, 0;
  --nsw-hover-light-rgb: 244, 232, 230;
  --nsw-active-rgb: 148, 27, 0;
  --nsw-active-light-rgb: 244, 232, 230;
  --nsw-focus-rgb: 227, 0, 42;
  --nsw-focus-light-rgb: 249, 204, 212;
  --nsw-disabled-rgb: 202, 141, 128;
}

.orange-accent {
  --nsw-brand-accent: #f3631b;
  --nsw-brand-accent-rgb: 243, 99, 27;
}

.purple {
  --nsw-brand-dark: #441170;
  --nsw-brand-light: #e6e1fd;
  --nsw-brand-supplementary: #8055f1;
  --nsw-brand-accent: #faaf05;
  --nsw-link: #441170;
  --nsw-link-light: rgb(236.3, 231.2, 240.7);
  --nsw-visited: rgb(112, 17, 76.8333333333);
  --nsw-visited-light: rgb(226.4, 207.4, 219.3666666667);
  --nsw-hover: rgba(68, 17, 112, 0.1);
  --nsw-hover-light: rgba(236.3, 231.2, 240.7, 0.1);
  --nsw-active: rgba(68, 17, 112, 0.2);
  --nsw-active-light: rgba(236.3, 231.2, 240.7, 0.2);
  --nsw-focus: rgb(53.4777790698, 27.4107209302, 180.5882790698);
  --nsw-focus-light: rgb(214.695555814, 209.482144186, 240.117655814);
  --nsw-disabled: rgb(161.5, 136, 183.5);
  --nsw-brand-dark-rgb: 68, 17, 112;
  --nsw-brand-light-rgb: 230, 225, 253;
  --nsw-brand-supplementary-rgb: 128, 85, 241;
  --nsw-brand-accent-rgb: 250, 175, 5;
  --nsw-link-rgb: 68, 17, 112;
  --nsw-link-light-rgb: 236, 231, 241;
  --nsw-visited-rgb: 112, 17, 77;
  --nsw-visited-light-rgb: 226, 207, 219;
  --nsw-hover-rgb: 68, 17, 112;
  --nsw-hover-light-rgb: 236, 231, 241;
  --nsw-active-rgb: 68, 17, 112;
  --nsw-active-light-rgb: 236, 231, 241;
  --nsw-focus-rgb: 53, 27, 181;
  --nsw-focus-light-rgb: 215, 209, 240;
  --nsw-disabled-rgb: 162, 136, 184;
}

.purple-accent {
  --nsw-brand-accent: #8055f1;
  --nsw-brand-accent-rgb: 128, 85, 241;
}

.red {
  --nsw-brand-dark: #630019;
  --nsw-brand-light: #ffe6ea;
  --nsw-brand-supplementary: #d7153a;
  --nsw-brand-accent: #b68d5d;
  --nsw-link: #630019;
  --nsw-link-light: rgb(239.4, 229.5, 232);
  --nsw-visited: rgb(155.7814201002, 61.1047393481, 27.1199047335);
  --nsw-visited-light: rgb(235.15628402, 216.2209478696, 209.4239809467);
  --nsw-hover: rgba(99, 0, 25, 0.1);
  --nsw-hover-light: rgba(239.4, 229.5, 232, 0.1);
  --nsw-active: rgba(99, 0, 25, 0.2);
  --nsw-active-light: rgba(239.4, 229.5, 232, 0.2);
  --nsw-focus: rgb(177.999, 0, 110.2155424242);
  --nsw-focus-light: rgb(239.5998, 204, 226.0431084848);
  --nsw-disabled: rgb(177, 127.5, 140);
  --nsw-brand-dark-rgb: 99, 0, 25;
  --nsw-brand-light-rgb: 255, 230, 234;
  --nsw-brand-supplementary-rgb: 215, 21, 58;
  --nsw-brand-accent-rgb: 182, 141, 93;
  --nsw-link-rgb: 99, 0, 25;
  --nsw-link-light-rgb: 239, 230, 232;
  --nsw-visited-rgb: 156, 61, 27;
  --nsw-visited-light-rgb: 235, 216, 209;
  --nsw-hover-rgb: 99, 0, 25;
  --nsw-hover-light-rgb: 239, 230, 232;
  --nsw-active-rgb: 99, 0, 25;
  --nsw-active-light-rgb: 239, 230, 232;
  --nsw-focus-rgb: 178, 0, 110;
  --nsw-focus-light-rgb: 240, 204, 226;
  --nsw-disabled-rgb: 177, 128, 140;
}

.red-accent {
  --nsw-brand-accent: #d7153a;
  --nsw-brand-accent-rgb: 215, 21, 58;
}

.teal {
  --nsw-brand-dark: #0b3f47;
  --nsw-brand-light: #d1eeea;
  --nsw-brand-supplementary: #2e808e;
  --nsw-brand-accent: #d912ae;
  --nsw-link: #0b3f47;
  --nsw-link-light: rgb(230.6, 235.8, 236.6);
  --nsw-visited: rgb(38.3368000009, 94.3523117995, 118.3589597131);
  --nsw-visited-light: rgb(211.6673600002, 222.8704623599, 227.6717919426);
  --nsw-hover: rgba(11, 63, 71, 0.1);
  --nsw-hover-light: rgba(230.6, 235.8, 236.6, 0.1);
  --nsw-active: rgba(11, 63, 71, 0.2);
  --nsw-active-light: rgba(230.6, 235.8, 236.6, 0.2);
  --nsw-focus: rgb(21.5974268293, 139.4015731707, 111.9139390244);
  --nsw-focus-light: rgb(208.3194853659, 231.8803146341, 226.3827878049);
  --nsw-disabled: #859fa3;
  --nsw-brand-dark-rgb: 11, 63, 71;
  --nsw-brand-light-rgb: 209, 238, 234;
  --nsw-brand-supplementary-rgb: 46, 128, 142;
  --nsw-brand-accent-rgb: 217, 18, 174;
  --nsw-link-rgb: 11, 63, 71;
  --nsw-link-light-rgb: 231, 236, 237;
  --nsw-visited-rgb: 38, 94, 118;
  --nsw-visited-light-rgb: 212, 223, 228;
  --nsw-hover-rgb: 11, 63, 71;
  --nsw-hover-light-rgb: 231, 236, 237;
  --nsw-active-rgb: 11, 63, 71;
  --nsw-active-light-rgb: 231, 236, 237;
  --nsw-focus-rgb: 22, 139, 112;
  --nsw-focus-light-rgb: 208, 232, 226;
  --nsw-disabled-rgb: 133, 159, 163;
}

.teal-accent {
  --nsw-brand-accent: #2e808e;
  --nsw-brand-accent-rgb: 46, 128, 142;
}

.yellow {
  --nsw-brand-dark: #694800;
  --nsw-brand-light: #fff4cf;
  --nsw-brand-supplementary: #faaf05;
  --nsw-brand-accent: #00aa45;
  --nsw-link: #694800;
  --nsw-link-light: rgb(240, 236.7, 229.5);
  --nsw-visited: rgb(90.79597534, 89.8181042532, 22.3449992632);
  --nsw-visited-light: rgb(222.159195068, 221.9636208506, 208.4689998526);
  --nsw-hover: rgba(105, 72, 0, 0.1);
  --nsw-hover-light: rgba(240, 236.7, 229.5, 0.1);
  --nsw-active: rgba(105, 72, 0, 0.2);
  --nsw-active-light: rgba(240, 236.7, 229.5, 0.2);
  --nsw-focus: rgb(183.999, 58.7044428571, 0);
  --nsw-focus-light: rgb(240.7998, 215.7408885714, 204);
  --nsw-disabled: rgb(180, 163.5, 127.5);
  --nsw-brand-dark-rgb: 105, 72, 0;
  --nsw-brand-light-rgb: 255, 244, 207;
  --nsw-brand-supplementary-rgb: 250, 175, 5;
  --nsw-brand-accent-rgb: 0, 170, 69;
  --nsw-link-rgb: 105, 72, 0;
  --nsw-link-light-rgb: 240, 237, 230;
  --nsw-visited-rgb: 91, 90, 22;
  --nsw-visited-light-rgb: 222, 222, 208;
  --nsw-hover-rgb: 105, 72, 0;
  --nsw-hover-light-rgb: 240, 237, 230;
  --nsw-active-rgb: 105, 72, 0;
  --nsw-active-light-rgb: 240, 237, 230;
  --nsw-focus-rgb: 184, 59, 0;
  --nsw-focus-light-rgb: 241, 216, 204;
  --nsw-disabled-rgb: 180, 164, 128;
}

.yellow-accent {
  --nsw-brand-accent: #faaf05;
  --nsw-brand-accent-rgb: 250, 175, 5;
}

.blue-accent {
  --nsw-brand-accent: #146cfd;
  --nsw-brand-accent-rgb: 20, 108, 253;
}

.nsw-palette {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 0 0;
  grid-template-areas: ". . . . . . . . . . . ." ". . . . . . . . . . . ." ". . . . . . . . . . . ." ". . . . . . . . . . . .";
  font-size: var(--nsw-font-size-xxs-desktop);
  color: var(--nsw-text-light);
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
.nsw-palette > div {
  padding: 0.5rem;
}

.nsw-docs :not(pre) code {
  padding: 0.1875rem;
  background: var(--nsw-grey-04);
  border-radius: var(--nsw-border-radius);
}
@media (min-width: 62rem) {
  .nsw-docs :target::before {
    content: "";
    display: block;
    height: 9.4375rem;
    margin: -9.4375rem 0 0;
  }
}
.nsw-docs--light .nsw-docs__main {
  background-color: var(--nsw-white);
}
@media (min-width: 62rem) {
  .nsw-docs__header {
    height: 9.4375rem;
  }
}
@media (min-width: 62rem) {
  .nsw-docs__header-wrapper {
    z-index: 300;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--nsw-white);
  }
}
.nsw-docs__header .nsw-container,
.nsw-docs__header .nsw-header__container {
  margin: 0;
}
@media (min-width: 62rem) {
  .nsw-docs__header .nsw-container,
  .nsw-docs__header .nsw-header__container {
    max-width: 77.5rem;
  }
  .nsw-docs__header .nsw-header__container .nsw-header__inner {
    justify-content: space-between;
    align-items: center;
  }
}
@media (min-width: 62rem) {
  .nsw-docs__wrapper {
    display: flex;
  }
}
.nsw-docs__nav {
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */
}
@media (min-width: 62rem) {
  .nsw-docs__nav {
    min-width: 18.75rem;
    max-width: 18.75rem;
  }
}
@media (min-width: 62rem) {
  .nsw-docs__nav-wrapper {
    z-index: 300;
    position: fixed;
    top: 9.4375rem;
    bottom: 0;
    left: 0;
    width: 18.75rem;
    overflow-y: auto;
    border-right: 1px solid var(--nsw-grey-04);
    background-color: var(--nsw-white);
    padding: 1rem;
  }
}
.nsw-docs__nav .nsw-main-nav__sub-nav a .nsw-brand {
  margin-right: -0.5rem;
}
@media (min-width: 62rem) {
  .nsw-docs__nav .nsw-main-nav {
    background-color: transparent;
  }
  .nsw-docs__nav .nsw-main-nav li {
    border-top: 1px solid var(--nsw-grey-04);
  }
  .nsw-docs__nav .nsw-main-nav a {
    font-weight: var(--nsw-font-normal);
  }
  .nsw-docs__nav .nsw-main-nav a.active {
    font-weight: var(--nsw-font-bold);
  }
  .nsw-docs__nav .nsw-main-nav a.current-section {
    font-weight: var(--nsw-font-bold);
    border-left: 2px solid var(--nsw-brand-accent);
  }
  .nsw-docs__nav .nsw-main-nav a.current-section:hover {
    background-color: var(--nsw-hover);
  }
  .nsw-docs__nav .nsw-main-nav a.current {
    background-color: var(--nsw-off-white);
  }
  .nsw-docs__nav .nsw-main-nav__list {
    max-width: none;
    margin: 0;
    flex-direction: column;
    cursor: pointer;
  }
  .nsw-docs__nav .nsw-main-nav__list > li:first-child {
    border-top: 0;
  }
}
@media (min-width: 62rem) and (min-width: 62rem) {
  .nsw-docs__nav .nsw-main-nav__list > li > a.active {
    box-shadow: none;
  }
}
@media (min-width: 62rem) {
  .nsw-docs__nav .nsw-main-nav__sub-nav {
    position: relative;
    top: auto;
    left: auto;
    max-width: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    display: none;
  }
  .nsw-docs__nav .nsw-main-nav__sub-nav.active {
    display: block;
    transform: none;
    left: auto;
  }
  .nsw-docs__nav .nsw-main-nav__sub-nav.current-section {
    border-left: 2px solid var(--nsw-brand-accent);
  }
  .nsw-docs__nav .nsw-main-nav__sub-nav ul {
    margin: 0;
    flex-direction: column;
  }
  .nsw-docs__nav .nsw-main-nav__sub-nav li {
    width: auto;
    margin: 0;
    border-bottom: 0;
  }
  .nsw-docs__nav .nsw-main-nav__sub-nav a {
    padding-left: 1.5rem;
    color: var(--nsw-text-dark);
  }
}
.nsw-docs__main {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--nsw-off-white);
}
.nsw-docs__main .nsw-docs__example {
  padding: 1rem;
  background-color: var(--nsw-white);
}
.nsw-docs__main--narrow .nsw-docs__demo, .nsw-docs__main--menu .nsw-docs__demo {
  max-width: 50rem;
}
@media (min-width: 62rem) {
  .nsw-docs__main--narrow .nsw-docs__demo, .nsw-docs__main--menu .nsw-docs__demo {
    max-width: 54.5rem;
  }
}
.nsw-docs__main--wide .nsw-docs__demo {
  max-width: calc(var(--nsw-container-width) + 2rem);
}
@media (min-width: 62rem) {
  .nsw-docs__main--wide .nsw-docs__demo {
    max-width: calc(var(--nsw-container-width) + 6.5rem);
  }
}
.nsw-docs__main--menu .nsw-docs__component {
  max-width: 21rem;
}
.nsw-docs__container {
  max-width: 50rem;
}
@media (min-width: 62rem) {
  .nsw-docs__container {
    max-width: 54.5rem;
  }
}
.nsw-docs__intro {
  background-color: var(--nsw-white);
}
.nsw-docs__intro .nsw-docs__container {
  padding: 1.5rem 1rem;
}
@media (min-width: 62rem) {
  .nsw-docs__intro .nsw-docs__container {
    padding: 3rem 3.25rem;
  }
}
.nsw-docs__intro--tabs {
  margin-bottom: -3rem;
}
.nsw-docs__intro--tabs .nsw-docs__container {
  padding-bottom: 4.875rem;
}
@media (min-width: 62rem) {
  .nsw-docs__intro--tabs .nsw-docs__container {
    padding-bottom: 5.5rem;
  }
}
.nsw-docs__tabs {
  flex-grow: 1;
}
.nsw-docs__content {
  padding: 1.5rem 1rem;
  flex-grow: 1;
}
@media (min-width: 62rem) {
  .nsw-docs__content {
    padding: 3rem 1rem 3rem 3.25rem;
  }
}
.nsw-docs__example--hidden {
  display: none;
}
.nsw-docs__example.nsw-docs__example--dark {
  background-color: var(--nsw-grey-01);
}
.nsw-docs__example.nsw-docs__example--light {
  background-color: var(--nsw-grey-04);
}
.nsw-docs__example.nsw-docs__example--brand-dark {
  background-color: var(--nsw-brand-dark);
}
.nsw-docs__example.nsw-docs__example--brand-light {
  background-color: var(--nsw-brand-light);
}
.nsw-docs__iframe {
  width: 100%;
  height: 500px;
  background-color: var(--nsw-white);
}
.nsw-docs__demo > h3 {
  padding: 1rem 0;
}
.nsw-docs__demo > h3:first-child {
  padding-top: 0;
}
.nsw-docs__demo > h3 + p {
  margin-top: -0.75rem;
  padding-bottom: 1rem;
}
.nsw-docs__demo > h3 + h4 {
  padding-top: 0;
}
.nsw-docs__demo > h4 {
  padding: 1rem 0;
}
.nsw-docs__component::after {
  content: "";
  clear: both;
  display: table;
}
.nsw-docs__external {
  padding-top: 0.5rem;
}
.nsw-docs__list {
  padding: 0;
  list-style-type: none;
}
.nsw-docs__list--4 {
  column-gap: 2.25rem;
  column-count: 2;
}
@media (min-width: 62rem) {
  .nsw-docs__list--4 {
    column-count: 4;
  }
}
.nsw-docs__list--3 {
  column-gap: 2.25rem;
  column-count: 2;
}
@media (min-width: 62rem) {
  .nsw-docs__list--3 {
    column-count: 3;
  }
}
@media (min-width: 62rem) {
  .nsw-docs__list--2 {
    column-gap: 2.25rem;
    column-count: 2;
  }
}
.nsw-docs__list li {
  margin: 0;
}
.nsw-docs__list a {
  position: relative;
  display: inline-block;
  margin: 0.25rem 0;
}
.nsw-docs__list .nsw-brand {
  position: absolute;
  right: -1.5rem;
}
.nsw-docs__box {
  background-color: var(--nsw-grey-03);
  min-height: 3.5rem;
}
.nsw-docs__box--large {
  min-height: 9.375rem;
}
.nsw-docs__section--white, .nsw-docs__section--light, .nsw-docs__section--dark {
  padding: 0.5rem;
  margin: 0.5rem -0.5rem 0 -0.5rem;
}
.nsw-docs__section--dark {
  background-color: var(--nsw-grey-01);
}
.nsw-docs__section--light {
  background-color: var(--nsw-grey-04);
}
.nsw-docs__section--spaced {
  margin-top: 1.5rem;
}
.nsw-docs__section--spaced:first-child {
  margin-top: 0;
}
@media (min-width: 48rem) {
  .nsw-docs__section--spaced {
    margin-top: 2rem;
  }
}
.nsw-docs__swatch {
  margin: 0 auto;
  background-color: var(--nsw-brand-dark);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
}
.nsw-docs__loader {
  z-index: 300;
  height: 100%;
  max-height: 100vh;
  overflow-y: auto;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(var(--nsw-white-rgb), 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}
.nsw-docs .nsw-in-page-alert + h1 {
  margin-top: 1.5rem;
}
@media (min-width: 62rem) {
  .nsw-docs .nsw-in-page-alert + h1 {
    margin-top: 3rem;
  }
}
.nsw-docs .nsw-card .nsw-brand {
  margin-left: 0.5rem;
}

.nsw-docs-code {
  position: relative;
}
.nsw-docs-code > button {
  margin-top: 1.5rem;
  padding: 0.125rem;
  cursor: pointer;
}
.nsw-docs-code > button.active .nsw-material-icons {
  transform: rotate(180deg);
}
.nsw-docs-code__content {
  position: relative;
  display: none;
  margin-top: 1.5rem;
  max-width: 54.5rem;
}
.nsw-docs-code__content.active {
  display: block;
}
.nsw-docs-code__content pre {
  margin: 0;
}
.nsw-docs-code__content .hljs {
  padding: 1rem;
  overflow: visible;
  background-color: transparent;
}
.nsw-docs-code__content > button {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.nsw-docs-code__copy {
  padding: 0;
  border: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
  position: absolute;
  display: none;
}
.nsw-docs-code__wrapper {
  min-height: 4.25rem;
  max-width: 54.5rem;
  max-height: 25rem;
  overflow: auto;
  background-color: var(--nsw-white);
}

.nsw-docs-tabs > .nsw-tabs__list-wrapper {
  background-color: transparent;
  padding: 0 1rem;
}
@media (min-width: 62rem) {
  .nsw-docs-tabs > .nsw-tabs__list-wrapper {
    padding: 0 3.25rem;
  }
}
.nsw-docs-tabs > .nsw-tabs__content {
  padding: 0;
  border-bottom: 0;
  border-color: var(--nsw-white);
}
.nsw-docs-tabs > .nsw-tabs__content:focus {
  outline: none;
}
.nsw-docs-tabs > .nsw-tabs__content .nsw-docs__container,
.nsw-docs-tabs > .nsw-tabs__content .nsw-docs__demo {
  padding: 1.5rem 1rem;
}
@media (min-width: 62rem) {
  .nsw-docs-tabs > .nsw-tabs__content .nsw-docs__container,
  .nsw-docs-tabs > .nsw-tabs__content .nsw-docs__demo {
    padding: 3rem 3.25rem;
  }
}
.nsw-docs-tabs .nsw-docs__external {
  padding: 0 0 1.5rem 0;
}

.nsw-docs-footer .nsw-container {
  margin: 0;
  padding: 0 1rem;
}
@media (min-width: 62rem) {
  .nsw-docs-footer .nsw-container {
    padding: 0 3.25rem;
  }
}
.nsw-docs-footer .nsw-footer__lower {
  padding: 1rem 0;
}

.nsw-docs__version {
  font-size: var(--nsw-font-size-xs-mobile);
  line-height: var(--nsw-line-height-xs-mobile);
  font-weight: var(--nsw-font-normal);
  padding: 0.125rem 1rem;
  border: solid 1px var(--nsw-brand-dark);
  border-radius: 1.5rem;
  color: var(--nsw-brand-dark);
  text-decoration: none;
  display: inline-block;
  margin-left: 1rem;
}
@media (min-width: 62rem) {
  .nsw-docs__version {
    font-size: var(--nsw-font-size-xs-desktop);
    line-height: var(--nsw-line-height-xs-desktop);
  }
}

.nsw-docs__element {
  height: 100%;
}

.nsw-autocomplete .nsw-material-icons {
  font-size: 2.25rem;
}
.nsw-autocomplete__item {
  cursor: pointer;
  transition: 0.2s;
  padding-left: 0;
  margin-top: 0;
}
.nsw-autocomplete__item.nsw-autocomplete__no-results {
  padding: 0.5rem 0.75rem;
}
@media (min-width: 768px) {
  .nsw-autocomplete__item.nsw-autocomplete__no-results {
    padding: 0.75rem 1.125rem;
  }
}
.nsw-autocomplete__item > *:first-child,
.nsw-autocomplete__item > *:last-child {
  margin-top: 0;
}
.nsw-autocomplete__item a {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0.5rem 0.75rem;
  font-weight: 400;
  color: var(--nsw-text-dark);
}
@media (min-width: 768px) {
  .nsw-autocomplete__item a {
    padding: 0.75rem 1.125rem;
  }
}
.nsw-autocomplete__results {
  position: absolute;
  z-index: 5;
  width: 100%;
  left: 0;
  top: calc(100% + 3px);
  transform: translateY(4px);
  background-color: var(--nsw-white);
  box-shadow: var(--nsw-box-shadow);
  border-radius: 0.25em;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0s 0.3s, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  overflow: hidden;
}
.nsw-autocomplete.active .nsw-autocomplete__results {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s, transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translateY(0);
}
.nsw-autocomplete__list {
  list-style: none;
  padding-left: 0;
  max-height: 250px;
  overflow: auto;
}

.nsw-color-swatches {
  border: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  margin: 16px 0;
}
.nsw-color-swatches__list {
  list-style: none;
  margin-top: 0;
  padding: 0;
  display: flex;
  gap: 0.375rem;
  align-items: center;
}
.nsw-color-swatches__swatch {
  position: relative;
  display: block;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  cursor: pointer;
}
.nsw-color-swatches__swatch::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border: 1px solid rgba(var(--nsw-brand-dark-rgb), 0.1);
  border-radius: inherit;
  pointer-events: none;
  transition: 0.2s;
}
.nsw-color-swatches__swatch:hover::before {
  border-color: rgba(var(--nsw-brand-dark-rgb), 0.3);
}
.nsw-color-swatches__item {
  margin-top: 0;
}
.nsw-color-swatches__item--selected .nsw-color-swatches__swatch::before {
  border-color: rgba(var(--nsw-brand-dark-rgb), 0.8);
  width: calc(100% + 6px);
  height: calc(100% + 6px);
}
.nsw-color-swatches__data code {
  background: var(--nsw-grey-04);
  border-radius: var(--nsw-border-radius);
  padding: 0.1875rem;
}