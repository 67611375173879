/* stylelint-disable declaration-no-important, selector-no-qualifying-type  */
@media print {
  .nsw-infobar,
  .nsw-header,
  .nsw-footer,
  .nsw-pagination {
    display: none;
  }

  .nsw-accordion__content {
    display: block;
  }

  *,
  *::before,
  *::after {
    background: transparent !important;
    color: var(--nsw-black) !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]::after {
    content: ' (' attr(href) ')';
  }

  abbr[title]::after {
    content: ' (' attr(title) ')';
  }

  a[href^='#']::after,
  a[href^='javascript:']::after {
    content: '';
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid var(--nsw-black);
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}
/* stylelint-enable declaration-no-important, selector-no-qualifying-type  */
